interface IPendoVisitorOptions {
  id: string;
  email: string;
  // eslint-disable-next-line camelcase
  full_name: string;
  // eslint-disable-next-line camelcase
  filevine_user_id?: number;
}

interface IPendoAccountOptions {
  id?: string;
  name?: string;
}

export interface IPendoInitOptions {
  visitor: IPendoVisitorOptions;
  account: IPendoAccountOptions;
}

declare global {
  interface Window {
    pendo: {
      initialize: (options: IPendoInitOptions) => void;
    };
  }
}

const { pendo } = window;

// eslint-disable-next-line import/prefer-default-export
export { pendo };

import React, { FC, useEffect, useState } from 'react';
import Button from '@fv-components/button';
import MaterialIcon from '@fv-components/material-icon';
import TextField, { Input } from '@fv-components/text-field';
import {
  IServiceMethod,
  Interval,
  CalendarType,
} from '@models';
import {
  IntervalDropDown, AltButton, CalendarTypeDropDown, DurationInput,
} from '@components';
import { getGuid } from '@libs/utils';
import { timelyClient } from '@src/App/App';

interface IServiceMethodFormProps {
  serviceMethod: IServiceMethod;
  onDone: (serviceMethod?: IServiceMethod) => void;
}

const ServiceMethodForm: FC<IServiceMethodFormProps> = (
  {
    serviceMethod: sm,
    onDone,
  }: IServiceMethodFormProps,
) => {
  const [serviceMethod, setServiceMethod] = useState<IServiceMethod>({ ...sm });
  const [isDirty, setIsDirty] = useState(false);
  const [isValid, setIsValid] = useState(true);

  useEffect(() => {
    if (!sm.id) {
      getGuid(timelyClient).then(
        (id: string) => setServiceMethod((current: IServiceMethod) => ({
          ...current,
          id,
        })),
      );
    }
  }, [sm]);

  const onDescriptionChange = (description: string) => {
    setIsDirty(true);
    setServiceMethod({
      ...serviceMethod,
      description,
    } as IServiceMethod);
  };

  const onDurationChange = (duration?: number) => {
    setIsDirty(true);
    setServiceMethod({
      ...serviceMethod,
      duration,
    });
  };

  const onIntervalChange = (interval?: Interval) => {
    setIsDirty(true);
    setServiceMethod({ ...serviceMethod, interval });
  };

  const onCalendarChange = (calendar?: CalendarType) => {
    setIsDirty(true);
    setServiceMethod({ ...serviceMethod, calendar });
  };

  useEffect(() => {
    setIsValid(!!serviceMethod?.description
      && !!serviceMethod.id
      && serviceMethod.duration !== undefined
      && !!serviceMethod?.interval
      && !!serviceMethod?.calendar);
  }, [serviceMethod]);

  return (
    <div className="flex flex-col gap-3">
      <b>Edit Service Method</b>
      <TextField label="Description" outlined>
        <Input
          required
          value={serviceMethod?.description}
          onChange={(event: React.FormEvent<HTMLInputElement>) => {
            onDescriptionChange(event.currentTarget.value);
          }}
        />
      </TextField>
      <IntervalDropDown
        onChange={onIntervalChange}
        value={serviceMethod?.interval}
        isRequired
      />
      <CalendarTypeDropDown
        onChange={onCalendarChange}
        value={serviceMethod?.calendar}
        isRequired
      />
      <DurationInput
        isRequired
        value={serviceMethod.duration}
        onChange={onDurationChange}
      />
      <div className="flex flex-row justify-end gap-3">
        <Button
          onClick={() => onDone(serviceMethod)}
          disabled={!isDirty || !isValid}
          role="button"
          aria-label="Done Service Method Editing"
          icon={<MaterialIcon icon="edit" />}
          raised
        >
          Done
        </Button>
        <AltButton
          onClick={() => onDone()}
          aria-label="Cancel Service Method Edit"
          icon={<MaterialIcon icon="cancel" />}
        >
          Cancel
        </AltButton>
      </div>
    </div>
  );
};

export default ServiceMethodForm;

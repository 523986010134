import {
  useLazyQuery, NormalizedCacheObject, ApolloClient, ApolloError, gql,
} from '@apollo/client';
import { getConfig } from '../../../config';

const { googleApiKey } = getConfig();

interface IGoogleCalendarQuery {
  pageToken?: string;
}

export interface IGoogleCalendar {
  id: string;
  summary: string;
  backgroundColor: string;
  timeZone: string;
}

interface IGoogleCalendarListResponse {
  calendars: {
    nextPageToken?: string;
    items: IGoogleCalendar[];
    kind: string;
    nextSyncToken: string;
  }
}

export interface IGoogleQueryHookProps {
  queryObject?: IGoogleCalendarQuery;
  client?: ApolloClient<NormalizedCacheObject>
}

interface IGoogleCalendarQueryHookResult {
  error?: ApolloError;
  loading?: boolean;
  items: IGoogleCalendar[];
  hasMore?: boolean;
  called?: boolean;
  getCalendars: VoidFunction;
}

const useGoogleQueryCalendars = ({
  queryObject,
  client,
} : IGoogleQueryHookProps): IGoogleCalendarQueryHookResult => {
  const CALENDARS_QUERY = gql`
    query calendarsQuery {
      calendars(pageToken: $pageToken, key: $key)
        @rest(type: "Calendars", path: "/calendar/v3/users/me/calendarList?minAccessRole=writer&{args}") {
          items @type(name: "GoogleCalendar") {
            id
            summary
            backgroundColor
            timeZone
          }
          nextPageToken
      }
    }
  `;

  const [fetch, {
    data, error, loading, called,
  }] = useLazyQuery<IGoogleCalendarListResponse>(
    CALENDARS_QUERY, {
      notifyOnNetworkStatusChange: true,
      fetchPolicy: 'cache-and-network',
      client,
      variables: {
        pageToken: queryObject?.pageToken,
        key: googleApiKey,
      },
    },
  );

  return {
    called,
    error,
    loading,
    items: [],
    hasMore: false,
    ...(data?.calendars?.items && {
      items: data.calendars?.items,
      hasMore: !!data.calendars?.nextPageToken,
    }),
    getCalendars: () => fetch(),
  };
};

export default useGoogleQueryCalendars;

import React, { FC, useEffect } from 'react';
import { filevineState } from '@libs/auth';
import { useProxy } from 'valtio';
import { IPendoInitOptions, pendo } from '@libs/pendo';

interface IPendoProps {
  userId?: string;
  userFullName?: string;
  email?: string;
  organizationId?: string;
  organizationName?: string;
}

const initializePendo = (
  userId?: string,
  userFullName?: string,
  email?: string,
  organizationId?: string,
  organizationName?: string,
  filevineUserId?: number,
) => {
  if (userId && email && userFullName) {
    const options: IPendoInitOptions = {
      visitor: {
        id: userId,
        email,
        full_name: userFullName,
        filevine_user_id: filevineUserId,
      },
      account: {
        id: organizationId,
        name: organizationName,
      },
    };
    pendo.initialize(options);
  }
};

const Pendo: FC<IPendoProps> = ({
  userId,
  userFullName,
  email,
  organizationId,
  organizationName,
}: IPendoProps) => {
  const { userId: filevineUserId } = useProxy(filevineState);

  useEffect(() => {
    initializePendo(
      userId,
      userFullName,
      email,
      organizationId,
      organizationName,
      filevineUserId,
    );
  }, [
    filevineUserId,
    email,
    organizationId,
    organizationName,
    userFullName,
    userId,
  ]);

  // yes that's right I'm returing nothing I wanted to get the filevine userId
  // and pendo logic out of the core app component so a change to fv state doesn't cause the
  // main app component to re-render
  return <></>;
};

export default Pendo;

import {
  useMutation, ApolloError, FetchResult, DocumentNode, gql,
} from '@apollo/client';
import IPermissionQuery from '@models/Identity/Role/Permission/IPermissionQuery';
import IPermission, { PermissionAction, PermissionObject } from '@models/Identity/Role/Permission/IPermission';
import { handleSaveResult } from '@libs/apollo';

type PermissionSaveHook = (
  props: IPermissionSaveHookProps
) => IPermissionSaveHookResult;

interface IPermissionSaveHookProps {
  queryObject?: IPermissionQuery;
  queryString?: DocumentNode;
}

export interface IPermissionSaveHookResult {
  result: {
    error?: ApolloError;
    loading: boolean;
    called: boolean;
  };
  savePermission: (
    permissionObject: PermissionObject,
    permissionAction: PermissionAction,
    id?: string,
  ) => Promise<FetchResult<IPermission>>;
}

interface ISavePermissionResult {
  savePermission: IPermission;
}

const usePermissionSave: PermissionSaveHook = (
  { queryString, queryObject }: IPermissionSaveHookProps,
) => {
  const SAVE_GQL = gql`
    mutation PermissionMutation($permission: PermissionInputType!) {
      savePermission(item: $permission) {
         id
      }
    }
  `;

  const [saveFunc, {
    error, loading, called, client,
  }] = useMutation<ISavePermissionResult>(SAVE_GQL);

  return {
    result: {
      error,
      loading,
      called,
    },
    savePermission: (
      permissionObject: PermissionObject,
      permissionAction: PermissionAction,
      id?: string,
    ) => {
      const permission = {
        id,
        permissionAction: permissionAction.toString() as PermissionAction,
        permissionObject: permissionObject.toString() as PermissionObject,
      };

      return saveFunc({
        variables: {
          permission,
        },
      }).then((result: FetchResult<ISavePermissionResult>) => ({
        ...result,
        data: {
          ...handleSaveResult<IPermission>(
            result.data?.savePermission || '',
            permission,
            client,
            'permissions',
            queryObject,
            queryString,
          ),
        },
      }));
    },
  };
};

export default usePermissionSave;

import React, {
  FC, useState, useRef, useEffect,
} from 'react';
import MaterialIcon from '@fv-components/material-icon';
import MenuSurface, { Corner } from '@fv-components/menu-surface';
import TextField, { Input } from '@fv-components/text-field';
import IconButton from '@fv-components/icon-button';

interface IFilterProps {
  filter?: any;
  onFilter: (value: any) => void;
  disabled?: boolean;
}

const Filter: FC<IFilterProps> = ({
  filter: f,
  onFilter,
  disabled,
}: IFilterProps) => {
  const anchor = useRef() as React.MutableRefObject<HTMLInputElement>;
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [filter, setFilter] = useState<any>(f);
  const [inputValue, setInputValue] = useState<any>(f);

  const onFilterChange = (event: React.FormEvent<HTMLInputElement>) => {
    setInputValue(event.currentTarget.value);
  };

  const closeMenu = () => {
    setInputValue(undefined);
    setIsMenuOpen(false);
  };

  const onFilterSet = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
    event.preventDefault();
    setFilter(inputValue);
    onFilter(inputValue);
    closeMenu();
  };

  const onClearFilter = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
    event.preventDefault();
    setFilter(undefined);
    onFilter(undefined);
    closeMenu();
  };

  const onKeyUp = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.keyCode === 13) {
      setFilter(inputValue);
      onFilter(inputValue);
      closeMenu();
    }
  };

  // adding this here to listen for a clearing of the filter from outside of the control
  useEffect(() => {
    if (!f && filter) {
      setFilter(undefined);
      setIsMenuOpen(false);
    }
    // if you add filter to the useEffect deps array it will fire when changing the filter
    // internally and we don't want that
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [f]);

  return (
    <>
      <button
        onClick={() => {
          setInputValue(filter);
          setIsMenuOpen(true);
        }}
        disabled={disabled}
        aria-haspopup="true"
        aria-expanded={isMenuOpen ? 'true' : 'false'}
        aria-controls="column-filter-menu"
        type="button"
        aria-label="Toggle Filter For Column"
        className={`border-0 bg-transparent cursor-pointer ${filter ? 'text-blue' : 'text-gray-mild hover:text-gray'}`}
        data-cy="column-filter-menu"
      >
        <MaterialIcon icon="filter_list" />
      </button>
      <MenuSurface
        open={isMenuOpen}
        anchorCorner={Corner.BOTTOM_LEFT}
        anchorElement={anchor.current}
        onClick={(event) => event.stopPropagation()}
        onClose={() => closeMenu()}
        disabled={disabled}
        id="column-filter-menu"
      >
        <div className="w-64 flex flex-row items-center p-2">
          <TextField label="Filter" outlined>
            <Input
              value={inputValue}
              onChange={onFilterChange}
              onKeyUp={onKeyUp}
              disabled={disabled}
              data-cy="filter-input"
            />
          </TextField>
          <IconButton
            disabled={disabled}
            onClick={onClearFilter}
            role="button"
            aria-label="Clear Filter"
            data-cy="filter-clear"
          >
            <MaterialIcon
              icon="clear"
            />
          </IconButton>
          <IconButton
            disabled={disabled}
            role="button"
            onClick={onFilterSet}
            aria-label="Set Filter"
            data-cy="filter-set"
          >
            <MaterialIcon
              icon="check"
            />
          </IconButton>
        </div>
      </MenuSurface>
      <div ref={anchor} />
    </>
  );
};

export default Filter;

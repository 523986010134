import React, { FC } from 'react';
import Pill from './Pill/Pill';

const css = require('./PillBox.module.scss');

interface IPillBoxProps {
  children?: JSX.Element[];
  reverse?: boolean;
}

const PillBox: FC<IPillBoxProps> = ({ children, reverse }) => (
  <div className={`${css.row} ${!!reverse && css.rightToLeft}`}>
    {children}
  </div>
);

export { Pill };

export default PillBox;

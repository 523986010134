/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { FC, useState } from 'react';
import Switch from '@fv-components/switch';
import UserForm from '@components/UserForm/UserForm';
import { IUser, IOrganization } from '@models';
import { OrganizationAutoComplete } from '@components';

interface IUserAdminFormProps {
  user: IUser;
  onFormChange: (user: IUser, isDirty: boolean, isValid: boolean) => void;
}

interface IUserAdminFormState {
  isUserValid?: boolean;
  isOrganizationValid?: boolean;
}

const UserAdminForm: FC<IUserAdminFormProps> = ({
  user,
  onFormChange,
}: IUserAdminFormProps) => {
  const [state, setState] = useState<IUserAdminFormState>({
    isUserValid: true,
    isOrganizationValid: true,
  });

  const onUserFormChanged = (updatedUser?: IUser, isUserValid?: boolean) => {
    setState({
      ...state,
      isUserValid,
    });

    onFormChange(
      { ...updatedUser },
      true,
      !!(isUserValid && state.isOrganizationValid),
    );
  };

  const onIsActiveChange = () => {
    onFormChange(
      {
        ...user,
        isActive: !user.isActive,
      },
      true,
      !!(state.isUserValid && state.isOrganizationValid),
    );
  };

  const onOrganizationPickerUpdate = (
    organization?: IOrganization,
  ) => {
    const isOrganizationValid = !!organization;
    setState({ ...state, isOrganizationValid });
    onFormChange(
      {
        ...user,
        organization,
        organizationId: organization?.id,
      },
      true,
      !!(state.isUserValid && isOrganizationValid),
    );
  };

  return (
    <div className="flex flex-col gap-3">
      <b className="text-2xl flex-1">
        Edit User
      </b>
      <UserForm onUserChange={onUserFormChanged} user={user} />
      <OrganizationAutoComplete onSelect={onOrganizationPickerUpdate} value={user.organization} />
      <div className="flex flex-row justify-end">
        <div>
          <Switch
            nativeControlId="isActiveSwitch"
            checked={user.isActive}
            onChange={onIsActiveChange}
          />
          <label className="ml-3" htmlFor="isActiveSwitch">Is Active</label>
        </div>
      </div>
    </div>
  );
};

export default UserAdminForm;

import { useLocation } from 'react-router-dom';

const useRouterState = <T extends unknown>() : T => {
  const location = useLocation<{ state: T }>();
  window.history.replaceState({}, document.title);

  return location?.state?.state || {} as T;
};

export default useRouterState;

import {
  useState,
  useEffect,
  useCallback,
  useRef,
} from 'react';
import { Input } from '@fv-components/text-field';

interface IHookResult {
  x: number;
  y: number;
}

type Hook = (
  anchor?: React.MutableRefObject<Input | HTMLElement | null>,
) => IHookResult | undefined;

const useMenuSurfaceCoordinates: Hook = (
  anchor?:
    React.MutableRefObject<Input | HTMLElement | null>,
): IHookResult | undefined => {
  const [coords, setCoords] = useState<{ x: number, y: number }>();

  const setNewCoordinates = useCallback(() => {
    if (anchor?.current instanceof Input) {
      const anchorRectangle = anchor?.current?.inputElement?.getBoundingClientRect();
      if (anchorRectangle) {
        setCoords({ x: anchorRectangle.x, y: anchorRectangle.y + anchorRectangle.height });
      }
    } else if (anchor?.current instanceof HTMLElement) {
      const anchorRectangle = anchor?.current?.getBoundingClientRect();
      if (anchorRectangle) {
        setCoords({ x: anchorRectangle.x, y: anchorRectangle.y + anchorRectangle.height });
      }
    }
  }, [anchor]);

  const timeout = useRef<NodeJS.Timeout>();

  const onResize = useCallback(() => {
    if (timeout.current) {
      clearTimeout(timeout.current);
    }
    timeout.current = setTimeout(setNewCoordinates, 100);
  }, [setNewCoordinates]);

  useEffect(() => {
    window.addEventListener('resize', onResize, false);
    // react warned me that i should do this so that the cleanup function returned will
    // have a reference to the element where the listeners were added and not try to use
    // the current anchor.current which may be new
    const current = anchor?.current;

    // adding these additional handlers becuase the
    // hook wasn't updating when the input ref was inside
    // of a modal and was previsouly at coordinates x: 0, y: 0
    if (current) {
      if (current instanceof Input && current.inputElement) {
        current.inputElement.addEventListener('focus', setNewCoordinates, false);
      } else if (current instanceof HTMLElement) {
        current.addEventListener('focus', setNewCoordinates, false);
        current.addEventListener('click', setNewCoordinates, false);
        current.addEventListener('keyup', setNewCoordinates, false);
      }
    }

    return () => {
      window.removeEventListener('resize', onResize);
      if (current) {
        if (current instanceof Input && current.inputElement) {
          current.inputElement.removeEventListener('focus', setNewCoordinates);
        } else if (current instanceof HTMLElement) {
          current.removeEventListener('focus', setNewCoordinates);
          current.removeEventListener('click', setNewCoordinates);
          current.removeEventListener('keyup', setNewCoordinates);
        }
      }
    };
  }, [anchor, onResize, setNewCoordinates]);

  useEffect(() => {
    setNewCoordinates();
  }, [setNewCoordinates]);

  return coords;
};

export default useMenuSurfaceCoordinates;

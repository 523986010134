import {
  ICalculationState,
  ITriggerCalculationState,
  IDeadlineCalculationState,
  IServiceMethodCalculationState,
} from '@src/App/Calculator/Calculator';
import produce from 'immer';
import { getConfig } from '@src/config';
import dayjs from 'dayjs';
import { IJurisdiction } from '@models';

const generateDescription = (
  triggerCalculationState: ITriggerCalculationState,
  deadlineCalculationState: IDeadlineCalculationState,
  jurisdiction: IJurisdiction,
  matter?: string,
  smcs?: IServiceMethodCalculationState,
  historyId?: string,
): string => {
  let description = smcs ? `Service via ${smcs.serviceMethod.description} ` : '';
  description += `${deadlineCalculationState.deadline.description}\n`;
  description += `Reference: ${deadlineCalculationState.deadline.specReference || ''}\n`;
  description += `Jurisdiction: ${jurisdiction.path ? `${jurisdiction.path} > ` : ''}${jurisdiction.description}\n`;
  description += `Trigger: ${triggerCalculationState.trigger?.description} `;
  description += triggerCalculationState.triggerLabel ? `- ${triggerCalculationState.triggerLabel} ` : '';
  description += `on ${dayjs(triggerCalculationState.triggerDate).format('YYYY-MM-DD')}\n`;
  description += matter ? `Project: ${matter}` : '';
  description += historyId ? `\n\nModify in Timely at: ${getConfig().hostUrl}/calculator/deadlines/${historyId}` : '';
  return description;
};

const filterForExport = (
  calculationState: ICalculationState,
): ITriggerCalculationState[] => calculationState.triggers
?.filter((tcs: ITriggerCalculationState) => !tcs.toBeRemoved)
.map(
  (tcs: ITriggerCalculationState) => ({
    ...tcs,
    deadlines: tcs.deadlines?.filter((dcs: IDeadlineCalculationState) => dcs.isSelected)
      .map(
        (dcs: IDeadlineCalculationState) => produce(dcs, (draft) => {
          if (draft) {
            const filteredServiceMethods = draft.serviceMethods?.filter(
            (smcs: IServiceMethodCalculationState) => smcs.isSelected && !smcs.toBeRemoved);
            // perfectly reasonable behavior per immer docs
            // https://immerjs.github.io/immer/docs/introduction
            // eslint-disable-next-line no-param-reassign
            draft.serviceMethods = filteredServiceMethods?.length
              ? filteredServiceMethods
              : undefined;
          }
        }),
      ) || [],
  }),
) || [];

export { generateDescription, filterForExport };

import React, {
  FC, useState, useEffect,
} from 'react';
import Table, { ITableState } from '@components/Table/Table';
import useServiceMethodGroupQuery, { IServiceMethodGroupQueryHookProps } from '@hooks/ServiceMethodGroup/useServiceMethodGroupQuery';
import { IJurisdiction, IServiceMethodGroup } from '@models';

interface IProps {
  jurisdiction?: IJurisdiction;
  onRowSelect?: (group: IServiceMethodGroup, isSelected: boolean) => void;
  onSelectAll: (isSelected: boolean) => void;
}

const InheritedServiceMethodGroup: FC<IProps> = ({
  jurisdiction,
  onRowSelect,
  onSelectAll,
}) => {
  const fields = [
    'id',
    'description',
    'jurisdiction { address hasChildren description id }',
    'jurisdictionId',
  ];

  const [requestObject, setRequestObject] = useState<IServiceMethodGroupQueryHookProps>({
    queryObject: { skip: 0, take: 10, includeInherited: true },
    fields,
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    setRequestObject((ro: IServiceMethodGroupQueryHookProps) => ({
      ...ro,
      queryObject: {
        ...ro.queryObject,
        jurisdictionId: jurisdiction?.parent?.id,
      },
    }));
  }, [jurisdiction]);

  const apolloWrapper = useServiceMethodGroupQuery(
    requestObject,
  );

  const tableStateChanged = (state: ITableState) => {
    setRequestObject((current: IServiceMethodGroupQueryHookProps) => ({
      ...current,
      queryObject: {
        ...state,
        ...state.filter,
        jurisdictionId: jurisdiction?.parent?.id,
        filter: undefined,
      },
    }));
  };

  const selected = apolloWrapper.result.items
    .filter((sm: IServiceMethodGroup) => !jurisdiction?.serviceMethodGroupInheritanceBlocks?.find(
      (block: string) => sm.id === block)) || [];

  return (
    <Table
      dataSource={jurisdiction?.parent?.id
        && !apolloWrapper.loading ? apolloWrapper.result.items : []}
      minHeight={350}
      maxHeight={350}
      onStateChange={tableStateChanged}
      hasMore={apolloWrapper.result.hasMore}
      takeOptions={[10, 25]}
      isMultiSelect
      onRowSelect={onRowSelect}
      isPagerVisible
      loading={!!jurisdiction?.parent?.id && apolloWrapper.loading}
      selected={selected}
      onSelectAll={onSelectAll}
    >
      {[
        {
          key: 'description',
          header: 'Description',
          field: 'description',
          isFilterable: true,
          isSortable: true,
        },
        {
          key: 'jurisdiction.description',
          header: 'Jurisdiction',
          field: 'jurisdiction.description',
        },
      ]}
    </Table>
  );
};

export default InheritedServiceMethodGroup;

import React, { FC } from 'react';
import Select, { Option } from '@fv-components/select';
import { PermissionObject } from '@models/Identity/Role/Permission/IPermission';

interface IPermissionObjectDropDownProps {
  onChange: (object?: PermissionObject) => void;
  value?: PermissionObject;
  label?: string;
}

const PermissionObjectDropDown: FC<IPermissionObjectDropDownProps> = (
  { onChange, value, label }: IPermissionObjectDropDownProps,
) => {
  const onSelect = (evt: React.FormEvent<HTMLSelectElement>) => {
    if (evt.currentTarget.value === '') {
      onChange(undefined);
    } else {
      onChange(evt.currentTarget.value as PermissionObject);
    }
  };

  return (
    <Select
      outlined
      label={label || 'Object'}
      onChange={onSelect}
      value={value || ''}
    >
      <>
        <Option key="NONE" value="" />
        {Object.values(PermissionObject).map((j) => (
          <Option key={j} value={j}>
            {j}
          </Option>
        ))}
      </>
    </Select>
  );
};

export default PermissionObjectDropDown;

import React, { FC } from 'react';
import { IJurisdiction, IServiceMethod, IServiceMethodGroup } from '@models';
import InheritedServiceMethodGroupTable from './InheritedServiceMethodGroupTable';
import InheritedServiceMethodTable from './InheritedServiceMethodTable';

interface IServiceMethodCombinedViewProps {
  jurisdiction?: IJurisdiction;
  onServiceMethodRowChange: (serviceMethod: IServiceMethod, isSelected: boolean) => void;
  onServiceMethodGroupRowChange: (group: IServiceMethodGroup, isSelected: boolean) => void;
  onServiceMethodSelectAll: (isSelected: boolean) => void;
  onServiceMethodGroupSelectAll: (isSelected: boolean) => void;
}

const ServiceMethodCombinedView: FC<IServiceMethodCombinedViewProps> = (
  {
    jurisdiction,
    onServiceMethodRowChange,
    onServiceMethodGroupRowChange,
    onServiceMethodSelectAll,
    onServiceMethodGroupSelectAll,
  },
) => (
  <div className={`grid ${jurisdiction?.parent?.id ? 'grid-cols-1 md:grid-cols-2' : 'grid-cols-1'}  gap-3`}>
    {jurisdiction?.parent?.id ? (
      <>
        <div className="flex flex-col gap-3">
          <b>Inherited Service Method Groups</b>
          <InheritedServiceMethodGroupTable
            jurisdiction={jurisdiction}
            onRowSelect={onServiceMethodGroupRowChange}
            onSelectAll={onServiceMethodGroupSelectAll}
          />
        </div>
        <div className="flex flex-col gap-3">
          <b>Inherited Service Methods</b>
          <InheritedServiceMethodTable
            jurisdiction={jurisdiction}
            onRowSelect={onServiceMethodRowChange}
            onSelectAll={onServiceMethodSelectAll}
          />
        </div>
      </>
) : (
  <div className="flex flex-row justify-center">
    <div>No inherited service methods or groups</div>
  </div>
)}
  </div>
);
export default ServiceMethodCombinedView;

import React, {
  FC, useEffect, useState, useCallback,
} from 'react';
import { proxy, snapshot } from 'valtio';
import MatterModal from '@src/App/Calculator/Deadlines/ExportControls/MatterModal';

interface ICustomDialogProps {
  onClose?: (event: string, result?: any) => void;
  isVisible?: boolean;
}

type Modal = 'matter';

const modals: { [key: string]: FC<ICustomDialogProps> } = {
  matter: MatterModal,
};

interface ICustomDialogHookResult {
  CustomDialog: FC<ICustomDialogProps>;
}

interface ICustomDialogService {
  isInitialized: boolean;
  showDialog: <T>(
    dialog: Modal,
    dialogProps?: any,
  ) => Promise<{ event: string, result?: T }>;
}

const customDialogService = proxy<ICustomDialogService>(
  {
    isInitialized: false,
    showDialog: () => { throw new Error('service has not been initialized'); },
  },
);

const getCustomDialogService = () => snapshot(customDialogService);

const useCustomDialog: () => ICustomDialogHookResult = () => {
  const [dialog, setDialog] = useState<string>();
  const [customDialogProps, setCustomDialogProps] = useState<ICustomDialogProps>();

  const showDialog = useCallback(<T extends unknown>(
    dia: Modal,
    dialogProps?: any,
  ) => new Promise < { event: string, result?: T }>((resolve) => {
    setDialog(dia);
    setCustomDialogProps({
      ...dialogProps,
      isVisible: true,
      onClose: (event: string, result?: T) => {
        setCustomDialogProps(
          (currentProps?: ICustomDialogProps) => ({ ...currentProps, isVisible: false }),
        );
        resolve({ event, result });
      },
    });
  }), []);

  // I only want this to run once
  useEffect(() => {
    customDialogService.showDialog = showDialog;
    customDialogService.isInitialized = true;
  }, [showDialog]);

  return {
    CustomDialog: () => {
      if (dialog && customDialogProps) {
        return React.createElement(modals[dialog], customDialogProps);
      }
      return React.createElement('div');
    },
  };
};

export { getCustomDialogService };
export default useCustomDialog;

import {
  useLazyQuery, ApolloError, DocumentNode, ApolloClient, gql, FetchPolicy, NormalizedCacheObject,
} from '@apollo/client';
import { useEffect, useState } from 'react';

type QueryHook = <T extends unknown>(
  props: IQueryHookProps
) => IQueryHookResult<T>;

export interface IQueryHookProps {
  variables: any;
  fields?: string[];
  lazy?: boolean;
  collection: string;
  notifyOnNetworkStatusChange?: boolean,
  fetchPolicy?: FetchPolicy,
  client?: ApolloClient<NormalizedCacheObject>,
  queryInputType?: string,
  queryString?: DocumentNode,
}

export interface IQueryResponse<T> {
  [key: string]: { hasMore: boolean; items: T[] };
}

interface IQueryHookResult<T> {
  loading: boolean;
  called: boolean;
  error?: ApolloError;
  result: { hasMore: boolean; items: T[] };
  load: VoidFunction;
  queryString: DocumentNode;
  refetch: VoidFunction | undefined;
}

export const getGql = (fields: string[], queryInputType: string, collection: string) : DocumentNode => gql`
    query($queryObject: ${queryInputType}!) {
      ${collection}(
        queryObject: $queryObject
      ) {
          hasMore
          items {
            ${fields.join(',')}
          }
      }
    }
  `;

const useTimelyQuery: QueryHook = <T extends unknown>(
  {
    variables,
    lazy,
    collection,
    notifyOnNetworkStatusChange,
    fetchPolicy,
    client,
    fields,
    queryInputType,
    queryString: qs,
  }: IQueryHookProps,
) => {
  if (!(fields && queryInputType && collection) && !qs) {
    throw Error(`useTimelyQuery requires either fields[], collection, and queryInputType OR a queryString  ${collection}`);
  }
  const [result, setResult] = useState<
    { hasMore: boolean; items: T[] }
  >({ hasMore: false, items: [] });
  const [queryString, setQueryString] = useState<DocumentNode>(
    fields && queryInputType && collection ? getGql(fields,
      queryInputType,
      collection) : qs,
  );

  useEffect(() => {
    if (fields && queryInputType && collection) {
      setQueryString(getGql(fields, queryInputType, collection));
    }
  }, [queryInputType, fields, collection]);

  useEffect(() => {
    if (qs) {
      setQueryString(qs);
    }
  }, [qs]);

  const [load, {
    loading, error, data, called, refetch,
  }] = useLazyQuery<IQueryResponse<T>>(queryString, {
    variables,
    notifyOnNetworkStatusChange,
    fetchPolicy,
    client,
  });

  useEffect(() => {
    if (!lazy && load && !called) {
      load();
    }
  }, [called, lazy, load]);

  useEffect(() => {
    if (data?.[collection]) {
      setResult(data[collection]);
    }
  }, [collection, data]);

  return {
    called,
    loading,
    error,
    result,
    load,
    queryString,
    refetch,
  };
};

export default useTimelyQuery;

import React, {
  FC, useState, useRef, useCallback,
} from 'react';
import TextField, { Input } from '@fv-components/text-field';
import MaterialIcon from '@fv-components/material-icon';
import ICategory from '@models/Deadlines/Categories/ICategory';
import ITrigger from '@models/Deadlines/Triggers/ITrigger';
import useCategoryQuery from '@hooks/Categories/useCategoryQuery';
import useLookupInput, { ISearchState } from '@hooks/useLookupInput';
import { InputLinearProgress, LookupMenu } from '@components/index';

interface IKeywordAutoCompleteProps {
  onSelect: (keyword?: ICategory) => void;
}

const KeywordAutoComplete: FC<IKeywordAutoCompleteProps> = (
  {
    onSelect,
  }: IKeywordAutoCompleteProps,
) => {
  const [isKeywordSelected, setIsKeywordSelected] = useState<boolean>();
  const [showMenu, setShowMenu] = useState(false);
  const inputRef = useRef<any | null>(null);
  const [inputValue, setInputValue] = useState<string>();
  const [description, setDescription] = useState<string>();

  const {
    items, loading, load, called,
  } = useCategoryQuery({
    queryObject: {
      description,
      skip: 0,
      take: 5,
    },
    fields: [
      'id',
      'description',
    ],
    lazy: true,
  });

  const onSearchUpdate = (searchState?: ISearchState) => {
    if (!called) {
      load();
    }
    setShowMenu(true);
    setIsKeywordSelected(false);
    setDescription(searchState?.searchTerm);
    onSelect(undefined);
  };

  const {
    inputOnChangeHandler,
  } = useLookupInput({
    debounce: 500,
    inputRefCurrent: inputRef?.current,
    callback: onSearchUpdate,
    setInputValue,
  });

  const onLookupSelect = useCallback((trigger: ITrigger) => {
    setInputValue(trigger.description);
    onSelect(trigger);
    setShowMenu(false);
    setIsKeywordSelected(true);
  }, [onSelect]);

  const onMenuClosed = useCallback(() => {
    setShowMenu(false);
  }, []);

  const onFocus = () => {
    if (!isKeywordSelected) {
      if (!called) {
        load();
      }
      setShowMenu(true);
    }
  };

  // this is required because clicking into the input field
  // would randomly close the lookup menu for some reason i can't determine
  const onClick = (event: React.MouseEvent<HTMLInputElement, MouseEvent>) => {
    event.stopPropagation();
  };

  return (
    <div className="flex flex-col flex-1">
      <div>
        <TextField
          label="Keywords"
          className="w-full"
          outlined
          trailingIcon={isKeywordSelected ? <MaterialIcon icon="done" /> : undefined}
        >
          <Input
            value={inputValue}
            onChange={inputOnChangeHandler}
            ref={inputRef}
            onFocus={onFocus}
            onClick={onClick}
            data-cy="trigger"
          />
        </TextField>
        {loading && <InputLinearProgress />}
        <LookupMenu<ITrigger>
          isOpen={showMenu && !!items?.length}
          onMenuItemSelected={onLookupSelect}
          descriptionField="description"
          keyField="id"
          onMenuClosed={onMenuClosed}
          inputRef={inputRef}
          items={items}
        />
      </div>
    </div>
  );
};

export default KeywordAutoComplete;

/* eslint-disable jsx-a11y/label-has-associated-control */
import React, {
  FC, useEffect, useState, FormEvent, useCallback,
} from 'react';
import { useHistory, useParams } from 'react-router-dom';
import TextField, { Input } from '@fv-components/text-field';
import Switch from '@fv-components/switch';
import { FetchResult } from '@apollo/client';
import LinearProgress from '@fv-components/linear-progress';
import {
  IServiceMethodGroup,
  IServiceMethod,
  IJurisdiction,
  ICategory,
  ITrigger,
  ITriggerDeadline,
  IDeadlineInheritanceBlock,
} from '@models';
import {
  ServiceMethodEditorDropDown, Keywords, AltButton, JurisdictionAutoComplete, IServiceMethodValues,
} from '@components';
import MaterialIcon from '@fv-components/material-icon';
import Button from '@fv-components/button';
import { getSnackBarService } from '@hooks/useSnackBar';
import { useTimelyDelete } from '@hooks/useTimelyDelete';
import { getConfirmationDialogService, discardChangesDialogService } from '@hooks/useConfirmationDialog';
import useTriggerQuery from '@hooks/Trigger/useTriggerQuery';
import RelationshipList, { ITriggerSelection } from './RelationshipList';
import KeywordSearch from '../KeywordSearch';
import DeadlineList from './DeadlineList';
import DeadlineInheritanceTable from './DeadlineInheritanceTable';

interface ITriggerFormProps {
  jurisdiction?: IJurisdiction;
}

const fields: string[] = [
  'id',
  'description',
  'isFilingRequired',
  'parentTriggers { id description jurisdictionId jurisdiction { id description address } triggerDeadlines { id deadlineId deadline { description specReference } trigger { description jurisdiction { description } } } serviceMethods { id } serviceMethodGroups { id } }',
  'jurisdictionId',
  'jurisdiction { address description id parent { description id } deadlineInheritanceBlocks { jurisdictionId, deadlineId }, triggerInheritanceBlocks }',
  'serviceMethods { id, description, jurisdictionId }',
  'serviceMethodGroups { id, description, jurisdictionId serviceMethods { id description jurisdictionId } }',
  'categories { id, description }',
  'childTriggers { id description jurisdictionId jurisdiction { id description address } triggerDeadlines { id deadlineId } serviceMethods { id description } serviceMethodGroups { id description serviceMethods { id description } } }',
  'triggerDeadlines { id triggerId deadlineId deadline { id description shortDescription refLink specReference serviceMethods { id description } categories { id description } } serviceMethods { id description } }',
  'deadlineInheritanceBlocks',
];

interface IToBeRemoved {
  descriptions: string;
  ids: string[];
}

type ToBeRemovedResult = IToBeRemoved | undefined

const checkIsValid = (
  trigger: ITrigger,
  childSelections?: ITriggerSelection[],
  parentSelections?: ITriggerSelection[],
): boolean => !childSelections?.find((t: ITriggerSelection) => !t.trigger?.id)
&& !parentSelections?.find((t: ITriggerSelection) => !t.trigger?.id)
&& !!trigger.jurisdiction?.id
  && !!trigger.description;

const getServiceMethodList = (
  jurisdiction?: IJurisdiction,
  serviceMethods?: IServiceMethod[],
): ToBeRemovedResult => {
  const list = serviceMethods?.filter(
    (sm: IServiceMethod) => sm.jurisdictionId
      && !jurisdiction?.address?.includes(sm.jurisdictionId));

  if (list?.length) {
    return {
      descriptions: list.map((sm: IServiceMethod) => sm.description).join(', '),
      ids: list.map((sm: IServiceMethod) => sm.id || ''),
    };
  }
  return undefined;
};

const getParentRelationshipList = (
  jurisdiction?: IJurisdiction,
  triggers?: ITrigger[],
): ToBeRemovedResult => {
  const list = triggers?.filter(
    (t: ITrigger) => t.jurisdiction?.id && jurisdiction
      && !jurisdiction.address?.includes(t.jurisdiction?.id));

  if (list?.length) {
    return {
      descriptions: list.map((t: ITrigger) => t.description).join(', '),
      ids: list.map((t: ITrigger) => t.id || ''),
    };
  }
  return undefined;
};

const getChildRelationshipList = (
  jurisdiction?: IJurisdiction,
  triggers?: ITrigger[],
): ToBeRemovedResult => {
  const list = triggers?.filter(
    (t: ITrigger) => jurisdiction?.address
      && !t.jurisdiction?.address?.includes(jurisdiction?.address));

  if (list?.length) {
    return {
      descriptions: list.map((t: ITrigger) => t.description).join(', '),
      ids: list.map((t: ITrigger) => t.id || ''),
    };
  }
  return undefined;
};

const remove = <T extends { id?: string }>(
  result: ToBeRemovedResult,
  items?: T[],
): T[] | undefined => (result ? items?.filter((item: T) => !result.ids.find(
    (id: string) => id === item.id,
  )) : items);

const removeFromSelection = (
  result: ToBeRemovedResult,
  items?: ITriggerSelection[],
): ITriggerSelection[] | undefined => (result ? items?.filter(
  (item: ITriggerSelection) => !result.ids.find(
    (id: string) => id === item.trigger?.id,
  )) : items);

const merge = <T extends any>(
  array: T[],
  item: T,
  index: number,
): T[] => array?.map((s: T, i: number) => (i !== index ? s : item));

const deleteFromArray = <T extends any>(
  array: T[],
  index: number,
): T[] => array?.filter((_, i: number) => i !== index);

const getConfirmationText = (
  parentResult: ToBeRemovedResult,
  childResult: ToBeRemovedResult,
  serviceMethodResult: ToBeRemovedResult,
): string => `${serviceMethodResult?.ids.length ? `If you make this change the following service methods will be removed:${serviceMethodResult.descriptions}.  ` : ''}
        ${parentResult?.ids.length ? `If you make this change the following parent relationships will be removed: ${parentResult.descriptions}.  ` : ''}
        ${childResult?.ids.length ? `If you make this change the following children relationships will be removed: ${childResult.descriptions}.  ` : ''}${
  'All deadlines will be removed.  '
}`;

const getCombinedServiceMethodAndGroupIds = (
  triggers: (ITrigger | undefined)[],
) => triggers.reduce(
  (accumulator: string[], t?: ITrigger) => {
    const tsIds = [
      ...(t?.serviceMethodGroups?.map((smg: IServiceMethodGroup) => smg.id || '') || []),
      ...(t?.serviceMethods?.map((smg: IServiceMethodGroup) => smg.id || '') || []),
    ];
    if (!accumulator.length) {
      return tsIds;
    }
    return accumulator.filter((a: string) => !!tsIds.includes(a));
  }, [],
);

const getAllowedMethodsAndGroups = (
  trigger: ITrigger,
  combinedIds: string[],
): {
  allowedMethods?: IServiceMethod[],
  allowedGroups?: IServiceMethodGroup[],
} => ({
  allowedMethods: trigger.serviceMethods?.filter(
    (sm: IServiceMethod) => combinedIds.includes(sm.id || '')),
  allowedGroups: trigger.serviceMethodGroups?.filter(
    (smg: IServiceMethodGroup) => combinedIds.includes(smg.id || '')),
});

// this will return true if there is a service method conflict
// meaning it will return true if there is a service method or group in
// the currently edited trigger that is not present on all parents
const isServiceMethodConflict = (trigger: ITrigger, parents?: ITriggerSelection[]): boolean => {
  const parentsWithServiceMethods = parents?.filter(
    (ts: ITriggerSelection) => !!ts.trigger?.serviceMethods?.length,
  ).map((ts: ITriggerSelection) => ts.trigger);
  if (!parentsWithServiceMethods?.length) {
    return false;
  }
  const combinedIdsFromParents = getCombinedServiceMethodAndGroupIds(parentsWithServiceMethods);
  const combinedIdsFromTrigger = getCombinedServiceMethodAndGroupIds([trigger]);
  return !!combinedIdsFromTrigger.find((smId: string) => !combinedIdsFromParents.includes(smId));
};

const TriggerForm: FC<ITriggerFormProps> = ({
  jurisdiction: j,
}: ITriggerFormProps) => {
  const [serviceMethodsChanged, setServiceMethodsChanged] = useState(false);
  const [trigger, setTrigger] = useState<ITrigger>({ jurisdiction: j, jurisdictionId: j?.id });
  const [isDirty, setIsDirty] = useState<boolean>(false);
  const [isValid, setIsValid] = useState<boolean>(true);
  const { id } = useParams<{ id: string }>();
  const [parentSelections, setParentSelections] = useState<ITriggerSelection[]>();
  const [childSelections, setChildSelections] = useState<ITriggerSelection[]>();
  const [parentDeadlineIds, setParentDeadlineIds] = useState<string[]>();
  const [childDeadlineIds, setChildDeadlineIds] = useState<string[]>();
  const [blockedTriggerIds, setBlockedTriggerIds] = useState<string[]>();
  const [parentServiceMethodIds, setParentServiceMethodIds] = useState<string[]>();
  const snackBar = getSnackBarService();
  const confirmationDialog = getConfirmationDialogService();
  const history = useHistory();
  const { delete: deleteTriggerDeadlines } = useTimelyDelete({ capitalizedType: 'TriggerDeadline' });

  const onDescriptionChange = (event: FormEvent<HTMLInputElement>) => {
    const updated = {
      ...trigger,
      description: event.currentTarget.value,
    };
    setTrigger(updated);
    setIsDirty(true);
    setIsValid(checkIsValid(updated, childSelections, parentSelections));
  };

  const [
    childTriggerDeadlinesToBeDeleted,
    setChildTriggerDeadlinesToBeDeleted,
  ] = useState<ITriggerDeadline[]>();

  const {
    result: { items },
    called,
    load,
    save: { saveTrigger, result: { loading: saveLoading } },
    loading,
  } = useTriggerQuery({
    queryObject: { id, skip: 0, take: 1 },
    fields,
    lazy: true,
  });

  useEffect(() => {
    if (!called && id) {
      load();
    }
  }, [called, id, load]);

  const checkServiceMethodsOfChildren = useCallback(async (
    serviceMethodIds?: string[],
    serviceMethodGroupIds?: string[],
    childTriggers?: ITrigger[],
  ): Promise<boolean> => {
    if (serviceMethodIds?.length || serviceMethodGroupIds?.length) {
      const chidlrenWithExtraServiceMethods = childTriggers?.filter(
        (ct: ITrigger) => {
          const hasExtraGroup = ct.serviceMethodGroups?.find((smg: IServiceMethodGroup) => !serviceMethodGroupIds?.includes(smg.id || ''));
          const hasExtraServiceMethod = ct.serviceMethods?.find(
            (sm: IServiceMethod) => !serviceMethodIds?.includes(sm.id || '')
              && !(ct.serviceMethodGroups
                && ct.serviceMethodGroups.find((smg: IServiceMethodGroup) => smg.serviceMethods?.find((x: IServiceMethod) => serviceMethodIds?.includes(x.id || '')))));
          return hasExtraGroup || hasExtraServiceMethod;
        },
      );

      if (chidlrenWithExtraServiceMethods?.length) {
        const isConfirmed = await confirmationDialog.showDialog(`Due to selections you have made the following child triggers will have their service methods altered.  If you cancel all changes will be lost. ${
          chidlrenWithExtraServiceMethods.map((x: ITrigger) => x.description).join(', ')}`, 'Altered Child Triggers');
        if (!isConfirmed) {
          setIsDirty(false);
          window.location.reload();
        }
        return isConfirmed;
      }
    }
    return new Promise<boolean>((resolve) => resolve(true));
  }, [confirmationDialog]);

  const afterParentsUpdated = useCallback(
    (tri: ITrigger, parents?: ITriggerSelection[]): {
      methods?: IServiceMethod[],
      groups?: IServiceMethodGroup[]
    } | undefined => {
      setParentSelections(parents);
      setParentDeadlineIds(parents?.flatMap((ts: ITriggerSelection) => ts.trigger?.triggerDeadlines?.map((td: ITriggerDeadline) => td.deadlineId || '') || []));
      const parentsWithServiceMethods = parents?.filter(
        (ts: ITriggerSelection) => !!ts.trigger?.serviceMethods?.length,
      ).map((ts: ITriggerSelection) => ts.trigger);
      // should be noted that the service methods on the trigger object
      // includes service methods directly added and servicemethods available
      // through a group
      // adding parents to the if condition for typescript
      if (parentsWithServiceMethods?.length) {
        const combinedIds = getCombinedServiceMethodAndGroupIds(parentsWithServiceMethods);
        setParentServiceMethodIds(combinedIds);

        const { allowedMethods, allowedGroups } = getAllowedMethodsAndGroups(tri, combinedIds);

        setTrigger({
          ...tri,
          serviceMethods: allowedMethods,
          serviceMethodGroups: allowedGroups,
        });
        return { methods: allowedMethods, groups: allowedGroups };
      }
      setParentServiceMethodIds(undefined);
      return undefined;
    }, [],
  );

  const afterChildrenUpdated = useCallback((children?: ITriggerSelection[]) => {
    setChildSelections(children);
    setChildDeadlineIds(children?.flatMap(
      (ts: ITriggerSelection) => ts.trigger?.triggerDeadlines?.map(
        (td: ITriggerDeadline) => td.deadlineId || '',
      ) || [],
    ));
  }, []);

  const afterParentsOrChildrenUpdate = useCallback((
    triggerId?: string,
    parents?: ITriggerSelection[],
    children?: ITriggerSelection[],
  ) => {
    setBlockedTriggerIds([
      triggerId || '',
      ...(parents?.map((x: ITriggerSelection) => x.trigger?.id || '') || []),
      ...(children?.map((x: ITriggerSelection) => x.trigger?.id || '') || []),
    ].filter((x: string) => !!x));
  }, []);

  // the logic here should really only be applied once when loading the trigger from the api call
  useEffect(() => {
    if (!trigger?.id && items.length) {
      setTrigger(items[0]);
      const parents: ITriggerSelection[] | undefined = items[0].parentTriggers?.map(
        (pt: ITrigger) => ({ id: pt.id, trigger: pt }),
      );
      const kids: ITriggerSelection[] | undefined = items[0].childTriggers?.map(
        (ct: ITrigger) => ({ id: ct.id, trigger: ct }),
      );
      afterParentsUpdated(items[0], parents);
      afterChildrenUpdated(kids);
      afterParentsOrChildrenUpdate(items[0].id, parents, kids);
    }
  }, [id, items, afterParentsOrChildrenUpdate, afterChildrenUpdated, afterParentsUpdated, trigger]);

  const onJurisdictionChange = (jurisdiction?: IJurisdiction) => {
    const serviceMethodsToBeRemoved = getServiceMethodList(jurisdiction, trigger?.serviceMethods);
    const parentsToBeRemoved = getParentRelationshipList(jurisdiction, trigger?.parentTriggers);
    const childrenToBeRemoved = getChildRelationshipList(jurisdiction, trigger?.childTriggers);
    if (serviceMethodsToBeRemoved?.ids.length
      || parentsToBeRemoved?.ids.length
      || childrenToBeRemoved?.ids.length
      || (trigger.triggerDeadlines && jurisdiction)
    ) {
      confirmationDialog.showDialog(
        getConfirmationText(parentsToBeRemoved, childrenToBeRemoved, serviceMethodsToBeRemoved),
        'Jurisdiction Change Warning',
        'Make Change',
      ).then((confirmed: boolean) => {
        if (confirmed) {
          const updatedTrigger: ITrigger = {
            ...trigger,
            jurisdiction,
            jurisdictionId: jurisdiction?.id,
            serviceMethods: remove<IServiceMethod>(
              serviceMethodsToBeRemoved,
              trigger?.serviceMethods,
            ),
            triggerDeadlines: [],
          };

          setIsDirty(true);
          setTrigger(updatedTrigger);
          const updatedParents = removeFromSelection(parentsToBeRemoved, parentSelections);
          const updatedChildren = removeFromSelection(childrenToBeRemoved, childSelections);
          afterParentsUpdated(updatedTrigger, updatedParents);
          afterChildrenUpdated(updatedChildren);
          afterParentsOrChildrenUpdate(trigger.id, updatedParents, updatedChildren);
        }
      });
    } else {
      setTrigger({
        ...trigger,
        jurisdiction,
        jurisdictionId: jurisdiction?.id,
      });
    }
  };

  const onIsFilingRequiredSwitchChange = () => {
    setTrigger({
      ...trigger,
      isFilingRequired: !trigger?.isFilingRequired,
    });
    setIsDirty(true);
  };

  const onCategoryPicked = (category: ICategory) => {
    setTrigger({
      ...trigger,
      categories: [...(trigger?.categories || []), category],
    });
    setIsDirty(true);
  };

  const onCategoryRemoved = (category: ICategory) => {
    if (trigger?.categories) {
      setTrigger({
        ...trigger,
        categories: trigger.categories.filter((cat: ICategory) => cat.id !== category.id),
      });
      setIsDirty(true);
    }
  };

  const onServiceMethodChange = (
    value: IServiceMethodValues,
  ) => {
    setIsValid(false);
    setServiceMethodsChanged(true);
    const { serviceMethods, groups: serviceMethodGroups } = value;
    setTrigger({
      ...trigger,
      serviceMethods,
      serviceMethodGroups,
    });
    setIsDirty(true);
  };

  const onServiceMethodClose = async (
    value: IServiceMethodValues,
  ) => {
    if (serviceMethodsChanged) {
      const { serviceMethods, groups: serviceMethodGroups } = value;
      await checkServiceMethodsOfChildren(
        serviceMethods.map((x: IServiceMethod) => x.id || ''),
        serviceMethodGroups.map((x: IServiceMethodGroup) => x.id || ''),
        childSelections?.map((x: ITriggerSelection) => x.trigger || {}),
      );
      setServiceMethodsChanged(false);
      setIsValid(checkIsValid(trigger, childSelections, parentSelections));
    }
  };

  const onSaveClicked = () => {
    const updatedTrigger: ITrigger = {
      ...trigger,
      deadlineInheritanceBlocks: trigger.deadlineInheritanceBlocks?.filter(
        (dhbId: string) => parentSelections?.find(
          (x: ITriggerSelection) => x.trigger?.triggerDeadlines?.find(
            (td: ITriggerDeadline) => td.deadlineId === dhbId))),
      parentTriggers: parentSelections?.map((x: ITriggerSelection) => x.trigger as ITrigger),
      childTriggers: childSelections?.map((x: ITriggerSelection) => x.trigger as ITrigger),
    };

    if (isDirty && isValid && updatedTrigger?.jurisdiction?.id && updatedTrigger.description) {
      setTrigger(updatedTrigger);
      saveTrigger(
        updatedTrigger.jurisdiction.id,
        updatedTrigger.description,
        !!updatedTrigger.isFilingRequired,
        updatedTrigger.id,
        updatedTrigger.parentTriggers,
        updatedTrigger.serviceMethods,
        updatedTrigger.childTriggers,
        updatedTrigger.categories,
        updatedTrigger.serviceMethodGroups,
        updatedTrigger.triggerDeadlines,
        updatedTrigger.deadlineInheritanceBlocks,
      ).then((result: FetchResult<ITrigger>) => {
        snackBar.showSnackBar(`Trigger: ${result.data?.description} has been saved.`);
        if (childTriggerDeadlinesToBeDeleted?.length) {
          const ids = childTriggerDeadlinesToBeDeleted.map((td: ITriggerDeadline) => td.id || '');
          deleteTriggerDeadlines(...ids)
            .then(() => {
              setChildTriggerDeadlinesToBeDeleted(undefined);
              history.push(`/admin/deadlines/triggers/${result.data?.id || ''}`);
            }).catch(() => snackBar.showSnackBar('There was an error deleting the deadline from child triggers.'));
        } else {
          history.push(`/admin/deadlines/triggers/${result.data?.id || ''}`);
        }
      }).catch(() => snackBar.showSnackBar('There was an error saving this trigger.'));
    }
  };

  const onParentAdd = () => {
    setParentSelections((current?: ITriggerSelection[]) => [
      ...(current || []),
      {},
    ]);
    setIsDirty(true);
    setIsValid(false);
  };

  const onChildAdd = () => {
    setChildSelections((current?: ITriggerSelection[]) => [
      ...(current || []),
      {},
    ]);
    setIsDirty(true);
    setIsValid(false);
  };

  const onParentDelete = useCallback((index: number) => {
    const updated = deleteFromArray(parentSelections || [], index);
    afterParentsUpdated(trigger, updated);
    afterParentsOrChildrenUpdate(trigger.id, updated, childSelections);
    setIsValid(checkIsValid(trigger, childSelections, updated));
    setIsDirty(true);
  }, [
    childSelections,
    afterParentsOrChildrenUpdate,
    afterParentsUpdated,
    parentSelections,
    trigger,
  ]);

  const onChildDelete = useCallback((index: number) => {
    const updated = deleteFromArray(childSelections || [], index);
    afterChildrenUpdated(updated);
    afterParentsOrChildrenUpdate(trigger.id, parentSelections, updated);
    setIsValid(checkIsValid(trigger, updated, parentSelections));
    setIsDirty(true);
  }, [
    childSelections,
    afterParentsOrChildrenUpdate,
    afterChildrenUpdated,
    parentSelections,
    trigger,
  ]);

  const makeChangesToParents = useCallback(async (parents: ITriggerSelection[]) => {
    const updatedServiceMethodValue = afterParentsUpdated(trigger, parents);
    afterParentsOrChildrenUpdate(
      trigger.id,
      parents,
      childSelections,
    );
    await checkServiceMethodsOfChildren(
      updatedServiceMethodValue?.methods?.map((x: IServiceMethod) => x.id || ''),
      updatedServiceMethodValue?.groups?.map((x: IServiceMethodGroup) => x.id || ''),
      childSelections?.map((x: ITriggerSelection) => x.trigger || {}),
    );
    setIsValid(checkIsValid(trigger, childSelections, parents));
    setIsDirty(true);
  }, [
    afterParentsOrChildrenUpdate,
    afterParentsUpdated,
    checkServiceMethodsOfChildren,
    childSelections,
    trigger,
  ]);

  const onParentUpdate = useCallback((
    index: number,
    ts: ITriggerSelection,
  ) => {
    const updated = merge(parentSelections || [], ts, index);
    if (ts.trigger?.id) {
      const isConflict = isServiceMethodConflict(trigger, updated);
      if (isConflict) {
        confirmationDialog.showDialog('Selecting this parent trigger will alter the selected service methods for this trigger and potentially its children!  Press OK to proceed.', 'Service Method Conflict', 'OK')
          .then((isConfirmed: boolean) => {
            if (isConfirmed) {
              makeChangesToParents(updated);
            } else {
              makeChangesToParents(
                merge(
                  parentSelections || [],
                  {
                    ...ts,
                    trigger: {
                      jurisdiction: ts.trigger?.jurisdiction,
                      jurisdictionId: ts.trigger?.jurisdiction?.id,
                    },
                  },
                  index,
                ),
              );
            }
          });
      } else {
        makeChangesToParents(updated);
      }
    } else {
      makeChangesToParents(updated);
    }
  }, [confirmationDialog, makeChangesToParents, parentSelections, trigger]);

  const onChildUpdate = useCallback(async (
    index: number,
    ts: ITriggerSelection,
  ) => {
    const updated = merge(childSelections || [], ts, index);
    afterChildrenUpdated(updated);
    afterParentsOrChildrenUpdate(trigger.id, parentSelections, updated);
    await checkServiceMethodsOfChildren(
      trigger.serviceMethods?.map((x: IServiceMethod) => x.id || ''),
      trigger.serviceMethodGroups?.map((x: IServiceMethodGroup) => x.id || '') || trigger?.serviceMethodGroups?.map((x: IServiceMethodGroup) => x.id || ''),
      updated?.map((x: ITriggerSelection) => x.trigger || {}),
    );
    setIsValid(checkIsValid(trigger, updated, parentSelections));
    setIsDirty(true);
  }, [
    childSelections,
    afterChildrenUpdated,
    afterParentsOrChildrenUpdate,
    trigger,
    parentSelections,
    checkServiceMethodsOfChildren,
  ]);

  const onDeadlineUpdate = (index: number, triggerDeadline: ITriggerDeadline) => {
    if (triggerDeadline?.deadlineId) {
      if (childDeadlineIds?.includes(triggerDeadline?.deadlineId)) {
        const matches = trigger.childTriggers?.filter(
          (ct: ITrigger) => ct.triggerDeadlines?.find(
            (td: ITriggerDeadline) => td.deadlineId === triggerDeadline.deadlineId,
          ),
        );
        const matchDesciptions = matches?.map((mt: ITrigger) => mt.description || '');

        confirmationDialog.showDialog(`The deadline you are attempting to add to this trigger is also included in the following child triggers: ${matchDesciptions?.join(', ') || ''
        }.  If you add this deadline it will be removed from the child triggers.  Would you like to continue?`)
          .then((isConfirmed: boolean) => {
            if (isConfirmed) {
              setIsDirty(true);
              setTrigger({
                ...trigger,
                triggerDeadlines: trigger?.triggerDeadlines?.map(
                  (dl: ITriggerDeadline, i: number) => (i !== index ? dl : triggerDeadline)),
              });
              const toBeDeleted = matches?.flatMap(
                (t: ITrigger) => t.triggerDeadlines?.filter(
                  (td: ITriggerDeadline) => td.deadlineId === triggerDeadline.deadlineId) || []);

              if (toBeDeleted) {
                setChildTriggerDeadlinesToBeDeleted(
                  (current?: ITriggerDeadline[]) => [...(current || []), ...toBeDeleted],
                );
              }
            } else {
              const triggerDeadlines = trigger?.triggerDeadlines?.map(
                (dl: ITriggerDeadline, i: number) => (i !== index
                  ? dl : { triggerId: trigger.id, deadline: {} }),
              );
              setTrigger({
                ...trigger,
                triggerDeadlines,
              });
            }
          });
      } else {
        setIsDirty(true);
        setTrigger({
          ...trigger,
          triggerDeadlines: trigger?.triggerDeadlines?.map(
            (dl: ITriggerDeadline, i: number) => (i !== index ? dl : triggerDeadline)),
        });
      }
    }
  };

  const onDeadlineAdd = () => {
    setIsDirty(true);
    setTrigger({
      ...trigger,
      triggerDeadlines: [
        ...(trigger?.triggerDeadlines || []),
        { triggerId: trigger?.id },
      ],
    });
  };

  const onDeadlineDelete = (index: number) => {
    setIsDirty(true);
    const match = trigger?.triggerDeadlines?.find((_, i: number) => i !== index);
    if (match?.deadlineId) {
      setChildTriggerDeadlinesToBeDeleted((current?: ITriggerDeadline[]) => current?.filter(
        (td: ITriggerDeadline) => td.deadlineId === match.deadlineId));
    }
    setTrigger({
      ...trigger,
      triggerDeadlines: trigger?.triggerDeadlines?.filter((_, i: number) => i !== index) || [],
    });
  };

  const onDeadlineBlockChange = (deadlineInheritanceBlocks: string[]) => {
    setIsDirty(true);
    setTrigger((current: ITrigger) => ({
      ...current,
      deadlineInheritanceBlocks,
    }));
  };

  const onCancel = () => {
    if (isDirty) {
      discardChangesDialogService.showDialog(history.goBack);
    } else {
      history.goBack();
    }
  };

  const onBlockAdd = (triggerId: string) => {
    setTrigger((current: ITrigger) => ({
      ...current,
      jurisdiction: {
        ...current.jurisdiction,
        triggerInheritanceBlocks: [
          ...(current.jurisdiction?.triggerInheritanceBlocks || []),
          triggerId,
        ],
      },
    }));
  };

  const onBlockRemove = (triggerId: string) => {
    setTrigger((current: ITrigger) => ({
      ...current,
      jurisdiction: {
        ...current.jurisdiction,
        triggerInheritanceBlocks: [
          ...(current.jurisdiction?.triggerInheritanceBlocks?.filter(
            (tId: string) => tId !== triggerId) || []),
        ],
      },
    }));
  };

  return (
    <>
      <div className="flex justify-between items-center">
        <b className="text-2xl mb-6">{`${trigger?.id ? 'Edit' : 'New'} Trigger`}</b>
      </div>
      <div className="flex flex-col gap-3">
        <TextField className="w-full" label="Name" outlined>
          <Input
            value={trigger?.description}
            onChange={onDescriptionChange}
          />
        </TextField>
        <b>Select Jurisdiction</b>
        <JurisdictionAutoComplete
          onSelect={onJurisdictionChange}
          value={trigger.jurisdiction}
        />
        <b>Select Service Methods</b>
        <ServiceMethodEditorDropDown
          jurisdictionId={trigger?.jurisdiction?.id}
          onSelect={onServiceMethodChange}
          onClose={onServiceMethodClose}
          values={{
            serviceMethods: trigger?.serviceMethods || [],
            groups: trigger?.serviceMethodGroups || [],
          }}
          lazy
          restrictToIds={parentServiceMethodIds}
        />
        <div>
          <Switch
            nativeControlId="isFilingRequiredSwitch"
            checked={trigger?.isFilingRequired}
            onChange={onIsFilingRequiredSwitchChange}
          />
          <label className="ml-10" htmlFor="isFilingRequiredSwitch">Is Filing Required</label>
        </div>
        <b>Keywords</b>
        <div className="gap-0 flex flex-col md:flex-row  md:items-start">
          <KeywordSearch onCategoryPicked={onCategoryPicked} />
          <Keywords
            categories={trigger?.categories}
            onCategoryRemove={onCategoryRemoved}
          />
        </div>
        <RelationshipList
          description="Parent"
          relationships={parentSelections}
          childTriggerJurisdiction={trigger?.jurisdiction}
          onAdd={onParentAdd}
          onUpdate={onParentUpdate}
          onDelete={onParentDelete}
          blockedTriggerIds={blockedTriggerIds}
          onBlockAdd={onBlockAdd}
          onBlockRemove={onBlockRemove}
        />
        <RelationshipList
          description="Child"
          relationships={childSelections}
          onAdd={onChildAdd}
          onDelete={onChildDelete}
          onUpdate={onChildUpdate}
          parentTriggerJurisdiction={trigger?.jurisdiction}
          blockedTriggerIds={blockedTriggerIds}
        />
        <DeadlineList
          triggerId={trigger.id}
          triggerDeadlines={trigger.triggerDeadlines}
          jurisdictionId={trigger?.jurisdiction?.id}
          onAdd={onDeadlineAdd}
          onDelete={onDeadlineDelete}
          onUpdate={onDeadlineUpdate}
          blockedDeadlineIds={parentDeadlineIds}
        />
        <DeadlineInheritanceTable
          triggerDeadlines={parentSelections?.reduce(
            (accumulator: ITriggerDeadline[], next: ITriggerSelection) => [
              ...accumulator,
              ...(next.trigger?.triggerDeadlines || []),
            ], [])}
          onChange={onDeadlineBlockChange}
          jurisdictionDeadlineInheritanceBlocks={
            trigger.jurisdiction?.deadlineInheritanceBlocks?.map(
              (x: IDeadlineInheritanceBlock) => x.deadlineId)
          }
          blockedIds={trigger.deadlineInheritanceBlocks}
        />
        <div className="flex flex-row justify-end gap-3">
          <Button
            onClick={onSaveClicked}
            disabled={!isDirty || !isValid || saveLoading}
            role="button"
            aria-label="Save Trigger"
            data-cy="save-trigger"
            icon={<MaterialIcon icon="save" />}
            raised
          >
            Save
          </Button>
          <AltButton
            onClick={onCancel}
            aria-label="Cancel Trigger Edit"
            icon={<MaterialIcon icon="cancel" />}
            disabled={saveLoading}
          >
            Cancel
          </AltButton>
        </div>
      </div>
      {(saveLoading || loading) && <LinearProgress indeterminate />}
    </>
  );
};

export default TriggerForm;

import React, { FC, useState, useEffect } from 'react';
import { Dayjs } from 'dayjs';
import Header from '../Header/Header';
import Grid from '../Grid/Grid';
import MonthRow from './MonthRow';
import MonthCell from './MonthCell';
import { DisplayMode } from '../DateSelector';

const css = require('./Months.module.css');

interface IMonthsProps {
  onMonthSelected: (date: Dayjs) => void;
  date: Dayjs;
  onClose: VoidFunction;
  isVisible: boolean;
  minDate?: Dayjs;
  maxDate?: Dayjs;
}

const Months: FC<IMonthsProps> = ({
  onMonthSelected,
  date: propDate,
  onClose,
  isVisible,
  minDate,
  maxDate,
}: IMonthsProps) => {
  const [date, setDate] = useState(propDate);

  useEffect(() => {
    setDate(propDate);
  }, [propDate]);

  return (
    <div
      className={[
        isVisible ? 'flex flex-col' : 'hidden',
        css.container,
      ].join(' ')}
    >
      <Header
        buttonText={date.year().toString()}
        isExpanded
        onNextButtonClick={() => setDate(date.add(1, 'year'))}
        onPrevButtonClick={() => setDate(date.add(-1, 'year'))}
        onButtonClick={onClose}
        displayMode={DisplayMode.MONTHS}
      />
      <Grid numberOfRows={3} cellsPerRow={4}>
        <MonthRow>
          <MonthCell
            onMonthSelect={onMonthSelected}
            date={date}
            minDate={minDate}
            maxDate={maxDate}
          />
        </MonthRow>
      </Grid>
    </div>
  );
};

export default Months;

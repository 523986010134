import React, { FC, useState } from 'react';
import Snackbar from '@fv-components/snackbar';
import Button from '@fv-components/button';
import TextField, { Input } from '@fv-components/text-field';
import LinearProgress from '@fv-components/linear-progress';
import { ITableState } from '@components/Table/Table';
import IOrganization from '@models/Identity/Organization/IOrganization';
import useOrganizationQuery, { IOrganizationQueryHookProps } from '@hooks/Identity/Organization/useOrganizationQuery';
import { AltButton, ConfirmationDialog } from '@components';
import { discardChangesDialogService } from '@hooks/useConfirmationDialog';
import MaterialIcon from '@fv-components/material-icon';
import OrganizationTable from './OrganizationTable/OrganizationTable';

interface IOrganizationAdminPageState {
  selectedOrganization?: IOrganization;
  organizationRequestObject: IOrganizationQueryHookProps;
  isEditingMode: boolean;
  isDeleteConfirmationDialogVisible: boolean;
  isValid?: boolean;
  isDirty?: boolean;
  toBeDeleted?: IOrganization[];
}

const OrganizationAdminPage: FC = () => {
  const organizationGraphQLQueryFields = [
    'id',
    'description',
  ];

  const [state, setState] = useState<IOrganizationAdminPageState>({
    isEditingMode: false,
    organizationRequestObject: {
      queryObject: { skip: 0, take: 100 },
      fields: organizationGraphQLQueryFields,
      fetchPolicy: 'network-only',
    },
    isDeleteConfirmationDialogVisible: false,
    isDirty: false,
    isValid: true,
  });

  const organizationApolloWrapper = useOrganizationQuery(state.organizationRequestObject);

  const onDeleteConfirmationClose = (deleteConfirmed: boolean) => {
    if (deleteConfirmed) {
      if (state.toBeDeleted && state.toBeDeleted.length > 0) {
        organizationApolloWrapper.delete.delete(
          ...state.toBeDeleted.filter(
            (x: IOrganization) => x.id,
          ).map((x: IOrganization) => x.id as string),
        );
      }
    }
    setState({
      ...state,
      isDeleteConfirmationDialogVisible: false,
      toBeDeleted: undefined,
    });
  };

  const onFabClick = () => {
    setState({
      ...state,
      selectedOrganization: {},
      isEditingMode: true,
    });
  };

  const organizationTableStateChanged = (tableState: ITableState) => {
    const queryObject = {
      ...tableState,
      ...tableState.filter,
      filter: undefined,
    };
    setState({
      ...state,
      organizationRequestObject: {
        queryObject,
        fields: organizationGraphQLQueryFields,
      },
    });
  };

  const onCancelClick = () => {
    if (state.isDirty) {
      discardChangesDialogService.showDialog(() => {
        setState({
          ...state,
          selectedOrganization: undefined,
          isEditingMode: false,
          isDirty: false,
        });
      });
    } else {
      setState({
        ...state,
        isEditingMode: false,
      });
    }
  };

  const onSaveClicked = () => {
    if (state.selectedOrganization && state.selectedOrganization.description) {
      organizationApolloWrapper.save.saveOrganization(state.selectedOrganization.description,
        state.selectedOrganization.id)
        .then(() => setState((previous: IOrganizationAdminPageState) => ({
          ...previous,
          isEditingMode: false,
          isValid: true,
          isDirty: false,
          selectedOrganization: undefined,
        })));
    }
  };

  const onOrganizationEdit = (organization: IOrganization) => {
    setState({
      ...state,
      selectedOrganization: {
        // doing this so that changes made to the user
        // wont affect the properties of the user in the grid unless saved
        ...organization,
      },
      isEditingMode: true,
      isValid: true,
      isDirty: false,
    });
  };

  const onDescriptionChange = (description: string) => {
    setState({
      ...state,
      selectedOrganization: {
        ...state.selectedOrganization,
        description,
      },
      isDirty: true,
    });
  };

  const onDelete = (toBeDeleted: IOrganization[]) => {
    setState({
      ...state,
      isDeleteConfirmationDialogVisible: true,
      toBeDeleted,
    });
  };

  const saveSuccessful = organizationApolloWrapper.save.result.called
    && !organizationApolloWrapper.save.result.loading
    && !organizationApolloWrapper.save.result.error;

  const saveError = organizationApolloWrapper.save.result.called
    && !organizationApolloWrapper.save.result.loading
    && organizationApolloWrapper.save.result.error;

  const deleteSuccessful = organizationApolloWrapper.delete.result.called
    && !organizationApolloWrapper.delete.result.loading
    && !organizationApolloWrapper.delete.result.error;

  const deleteError = organizationApolloWrapper.delete.result.called
    && !organizationApolloWrapper.delete.result.loading
    && organizationApolloWrapper.delete.result.error;

  return (
    <div className="flex flex-col gap-3">
      <div className={state.isEditingMode ? 'hidden' : 'w-full'}>
        <OrganizationTable
          onTableStateChanged={organizationTableStateChanged}
          onOrganizationEdit={onOrganizationEdit}
          hasMore={organizationApolloWrapper.result.hasMore}
          organizations={organizationApolloWrapper.result.items}
          onFabClick={onFabClick}
          onDelete={onDelete}
          loading={organizationApolloWrapper.loading
              || organizationApolloWrapper.delete.result.loading}
        />

      </div>
      {state.isEditingMode
            && (
            <div className="flex flex-col gap-3">
              <b className="text-2xl flex-1">{state.selectedOrganization?.id ? 'Edit Organization' : 'New Organization'}</b>
              <div className="flex flex-row">
                <TextField label="Name" outlined>
                  <Input
                    value={state.selectedOrganization?.description}
                    onChange={(event: React.FormEvent<HTMLInputElement>) => {
                      onDescriptionChange(event.currentTarget.value);
                    }}
                  />
                </TextField>
              </div>
              {organizationApolloWrapper.save.result.loading
                    && <LinearProgress indeterminate />}
              <div className="flex flex-row justify-end gap-3">
                <Button
                  onClick={onSaveClicked}
                  disabled={!state.isDirty || !state.isValid}
                  role="button"
                  aria-label="Save Organization"
                  icon={<MaterialIcon icon="save" />}
                  raised
                >
                  Save
                </Button>
                <AltButton
                  onClick={onCancelClick}
                  aria-label="Cancel Organization Edit"
                  icon={<MaterialIcon icon="cancel" />}
                >
                  Cancel
                </AltButton>

              </div>
            </div>
            )}
      <ConfirmationDialog
        isOpen={state.isDeleteConfirmationDialogVisible}
        text="Do you really want to delete these organizations?"
        onClose={onDeleteConfirmationClose}
      />
      <Snackbar open={saveSuccessful} message="Save successful." />
      <Snackbar open={!!saveError} message="Error! Unable to save organization." />
      <Snackbar open={deleteSuccessful} message="Delete successful." />
      <Snackbar open={!!deleteError} message="There was an error deleting the organization." />
    </div>
  );
};

export default OrganizationAdminPage;

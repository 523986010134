import React, {
  FC, useState, useEffect, useCallback,
} from 'react';
import { useParams, useHistory, Redirect } from 'react-router-dom';
import { useProjectQuery, getSnackBarService, useTimelyDelete } from '@hooks';
import type { IQueryResponse } from '@hooks';
import { Table, InlineOrgPicker } from '@components';
import { ITableState } from '@components/Table/Table';
import {
  IProjectQuery,
  IProject,
  IHistoryTrigger,
  IHistoryTriggerDeadline,
  IHistoryTriggerDeadlineServiceMethod,
} from '@models';
import Card from '@fv-components/card';
import dayjs from 'dayjs';
import { IRowMenuOption } from '@components/Table/Menu/RowMenu';
import { getConfirmationDialogService } from '@hooks/useConfirmationDialog';
import {
  ApolloClient, gql, NormalizedCacheObject, useMutation,
} from '@apollo/client';
import { filevineState } from '@libs/auth';
import { useProxy } from 'valtio';
import { getVerifiedDeadlines } from '@hooks/Filevine/useFilevineSyncDeadlines';

const HISTORY_TRIGGER_QUERY_GQL = gql`
query($queryObject: HistoryTriggerQueryInputType!) {
      historyTrigger(
        queryObject: $queryObject
      ) {
          hasMore
          items {
            historyId
            historyTriggerDeadlines { filevineDeadlineId historyTriggerDeadlineServiceMethods { filevineDeadlineId } }
          }
      }
    }`;

interface IProps {
  timelyClient: ApolloClient<NormalizedCacheObject>;
  loggedInUserId: string;
}

const getDeadlineIds = async (
  id: string,
  timelyClient: ApolloClient<NormalizedCacheObject>,
): Promise<string[]> => {
  const items: IHistoryTrigger[] = await timelyClient.query<IQueryResponse<IHistoryTrigger>>({
    query: HISTORY_TRIGGER_QUERY_GQL,
    variables: { queryObject: { id, skip: 0, take: 1 } },
  }).then((response) => response.data.historyTrigger.items);
  // we are looping through the history items (should only have one)
  // to find all the filevine deadline ids that need to be deleted
  // maybe I could break this up into smaller functions for each loop
  return items.reduce(
    (htIds: string[], ht: IHistoryTrigger) => [
      ...htIds,
      ...ht.historyTriggerDeadlines.reduce(
        (htdIds: string[], htd: IHistoryTriggerDeadline) => [
          ...htdIds,
          ...(htd.filevineDeadlineId ? [htd.filevineDeadlineId] : []),
          ...htd.historyTriggerDeadlineServiceMethods.reduce(
            (htdsmIds: string[], htdsm: IHistoryTriggerDeadlineServiceMethod) => [
              ...htdsmIds,
              ...(htdsm.filevineDeadlineId ? [htdsm.filevineDeadlineId] : []),
            ], [],
          ),
        ], [],
      ),
    ],
    [],
  );
};

const DELETE_GQL = gql`mutation deleteDeadline($projectId: String!, $deadlineId: String!) {
        deadlines(projectId: $projectId, deadlineId: $deadlineId)
          @rest(
            type: "FilevineDeadline",
            path: "/projects/{args.projectId}/deadlines/{args.deadlineId}",
            method: "DELETE"
            ) {
              noResponse
            }
          items(deadlineId: $deadlineId)
          @rest(
            type: "FilevineDeadline",
            path: "/PartnerIdentifiers/deadline/{args.deadlineId}",
            method: "DELETE"
            ) {
              noResponse
            }
          }
          `;

const Projects: FC<IProps> = ({
  timelyClient,
  loggedInUserId,
}) => {
  const { userId } = useParams();
  const history = useHistory();
  const confirmationDialog = getConfirmationDialogService();
  const snackbar = getSnackBarService();
  const { orgId } = useProxy(filevineState);

  const deleteHistoryTrigger = useTimelyDelete({ capitalizedType: 'HistoryTrigger' });

  const [queryObject, setQueryObject] = useState<IProjectQuery>({
    userId,
    filevineOrgId: orgId ? `${orgId}` : undefined,
    skip: 0,
    take: 25,
  });
  const {
    result: { items, hasMore },
    loading,
    refetch,
  } = useProjectQuery({
    queryObject,
    fetchPolicy: 'network-only',
  });

  const [deleteFunc] = useMutation(DELETE_GQL, { client: filevineState.client });

  useEffect(() => {
    setQueryObject((current: IProjectQuery) => ({
      ...current,
      filevineOrgId: orgId ? `${orgId}` : undefined,
    }));
  }, [orgId]);

  const onTableStateChanged = (tableState: ITableState) => {
    setQueryObject((current: IProjectQuery) => ({
      userId: current.userId,
      filevineOrgId: current.filevineOrgId,
      ...tableState,
      ...tableState.filter,
      filter: undefined,
    }));
  };

  const deleteDeadlinesAndProject = useCallback(async (project: IProject) => {
    const confirmed = await confirmationDialog.showDialog('This will also delete all deadlines from Filevine for this trigger.  Do you wish to proceed?', 'Delete Warning!', 'Delete');
    if (confirmed) {
      try {
        const deadlineIds = await getDeadlineIds(project.id, timelyClient);

        if (deadlineIds && filevineState.client) {
          const verifiedDeadlines: string[] = await getVerifiedDeadlines(
            filevineState.client, project.filevineProjectId,
          );

          const toBeDeleted: string[] = deadlineIds.filter(
            (id: string) => verifiedDeadlines.includes(id),
          );

          try {
            await Promise.all(toBeDeleted.map((deadlineId: string) => deleteFunc(
              {
                variables: {
                  deadlineId,
                  projectId: project.filevineProjectId,
                },
              },
            )));
          } catch {
            snackbar.showSnackBar('Some deadlines may not have been deleted from Filevine.');
          } finally {
            try {
              await deleteHistoryTrigger.delete(project.id);
              snackbar.showSnackBar('Deadlines deleted.');
              if (refetch) {
                refetch();
              }
            } catch {
              snackbar.showSnackBar('There was an error deleting a record from Timely.');
            }
          }
        } else {
          snackbar.showSnackBar('There was an error deleting deadlines. You are not currently signed in to Filevine.');
        }
      } catch {
        snackbar.showSnackBar('There was an error trying to retrieve Filevine deadline IDs for this project.');
      }
    }
  }, [confirmationDialog, deleteFunc, deleteHistoryTrigger, refetch, snackbar, timelyClient]);

  const rowOptions: IRowMenuOption[] = [
    {
      key: 'edit',
      text: 'Edit',
      onClick: (project: IProject) => {
        history.push(`/calculator/deadlines/${project.historyId}`);
      },
    },
    {
      key: 'delete',
      text: 'Delete',
      onClick: deleteDeadlinesAndProject,
    },
  ];

  const onRowClick = (project: IProject) => {
    history.push(`/calculator/deadlines/${project.historyId}`);
  };

  if (userId !== loggedInUserId) {
    return <Redirect to={`/projects/${loggedInUserId}`} />;
  }

  return (
    <div className="container mx-auto flex flex-col gap-3 py-6">
      <Card className="p-6">
        <div className="flex flex-row items-center justify-between">
          <b className="text-2xl flex-1">Projects</b>
          <InlineOrgPicker />
        </div>

        <Table
          dataSource={items}
          minHeight={400}
          maxHeight={700}
          onStateChange={onTableStateChanged}
          hasMore={hasMore}
          takeOptions={[25, 50, 100, 500]}
          isPagerVisible
          loading={loading}
          rowMenuOptions={rowOptions}
          onRowClick={onRowClick}
        >
          {[
            {
              key: 'matterDescription',
              header: 'Matter',
              field: 'matterDescription',
              isFilterable: true,
              isSortable: true,
            },
            {
              key: 'filevineSyncTimestamp',
              header: 'Update Date',
              field: 'filevineSyncTimestamp',
              isSortable: true,
              formatter: (value) => (value !== '0001-01-01T00:00:00' ? dayjs(value).format('YY-MM-DD hh:mm A') : ''),
            },
            {
              key: 'triggerDescription',
              header: 'Trigger',
              field: 'triggerDescription',
              isFilterable: true,
              isSortable: true,
            },
            {
              key: 'serviceMethodDescription',
              header: 'Service Method',
              field: 'serviceMethodDescription',
              isFilterable: true,
              isSortable: true,
            },
            {
              key: 'jurisdictionDescription',
              header: 'Jurisdiction',
              field: 'jurisdictionDescription',
              isFilterable: true,
              isSortable: true,
            },
          ]}
        </Table>
      </Card>

    </div>
  );
};

export default Projects;

import React, { FC, useState, useCallback } from 'react';
import { ITableMenuOption } from '@components/Table/Menu/TableMenu';
import Table from '@components/Table/Table';
import { IRowMenuOption } from '@components/Table/Menu/RowMenu';
import { IServiceMethod, IJurisdiction } from '@models';
import ServiceMethodForm from './ServiceMethodForm';

interface IServiceMethodTableProps {
  serviceMethods?: IServiceMethod[];
  onDelete: (items?: IServiceMethod[]) => void;
  jurisdiction?: IJurisdiction;
  onDoneEditing: (serviceMethod?: IServiceMethod) => void;
  onEditingStart: () => void;
}

const ServiceMethodTable: FC<IServiceMethodTableProps> = ({
  serviceMethods,
  onDelete,
  jurisdiction,
  onDoneEditing,
  onEditingStart,
}) => {
  const [isFormVisible, setIsFormVisible] = useState(false);
  const [serviceMethod, setServiceMethod] = useState<IServiceMethod | undefined>();
  const [selected, setSelected] = useState<IServiceMethod[]>();

  const options: ITableMenuOption[] = [
    {
      key: 'deleteSelected',
      text: 'Delete Selected',
      onClick: () => {
        onDelete(selected);
        setSelected(undefined);
      },
    },
  ];

  const rowOptions: IRowMenuOption[] = [
    {
      key: 'edit',
      text: 'Edit',
      onClick: (item: IServiceMethod) => {
        onEditingStart();
        setIsFormVisible(true);
        setServiceMethod(item);
      },
    },
  ];

  const onAddMethodClick = () => {
    onEditingStart();
    setIsFormVisible(true);
    setServiceMethod({ jurisdictionId: jurisdiction?.id, jurisdiction });
  };

  const onDone = (item?: IServiceMethod) => {
    setIsFormVisible(false);
    onDoneEditing(item);
  };

  const onRowSelect = useCallback(
    (sm: IServiceMethod, isSelected: boolean) => {
      setSelected(
        (current?: IServiceMethod[]) => (isSelected ? [...(current || []), sm]
          : (current?.filter((m: IServiceMethod) => sm.id !== m.id) || [])),
      );
    }, [],
  );

  return (
    <div className="flex-1 flex flex-col gap-3">
      {isFormVisible && serviceMethod ? (
        <ServiceMethodForm
          serviceMethod={serviceMethod}
          onDone={onDone}
        />
      ) : (
        <Table
          dataSource={serviceMethods}
          isMultiSelect
          tableMenuOptions={options}
          rowMenuOptions={rowOptions}
          maxHeight={350}
          takeOptions={[100, 500]}
          onRowSelect={onRowSelect}
          onFabClick={onAddMethodClick}
          fabText="Add Service Method"
          title={<b>Service Methods</b>}
          fabIcon="add"
          isFabVisible
        >
          {[
            {
              key: 'description',
              header: 'Description',
              field: 'description',
            },
            {
              key: 'duration',
              header: 'Duration',
              field: 'duration',
            },
            {
              key: 'interval',
              header: 'Interval',
              field: 'interval',
            },
            {
              key: 'calendar',
              header: 'Calendar',
              field: 'calendar',
            },
          ]}
        </Table>
      )}
    </div>
  );
};

export default ServiceMethodTable;

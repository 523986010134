import { useState, useEffect } from 'react';

interface IUseIpifyHook {
 clientIpAddress?: string
}

interface IClientIpAddress {
  ip: string;
}

const useIpAddress = (): IUseIpifyHook => {
  const [clientIpAddress, setClientIpAddress] = useState<string>();

  // i wrapped it in a use effect hook with an empty
  // array to make sure it doesn't run multiple times
  useEffect(() => {
    const fetchIpAddress = async () => {
      try {
        const response = await fetch('https://api.ipify.org/?format=json', { method: 'get' });
        const data: IClientIpAddress = await response.json();
        setClientIpAddress(data.ip);
      } catch {
        setClientIpAddress('0.0.0.0');
      }
    };
    fetchIpAddress();
  }, []);

  return {
    clientIpAddress,
  };
};

export default useIpAddress;

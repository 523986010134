import React, { FC, useState } from 'react';
import LinearProgress from '@fv-components/linear-progress';
import useInviteQuery, { IInviteQueryHookProps } from '@hooks/Identity/Invite/useInviteQuery';
import { ITableState } from '@components/Table/Table';
import IInvite from '@models/Identity/Invite/IInvite';
import IInviteInput from '@models/Identity/Invite/IInviteInput';
import { CreateInvitesForm, EditInviteForm, InviteTable } from '@components';
import { confirmationDialogService } from '@hooks/useConfirmationDialog';
import dayjs from 'dayjs';
import { snackBarService } from '@hooks/useSnackBar';
import { IDeleteResult } from '@libs/apollo';
import { getIsExpired } from '@libs/utils';
import { IOrganization } from '@models';

interface IInviteAdminPageState {
  selectedInvite?: IInvite;
  inviteRequestObject: IInviteQueryHookProps;
  isEditingMode: boolean;
}

interface IProps {
  organization?: IOrganization;
  onIsEditingChange?: (isEditing: boolean) => void;
}

const InviteAdminPage: FC<IProps> = ({
  organization,
  onIsEditingChange,
}) => {
  const inviteGraphQLQueryFields = [
    'id',
    'emailAddress',
    'expirationDate',
    'organization { id description }',
    'roles { id description }',
  ];

  const [state, setState] = useState<IInviteAdminPageState>({
    inviteRequestObject: {
      queryObject: { skip: 0, take: 100 },
      fields: inviteGraphQLQueryFields,
      fetchPolicy: 'network-only',
    },
    isEditingMode: false,
  });
  const inviteApolloWrapper = useInviteQuery(state.inviteRequestObject);

  const onSaveClicked = (invite: IInvite) => {
    if (invite && invite.emailAddress && invite.id && invite.roles) {
      inviteApolloWrapper.save.saveInvite(
        invite.id,
        invite.emailAddress,
        invite.roles,
        invite.organization,
        invite.expirationDate,
      )
        .then(() => {
          if (onIsEditingChange) {
            onIsEditingChange(false);
          }
          setState({
            ...state,
            isEditingMode: false,
            selectedInvite: undefined,
          });
          snackBarService.showSnackBar('Save successful.');
        }).catch(() => snackBarService.showSnackBar('Error! Unable to save invite.'));
    }
  };

  const onSendClicked = (inviteInput: IInviteInput) => {
    inviteApolloWrapper.send.sendInvites(inviteInput)
      .then(() => {
        snackBarService.showSnackBar('Invite(s) successfully sent.');
        setState({
          ...state,
          isEditingMode: false,
        });
        if (onIsEditingChange) {
          onIsEditingChange(false);
        }
      }).catch(() => snackBarService.showSnackBar('Error! Unable to send the invite(s).'));
  };

  const inviteTableStateChanged = (tableState: ITableState) => {
    const queryObject = {
      ...tableState,
      ...tableState.filter,
      filter: undefined,
    };
    setState({
      ...state,
      inviteRequestObject: {
        queryObject,
        fields: inviteGraphQLQueryFields,
      },
    });
  };

  const onInviteEdit = (invite: IInvite) => {
    if (onIsEditingChange) {
      onIsEditingChange(true);
    }
    setState({
      ...state,
      selectedInvite: invite,
      isEditingMode: true,
    });
  };

  const onCancelClick = () => {
    if (onIsEditingChange) {
      onIsEditingChange(false);
    }
    setState({
      ...state,
      isEditingMode: false,
    });
  };

  const onDelete = async (toBeDeleted?: IInvite[]) => {
    if (toBeDeleted?.length) {
      const confirmed = await confirmationDialogService.showDialog('Do you really want to delete these invites?', 'Delete Confirmation', 'Delete');
      if (confirmed) {
        const ids = toBeDeleted.filter((x: IInvite) => x.id).map((x: IInvite) => x.id as string);
        const deleteResult = await inviteApolloWrapper.delete.delete(...ids);
        if (deleteResult.find((r: IDeleteResult) => !r.deleted)) {
          snackBarService.showSnackBar('Error!  Unable to delete all invite(s).');
        } else {
          snackBarService.showSnackBar('Success deleting invite(s).');
        }
      }
    }
  };

  const onAddClicked = () => {
    if (onIsEditingChange) {
      onIsEditingChange(true);
    }
    setState({
      ...state,
      isEditingMode: true,
      selectedInvite: undefined,
    });
  };

  const onResend = async (invite: IInvite) => {
    if (invite.id && getIsExpired(invite.expirationDate)) {
      try {
        await inviteApolloWrapper.resend.resendInvite(invite.id);
        snackBarService.showSnackBar('Success resending invite.');
      } catch {
        snackBarService.showSnackBar('Error! Unable to resend invite.');
      }
    } else if (!invite.expirationDate || dayjs(invite.expirationDate) <= dayjs()) {
      confirmationDialogService.showDialog('The expiration date needs to be in the future.', undefined, undefined, undefined, true);
    }
  };

  return (
    <div className="flex flex-col gap-3">
      <div className={state.isEditingMode ? 'hidden' : 'w-full'}>
        <InviteTable
          onTableStateChanged={inviteTableStateChanged}
          onInviteEdit={onInviteEdit}
          hasMore={inviteApolloWrapper.result.hasMore}
          invites={inviteApolloWrapper.result.items}
          loading={inviteApolloWrapper.loading}
          onDelete={onDelete}
          onResend={onResend}
          onAddClicked={onAddClicked}
          isOrgColumnVisible={!organization?.id}
        />
      </div>
      {state.isEditingMode
            && (
            <div className="flex flex-col gap-3">
              <div className="flex flex-col md:flex-row gap-3">
                {state.selectedInvite ? (
                  <EditInviteForm
                    invite={state.selectedInvite}
                    onSave={onSaveClicked}
                    onCancel={onCancelClick}
                    isChangeOrganizationAllowed={!organization?.id}
                  />
                ) : (
                  <div className="flex-1">
                    <CreateInvitesForm
                      onSave={onSendClicked}
                      onCancel={onCancelClick}
                      organization={organization}
                    />
                  </div>
                )}
              </div>
              {(inviteApolloWrapper.save.result.loading
            || inviteApolloWrapper.send.result.loading)
            && <LinearProgress indeterminate />}
            </div>
            )}
    </div>
  );
};

export default InviteAdminPage;

/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, {
  FC, useState, useRef, useEffect, Fragment, useCallback,
} from 'react';
import TextField, { Input } from '@fv-components/text-field';
import MaterialIcon from '@fv-components/material-icon';
import { MenuList } from '@fv-components/menu';
import BetterMenuSurface from '@components/BetterMenuSurface';
import InputLinearProgress from '../InputLinearProgress/InputLinearProgress';
import MultiSelectDropDownItem from './MultiSelectDropDownItem';

export type DisplayValueType = string | ((option: any) => string);

export interface IMultiSelectDropDownItem {
  item: any;
  selected: boolean;
}

export interface IItemGroup {
  items: any[];
  header: string;
}

interface IItemGroupWithSelect {
  items: IMultiSelectDropDownItem[];
  header: string;
}

interface IMultiSelectDropDownProps {
  onChange: (selectedItems: any[]) => void;
  display: DisplayValueType;
  items: any[] | IItemGroup[];
  label?: string;
  loading?: boolean;
  autoFocus?: boolean;
  values: any[];
  onFocus?: VoidFunction;
  onClose?: (selectedItems: any[]) => void;
  disabled?: boolean;
  caption?: DisplayValueType;
}

const getValueText = (
  option: any,
  display?: DisplayValueType,
): string => {
  if (typeof display === 'string' && option[display]) {
    return option[display];
  }
  if (typeof display === 'function') {
    return display(option);
  }
  return '';
};

const getDisplayText = (
  display: DisplayValueType,
  values?: any[],
) => {
  if (values?.length === 1) {
    return getValueText(values[0], display);
  }
  if (values && values.length) {
    return `${getValueText(values[0], display)} +${values.length - 1}`;
  }
  return '';
};

const MultiSelectDropDown: FC<IMultiSelectDropDownProps> = (
  {
    onChange,
    items: i,
    display,
    label,
    loading,
    autoFocus,
    values,
    onFocus,
    onClose,
    disabled,
    caption,
  }: IMultiSelectDropDownProps,
) => {
  const [isGrouped, setIsGrouped] = useState<boolean>(!!i.length
    && !i.find((x) => x.header === undefined));
  const [displayText, setDisplayText] = useState<string>();
  const [isDropDownOpen, setIsDropDownOpen] = useState<boolean>(false);
  const [items, setItems] = useState<IMultiSelectDropDownItem[] | IItemGroupWithSelect[]>([]);
  const menuListRef = useRef<HTMLDivElement>(null);
  const pickerAnchor = useRef() as React.MutableRefObject<HTMLInputElement>;

  useEffect(() => {
    setIsGrouped(!!i.length && !i.find((x) => x.header === undefined));
  }, [i]);

  useEffect(() => {
    setDisplayText(getDisplayText(display, values));
  }, [display, values]);

  // the purpose of this is to convert the items and the values into a combined object
  // that will contain the selected values so that we don't have to constantly
  // keep track of the selected index.
  useEffect(() => {
    if (!loading && i.length) {
      if (!isGrouped) {
        const withSelect = (i as any[]).map(
          (x: any) => ({
            item: x,
            selected: !!values?.find((y: any) => x.id === y.id),
          }),
        );
        setItems(withSelect);
      } else {
        const withSelect = (i as IItemGroup[]).map(
          (ig: IItemGroup) => ({
            ...ig,
            items: ig.items?.map(
              (x: any) => ({
                item: x,
                selected: !!values?.find((y: any) => x.id === y.id),
              }),
            ),
          }),
        );
        setItems(withSelect);
      }
    } else if (!loading && !i.length) {
      // if incoming items are empty we need to clear out our local list
      // only makes sense
      setItems([]);
    }
  }, [loading, values, isGrouped, i]);

  const onClick = () => {
    setIsDropDownOpen(!isDropDownOpen);
  };

  const onMenuSurfaceClose = useCallback(() => {
    setIsDropDownOpen(false);
    if (onClose) {
      const flat = (isGrouped ? (items as IItemGroupWithSelect[]).flatMap(
        (x: IItemGroupWithSelect) => x.items,
      ) : (items as IMultiSelectDropDownItem[]))
        .filter((x: IMultiSelectDropDownItem) => x?.selected)
        .map((x: IMultiSelectDropDownItem) => x.item);

      onClose(flat);
    }
  }, [isGrouped, items, onClose]);

  const onItemSelected = (option: IMultiSelectDropDownItem) => {
    const flat = (isGrouped ? (items as IItemGroupWithSelect[]).flatMap(
      (x: IItemGroupWithSelect) => x.items,
    ) : (items as IMultiSelectDropDownItem[]))
      .filter((x: IMultiSelectDropDownItem) => x?.selected)
      .map((x: IMultiSelectDropDownItem) => x.item);

    if (option.selected) {
      onChange([
        ...flat,
        option.item,
      ]);
    } else {
      onChange(flat.filter((x: any) => x.id !== option.item.id));
    }
  };

  const onInputKeyDown = (event: React.KeyboardEvent) => {
    switch (event.key) {
      case 'Enter':
        if (isDropDownOpen) {
          onMenuSurfaceClose();
        } else {
          onClick();
        }
        break;
      default:
        break;
    }
  };

  const onMenuItemKeyDown = (event: React.KeyboardEvent, isTop: boolean) => {
    switch (event.key) {
      case 'Escape':
        onMenuSurfaceClose();
        break;
      case 'ArrowUp':
        if (isTop) {
          onMenuSurfaceClose();
        }
        break;
      case 'Tab':
        onMenuSurfaceClose();
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    if (menuListRef && isDropDownOpen) {
      // yes this set timeout was necessary
      // it wouldn't find the button otherwise
      setTimeout(() => {
        // unfortunately i don't have a good way to get a
        // ref of the button control so i had to put a div inside
        // this button and go through its parents to get a ref to the button
        // so i can focus on it.
        const firstListItemRef = menuListRef.current?.getElementsByTagName('li')[0];
        if (firstListItemRef) {
          firstListItemRef.focus();
        }
      }, 0);
    }
  }, [isDropDownOpen, menuListRef]);

  return (
    <div
      ref={pickerAnchor}
      className="cursor-pointer w-full relative"
      onClick={onClick}
    >
      <BetterMenuSurface
        menuContainerId={`${label?.toLowerCase()}-multiselect-dropdown`}
        isOpen={isDropDownOpen}
        onMenuClosed={onMenuSurfaceClose}
        anchor={(
          <TextField
            label={label}
            outlined
            disabled={disabled}
            className="w-full"
            role="button"
            aria-label={`Toggle ${label} Multiselect dropdown`}
            aria-expanded={isDropDownOpen ? 'true' : 'false'}
            aria-haspopup="true"
            aria-controls={`${label?.toLowerCase()}-multiselect-dropdown`}
            onFocus={onFocus}
            trailingIcon={(
              <MaterialIcon
                className="focus:outline-none"
                role="button"
                icon="keyboard_arrow_down"
              />
            )}
            data-cy="dropdown-field"
          >
            <Input
              className="cursor-pointer pr-10"
              value={displayText}
              autoFocus={autoFocus}
              onKeyDown={onInputKeyDown}
              data-cy="dropdown-input"
              disabled={disabled}
            />
          </TextField>
        )}
      >
        <MenuList>
          {isGrouped ? (items as IItemGroupWithSelect[])
            .filter((g: IItemGroupWithSelect) => !!g.header).map(
              (g: IItemGroupWithSelect, gIndex: number) => (
                <Fragment key={g.header}>
                  <b className="text-sm pl-3">{g.header}</b>
                  {g.items?.map((option: IMultiSelectDropDownItem, oIndex: number) => (
                    <Fragment key={option.item.id}>
                      <MultiSelectDropDownItem
                        onKeyDown={
                          (event: React.KeyboardEvent) => onMenuItemKeyDown(
                            event,
                            !gIndex && !oIndex,
                          )
                        }
                        option={option}
                        display={getValueText(option.item, display)}
                        onItemSelected={onItemSelected}
                      />
                    </Fragment>
                  ))}
                </Fragment>
              )
              ,
            ) : (items as IMultiSelectDropDownItem[]).map(
            (option: IMultiSelectDropDownItem, index: number) => (
              <Fragment key={option.item.id}>
                <MultiSelectDropDownItem
                  onKeyDown={(event: React.KeyboardEvent) => onMenuItemKeyDown(event, !index)}
                  option={option}
                  display={getValueText(option.item, display)}
                  onItemSelected={onItemSelected}
                  caption={getValueText(option.item, caption)}
                />
              </Fragment>
            ),
          )}
        </MenuList>
      </BetterMenuSurface>
      {loading && <InputLinearProgress />}
    </div>
  );
};

export default MultiSelectDropDown;

import React, {
  useState, useEffect, useRef, useCallback,
} from 'react';
import TextField, { Input } from '@fv-components/text-field';
import MaterialIcon from '@fv-components/material-icon';
import useLookupInput, { ISearchState } from '@hooks/useLookupInput';
import { InputLinearProgress, NewLookupMenu } from '@components';

interface IAutoCompleteProps<T> {
  onSelect: (item?: T) => void,
  value?: T;
  isDisabled?: boolean;
  isAutoSelect?: boolean;
  label?: string;
  items?: T[];
  hasMore: boolean;
  load?: VoidFunction;
  called?: boolean;
  loading: boolean;
  onFilterChange: (filter?: string) => void;
  onLoadMore?: VoidFunction;
  cypressLabel: string;
  descriptionField: string;
  captionFields?: string[];
  isSticky?: boolean;
  isRequired?: boolean;
  autoComplete?: string;
  keyField?: string;
  trailingIcon?: React.ReactElement<React.HTMLProps<HTMLOrSVGElement>>;
}

const AutoComplete: <T>(
  props: IAutoCompleteProps<T>,
) => React.ReactElement = <T extends { [key: string]: any }>(
  {
    onSelect,
    value,
    isDisabled,
    isAutoSelect,
    label,
    items,
    hasMore,
    load,
    called,
    loading,
    onFilterChange,
    onLoadMore,
    cypressLabel,
    descriptionField,
    captionFields,
    isRequired,
    autoComplete,
    keyField,
    trailingIcon,
  }: IAutoCompleteProps<T>,
) => {
  const [isItemSelected, setIsItemSelected] = useState<boolean>();
  const [showMenu, setShowMenu] = useState(false);
  const inputRef = useRef<any | null>(null);
  const [inputValue, setInputValue] = useState<string>();
  const [searchString, setSearchString] = useState<string>();

  const onSearchUpdate = (searchState?: ISearchState) => {
    if (!called && load) {
      load();
    }
    setShowMenu(true);
    setIsItemSelected(false);
    onFilterChange(searchState?.searchTerm);
    onSelect(undefined);
    setSearchString(searchState?.searchTerm);
  };

  const {
    inputOnChangeHandler,
  } = useLookupInput({
    debounce: 500,
    inputRefCurrent: inputRef?.current,
    callback: onSearchUpdate,
    setInputValue,
  });

  const onLookupSelect = useCallback((item: T) => {
    if (!loading) {
      // can't because type T is not garanteed any field
      // eslint-disable-next-line dot-notation
      setInputValue(item[descriptionField]);
      onSelect(item);
      setShowMenu(false);
      setIsItemSelected(true);
    }
  }, [descriptionField, loading, onSelect]);

  useEffect(() => {
    if (value) {
      setIsItemSelected(true);
      // can't because type T is not garanteed any field
      // eslint-disable-next-line dot-notation
      setInputValue(value[descriptionField]);
    } else {
      // added to listen to value changes from outside and clear
      // isSelected if component controlling the value clears it
      setIsItemSelected(false);
    }
  }, [descriptionField, value]);

  useEffect(() => {
    if (!loading
      && called
      && isAutoSelect
      && !isItemSelected
      && items?.length === 1
    ) {
      onLookupSelect(items[0]);
    }
  }, [called, isAutoSelect, isItemSelected, loading, onLookupSelect, items]);

  const onMenuClosed = useCallback(() => {
    setShowMenu(false);
  }, []);

  const onFocus = () => {
    if (!isItemSelected) {
      if (!called && load) {
        load();
      }
      setShowMenu(true);
    }
  };

  const onClear = () => {
    setIsItemSelected(undefined);
    setInputValue(undefined);
    onFilterChange(undefined);
    onSelect(undefined);
    setShowMenu(true);
  };

  return (
    <div className="flex flex-col w-full">
      <div>
        <NewLookupMenu<T>
          isOpen={showMenu && !!items?.length}
          onMenuItemSelected={onLookupSelect}
          descriptionField={descriptionField}
          keyField={keyField || 'id'}
          onMenuClosed={onMenuClosed}
          inputRef={inputRef}
          items={items}
          captionFields={captionFields}
          onLoadMore={onLoadMore}
          hasMore={hasMore}
          isSelectionBlocked={inputValue !== searchString}
        >
          <TextField
            label={label}
            className="w-full"
            outlined
            onLeadingIconSelect={onClear}
            leadingIcon={inputValue ? <MaterialIcon icon="clear" /> : undefined}
            trailingIcon={isItemSelected ? trailingIcon || <MaterialIcon icon="done" /> : undefined}
          >
            <Input
              value={inputValue}
              onChange={inputOnChangeHandler}
              ref={inputRef}
              disabled={isDisabled}
              onFocus={onFocus}
              data-cy={cypressLabel}
              required={isRequired}
              autoComplete={autoComplete}
            />
          </TextField>
        </NewLookupMenu>
        {loading && <InputLinearProgress />}
      </div>
    </div>
  );
};

export default AutoComplete;

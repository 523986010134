import React, { FC } from 'react';

import Table, { ITableState } from '@components/Table/Table';
import IUser from '@models/Identity/User/IUser';
import { IRowMenuOption } from '@components/Table/Menu/RowMenu';
import dayjs from 'dayjs';

interface IUserTableProps {
  users?: IUser[];
  onTableStateChanged: (state: ITableState) => void;
  hasMore?: boolean;
  onUserEdit: (user: IUser) => void;
  onSetActive?: (user: IUser, isActive: boolean) => void;
  loading: boolean;
  isLimited?: boolean;
}

const UserTable: FC<IUserTableProps> = (
  {
    users,
    onTableStateChanged,
    hasMore,
    onUserEdit,
    loading,
    onSetActive,
    isLimited,
  },
) => {
  const limitedRowOptions: IRowMenuOption[] = [
    {
      key: 'makeActive',
      text: 'Make Active',
      onClick: (selectedUser: IUser) => {
        if (onSetActive) {
          onSetActive(selectedUser, true);
        }
      },
      getIsVisible: (item: IUser) => !item.isActive,
    },
    {
      key: 'makeInactive',
      text: 'Make Inactive',
      onClick: (selectedUser: IUser) => {
        if (onSetActive) {
          onSetActive(selectedUser, false);
        }
      },
      getIsVisible: (item: IUser) => !!item.isActive,
    },
    {
      key: 'modifyRoles',
      text: 'Modify Roles',
      onClick: (selectedUser: IUser) => {
        onUserEdit(selectedUser);
      },
      getIsVisible: (item: IUser) => !!item.isActive,
    },
  ];

  const rowOptions: IRowMenuOption[] = [
    {
      key: 'edit',
      text: 'Edit',
      onClick: (selectedUser: IUser) => {
        onUserEdit(selectedUser);
      },
    },
  ];

  return (
    <Table
      dataSource={users}
      rowMenuOptions={isLimited ? limitedRowOptions : rowOptions}
      minHeight={400}
      maxHeight={700}
      onStateChange={onTableStateChanged}
      hasMore={hasMore}
      takeOptions={[100, 500]}
      isPagerVisible
      loading={loading}
      title="Users"
    >
      {[
        {
          key: 'firstName',
          header: 'First Name',
          field: 'firstName',
          isFilterable: true,
          isSortable: true,
        },
        {
          key: 'lastName',
          header: 'Last Name',
          field: 'lastName',
          isFilterable: true,
          isSortable: true,
        },
        {
          key: 'email',
          header: 'Email',
          field: 'email',
          isFilterable: true,
          isSortable: true,
        },
        ...(!isLimited ? [{
          key: 'organization',
          header: 'Organization',
          field: 'organization.description',
        }] : []),
        {
          key: 'lastLogin',
          header: 'Last Login',
          field: 'lastLogin',
          isSortable: true,
          formatter: (value) => (value !== '0001-01-01T00:00:00' ? dayjs(value).format('YY-MM-DD HH:mm A') : ''),
        },
        {
          key: 'failedLoginAttempts',
          header: 'Failed Login Attempts',
          field: 'failedLoginAttempts',
          isSortable: true,
        },
        {
          key: 'lockedOutTill',
          header: 'Locked Out Till',
          field: 'lockedOutTill',
          isSortable: true,
          formatter: (value) => (value !== null ? dayjs(value).format('YY-MM-DD HH:mm A') : ''),
        },
        {
          key: 'isActive',
          header: 'Is Active',
          field: 'isActive',
          isSortable: true,
        },
      ]}
    </Table>
  );
};

export default UserTable;

import { useQuery, ApolloError, gql } from '@apollo/client';

type JurisdictionQueryHook = () => IJurisdictionQueryHookResult;

interface IJurisdictionQueryResponse {
  jurisdictionCount: number;
}

interface IJurisdictionQueryHookResult {
  loading: boolean;
  error?: ApolloError;
  result: number;
}

const useJurisdictionCountQuery: JurisdictionQueryHook = () => {
  const GET_JURISDICTION_COUNT = gql`
    query {
      jurisdictionCount
    }
  `;

  const { loading, error, data } = useQuery<IJurisdictionQueryResponse>(GET_JURISDICTION_COUNT);

  return {
    loading,
    error,
    result: data?.jurisdictionCount || 0,
  };
};

export default useJurisdictionCountQuery;

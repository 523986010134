import {
  useMutation, ApolloError, gql,
} from '@apollo/client';
import IFilevineDeadline from '@models/Filevine/Deadlines/IFilevineDeadline';
import { useProxy } from 'valtio';
import { filevineState } from '@libs/auth';
import { generateDescription } from '@libs/export';
import {
  IServiceMethodCalculationState,
  ITriggerCalculationState,
  IDeadlineCalculationState,
  ICalculationState,
} from '@src/App/Calculator/Calculator';
import dayjs from 'dayjs';
import { IMatter, IJurisdiction } from '@models';
import { useState, useEffect, useCallback } from 'react';
import { getSnackBarService } from '@hooks/useSnackBar';
import { FilevineApolloClient } from './useFilevineClient';

export interface ISyncResult {
  saved: IFilevineDeadline[];
  deleted: IFilevineDeadline[];
}

export interface ISaveDeadlineResult {
  loading: boolean;
  error: { new?: ApolloError, updated?: ApolloError, deleted?: ApolloError }
  syncDeadlines: (matter: IMatter) => Promise<ISyncResult>;
  new: number;
  updated: number;
  deleted: number;
}

export interface ISaveDeadlineHookProps {
  calculationState?: ICalculationState;
  projectId?: string;
}

const mapServiceMethodDeadlineToFilevineDeadline = (
  historyId: string,
  smcs: IServiceMethodCalculationState,
  triggerCalculationState: ITriggerCalculationState,
  deadlineCalculationState: IDeadlineCalculationState,
  jurisdiction: IJurisdiction,
  filevineProjectId?: number,
): IFilevineDeadline => ({
  projectId: {
    native: filevineProjectId || 0,
  },
  deadlineId: {
    native: smcs.filevineDeadlineId
      ? +smcs.filevineDeadlineId : undefined,
    // the calculation id will later be saved as the history id
    // so we just put that here as the partner id
    partner: smcs.calculationId,
  },
  name: deadlineCalculationState.deadline.shortDescription,
  notes: generateDescription(
    triggerCalculationState,
    deadlineCalculationState,
    jurisdiction,
    undefined,
    smcs,
    historyId,
  ),
  dueDate: dayjs(smcs.calculatedDate).format('M/D/YYYY'),
});

const mapDeadlineToFilevineDeadline = (
  historyId: string,
  triggerCalculationState: ITriggerCalculationState,
  deadlineCalculationState: IDeadlineCalculationState,
  jurisdiction: IJurisdiction,
  filevineProjectId?: number,
): IFilevineDeadline => ({
  projectId: {
    native: filevineProjectId || 0,
  },
  deadlineId: {
    // the calculation id will later be saved as the history id
    // so we just put that here as the partner id
    native: deadlineCalculationState.filevineDeadlineId
      ? +deadlineCalculationState.filevineDeadlineId : undefined,
    partner: deadlineCalculationState.calculationId,
  },
  name: `${deadlineCalculationState.deadline.shortDescription}`,
  notes: generateDescription(
    triggerCalculationState,
    deadlineCalculationState,
    jurisdiction,
    undefined,
    undefined,
    historyId,
  ),
  dueDate: dayjs(deadlineCalculationState.calculatedDate).format('M/D/YYYY'),
});

export interface IFilvineDeadlineAnalysis {
  new: IFilevineDeadline[];
  updated: IFilevineDeadline[];
  deleted: IFilevineDeadline[];
}

const isExistingDeadline = (
  filevineDeadlineId?: string,
  verifiedDeadlines?: string[],
) => !!filevineDeadlineId && (!verifiedDeadlines
  || !!verifiedDeadlines?.includes(filevineDeadlineId));

const analyze = (
  calculationState?: ICalculationState,
  verifiedDeadlines?: string[],
): IFilvineDeadlineAnalysis => {
  const results: IFilvineDeadlineAnalysis = {
    new: [],
    updated: [],
    deleted: [],
  };
  if (calculationState?.historyId && calculationState.jurisdiction) {
    const historyId = calculationState?.historyId;
    const jurisdiction = calculationState?.jurisdiction;
    // this lint warning is really dumb this is a legit line
  // eslint-disable-next-line no-unused-expressions
  calculationState?.triggers?.forEach((tcs: ITriggerCalculationState) => {
    // eslint-disable-next-line no-unused-expressions
    tcs.deadlines?.forEach((dcs: IDeadlineCalculationState) => {
      if (dcs.toBeRemoved) {
        if (dcs.filevineDeadlineId) {
          if (isExistingDeadline(dcs.filevineDeadlineId, verifiedDeadlines)) {
            results.deleted.push(mapDeadlineToFilevineDeadline(historyId, tcs, dcs, jurisdiction));
          } else {
            results.deleted.push({
              ...mapDeadlineToFilevineDeadline(historyId, tcs, dcs, jurisdiction),
              isAlreadyDeleted: true,
            });
          }
        }
      } else if (!dcs.filevineDeadlineId && dcs.isSelected && !tcs.toBeRemoved) {
        if (!dcs.serviceMethods?.find(
          (smcs: IServiceMethodCalculationState) => smcs.isSelected && !smcs.toBeRemoved)) {
          results.new.push(mapDeadlineToFilevineDeadline(historyId, tcs, dcs, jurisdiction));
        }
      // I could have just used else here but i wanted to
      // put the condition here for clarity
      } else if (dcs.filevineDeadlineId) {
        if (dcs.isSelected && !dcs.serviceMethods?.find(
          (smcs: IServiceMethodCalculationState) => smcs.isSelected && !smcs.toBeRemoved)
        ) {
          if (isExistingDeadline(dcs.filevineDeadlineId, verifiedDeadlines)) {
            results.updated.push(mapDeadlineToFilevineDeadline(historyId, tcs, dcs, jurisdiction));
          } else {
            results.new.push(mapDeadlineToFilevineDeadline(historyId, tcs, dcs, jurisdiction));
          }
        } else if (dcs.filevineDeadlineId) {
          // this is an odd case where it has a filevine id it has service methods
          // that will be saved as deadlines and should have been marked as to be
          // removed but it didn't for some reason
          if (isExistingDeadline(dcs.filevineDeadlineId, verifiedDeadlines)) {
            results.deleted.push(mapDeadlineToFilevineDeadline(historyId, tcs, dcs, jurisdiction));
          } else {
            results.deleted.push({
              ...mapDeadlineToFilevineDeadline(historyId, tcs, dcs, jurisdiction),
              isAlreadyDeleted: true,
            });
          }
        }
      }
      // eslint-disable-next-line no-unused-expressions
      dcs.serviceMethods?.forEach(
        (smcs: IServiceMethodCalculationState) => {
          if (smcs.toBeRemoved || !smcs.isSelected) {
            if (smcs.filevineDeadlineId) {
              if (isExistingDeadline(smcs.filevineDeadlineId, verifiedDeadlines)) {
                results.deleted.push(
                  mapServiceMethodDeadlineToFilevineDeadline(
                    historyId, smcs, tcs, dcs, jurisdiction,
                  ),
                );
              } else {
                results.deleted.push({
                  ...mapServiceMethodDeadlineToFilevineDeadline(
                    historyId, smcs, tcs, dcs, jurisdiction,
                  ),
                  isAlreadyDeleted: true,
                });
              }
            }
          } else if (!smcs.filevineDeadlineId && dcs.isSelected) {
            results.new.push(
              mapServiceMethodDeadlineToFilevineDeadline(historyId, smcs, tcs, dcs, jurisdiction),
            );
            // I could have just used else here but i wanted to
            // put the condition here for clarity
          } else if (smcs.filevineDeadlineId) {
            if (isExistingDeadline(smcs.filevineDeadlineId, verifiedDeadlines)) {
              results.updated.push(
                mapServiceMethodDeadlineToFilevineDeadline(historyId, smcs, tcs, dcs, jurisdiction),
              );
            } else {
              results.new.push(
                mapServiceMethodDeadlineToFilevineDeadline(historyId, smcs, tcs, dcs, jurisdiction),
              );
            }
          }
        });
    }
  );
  });
  }
  return results;
};

const QUERY_GQL = gql`
    query deadlinesQuery {
      deadlines(projectId: $projectId)
        @rest(type: "FilevineDeadline", path: "/projects/{args.projectId}/deadlines?limit=1000&offset=0&requestedFields=deadlineId") {
          items {
            deadlineId {
              native
              partner
            }
          }
          hasMore
      }
    }
  `;

const POST_GQL = gql`mutation saveDeadlines($deadline: PublishablePostInput!, $projectId: Integer!) {
        deadlines(projectId: $projectId, input: $deadline)
          @rest(
            type: "FilevineDeadline",
            path: "/projects/{args.projectId}/deadlines",
            method: "POST"
            ) {
                deadlineId {
                  native
                  partner
                }
              }
          }`;

const PATCH_GQL = gql`mutation updateDeadlines($deadline: PublishablePostInput!, $projectId: Integer!, $deadlineId: Integer!) {
        deadlines(projectId: $projectId, input: $deadline, deadlineId: $deadlineId)
          @rest(
            type: "FilevineDeadline",
            path: "/projects/{args.projectId}/deadlines/{args.deadlineId}",
            method: "PATCH"
            ) {
               deadlineId {
                  native
                  partner
                }
              }
          }`;

const DELETE_GQL = gql`mutation deleteDeadline($projectId: String!, $deadlineId: String!) {
        deadlines(projectId: $projectId, deadlineId: $deadlineId)
          @rest(
            type: "FilevineDeadline",
            path: "/projects/{args.projectId}/deadlines/{args.deadlineId}",
            method: "DELETE"
            ) {
              noResponse
            }
          items(deadlineId: $deadlineId)
          @rest(
            type: "FilevineDeadline",
            path: "/PartnerIdentifiers/deadline/{args.deadlineId}",
            method: "DELETE"
            ) {
              noResponse
            }
          }

          `;

export const getVerifiedDeadlines = async (
  client: FilevineApolloClient,
  projectId: string,
): Promise<string[]> => client
  ?.query<{ deadlines: { items: IFilevineDeadline[], hasMore: boolean } }>({
    query: QUERY_GQL,
    variables: {
      offset: 0,
      limit: 1000,
      projectId,
    },
    fetchPolicy: 'network-only',
  }).then((result) => result.data.deadlines?.items.map((deadline: IFilevineDeadline) => `${deadline.deadlineId?.native || ''}`)) || [];

const useFilevineSyncDeadlines = ({
  calculationState,
  projectId,
}: ISaveDeadlineHookProps): ISaveDeadlineResult => {
  const [newDeadlines, setNewDeadlines] = useState<IFilevineDeadline[]>();
  const [updatedDeadlines, setUpdatedDeadlines] = useState<IFilevineDeadline[]>();
  const [deletedDeadlines, setDeletedDeadlines] = useState<IFilevineDeadline[]>();
  const snackBar = getSnackBarService();
  const [isSyncing, setIsSyncing] = useState(false);

  const { client } = useProxy(filevineState);

  const [saveFunc, {
    error,
  }] = useMutation<{
    deadlines: IFilevineDeadline,
  }>(POST_GQL, { client: client as FilevineApolloClient });

  const [updateFunc, {
    error: patchError,
  }] = useMutation<{
    deadlines: IFilevineDeadline,
  }>(PATCH_GQL, { client: client as FilevineApolloClient });

  const [deleteFunc, {
    error: deleteError,
  }] = useMutation(DELETE_GQL, { client: client as FilevineApolloClient });

  const toPromise = useCallback((
    matter: IMatter,
    updates?: IFilevineDeadline[],
    news?: IFilevineDeadline[],
  ) => Promise.all<IFilevineDeadline | undefined>([
    ...(news?.map(
      (deadline: IFilevineDeadline) => saveFunc(
        {
          variables: {
            deadline: {
              ...deadline,
              deadlineId: {
                partner: deadline.deadlineId?.partner,
              },
              projectId: undefined,
              name: `${deadline.name?.slice(0, 47)}${(deadline.name?.length || 0) >= 47 ? '...' : ''}`,
            },
            projectId: matter.filevineProjectId,
          },
        },
      ).then((x) => x.data?.deadlines).catch((e: Error) => (e.message.includes('403') ? ({ ...deadline, isAlreadyDeleted: true }) : undefined)),
    ) || []),
    ...(updates?.map(
      (deadline: IFilevineDeadline) => updateFunc(
        {
          variables: {
            deadline: {
              dueDate: deadline.dueDate,
              notes: deadline.notes,
            },
            deadlineId: deadline.deadlineId?.native,
            projectId: matter.filevineProjectId,
          },
        },
      ).then((x) => x.data?.deadlines).catch((e: Error) => (e.message.includes('403') ? ({ ...deadline, isAlreadyDeleted: true }) : undefined)),
    ) || []),
  ]), [saveFunc, updateFunc]);

  useEffect(() => {
    const results = analyze(calculationState);
    setNewDeadlines(results.new);
    setUpdatedDeadlines(results.updated);
    setDeletedDeadlines(results.deleted);
  }, [calculationState]);

  const deleteDeadlines = useCallback(async (
    matter: IMatter,
    analysis?: IFilvineDeadlineAnalysis,
  ): Promise<IFilevineDeadline[]> => {
    try {
      if (analysis && analysis.deleted.length) {
        const result = await Promise.all([
          ...(analysis.deleted
            ?.filter((deadline: IFilevineDeadline) => !deadline.isAlreadyDeleted)
            ?.map(
            (deadline: IFilevineDeadline) => deleteFunc(
              {
                variables: {
                  deadlineId: deadline.deadlineId?.native,
                  projectId: matter.filevineProjectId,
                  partnerId: deadline.deadlineId?.partner,
                },
              },
            ).then((x) => x.data?.deadlines).catch((e: Error) => (e.message.includes('403') ? ({ ...deadline, isAlreadyDeleted: true }) : undefined)))),
          ...(analysis.deleted
            ?.filter((deadline: IFilevineDeadline) => deadline.isAlreadyDeleted) || []),
        ]);
        return result;
      }
      if (analysis && !analysis.deleted.length) {
        return [];
      }
      const result = await Promise.all([
        ...(deletedDeadlines?.map(
            (deadline: IFilevineDeadline) => deleteFunc(
              {
                variables: {
                  deadlineId: deadline.deadlineId?.native,
                  projectId: matter.filevineProjectId,
                  partnerId: deadline.deadlineId?.partner,
                },
              },
            ).then(() => deadline)) || []),
      ]);
      return result;
    } catch {
      snackBar.showSnackBar('There was an error cleaning up some of your outdated deadlines.');
      return [];
    }
  }, [deletedDeadlines, deleteFunc, snackBar]);

  const saveDeadlines = useCallback(async (
    matter: IMatter,
    analysis?: IFilvineDeadlineAnalysis,
  ): Promise<IFilevineDeadline[]> => {
    try {
      if (analysis) {
        try {
          const result = await toPromise(matter, analysis.updated, analysis.new);
          return result.filter((y: IFilevineDeadline | undefined) => !!y) as IFilevineDeadline[];
        } catch {
          snackBar.showSnackBar('There was an error saving some of your deadlines. A deadline in Filevine has been deleted and can no longer be updated.');
        }
      }

      const result = await toPromise(matter, updatedDeadlines, newDeadlines);
      return result.filter((y: IFilevineDeadline | undefined) => !!y) as IFilevineDeadline[];
    } catch (e) {
      snackBar.showSnackBar('There was an error saving some of your deadlines.');
      throw e;
    }
  }, [newDeadlines, snackBar, toPromise, updatedDeadlines]);

  const syncDeadlines = useCallback(async (matter: IMatter) => {
    try {
      setIsSyncing(true);
      if (calculationState?.isSavedToFilevine && client && projectId) {
        try {
          const verifiedDeadlines = await getVerifiedDeadlines(
            client as FilevineApolloClient,
            projectId,
          );
          const analysis = analyze(calculationState, verifiedDeadlines);
          const saved = await saveDeadlines(matter, analysis);
          const deleted = await deleteDeadlines(matter, analysis);
          setIsSyncing(false);
          return { saved, deleted };
        } catch {
          const saved = await saveDeadlines(matter);
          const deleted = await deleteDeadlines(matter);
          setIsSyncing(false);
          return { saved, deleted };
        }
      }
      const saved = await saveDeadlines(matter);
      const deleted = await deleteDeadlines(matter);
      setIsSyncing(false);
      return { saved, deleted };
    } catch (e) {
      setIsSyncing(false);
      return { saved: [], deleted: [] };
    }
  }, [calculationState, deleteDeadlines, saveDeadlines, client, projectId]);

  return {
    error: { new: error, updated: patchError, deleted: deleteError },
    loading: isSyncing,
    new: newDeadlines?.length || 0,
    updated: updatedDeadlines?.length || 0,
    deleted: deletedDeadlines ?.length || 0,
    syncDeadlines,

  };
};

export { useFilevineSyncDeadlines };

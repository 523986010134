import React, {
  useState, FC, useEffect,
} from 'react';
import Button from '@fv-components/button';
import Snackbar from '@fv-components/snackbar';
import useUserQuery from '@hooks/Identity/User/useUserQuery';
import { UserForm, SetPassword } from '@components';
import { IUser } from '@models';
import { useParams, Redirect } from 'react-router-dom';
import { FetchResult } from '@apollo/client';
import MaterialIcon from '@fv-components/material-icon';

const css = require('./Account.module.css');

interface IAccountState {
  user?: IUser;
  isUserValid?: boolean;
  isUserDirty?: boolean;
  isPasswordValid?: boolean;
  isPasswordDirty: boolean;
  connectToFilevine?: boolean;
}
interface IAccountProps {
  onAccountUpdate: (
    firstName: string,
    lastName: string,
    emailAddress: string,
    token: string,
  ) => void,
  loggedInUserId: string;
}

const Account: FC<IAccountProps> = ({
  onAccountUpdate,
  loggedInUserId,
}) => {
  const { userId } = useParams<{ userId: string }>();
  const queryObject = { id: userId, skip: 0, take: 1 };
  const fields = ['id', 'email', 'firstName', 'lastName'];
  const apolloWrapper = useUserQuery({ queryObject, fields });
  const [state, setState] = useState<IAccountState>({
    isPasswordDirty: false,
    isPasswordValid: true,
    isUserDirty: false,
    isUserValid: true,
  });

  useEffect(() => {
    if (apolloWrapper.result.items.length > 0 && !state.user) {
      setState((s: IAccountState) => ({
        ...s,
        user: {
          ...apolloWrapper.result.items[0],
        },
      }));
    }
  }, [apolloWrapper.result.items, state]);

  const onUserChange = (user?: IUser, isUserValid?: boolean) => {
    setState({
      ...state,
      user,
      isUserValid,
      isUserDirty: true,
    });
  };

  const onPasswordChange = (password?: string, isPasswordValid?: boolean) => {
    setState({
      ...state,
      user: {
        ...state.user,
        password,
      },
      isPasswordValid,
      isPasswordDirty: true,
    });
  };

  const onSaveButtonClicked = () => {
    if ((state.isUserValid || !state.isUserDirty)
      && (!state.isPasswordDirty || state.isPasswordValid)
      && state.user?.id && state.user?.email && state.user?.firstName && state.user?.lastName
    ) {
      apolloWrapper.useSaveUser.saveUser(state.user.id,
        state.user.email,
        state.user.firstName,
        state.user.lastName,
        state.user.password).then((response: FetchResult<IUser>) => {
        if (state.user?.email
            && state.user?.firstName
            && state.user?.lastName
            && response.data?.updatedToken) {
          onAccountUpdate(
              state.user?.firstName,
              state.user?.lastName,
              state.user?.email,
              response.data?.updatedToken,
          );
        }
      }).then(() => setState({
        ...state,
        isPasswordDirty: false,
        isUserDirty: false,
      }));
    }
  };

  const saveSuccessful = apolloWrapper.useSaveUser.result.called
    && !apolloWrapper.useSaveUser.result.loading
    && !apolloWrapper.useSaveUser.result.error;

  const saveError = apolloWrapper.useSaveUser.result.called
    && !apolloWrapper.useSaveUser.result.loading
    && apolloWrapper.useSaveUser.result.error;

  if (userId !== loggedInUserId) {
    return <Redirect to={`/my-profile/${loggedInUserId}`} />;
  }

  return (
    <>
      <div className={css.container}>
        <div className={css.column}>
          <h2>My Profile</h2>
          <UserForm onUserChange={onUserChange} user={state.user} />
          <SetPassword onPasswordChange={onPasswordChange} isRequired={false} />
          <div className={css.rowReverse}>
            <Button
              aria-label="Save User Account"
              onClick={onSaveButtonClicked}
              icon={<MaterialIcon icon="save" />}
              disabled={(
                !state.isUserValid
                || !state.isPasswordValid
                || !(state.isUserDirty || state.isPasswordDirty))}
              raised
            >
              Save
            </Button>
          </div>
        </div>
      </div>
      {saveSuccessful
        && (
        <Snackbar
          message="Save successful."
        />
        )}
      {saveError
        && (
        <Snackbar
          message="There was an error updating your account."
        />
        )}

    </>

  );
};

export default Account;

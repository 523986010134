import React, { FC, CSSProperties } from 'react';
import Checkbox from '@fv-components/checkbox';
import IColumnProps from '../Column';
import RowMenu, { IRowMenuOption } from '../Menu/RowMenu';
import Cell from './Cell';

export interface IRowProps {
  columnProps: IColumnProps[];
  item: any;
  style?: CSSProperties;
  isCheckboxVisible?: boolean;
  isSelected?: boolean;
  onSelect?: (item: any, checked: boolean) => void;
  onRowClick?: (item: any) => void;
  options?: IRowMenuOption[];
  disabled?: boolean;
  cypressId?: string;
  tabIndex?: number;
}

const shouldIAllowEventToBubble = (className: string) => {
  const lowerCaseClassNames = className.toLowerCase();
  return !(lowerCaseClassNames.includes('mdc-checkbox') || lowerCaseClassNames.includes('material-icons') || lowerCaseClassNames.includes('mdc-icon-button'));
};

const Row: FC<IRowProps> = ({
  columnProps,
  item,
  style,
  isCheckboxVisible,
  isSelected,
  onSelect,
  onRowClick,
  options,
  disabled,
  cypressId,
  tabIndex,
}: IRowProps) => (
  // eslint requires a role to be applied to a non interactive
  // element if it has an on click handler.  the rule isn't smart
  // enough to tell that the click handler is only applied conditionally
  // eslint-disable-next-line jsx-a11y/no-static-element-interactions
  <div
    role={onRowClick ? 'button' : undefined}
    data-cy={`data-table-row-container-${cypressId || ''}`}
    className={`flex flex-row items-center border-solid border-0 border-b border-gray-mild h-12 ${onRowClick ? 'hover:bg-gray-light cursor-pointer focus:bg-gray-light focus:outline-none ' : ''}`}
    onClick={onRowClick ? (event) => shouldIAllowEventToBubble(
      (event.target as HTMLElement).className,
    )
      && onRowClick(item) : undefined}
    onKeyUp={(
      event: React.KeyboardEvent<HTMLDivElement>,
    ) => event.key === 'Enter'
        && onRowClick
        && onRowClick(item)}
    tabIndex={tabIndex}
  >
    {isCheckboxVisible
      && (
        <Checkbox
          checked={isSelected}
          onChange={(evt: React.ChangeEvent<HTMLInputElement>) => {
            if (onSelect) {
              onSelect(item, evt.target.checked);
            }
          }}
          disabled={disabled}
        />
      )}
    <div className={`flex-1 grid grid-cols-${columnProps.length} gap-1`}>
      {columnProps.map((col: IColumnProps) => (
        <Cell
          key={col.key}
          rowProps={{
            columnProps,
            item,
            style,
            isCheckboxVisible,
            isSelected,
            onSelect,
            options,
          }}
          colProps={col}
        />
      ))}
    </div>
    <RowMenu options={options} item={item} disabled={disabled} />
  </div>
);

export default Row;

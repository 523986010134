import React, { FC } from 'react';
import Button from '@fv-components/button';
import MaterialIcon from '@fv-components/material-icon';
import { Caption } from '@fv-components/typography';
import { TriggerAutoComplete, DatePicker, TriggerServiceMethodDropDown } from '@components';
import {
  ITrigger, IFilingMethod, IJurisdiction,
} from '@models';
import TextField, { Input } from '@fv-components/text-field';
import dayjs from 'dayjs';

interface ITriggerListItemProps {
  jurisdiction?: IJurisdiction;
  date?: Date;
  trigger?: ITrigger;
  serviceMethodId?: string;
  triggerLabel?: string;
  onDelete: VoidFunction;
  onTriggerSelect: (trigger?: ITrigger) => void;
  onDateSelect: (date?: Date) => void;
  onServiceMethodSelect: (serviceMethodId?: string) => void;
  isSavedToFilevine?: boolean;
  onTriggerLabelChange: (triggerLabel?: string) => void;
  setIsServiceRequired: (isServiceRequired: boolean) => void;
  blockedTriggerIds?: string[];
}

const TriggerListItem: FC<ITriggerListItemProps> = (
  {
    jurisdiction,
    date,
    trigger,
    serviceMethodId,
    onTriggerSelect,
    onDateSelect,
    onServiceMethodSelect,
    onDelete,
    isSavedToFilevine,
    onTriggerLabelChange,
    triggerLabel,
    setIsServiceRequired,
    blockedTriggerIds,
  }: ITriggerListItemProps,
) => {
  const showWarnings = trigger?.description?.toLowerCase().includes('filed')
    && !!jurisdiction?.filingMethods?.filter(
      (fm: IFilingMethod) => !!fm.warningMessage).length;

  return (
    <div className="flex flex-row justify-between">
      <div className="flex flex-col flex-0">
        <Button
          onClick={onDelete}
          icon={<MaterialIcon icon="close" />}
          role="button"
          aria-label="Remove Trigger"
          data-cy="remove-trigger"
        />
      </div>
      <div className="flex flex-col flex-1 gap-3">
        <TriggerAutoComplete
          onSelect={onTriggerSelect}
          jurisdictionIds={jurisdiction?.address ? jurisdiction.address.split('.') : []}
          value={trigger}
          isDisabled={isSavedToFilevine}
          blockIdsFromSelection={blockedTriggerIds}
        />
        <TextField
          label="Trigger Label"
          outlined
        >
          <Input
            value={triggerLabel || ''}
            onChange={
                (
                  event: React.FormEvent<HTMLInputElement>,
                ) => onTriggerLabelChange(event.currentTarget.value || undefined)
              }
            type="text"
            data-cy="trigger-label"
            disabled={!trigger?.id}
          />
        </TextField>
        <div className="flex md:flex-row flex-col-reverse gap-3">
          <DatePicker
            onDateChange={onDateSelect}
            dateFormat="MM/DD/YYYY"
            value={date}
            disabled={!jurisdiction?.id}
            minDate={new Date(1900, 0, 1)}
            maxDate={dayjs().add(100, 'years').toDate()}
            isRequired
          />
          {(trigger?.id && jurisdiction?.id) && (
            <TriggerServiceMethodDropDown
              onSelect={onServiceMethodSelect}
              isDisabled={!trigger?.id || !jurisdiction?.id}
              triggerId={trigger.id}
              jurisdictionId={jurisdiction.id}
              value={serviceMethodId}
              onItemsChanged={setIsServiceRequired}
            />
          )}
        </div>

        {showWarnings && (
          <ul>
            {
              jurisdiction?.filingMethods?.filter(
                (fm: IFilingMethod) => !!fm.warningMessage,
              ).map((fm: IFilingMethod) => (
                <li key={fm.id}>
                  <Caption>
                    {`For ${fm.description}: ${fm.warningMessage}`}
                  </Caption>
                </li>
              ))
            }
          </ul>
        )}
      </div>
    </div>

  );
};

export default TriggerListItem;

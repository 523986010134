import React, { FC } from 'react';
import Select, { Option } from '@fv-components/select';
import CalendarType from '@models/Calendar/CalendarType';

interface ICalendarTypeDropDownProps {
  onChange: (type?: CalendarType) => void;
  value?: CalendarType;
  label?: string;
  isRequired?: boolean;
}

const CalendarTypeDropDown: FC<ICalendarTypeDropDownProps> = (
  {
    onChange, value, label, isRequired,
  }: ICalendarTypeDropDownProps,
) => {
  const onSelect = (evt: React.FormEvent<HTMLSelectElement>) => {
    if (evt.currentTarget.value === '') {
      onChange(undefined);
    } else {
      onChange(evt.currentTarget.value as CalendarType);
    }
  };

  return (
    <Select
      outlined
      label={label || 'Calendar'}
      onChange={onSelect}
      value={value || ''}
      required={isRequired}
    >
      <>
        <Option key="NONE" value="" />
        {Object.keys(CalendarType).map((j) => (
          <Option key={j} value={j}>
            {j}
          </Option>
        ))}
      </>
    </Select>
  );
};

export default CalendarTypeDropDown;

import React, { FC } from 'react';
import Table, { ITableState } from '@components/Table/Table';
import IPermission from '@models/Identity/Role/Permission/IPermission';

interface IJurisdictionTableProps {
  permissions?: IPermission[];
  onTableStateChanged: (state: ITableState) => void;
  hasMore?: boolean;
  onPermissionSelect: (permission: IPermission, isSelected: boolean) => void;
  selectedPermissions: IPermission[];
  loading: boolean;
}

const JurisdictionTable: FC<IJurisdictionTableProps> = (
  {
    permissions,
    onTableStateChanged,
    hasMore,
    onPermissionSelect,
    selectedPermissions,
    loading,
  },
) => (
  <Table
    dataSource={permissions}
    isMultiSelect
    minHeight={400}
    maxHeight={600}
    onStateChange={onTableStateChanged}
    hasMore={hasMore}
    takeOptions={[100, 500]}
    isPagerVisible
    selected={selectedPermissions}
    onRowSelect={onPermissionSelect}
    loading={loading}
  >
    {[
      {
        key: 'permissionObject',
        header: 'Object',
        field: 'permissionObject',
        isFilterable: true,
        isSortable: true,
      },
      {
        key: 'permissionAction',
        header: 'Action',
        field: 'permissionAction',
        isFilterable: false,
        isSortable: false,
      },
    ]}
  </Table>
);

export default JurisdictionTable;

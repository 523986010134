import {
  useMutation, ApolloError, FetchResult, DocumentNode, gql,
} from '@apollo/client';
import IInviteQuery from '@models/Identity/Invite/IInviteQuery';
import IInvite from '@models/Identity/Invite/IInvite';
import IRole from '@models/Identity/Role/IRole';
import IInviteInput from '@models/Identity/Invite/IInviteInput';
import { handleSaveResult } from '@libs/apollo';
import dayjs from 'dayjs';

type InviteSendHook = (
  props: IInviteSendHookProps
) => IInviteSendHookResult;

interface IInviteSendHookProps {
  queryObject?: IInviteQuery;
  queryString?: DocumentNode;
}

export interface IInviteSendHookResult {
  result: {
    error?: ApolloError;
    loading: boolean;
    called: boolean;
  };
  sendInvites: (
    invites: IInviteInput
  ) => Promise<FetchResult<IInvite[]>>;
}

interface ISendInviteResult {
  sendInvites: IInvite[];
}

const useInviteSend: InviteSendHook = (
  { queryString, queryObject }: IInviteSendHookProps,
) => {
  const SAVE_GQL = gql`
    mutation InviteMutation($invites: InviteSendInputType!) {
      sendInvites(item: $invites) {
         id
         emailAddress
      }
    }
  `;

  const [saveFunc, {
    error, loading, called, client,
  }] = useMutation<ISendInviteResult>(SAVE_GQL);

  return {
    result: {
      error,
      loading,
      called,
    },
    sendInvites: (
      send: IInviteInput,
    ) => {
      const invitesInputs = {
        ...send,
        // i have to do this silly mapping to remove the __typename property
        roles: send.roles?.map((j: IRole) => (
          { id: j.id, description: j.description })),
        organization: {
          id: send.organization?.id,
          description: send.organization?.description,
        },
      };

      return saveFunc({
        variables: {
          invites: {
            ...invitesInputs,
            expirationDate: dayjs(invitesInputs.expirationDate).format('YYYY-MM-DD'),
          },
        },
      }).then((result: FetchResult<ISendInviteResult>) => {
        const invites = result.data?.sendInvites.map((i: IInvite) => {
          const invite = {
            ...i,
            id: undefined,
            roles: [...send.roles],
            organization: {
              ...send.organization,
            },
            expirationDate: invitesInputs.expirationDate,
          };
          return handleSaveResult<IInvite>(
            i || '',
            invite,
            client,
            'invites',
            queryObject,
            queryString,
          );
        });
        return {
          ...result,
          data: invites,
        };
      });
    },
  };
};

export default useInviteSend;

import {
  useMutation, ApolloError, FetchResult, DocumentNode, gql,
} from '@apollo/client';
import IOrganizationQuery from '@models/Identity/Organization/IOrganizationQuery';
import IOrganization from '@models/Identity/Organization/IOrganization';
import { handleSaveResult } from '@libs/apollo';

type OrganizationSaveHook = (
  props: IOrganizationSaveHookProps
) => IOrganizationSaveHookResult;

interface IOrganizationSaveHookProps {
  queryObject?: IOrganizationQuery;
  queryString?: DocumentNode;
}

export interface IOrganizationSaveHookResult {
  result: {
    error?: ApolloError;
    loading: boolean;
    called: boolean;
  };
  saveOrganization: (
    description: string,
    id?: string,
  ) => Promise<FetchResult<IOrganization>>;
}

interface ISaveOrganizationResult {
  saveOrganization: IOrganization;
}

const useOrganizationSave: OrganizationSaveHook = (
  { queryString, queryObject }: IOrganizationSaveHookProps,
) => {
  const SAVE_GQL = gql`
    mutation OrganizationMutation($organization: OrganizationInputType!) {
      saveOrganization(item: $organization) {
         id
      }
    }
  `;

  const [saveFunc, {
    error, loading, called, client,
  }] = useMutation<ISaveOrganizationResult>(SAVE_GQL);

  return {
    result: {
      error,
      loading,
      called,
    },
    saveOrganization: (
      description: string,
      id?: string,
    ) => {
      const organization = {
        id,
        description,
      };
      return saveFunc({
        variables: {
          organization,
        },
      }).then((result: FetchResult<ISaveOrganizationResult>) => ({
        ...result,
        data: {
          ...handleSaveResult<IOrganization>(
            result.data?.saveOrganization || '',
            organization,
            client,
            'organizations',
            queryObject,
            queryString,
          ),
        },
      }));
    },
  };
};

export default useOrganizationSave;

import {
  useMutation, ApolloError, FetchResult, DocumentNode, gql,
} from '@apollo/client';
import IUser from '@models/Identity/User/IUser';
import IUserQuery from '@models/Identity/User/IUserQuery';
import IRole from '@models/Identity/Role/IRole';
import { handleSaveResult } from '@libs/apollo';
import IOrganization from '@models/Identity/Organization/IOrganization';

type UserSaveHook = (
  props: IUserSaveHookProps
) => IUserSaveHookResult;

interface IUserSaveHookProps {
  queryObject?: IUserQuery;
  queryString?: DocumentNode;
}

export interface IUserSaveHookResult {
  result: {
    data?: IUser | null;
    error?: ApolloError;
    loading: boolean;
    called: boolean;
  };
  saveUser: (
    id: string,
    email: string,
    firstName: string,
    lastName: string,
    password?: string,
    roles?: IRole[],
    isActive?: boolean,
    organization?: IOrganization,
  ) => Promise<FetchResult<IUser>>;
}

interface ISaveUserResult {
  saveUser: IUser
}

const useUserSave: UserSaveHook = (
  { queryObject, queryString }: IUserSaveHookProps,
) => {
  const SAVE_USER = gql`
    mutation UserMutation($user: UserInputType!) {
      saveUser(item: $user) {
         id
         updatedToken
      }
    }
  `;

  const [saveFunc, {
    data, error, loading, called, client,
  }] = useMutation<ISaveUserResult>(SAVE_USER);

  return {
    result: {
      data: data?.saveUser,
      error,
      loading,
      called,
    },
    saveUser: (
      id: string,
      email: string,
      firstName: string,
      lastName: string,
      password?: string,
      roles?: IRole[],
      isActive?: boolean,
      organization?: IOrganization,
    ) => {
      const user = {
        id,
        firstName,
        lastName,
        email,
        // putting this here in the case of an empty string password
        password: password || undefined,
        roles,
        isActive,
        organization,
      };

      return saveFunc({
        variables: {
          user: {
            ...user,
            roles: user.roles?.map((r: IRole) => (
              { id: r.id, description: r.description })),
            organizationId: (user.organization?.id || null) as string,
            organization: undefined,
          },
        },
      }).then((result: FetchResult<ISaveUserResult>) => ({
        ...result,
        data: {
          ...handleSaveResult<IUser>(
            result.data?.saveUser || '',
            user,
            client,
            'users',
            queryObject,
            queryString,
          ),
        },
      }));
    },
  };
};

export default useUserSave;

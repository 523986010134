import { Auth } from 'aws-amplify';
import { proxy } from 'valtio';
import { subscribeKey } from 'valtio/utils';
import { CognitoUserSession } from 'amazon-cognito-identity-js';
import { getConfig, getTenantConfig } from '@src/config';
import { FilevineApolloClient } from '@hooks/Filevine/useFilevineClient';

enum LocalStorageKey
{
  AccessToken = 'accessToken',
  OrgId = 'orgId',
  UserId = 'userId',
  UserOrgs = 'userOrgs',
  SAMLAuthProvider = 'SAMLAuthProvider',
}

interface IUserId {
  userId: {
    native: number;
    partnerId: string;
  }
}

export interface IUserOrg {
  name: string;
  orgId: number;
}

interface IUserAndOrgId {
  user: IUserId;
  orgs: IUserOrg[];
}

interface IFilevineState {
  userId?: number;
  orgId?: number;
  userOrgs: IUserOrg[];
  SAMLAuthProvider: string;
  client?: FilevineApolloClient;
  headers?: any;
}

const userId = localStorage.getItem(LocalStorageKey.UserId);
const orgId = localStorage.getItem(LocalStorageKey.OrgId);
const userOrgs = localStorage.getItem(LocalStorageKey.UserOrgs);
const SAMLAuthProvider = localStorage.getItem(LocalStorageKey.SAMLAuthProvider);

const filevineState = proxy<IFilevineState>({
  userId: userId ? +userId : undefined,
  orgId: orgId ? +orgId : undefined,
  userOrgs: userOrgs ? JSON.parse(userOrgs) : [],
  SAMLAuthProvider: SAMLAuthProvider ? JSON.parse(SAMLAuthProvider) : undefined,
});

subscribeKey(filevineState, 'orgId', (value?: any) => localStorage.setItem(LocalStorageKey.OrgId, value?.toString() || ''));
subscribeKey(filevineState, 'userId', (value?: any) => localStorage.setItem(LocalStorageKey.UserId, value?.toString() || ''));
subscribeKey(filevineState, 'userOrgs', (value?: any) => localStorage.setItem(LocalStorageKey.UserOrgs, JSON.stringify(value)));
subscribeKey(filevineState, 'SAMLAuthProvider', (value?: any) => localStorage.setItem(LocalStorageKey.SAMLAuthProvider, JSON.stringify(value)));

export const handleAmplifyConfigure = async (): Promise<void> => {
  const tc = await getTenantConfig();

  if (tc?.appClientID && tc?.cognitoUserPoolID) {
    const authConfig = {
      userPoolId: tc?.cognitoUserPoolID,
      userPoolWebClientId: tc?.appClientID,
      mandatorySignIn: true,
      oauth: {},
    };

    if (filevineState.SAMLAuthProvider) {
      const { hostUrl } = getConfig();
      authConfig.oauth = {
        domain: `${tc?.tenantID}.auth.${tc?.awsRegion}.amazoncognito.com`,
        scope: ['email', 'profile', 'openid'],
        redirectSignIn: `${hostUrl}/success`,
        responseType: 'code',
      };
    }

    Auth.configure({
      Auth: authConfig,
    });
  }
};

const getAccessToken = async (): Promise<string> => {
  const session = await Auth.currentSession();
  const token = await session.getAccessToken().getJwtToken();
  return token;
};

const showLoginPage = () => {
  if (!window.location.href.includes('login') && !window.location.href.includes('success')) {
    window.location.assign('/login');
  }
};

export const fetchUserAndOrgId = (session: string) => {
  const { filevineApiBaseUrl } = getConfig();
  return fetch(`${filevineApiBaseUrl}/utils/GetUserOrgsWithToken`, {
    method: 'post',
    headers: {
      Authorization: `Bearer ${session}`,
    },
  }).then((response: Response) => {
    if (response.ok) {
      return response.json();
    }
    throw new Error();
  }).catch(() => {
    throw new Error('There was an error fetching your user and org id from Filevine. Error: FVOA631');
  });
};

export const fetchAndSetUserAndOrgId = async (session: string) => {
  const userOrgAndId: IUserAndOrgId = await fetchUserAndOrgId(session);

  if (userOrgAndId?.orgs && userOrgAndId?.user?.userId?.native) {
    // User but has no org
    if (!userOrgAndId.orgs?.length) {
      throw new Error('This feature only supports active subscribed Filevine users.  If you believe this to be in error, please contact your organization administrator to be properly configured within the organization. Error: FVOA742');
    }

    // Good user w/ 1 or more orgs
    userOrgAndId.orgs.sort(
      (a:IUserOrg, b:IUserOrg) => (a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1),
    );
    filevineState.userOrgs = userOrgAndId.orgs;
    filevineState.userId = userOrgAndId.user.userId.native;

    // Only set a default orgId if they don't already have one thats a valid org
    if (!filevineState.orgId
      || !(userOrgAndId.orgs.findIndex((x) => x.orgId === filevineState.orgId) > -1)) {
      filevineState.orgId = userOrgAndId.orgs[0].orgId;
    }
    return true;
  }
  throw new Error('There was an error setting your Filevine user or org id. Error: FVOA853');
};

const updateFVUserFromSession = () => new Promise<void>((resolve, reject) => {
  const asyncFunction = () => {
    Auth.currentSession()
      .then((data: CognitoUserSession) => {
        const session = data.getAccessToken().getJwtToken();
        fetchAndSetUserAndOrgId(session).then(() => resolve()).catch(() => reject());
      }).catch(() => {
        reject(new Error('Unable to get current session.'));
      });
  };

  asyncFunction();
});

export {
  filevineState,
  getAccessToken,
  showLoginPage,
  updateFVUserFromSession,
};

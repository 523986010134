import Tab, {
  TabRipple,
} from '@material/react-tab';
import './Tab.scss';

/**
 * Tab MCWR Component
 *
 * @version ./package.json
 */
export default Tab;

export {
  TabRipple,
};

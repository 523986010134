/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { FC, useState } from 'react';
import Button from '@fv-components/button';
import LinearProgress from '@fv-components/linear-progress';
import { IUser, IRole } from '@models';
import MaterialIcon from '@fv-components/material-icon';
import useUserQuery, { IUserQueryHookProps } from '@hooks/Identity/User/useUserQuery';
import { ITableState } from '@components/Table/Table';
import { snackBarService } from '@hooks/useSnackBar';
import useRoleQuery, { IRoleQueryHookProps } from '@hooks/Identity/Role/useRoleQuery';
import { AltButton, RoleTable } from '@components';
import { discardChangesDialogService } from '@hooks/useConfirmationDialog';
import UserAdminForm from './UserAdminForm';
import UserTable from './UserTable';

interface IUserAdminPageState {
  selectedUser?: IUser;
  userRequestObject: IUserQueryHookProps;
  roleRequestObject: IRoleQueryHookProps;
  isEditingMode: boolean;
  isValid?: boolean;
  isDirty?: boolean;
}

interface IProps {
  isLimited?: boolean;
  onIsEditingChange?: (isEditing: boolean) => void;
}

const UserAdminPage: FC<IProps> = ({
  isLimited,
  onIsEditingChange,
}) => {
  const userGraphQLQueryFields = [
    'id',
    'firstName',
    'lastName',
    'email',
    'lastLogin',
    'failedLoginAttempts',
    'lockedOutTill',
    'roles { id description }',
    'isActive',
    'organization { id description }',
  ];

  const roleGraphQLQueryFields = [
    'id',
    'description',
  ];
  const [state, setState] = useState<IUserAdminPageState>({
    userRequestObject: {
      queryObject: { skip: 0, take: 100 },
      fields: userGraphQLQueryFields,
      fetchPolicy: 'network-only',
    },
    isEditingMode: false,
    roleRequestObject: {
      queryObject: { skip: 0, take: 100 },
      fields: roleGraphQLQueryFields,
      lazy: true,
      fetchPolicy: 'network-only',
    },
    isDirty: false,
    isValid: true,
  });

  const roleApolloWrapper = useRoleQuery(state.roleRequestObject);
  const userApolloWrapper = useUserQuery(state.userRequestObject);

  const onRoleSelected = (selectedItem: IRole, isSelected: boolean) => {
    const selectedUser: IUser = {
      ...state.selectedUser,
      roles: isSelected ? [...state.selectedUser?.roles || [], selectedItem]
        : state.selectedUser?.roles?.filter((x: IRole) => x.id !== selectedItem.id),
    };
    setState({
      ...state,
      selectedUser,
      isDirty: true,
    });
  };

  const saveUser = () => {
    if (state.selectedUser
      && state.selectedUser.email
      && state.selectedUser.firstName
      && state.selectedUser.lastName
      && state.selectedUser.id
    ) {
      userApolloWrapper.useSaveUser.saveUser(
        state.selectedUser.id,
        state.selectedUser.email,
        state.selectedUser.firstName,
        state.selectedUser.lastName,
        state.selectedUser.password,
        state.selectedUser.roles,
        state.selectedUser.isActive,
        state.selectedUser.organization,
      )
        .then(() => {
          snackBarService.showSnackBar('Success updating the user.');
          if (onIsEditingChange) {
            onIsEditingChange(false);
          }
          setState({
            ...state,
            isEditingMode: false,
            isValid: true,
            isDirty: false,
            selectedUser: undefined,
          });
        })
        .catch(() => snackBarService.showSnackBar('Error! There was an error updating the user.'));
    }
  };

  const saveRoleUpdates = () => {
    if (state.selectedUser?.id && state.selectedUser.roles) {
      userApolloWrapper.updateRoles.setRoles(
        state.selectedUser.id,
        state.selectedUser.roles,
      )
        .then(() => {
          snackBarService.showSnackBar('Success updating the user roles.');
          setState({
            ...state,
            isEditingMode: false,
            isValid: true,
            isDirty: false,
            selectedUser: undefined,
          });
          if (onIsEditingChange) {
            onIsEditingChange(false);
          }
        })
        .catch(() => snackBarService.showSnackBar('Error! There was an error updating the user roles.'));
    }
  };

  const onSaveClicked = () => {
    if (!isLimited) {
      saveUser();
    } else {
      saveRoleUpdates();
    }
  };

  const userTableStateChanged = (tableState: ITableState) => {
    const queryObject = {
      ...tableState,
      ...tableState.filter,
      filter: undefined,
    };
    setState({
      ...state,
      userRequestObject: {
        queryObject,
        fields: userGraphQLQueryFields,
      },
    });
  };

  const roleTableStateChanged = (tableState: ITableState) => {
    const queryObject = {
      ...tableState,
      ...tableState.filter,
      filter: undefined,
    };
    setState({
      ...state,
      roleRequestObject: {
        queryObject,
        fields: roleGraphQLQueryFields,
      },
    });
  };

  const onUserEdit = (user: IUser) => {
    roleApolloWrapper.load();
    if (onIsEditingChange) {
      onIsEditingChange(true);
    }
    setState({
      ...state,
      selectedUser: {
        // doiung this so that changes made to the user
        // wont affect the properties of the user in the grid unless saved
        ...user,
      },
      isEditingMode: true,
      isValid: true,
      isDirty: false,
    });
  };

  const onFormChange = (selectedUser: IUser, isDirty: boolean, isValid: boolean) => {
    setState({
      ...state,
      selectedUser,
      isValid,
      isDirty,
    });
  };

  const onCancelClick = async () => {
    if (state.isDirty) {
      discardChangesDialogService.showDialog(() => {
        setState({
          ...state,
          selectedUser: undefined,
          isEditingMode: false,
        });
        if (onIsEditingChange) {
          onIsEditingChange(false);
        }
      });
    } else {
      setState({
        ...state,
        isEditingMode: false,
      });
      if (onIsEditingChange) {
        onIsEditingChange(false);
      }
    }
  };

  const onSetIsActive = (user: IUser, isActive: boolean) => {
    if (user.id) {
      userApolloWrapper.updateIsActive.setIsActive(user.id, isActive)
        .then(() => snackBarService.showSnackBar('Save successful.'))
        .catch(() => snackBarService.showSnackBar('Error! Unable to save user.'));
    }
  };

  return (
    <div className="flex flex-col gap-3">
      <div className="flex flex-row">
        <div className={state.isEditingMode ? 'hidden' : 'w-full'}>
          <UserTable
            onTableStateChanged={userTableStateChanged}
            onUserEdit={onUserEdit}
            hasMore={userApolloWrapper.result.hasMore}
            users={userApolloWrapper.result.items}
            loading={userApolloWrapper.loading}
            isLimited={isLimited}
            onSetActive={onSetIsActive}
          />
        </div>
        {state.isEditingMode && !!state.selectedUser
          && (
          <div className="flex flex-col flex-1">
            <div className="flex md:flex-row flex-col gap-3">
              {!isLimited && (
              <div className="flex-1">
                <UserAdminForm
                  user={state.selectedUser}
                  onFormChange={onFormChange}
                />
              </div>
              )}
              <div className="flex-1 flex flex-col gap-3">
                {isLimited && (
                  <b className="text-2xl flex-1">
                    {`Roles For: ${state.selectedUser.firstName} ${state.selectedUser.lastName}`}
                  </b>
                )}
                <RoleTable
                  onTableStateChanged={roleTableStateChanged}
                  hasMore={roleApolloWrapper.result.hasMore}
                  roles={roleApolloWrapper.result.items}
                  onRoleSelect={onRoleSelected}
                  selectedRoles={state.selectedUser?.roles || []}
                  loading={roleApolloWrapper.loading}
                />
              </div>
            </div>
            {(userApolloWrapper.useSaveUser.result.loading
              || userApolloWrapper.updateIsActive.result.loading
              || userApolloWrapper.updateRoles.result.loading)
              && <LinearProgress indeterminate />}
            <div className="flex flex-row justify-end gap-3">
              <Button
                onClick={onSaveClicked}
                disabled={!state.isDirty || !state.isValid}
                role="button"
                aria-label="Save User"
                raised
                icon={<MaterialIcon icon="save" />}
              >
                Save
              </Button>
              <AltButton
                onClick={onCancelClick}
                aria-label="Cancel User Edit"
                icon={<MaterialIcon icon="cancel" />}
              >
                Cancel
              </AltButton>
            </div>
          </div>
          )}
      </div>
    </div>
  );
};

export default UserAdminPage;

import {
  useMutation, ApolloError, FetchResult, DocumentNode, gql,
} from '@apollo/client';
import IRoleQuery from '@models/Identity/Role/IRoleQuery';
import IRole from '@models/Identity/Role/IRole';
import IJurisdiction from '@models/Jurisdiction/IJurisdiction';
import IPermission from '@models/Identity/Role/Permission/IPermission';
import { handleSaveResult } from '@libs/apollo';

type RoleSaveHook = (
  props: IRoleSaveHookProps
) => IRoleSaveHookResult;

interface IRoleSaveHookProps {
  queryObject?: IRoleQuery;
  queryString?: DocumentNode;
}

export interface IRoleSaveHookResult {
  result: {
    error?: ApolloError;
    loading: boolean;
    called: boolean;
  };
  saveRole: (
    description: string,
    id?: string,
    jurisdictions?: IJurisdiction[],
    permissions?: IPermission[],
    roles?: IRole[],
  ) => Promise<FetchResult<IRole>>;
}

interface ISaveRoleResult {
  saveRole: IRole;
}

const useRoleSave: RoleSaveHook = (
  { queryString, queryObject }: IRoleSaveHookProps,
) => {
  const SAVE_GQL = gql`
    mutation RoleMutation($role: RoleInputType!) {
      saveRole(item: $role) {
        id
      }
    }
  `;

  const [saveFunc, {
    error, loading, called, client,
  }] = useMutation<ISaveRoleResult>(SAVE_GQL);

  return {
    result: {
      error,
      loading,
      called,
    },
    saveRole: (
      description: string,
      id?: string,
      jurisdictions?: IJurisdiction[],
      permissions?: IPermission[],
      roles?: IRole[],
    ) => {
      const role = {
        id,
        description,
        jurisdictions,
        permissions,
        roles,
      };

      return saveFunc({
        variables: {
          role: {
            ...role,
            // i have to do this silly mapping to remove the __typename property
            jurisdictions: jurisdictions?.map((j: IJurisdiction) => (
              { id: j.id, description: j.description })),
            roles: roles?.map((r: IRole) => (
              { id: r.id, description: r.description })),
            permissions: permissions?.map((p: IPermission) => (
              {
                id: p.id,
                permissionObject: p.permissionObject,
                permissionAction: p.permissionAction,
              })),
          },
        },
      }).then((result: FetchResult<ISaveRoleResult>) => ({
        ...result,
        data: {
          ...handleSaveResult<IRole>(
            result.data?.saveRole || '',
            role,
            client,
            'roles',
            queryObject,
            queryString,
          ),
        },
      }));
    },
  };
};

export default useRoleSave;

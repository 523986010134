import React, {
  FC, useEffect, useState,
} from 'react';
import MaterialIcon from '@fv-components/material-icon';
import { AltButton } from '@components';
import Button from '@fv-components/button';
import { useHistory, useParams } from 'react-router-dom';
import {
  ITrigger, IServiceMethod, IServiceMethodGroup, ICategory, IDeadlineInheritanceBlock,
} from '@models';
import { getConfirmationDialogService } from '@hooks/useConfirmationDialog';
import { useDelete, IDeleteResult } from '@libs/apollo';
import { getSnackBarService } from '@hooks/useSnackBar';
import useTriggerQuery from '@hooks/Trigger/useTriggerQuery';
import { MenuList, MenuListItem } from '@fv-components/menu';
import ViewColumn from './ViewColumn';
import ViewRow from './ViewRow';
import DeadlineList from './DeadlineList/DeadlineList';

const fields: string[] = [
  'id',
  'description',
  'isFilingRequired',
  'parentTriggers { id description jurisdictionId jurisdiction { id description address }  }',
  'jurisdictionId',
  'jurisdiction { address description id parent { description id } deadlineInheritanceBlocks { deadlineId jurisdictionId }, triggerInheritanceBlocks }',
  'serviceMethods { id, description, jurisdictionId }',
  'serviceMethodGroups { id, description, jurisdictionId serviceMethods { id description jurisdictionId } }',
  'categories { id, description }',
  'childTriggers { id description jurisdictionId jurisdiction { id description address }  } ',
  'deadlineInheritanceBlocks',
];

const getServiceMethodsNotInGroups = (
  serviceMethods?: IServiceMethod[],
  groups?: IServiceMethodGroup[],
) => serviceMethods?.filter(
  (x: IServiceMethod) => !groups?.find(
    (g: IServiceMethodGroup) => g.serviceMethods?.find(
      (sm: IServiceMethod) => sm.id === x.id))) || [];

const TriggerView: FC = () => {
  const history = useHistory();
  const confirmationDialog = getConfirmationDialogService();
  const deleteTrigger = useDelete({ capitalizedType: 'Trigger' });
  const snackBar = getSnackBarService();
  const [trigger, setTrigger] = useState<ITrigger>();
  const { id } = useParams<{ id: string }>();

  const onDeleteConfirm = (confirmed: boolean) => {
    if (confirmed && trigger?.id) {
      deleteTrigger.delete(trigger?.id).then((results: IDeleteResult[]) => {
        if (!results.find((x: IDeleteResult) => x.deleted === false)) {
          history.push('/admin/deadlines', { jurisdiction: trigger.jurisdiction });
          snackBar.showSnackBar('Trigger deleted!');
        } else {
          snackBar.showSnackBar('There was an error deleting this trigger.');
        }
      });
    }
  };

  const { result: { items }, called, load } = useTriggerQuery({
    queryObject: { id, skip: 0, take: 1 },
    fields,
    lazy: true,
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    if (trigger?.id !== id && items.length) {
      setTrigger(items[0]);
    }
  }, [id, items, trigger]);

  const onDeleteTriggerClick = () => {
    confirmationDialog
      .showDialog(`Are you sure that you would like to delete this trigger?${trigger?.childTriggers?.length ? ` If you delete this trigger its' ${trigger.childTriggers.length} children will not inherit its' deadlines.` : ''}`)
      .then(onDeleteConfirm);
  };

  useEffect(() => {
    if (!called && id) {
      load();
    }
  }, [called, id, load]);

  const parentOrChildClick = (triggerId: string) => {
    history.push(`/admin/deadlines/triggers/${triggerId}`);
  };

  const sortTriggers = (list?: ITrigger[]) => {
    const result = [...(list || [])].sort((a: ITrigger, b: ITrigger) => (`${a.description}${a.jurisdiction?.description}` < `${b.description}${b.jurisdiction?.description}` ? -1 : 1));
    return result;
  };

  return (
    <div className="flex-1 flex flex-col gap-3">
      <div className="flex items-center justify-between">
        <b className="text-2xl">Deadlines</b>
        <div className="flex flex-row gap-2">
          <AltButton
            onClick={() => history.push('/admin/deadlines', { jurisdiction: trigger?.jurisdiction })}
            aria-label="Back To Search Trigger"
            data-cy="search-trigger"
            icon={<MaterialIcon icon="search" />}
          >
            Search
          </AltButton>
          <AltButton
            onClick={() => history.push('/admin/deadlines/triggers/new', { jurisdiction: trigger?.jurisdiction })}
            aria-label="Show Trigger Search Bar"
            data-cy="search-trigger"
            icon={<MaterialIcon icon="add" />}
          >
            Add Trigger
          </AltButton>
        </div>
      </div>
      <b className="text-xl">Trigger</b>
      <ViewRow>
        <ViewColumn>
          <b>Description:</b>
          <div className="truncate">{trigger?.description}</div>
        </ViewColumn>
        <ViewColumn>
          <b>Jurisdiction:</b>
          <div className="truncate">{trigger?.jurisdiction?.description}</div>
        </ViewColumn>
      </ViewRow>
      <ViewRow>
        <ViewColumn>
          <b>
            {`Parent Triggers ${trigger?.parentTriggers?.length ? ` (${trigger.parentTriggers?.length})` : ''}:`}
          </b>
          <div className="max-h-52 overflow-y-auto">
            <MenuList>
              {sortTriggers(trigger?.parentTriggers)?.map((pt: ITrigger) => (
                <MenuListItem
                  key={pt.id}
                  className="flex flex-row items-center gap-2"
                  onClick={() => pt?.id && parentOrChildClick(pt.id)}
                >
                  <MaterialIcon
                    icon="folder"
                    className="cursor-pointer text-blue"
                  />
                  <div className="flex flex-col">
                    <div className="text-sm">{pt.description}</div>
                    <div className="text-xs text-gray">{pt.jurisdiction?.description}</div>
                  </div>
                </MenuListItem>
              ),
              )}
            </MenuList>
          </div>
        </ViewColumn>
        <ViewColumn>
          <b>
            {`Child Triggers ${trigger?.childTriggers?.length ? ` (${trigger.childTriggers?.length})` : ''}:`}
          </b>
          <div className="max-h-52 overflow-y-auto">
            <MenuList>
              {sortTriggers(trigger?.childTriggers)?.map((ct: ITrigger) => (
                <MenuListItem
                  key={ct.id}
                  className="flex flex-row items-center gap-2"
                  onClick={() => ct?.id && parentOrChildClick(ct.id)}
                >
                  <MaterialIcon
                    icon="folder"
                    className="cursor-pointer text-blue"
                  />
                  <div className="flex flex-col">
                    <div className="text-sm">{ct.description}</div>
                    <div className="text-xs text-gray">{ct.jurisdiction?.description}</div>
                  </div>
                </MenuListItem>
              ),
              )}
            </MenuList>
          </div>
        </ViewColumn>
      </ViewRow>
      {(!!trigger?.serviceMethods?.length || !!trigger?.serviceMethodGroups?.length) && (
        <ViewRow>
          <ViewColumn>
            <b>
              Service Method Groups:
            </b>
            <div>{`${trigger.serviceMethodGroups?.map((g: IServiceMethodGroup) => g.description).join(', ') || ''}`}</div>
          </ViewColumn>
          <ViewColumn>
            <b>
              Service Methods:
            </b>
            <div>
              {`${getServiceMethodsNotInGroups(trigger.serviceMethods, trigger.serviceMethodGroups)
                .map((g: IServiceMethod) => g.description).join(', ') || ''}`}
            </div>
          </ViewColumn>
        </ViewRow>
      )}
      <ViewRow rightToLeft>
        {!!trigger?.categories?.length && (
          <ViewColumn>
            <b>
              {`Keywords (${trigger?.categories?.length}):`}
            </b>
            <div>{trigger.categories?.map((sm: ICategory) => `${sm.description}`).join(', ')}</div>
          </ViewColumn>
        )}
        <div className="flex flex-row gap-3">
          <AltButton
            onClick={onDeleteTriggerClick}
            aria-label="Delete Trigger"
            data-cy="delete-trigger"
            icon={<MaterialIcon icon="delete" />}
          >
            Delete Trigger
          </AltButton>
          <Button
            onClick={() => history.push(`/admin/deadlines/triggers/${id}/edit`)}
            aria-label="Edit Trigger"
            raised
            data-cy="edit-trigger"
            icon={<MaterialIcon icon="edit" />}
          >
            Edit Trigger
          </Button>
        </div>
      </ViewRow>
      {!!trigger?.id && !!trigger?.jurisdiction?.id
        && (
          <DeadlineList
            trigger={trigger}
            parentTriggerIds={trigger?.parentTriggers?.map((tr: ITrigger) => tr.id || '')}
            deadlineInheritanceBlocks={[
              ...(trigger.jurisdiction.deadlineInheritanceBlocks?.map(
                (b: IDeadlineInheritanceBlock) => b.deadlineId) || []),
              ...(trigger.deadlineInheritanceBlocks || []),
            ]}
          />
        )}
    </div>
  );
};

export default TriggerView;

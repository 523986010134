import React, { FC, useState } from 'react';
import Snackbar from '@fv-components/snackbar';
import { ITableMenuOption } from '@components/Table/Menu/TableMenu';
import ConfirmationDialog from '@components/ConfirmationDialog/ConfirmationDialog';
import Table, { ITableState } from '@components/Table/Table';
import { IRowMenuOption } from '@components/Table/Menu/RowMenu';
import ICategory from '@models/Deadlines/Categories/ICategory';
import useCategoryQuery, { ICategoryQueryHookProps } from '@hooks/Categories/useCategoryQuery';
import EditKeywordModal from './EditKeywordModal';

const KeywordAdminPage: FC = () => {
  const graphQLQueryFields = [
    'id',
    'description',
  ];
  const [isEditorModalVisible, setIsEditorModalVisible] = useState(false);
  const [isConfirmationDialogVisible, setIsConfirmationDialogVisible] = useState(false);
  const [selectedCategorys, setSelectedCategorys] = useState<ICategory[]>([]);
  const [category, setCategory] = useState<ICategory | undefined>();
  const [requestObject, setRequestObject] = useState<ICategoryQueryHookProps>({
    queryObject: { skip: 0, take: 100 },
    fields: graphQLQueryFields,
    fetchPolicy: 'network-only',
  });

  const apolloWrapper = useCategoryQuery(requestObject);

  const options: ITableMenuOption[] = [
    {
      key: 'deleteSelected',
      text: 'Delete Selected',
      onClick: (selectedItems?: ICategory[]) => {
        if (selectedItems) {
          setSelectedCategorys(selectedItems);
          setIsConfirmationDialogVisible(true);
        }
      },
    },
  ];

  const rowOptions: IRowMenuOption[] = [
    {
      key: 'edit',
      text: 'Edit',
      onClick: (item: ICategory) => {
        setCategory(item);
        setIsEditorModalVisible(true);
      },
    },
  ];

  const onDeleteConfirmationClose = (deleteConfirmed: boolean) => {
    if (deleteConfirmed) {
      if (selectedCategorys && selectedCategorys.length > 0) {
        apolloWrapper.deleteCategoryHook.delete(
          ...selectedCategorys.filter((x: ICategory) => x.id)
            .map((x: ICategory) => x.id as string),
        );
      }
      setSelectedCategorys([]);
    }
    setIsConfirmationDialogVisible(false);
  };

  const onFabClick = () => {
    setIsEditorModalVisible(true);
  };

  const onEditorModalClosed = () => {
    setIsEditorModalVisible(false);
    setCategory(undefined);
  };

  const tableStateChanged = (state: ITableState) => {
    const queryObject = {
      ...state,
      ...state.filter,
      filter: undefined,
    };
    setRequestObject({
      queryObject,
      fields: graphQLQueryFields,
    });
  };

  const deleteSuccessful = apolloWrapper.deleteCategoryHook.result.called
    && !apolloWrapper.deleteCategoryHook.result.loading
    && !apolloWrapper.deleteCategoryHook.result.error;

  const deleteError = apolloWrapper.deleteCategoryHook.result.called
    && !apolloWrapper.deleteCategoryHook.result.loading
    && apolloWrapper.deleteCategoryHook.result.error;

  const saveSuccessful = apolloWrapper.saveCategoryHook.result.called
    && !apolloWrapper.saveCategoryHook.result.loading
    && !apolloWrapper.saveCategoryHook.result.error;

  const saveError = apolloWrapper.saveCategoryHook.result.called
    && !apolloWrapper.saveCategoryHook.result.loading
    && apolloWrapper.saveCategoryHook.result.error;

  return (
    <div className="flex flex-col gap-3">
      <Table
        dataSource={apolloWrapper.items}
        isMultiSelect
        tableMenuOptions={options}
        rowMenuOptions={rowOptions}
        minHeight={400}
        maxHeight={700}
        isFabVisible
        onFabClick={onFabClick}
        fabIcon="add"
        fabAriaLabel="Add New Keyword"
        onStateChange={tableStateChanged}
        hasMore={apolloWrapper.hasMore}
        takeOptions={[100, 500]}
        isPagerVisible
        loading={apolloWrapper.loading || apolloWrapper.deleteCategoryHook.result.loading}
        fabText="Add Keyword"
        title="Keywords"
      >
        {[
          {
            key: 'description',
            header: 'Description',
            field: 'description',
            isFilterable: true,
            isSortable: true,
          },
        ]}
      </Table>
      {isEditorModalVisible
        && (
          <EditKeywordModal
            isOpen
            category={category}
            onClose={onEditorModalClosed}
            save={apolloWrapper.saveCategoryHook}
          />
        )}
      <ConfirmationDialog
        isOpen={isConfirmationDialogVisible}
        text="Do you really want to delete these keywords?"
        onClose={onDeleteConfirmationClose}
      />
      <Snackbar open={deleteSuccessful} message="Delete successful." />
      <Snackbar open={!!deleteError} message="There was an error deleting the keywords." />
      <Snackbar open={saveSuccessful} message="Save successful." />
      <Snackbar open={!!saveError} message="There was an error saving the keyword." />
    </div>
  );
};

export default KeywordAdminPage;

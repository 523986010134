/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { FC } from 'react';
import MaterialIcon from '@fv-components/material-icon';
import {
  MenuListItem,
  MenuListItemText,
  MenuListItemGraphic,
} from '@fv-components/menu';
import { IMultiSelectDropDownItem } from './MultiSelectDropDown';

interface IMultiSelectDropDownItemProps {
  option: IMultiSelectDropDownItem;
  display: string;
  onItemSelected: (option: IMultiSelectDropDownItem) => void;
  onKeyDown: (event: React.KeyboardEvent) => void;
  caption?: string;
}

const MultiSelectDropDown: FC<IMultiSelectDropDownItemProps> = (
  {
    option,
    display,
    onItemSelected,
    onKeyDown,
    caption,
  }: IMultiSelectDropDownItemProps,
) => {
  const onSelect = (
    menuOption: IMultiSelectDropDownItem,
    event?: React.MouseEvent<HTMLElement, MouseEvent>,
  ) => {
    if (event) {
      event.stopPropagation();
    }
    onItemSelected({
      ...menuOption,
      selected: !menuOption.selected,
    });
  };

  const onMenuItemKeyDown = (
    event: React.KeyboardEvent,
    menuOption: IMultiSelectDropDownItem,
  ) => {
    switch (event.key) {
      case ' ':
      case 'Enter':
        onSelect(menuOption);
        break;
      default:
        onKeyDown(event);
        break;
    }
  };

  return (
    <MenuListItem
      aria-label={`Select Option ${display}`}
      // TODO this role property was causing the application to crash
      // Find a better way to make this accessible
      // role="checkbox"
      // aria-checked={state.selectedIndexes.indexOf(index) < 0 ? 'false' : 'true'}
      // TODO ... i have no way of knowing if the item has an id
      // this works for now
      onClick={(
        event: React.MouseEvent<HTMLElement, MouseEvent>,
      ) => onSelect(option, event)}
      onKeyDown={(event: React.KeyboardEvent) => onMenuItemKeyDown(event, option)}
    >
      <MenuListItemGraphic
        className={
          option.selected ? 'text-blue' : 'text-gray-light'
        }
        graphic={(
          <MaterialIcon
            icon="check"
          />
        )}
      />
      <div className="flex flex-col">
        <MenuListItemText primaryText={display} />
        {!!caption && (
          <div className="text-gray text-xs">
            {caption}
          </div>
        )}
      </div>
    </MenuListItem>
  );
};

export default MultiSelectDropDown;

import React, { FC } from 'react';
import useJurisdictionQuery from '@hooks/Jurisdiction/useJurisdictionQuery';

const css = require('./NoRoles.module.css');

const NoRoles: FC = () => {
  // the purpose of adding this is to ensure that if you ended up here we try to make
  // a network call and if the network call has an issue with your token you will
  // get kicked out of the app
  useJurisdictionQuery({ fields: ['id'], queryObject: { skip: 0, take: 1 } });

  return (
    <div className={css.container}>
      <div className={css.column}>
        <div>
          <h2>Contact Us</h2>
          <p>{'You have successfully logged in but you haven\'t been set up with access to any jurisdictions yet. Please contact us to get started!'}</p>
          {/* <p>TODO: Provide a link to sales or enable
          some chat program to contact support.</p> */}
        </div>
      </div>
    </div>
  );
};

export default NoRoles;

import { ApolloError, FetchPolicy } from '@apollo/client';
import IUserQuery from '@models/Identity/User/IUserQuery';
import IUser from '@models/Identity/User/IUser';
import useTimelyQuery from '@hooks/useTimelyQuery';
import useUserSave, { IUserSaveHookResult } from './useUserSave';
import useUserSetIsActive, { ISetIsActiveHookResult } from './useUserSetIsActive';
import useUserSetRoles, { ISetRolesHookResult } from './useUserSetRoles';

type UserQueryHook = (
  props: IUserQueryHookProps
) => IUserQueryHookResult;

export interface IUserQueryHookProps {
  queryObject: IUserQuery;
  fields: string[];
  lazy?: boolean;
  fetchPolicy?: FetchPolicy;
}

interface IUserQueryHookResult {
  loading: boolean;
  error?: ApolloError;
  result: { hasMore: boolean; items: IUser[] };
  useSaveUser: IUserSaveHookResult;
  updateIsActive: ISetIsActiveHookResult;
  updateRoles: ISetRolesHookResult;
  called: boolean;
}

const useUserQuery: UserQueryHook = ({
  queryObject, fields, lazy, fetchPolicy,
} : IUserQueryHookProps) => {
  const {
    loading, error, result, queryString, called, load,
  } = useTimelyQuery<IUser>({
    queryInputType: 'UserQueryInputType',
    collection: 'users',
    fields,
    variables: { queryObject },
    lazy,
    fetchPolicy,
  });

  const save = useUserSave({ queryString, queryObject });
  const updateIsActive = useUserSetIsActive({ queryString, queryObject });
  const updateRoles = useUserSetRoles({ queryString, queryObject });

  return {
    called,
    load,
    loading,
    error,
    result,
    useSaveUser: save,
    updateIsActive,
    updateRoles,
  };
};

export default useUserQuery;

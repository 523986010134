import { useMutation, ApolloError, gql } from '@apollo/client';

type UseForgotPasswordHook = () => IUseForgotPasswordHookResult;

export interface IUseForgotPasswordHookResult {
  result: {
    data?: { forgotPassword: boolean } | null;
    error?: ApolloError;
    loading: boolean;
    called: boolean;
  };
  forgotPassword: (
      email: string,
  ) => void;
}

const useForgotPassword: UseForgotPasswordHook = () => {
  const FORGOT_PASSWORD = gql`
    mutation ($request: PasswordResetRequestInputType!) {
      forgotPassword(item: $request)
    }
  `;

  const [saveFunc, {
    data, error, loading, called,
  }] = useMutation<{forgotPassword: boolean}>(
    FORGOT_PASSWORD,
  );

  return {
    result: {
      data, error, loading, called,
    },
    forgotPassword: (
      email: string,
    ) => saveFunc({
      variables: {
        request: {
          email,
        },
      },
    }),
  };
};

export default useForgotPassword;

import React, { FC } from 'react';
import { useHistory } from 'react-router-dom';
import RegisterForm from './RegisterForm/RegisterForm';

interface IRegistrationProps {
  inviteId: string;
}

const Register : FC<IRegistrationProps> = ({
  inviteId,
}) => {
  const history = useHistory();

  const onRegister = () => {
    history.push('/sign-in');
  };

  return (<RegisterForm onRegister={onRegister} inviteId={inviteId} />);
};

export default Register;

import React, { FC, useState, useCallback } from 'react';
import Snackbar from '@fv-components/snackbar';
import { ITableMenuOption } from '@components/Table/Menu/TableMenu';
import { JurisdictionAutoComplete, Table, ConfirmationDialog } from '@components';
import useFilingMethodQuery, { IFilingMethodQueryHookProps } from '@hooks/FilingMethod/useFilingMethodQuery';
import { ITableState } from '@components/Table/Table';
import { IRowMenuOption } from '@components/Table/Menu/RowMenu';
import { IFilingMethod, IJurisdiction } from '@models';
import EditFilingMethodModal from './EditFilingMethodModal/EditFilingMethodModal';

const FilingMethodAdminPage: FC = () => {
  const graphQLQueryFields = [
    'id',
    'description',
    'jurisdiction { address hasChildren description id parent { description id address } }',
    'jurisdictionId',
    'warningMessage',
  ];
  const [isEditorModalVisible, setIsEditorModalVisible] = useState(false);
  const [isConfirmationDialogVisible, setIsConfirmationDialogVisible] = useState(false);
  const [selectedFilingMethods, setSelectedFilingMethods] = useState<IFilingMethod[]>([]);
  const [filingMethod, setFilingMethod] = useState<IFilingMethod>();
  const [jurisdiction, setJurisdiction] = useState<IJurisdiction>();

  const [
    filingMethodRequestObject,
    setFilingMethodRequestObject,
  ] = useState<IFilingMethodQueryHookProps>({
    queryObject: { skip: 0, take: 100 },
    fields: graphQLQueryFields,
    fetchPolicy: 'network-only',
  });

  const apolloWrapper = useFilingMethodQuery(filingMethodRequestObject);

  const options: ITableMenuOption[] = [
    {
      key: 'deleteSelected',
      text: 'Delete Selected',
      onClick: (selectedItems?: IFilingMethod[]) => {
        if (selectedItems && selectedItems.length) {
          setSelectedFilingMethods(selectedItems);
          setIsConfirmationDialogVisible(true);
        }
      },
    },
  ];

  const rowOptions: IRowMenuOption[] = [
    {
      key: 'edit',
      text: 'Edit',
      onClick: (item: IFilingMethod) => {
        setFilingMethod(item);
        setIsEditorModalVisible(true);
      },
    },
  ];

  const onDeleteConfirmationClose = (deleteConfirmed: boolean) => {
    if (deleteConfirmed) {
      if (selectedFilingMethods && selectedFilingMethods.length > 0) {
        apolloWrapper.delete.delete(
          ...selectedFilingMethods.filter((x: IFilingMethod) => x.id)
            .map((x: IFilingMethod) => x.id as string),
        );
      }
      setSelectedFilingMethods([]);
    }
    setIsConfirmationDialogVisible(false);
  };

  const onFabClick = () => {
    setFilingMethod({
      jurisdiction,
      jurisdictionId: jurisdiction?.id,
    });
    setIsEditorModalVisible(true);
  };

  const onEditorModalClosed = () => {
    setIsEditorModalVisible(false);
    setFilingMethod(undefined);
  };

  const tableStateChanged = (state: ITableState) => {
    setFilingMethodRequestObject({
      queryObject: {
        ...state,
        ...state.filter,
        filter: undefined,
        jurisdictionId: jurisdiction?.id,
      },
      fields: graphQLQueryFields,
    });
  };

  const onJurisdictionSelected = useCallback((j?: IJurisdiction) => {
    setJurisdiction(j);
    setFilingMethodRequestObject((current: IFilingMethodQueryHookProps) => ({
      ...current,
      queryObject: {
        ...current.queryObject,
        jurisdictionId: j?.id,
      },
    }));
  },
  []);

  const deleteSuccessful = apolloWrapper.delete.result.called
    && !apolloWrapper.delete.result.loading
    && !apolloWrapper.delete.result.error;

  const deleteError = apolloWrapper.delete.result.called
    && !apolloWrapper.delete.result.loading
    && apolloWrapper.delete.result.error;

  const saveSuccessful = apolloWrapper.save.result.called
    && !apolloWrapper.save.result.loading
    && !apolloWrapper.save.result.error;

  const saveError = apolloWrapper.save.result.called
    && !apolloWrapper.save.result.loading
    && apolloWrapper.save.result.error;

  return (
    <div>
      <JurisdictionAutoComplete
        value={jurisdiction}
        onSelect={onJurisdictionSelected}
      />
      <Table
        dataSource={apolloWrapper.result.items}
        isMultiSelect
        tableMenuOptions={options}
        rowMenuOptions={rowOptions}
        minHeight={400}
        maxHeight={700}
        isFabVisible
        onFabClick={onFabClick}
        fabIcon="add"
        fabAriaLabel="Add New FilingMethod"
        onStateChange={tableStateChanged}
        hasMore={apolloWrapper.result.hasMore}
        takeOptions={[100, 500]}
        isPagerVisible
        loading={apolloWrapper.loading || apolloWrapper.delete.result.loading}
        fabText="Add Filing Methods"
        title="Filing Methods"
      >
        {[
          {
            key: 'description',
            header: 'Method',
            field: 'description',
            isFilterable: true,
            isSortable: true,
          },
          {
            key: 'jurisdiction.description',
            header: 'Jurisdiction',
            field: 'jurisdiction.description',
            isFilterable: false,
            isSortable: false,
          },
          {
            key: 'jurisdiction.parent.description',
            header: 'Parent Jurisdiction',
            field: 'jurisdiction.parent.description',
            isFilterable: false,
            isSortable: false,
          },
          {
            key: 'warningMessage',
            header: 'Warning',
            field: 'warningMessage',
            isFilterable: true,
            isSortable: true,
          },
        ]}
      </Table>
      {isEditorModalVisible
        && (
          <EditFilingMethodModal
            isOpen
            filingMethod={filingMethod}
            onClose={onEditorModalClosed}
            save={apolloWrapper.save}
          />
        )}
      <Snackbar open={deleteSuccessful} message="Delete successful." />
      <Snackbar open={!!deleteError} message="There was an error deleting the filing methods." />
      <Snackbar open={saveSuccessful} message="Save successful." />
      <Snackbar open={!!saveError} message="Error! Unable to save the filing method." />
      <ConfirmationDialog
        isOpen={isConfirmationDialogVisible}
        text="Do you really want to delete these filing methods?"
        onClose={onDeleteConfirmationClose}
      />
    </div>
  );
};

export default FilingMethodAdminPage;

/* eslint-disable jsx-a11y/label-has-associated-control */
import React, {
  FC,
  useState,
  useEffect,
  useCallback,
} from 'react';
import { ITrigger, IJurisdiction, ITriggerInheritanceBlock } from '@models';
import { TriggerAutoComplete, JurisdictionAutoComplete } from '@components';
import MaterialIcon from '@fv-components/material-icon';
import IconButton from '@fv-components/icon-button';
import { useTriggerInheritanceBlockMutations } from '@hooks';

interface ITriggerRelationshipProps {
  trigger?: ITrigger;
  onUpdate: (trigger: ITrigger) => void;
  parentTriggerJurisdiction?: IJurisdiction;
  childTriggerJurisdiction?: IJurisdiction;
  blockedTriggerIds?: string[];
  onBlockAdd?: (triggerId: string) => void;
  onBlockRemove?: (triggerId: string) => void;
}

const TriggerRelationship: FC<ITriggerRelationshipProps> = (
  {
    trigger,
    onUpdate,
    parentTriggerJurisdiction,
    childTriggerJurisdiction,
    blockedTriggerIds,
    onBlockAdd,
    onBlockRemove,
  }: ITriggerRelationshipProps,
) => {
  const [jurisdiction, setJurisdiction] = useState<IJurisdiction>();
  const { addBlock, removeBlock } = useTriggerInheritanceBlockMutations();

  useEffect(() => {
    setJurisdiction(trigger?.jurisdiction
      || parentTriggerJurisdiction);
  }, [parentTriggerJurisdiction, trigger]);

  const triggerSelected = useCallback((trig?: ITrigger) => {
    if (trig || (trigger?.id && !trig)) {
      onUpdate(trig || { jurisdiction, jurisdictionId: jurisdiction?.id });
    }
  }, [jurisdiction, onUpdate, trigger]);

  const isBlocked = childTriggerJurisdiction?.triggerInheritanceBlocks?.includes(trigger?.id || '');

  const onToggleBlockClick = () => {
    if (childTriggerJurisdiction?.id && trigger?.id) {
      if (isBlocked) {
        removeBlock({
          jurisdictionId: childTriggerJurisdiction?.id,
          triggerId: trigger.id,
        }).then((block?: ITriggerInheritanceBlock) => onBlockRemove
          && block?.triggerId && onBlockRemove(block.triggerId));
      } else {
        addBlock({
          jurisdictionId: childTriggerJurisdiction?.id,
          triggerId: trigger.id,
        }).then((block?: ITriggerInheritanceBlock) => onBlockAdd
          && block?.triggerId && onBlockAdd(block.triggerId));
      }
    }
  };

  return (
    <div className="flex gap-3 flex-col md:flex-row">
      {childTriggerJurisdiction && (
        <IconButton
          className={`${!(childTriggerJurisdiction.id === jurisdiction?.id || !trigger?.id) ? 'text-blue' : ''} m-0`}
          onClick={onToggleBlockClick}
          role="button"
          disabled={childTriggerJurisdiction.id === jurisdiction?.id || !trigger?.id}
        >
          <MaterialIcon
            icon={isBlocked ? 'visibility_off' : 'visibility'}
          />
        </IconButton>
      )}
      <div className="md:flex-1">
        <JurisdictionAutoComplete
          value={jurisdiction}
          onSelect={setJurisdiction}
          mustBeBelowJurisdiction={parentTriggerJurisdiction}
          mustBeAboveJurisdiction={childTriggerJurisdiction}
          isAutoSelect
        />
      </div>
      <div className="flex-1">
        <TriggerAutoComplete
          onSelect={triggerSelected}
          jurisdictionIds={jurisdiction?.id ? [jurisdiction?.id] : undefined}
          value={trigger}
          blockIdsFromSelection={blockedTriggerIds}
        />
      </div>
    </div>
  );
};

export default TriggerRelationship;

import React, { FC, useState } from 'react';
import Dialog, {
  DialogTitle,
  DialogContent,
} from '@fv-components/dialog';
import Button from '@fv-components/button';
import TextField, { Input } from '@fv-components/text-field';
import LinearProgress from '@fv-components/linear-progress';
import { AltButton, ModalBottomButtonRow } from '@components';
import { ICategory } from '@models';
import { ICategorySaveHookResult } from '@hooks/Categories/useCategorySave';
import { discardChangesDialogService } from '@hooks/useConfirmationDialog';
import { snackBarService } from '@hooks/useSnackBar';
import MaterialIcon from '@fv-components/material-icon';

interface IProps {
  isOpen: boolean;
  category?: ICategory;
  onClose: VoidFunction;
  save: ICategorySaveHookResult;
}

const EditKeywordModal: FC<IProps> = (
  {
    isOpen,
    category: c,
    onClose,
    save,
  }: IProps,
) => {
  const [category, setCategory] = useState<ICategory | undefined>(c);
  const [isDirty, setIsDirty] = useState(false);

  const onCancel = () => {
    if (isDirty) {
      discardChangesDialogService.showDialog(() => {
        setCategory(undefined);
        onClose();
        setIsDirty(false);
      });
    } else {
      setCategory(undefined);
      onClose();
      setIsDirty(false);
    }
  };

  const onDescriptionChange = (description: string) => {
    setIsDirty(true);
    setCategory({
      ...category,
      description,
    });
  };

  const saveCategory = async () => {
    if (category?.description
    ) {
      try {
        await save.saveCategory(category.description, category.id);
        setCategory(undefined);
        onClose();
        setIsDirty(false);
      } catch (e) {
        snackBarService.showSnackBar('There was an error saving this category.');
      }
    }
  };

  return (
    <Dialog onClose={onCancel} open={isOpen}>
      <DialogTitle>
        {category?.id ? 'Edit ' : 'Add New '}
        Keyword
      </DialogTitle>
      <DialogContent>
        <div className="flex flex-col gap-3 mt-2">
          <TextField label="Description" outlined>
            <Input
              value={category?.description}
              onChange={(event: React.FormEvent<HTMLInputElement>) => {
                onDescriptionChange(event.currentTarget.value);
              }}
            />
          </TextField>
        </div>
        <ModalBottomButtonRow>
          <Button
            onClick={saveCategory}
            role="button"
            aria-label="Save Keyword"
            raised
            disabled={!category?.description || !isDirty || save.result.loading}
            icon={<MaterialIcon icon="save" />}
          >
            Save
          </Button>
          <AltButton
            onClick={onCancel}
            aria-label="Cancel Keyword Edit"
            icon={<MaterialIcon icon="cancel" />}
          >
            Cancel
          </AltButton>
        </ModalBottomButtonRow>
      </DialogContent>
      <>
        {save.result.loading && <LinearProgress indeterminate />}
      </>
    </Dialog>
  );
};

export default EditKeywordModal;

import React, { FC } from 'react';
import useJurisdictionCountQuery from '@hooks/Jurisdiction/useJurisdictionCountQuery';

const Confirmation: FC = () => {
  const { result, loading, error } = useJurisdictionCountQuery();

  return (
    <>
      {!loading && !error && `Healthy: ${result > 0 && !error ? 'Yes' : 'No'}`}
      {loading && 'Healthy: loading...'}
    </>
  );
};

export default Confirmation;

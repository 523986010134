import React, { FC } from 'react';
import Table, { ITableState } from '@components/Table/Table';
import IJurisdiction from '@models/Jurisdiction/IJurisdiction';

interface IJurisdictionTableProps {
  jurisdictions?: IJurisdiction[];
  onTableStateChanged: (state: ITableState) => void;
  hasMore?: boolean;
  onJurisdictionSelect: (jurisdiction: IJurisdiction, isSelected: boolean) => void;
  selectedJurisdictions: IJurisdiction[];
  loading: boolean;
}

const JurisdictionTable: FC<IJurisdictionTableProps> = (
  {
    jurisdictions,
    onTableStateChanged,
    hasMore,
    onJurisdictionSelect,
    selectedJurisdictions,
    loading,
  },
) => (
  <Table
    dataSource={jurisdictions}
    isMultiSelect
    minHeight={400}
    maxHeight={600}
    onStateChange={onTableStateChanged}
    hasMore={hasMore}
    takeOptions={[100, 500]}
    isPagerVisible
    selected={selectedJurisdictions}
    onRowSelect={onJurisdictionSelect}
    loading={loading}
  >
    {[
      {
        key: 'description',
        header: 'Jurisdiction',
        field: 'description',
        isFilterable: true,
        isSortable: true,
      },
      {
        key: 'parent.description',
        header: 'Parent Jurisdiction',
        field: 'parent.description',
        isFilterable: false,
        isSortable: false,
      },
    ]}
  </Table>
);

export default JurisdictionTable;

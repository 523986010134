import React, { FC } from 'react';
import Card from '@fv-components/card';
import Button from '@fv-components/button';
import MaterialIcon from '@fv-components/material-icon';
import { proxy, useProxy } from 'valtio';

interface IRefreshNotice {
  needsRefreshing: boolean;
}

export const refreshNotice = proxy<IRefreshNotice>({
  needsRefreshing: localStorage.getItem('needsRefreshing') === 'true',
});

const RefreshNotice: FC = () => {
  const onClick = () => {
    refreshNotice.needsRefreshing = true;
    localStorage.setItem('needsRefreshing', 'false');

    caches.keys().then(
      (names: string[]) => names.forEach(
        (name: string) => caches.delete(name),
      ),
    );

    window.location.assign(`${window.location.href}?v=${new Date().getTime()}`);
  };

  const { needsRefreshing } = useProxy(refreshNotice);

  return needsRefreshing ? (
    <Card className="fixed bottom-0 left-0 m-6 p-6 flex flex-row gap-6 items-center z-20 bg-black">
      <div className="text-white">
        Update to the latest version of Timely for the most accurate deadlines.
      </div>
      <Button
        onClick={onClick}
        role="button"
        aria-label="update Timely"
        icon={<MaterialIcon icon="file_download" />}
        raised
        data-cy="update timely"
      >
        Update
      </Button>
    </Card>
  )
    : <></>;
};

export default RefreshNotice;

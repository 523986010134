import {
  useMutation, ApolloError, FetchResult, DocumentNode, gql,
} from '@apollo/client';
import IFilingMethodQuery from '@models/Jurisdiction/FilingMethods/IFilingMethodQuery';
import IFilingMethod from '@models/Jurisdiction/FilingMethods/IFilingMethod';
import { handleSaveResult } from '@libs/apollo';
import IJurisdiction from '@models/Jurisdiction/IJurisdiction';

type FilingMethodSaveHook = (
  props: IFilingMethodSaveHookProps
) => IFilingMethodSaveHookResult;

interface IFilingMethodSaveHookProps {
  queryObject?: IFilingMethodQuery;
  queryString?: DocumentNode;
}

export interface IFilingMethodSaveHookResult {
  result: {
    data?: IFilingMethod | null;
    error?: ApolloError;
    loading: boolean;
    called: boolean;
  };
  saveFilingMethod: (
    jurisdiction: IJurisdiction,
    description: string,
    warningMessage: string,
    id?: string
  ) => Promise<FetchResult<IFilingMethod>>;
}

interface ISaveFilingMethodResult {
  saveFilingMethod: IFilingMethod;
}

const useFilingMethodSave: FilingMethodSaveHook = (
  { queryString, queryObject }: IFilingMethodSaveHookProps,
) => {
  const SAVE_GQL = gql`
    mutation FilingMethodMutation($filingMethod: FilingMethodInputType!) {
      saveFilingMethod(item: $filingMethod) {
         id
      }
    }
  `;
  const [saveFunc, {
    data, error, loading, called, client,
  }] = useMutation<ISaveFilingMethodResult>(SAVE_GQL);

  return {
    result: {
      data: data?.saveFilingMethod,
      error,
      loading,
      called,
    },
    saveFilingMethod: (
      jurisdiction: IJurisdiction,
      description: string,
      warningMessage: string,
      id?: string,
    ) => {
      const filingMethod = {
        id,
        jurisdiction,
        jurisdictionId: jurisdiction.id,
        description,
        warningMessage,
      };

      return saveFunc({
        variables: {
          filingMethod: {
            ...filingMethod,
            jurisdiction: undefined,
          },
        },
      }).then((result: FetchResult<ISaveFilingMethodResult>) => ({
        ...result,
        data: {
          ...handleSaveResult<IFilingMethod>(
            result.data?.saveFilingMethod || '',
            filingMethod,
            client,
            'filingMethods',
            queryObject,
            queryString,
          ),
        },
      }));
    },
  };
};

export default useFilingMethodSave;

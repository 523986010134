import React, { FC } from 'react';
import Button from '@fv-components/button';
import MaterialIcon from '@fv-components/material-icon';
import { DisplayMode } from '../DateSelector';

const css = require('./Header.module.css');
const dsCss = require('../DateSelector.module.css');

interface IHeaderProps {
  onButtonClick: VoidFunction;
  buttonText: string;
  onPrevButtonClick: VoidFunction;
  onNextButtonClick: VoidFunction;
  isExpanded: boolean;
  displayMode: DisplayMode;
}
const Header: FC<IHeaderProps> = (
  {
    onButtonClick,
    buttonText,
    onPrevButtonClick,
    onNextButtonClick,
    isExpanded,
    displayMode,
  }: IHeaderProps,
) => {
  const getNavButtonAriaLabel = () => {
    if (displayMode === DisplayMode.CALENDAR) {
      return 'Month';
    }
    if (displayMode === DisplayMode.YEARS) {
      return 'Year List';
    }
    return 'Year';
  };

  return (
    <>
      <div className={css.container}>
        <Button
          onClick={onButtonClick}
          role="button"
          aria-label="Toggle Year Month Selector"
          aria-expanded={displayMode === DisplayMode.CALENDAR ? 'false' : 'true'}
        >
          <div className={`${dsCss.row} ${dsCss.alignCenter}`}>
            <b>{buttonText}</b>
            <MaterialIcon
              icon={!isExpanded ? 'expand_more' : 'expand_less'}
            />
          </div>
        </Button>
        <div className={dsCss.row}>
          <Button
            className={css.prevNextButton}
            onClick={onPrevButtonClick}
            role="button"
            aria-label={`Previous ${getNavButtonAriaLabel()}`}
          >
            <MaterialIcon icon="navigate_before" />
          </Button>
          <Button
            className={css.prevNextButton}
            onClick={onNextButtonClick}
            role="button"
            aria-label={`Next ${getNavButtonAriaLabel()}`}
          >
            <MaterialIcon icon="navigate_next" />
          </Button>
        </div>
      </div>
      <hr className={css.divider} />
    </>
  );
};

export default Header;

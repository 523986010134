import IconButton, {
  IconToggle,
  IconButtonBase,
} from '@material/react-icon-button';
import './IconButton.scss';

export default IconButton;

export {
  IconButtonBase,
  IconToggle,
};

import React, {
  FC, useState, useRef,
} from 'react';
import { useHistory } from 'react-router-dom';
import MaterialIcon from '@fv-components/material-icon';
import MenuSurface, { Corner } from '@fv-components/menu-surface';
import {
  MenuList,
  MenuListItem,
  MenuListItemText,
} from '@fv-components/menu';
import Button from '@fv-components/button';
import { IPermissionMap } from '@models';
import useFilevineClient from '@hooks/Filevine/useFilevineClient';
import { useMenuSurfaceCoordinates } from '@hooks';

interface IAccountMenuProps {
  onLogoutClicked: VoidFunction;
  userId?: string;
  userFullName?: string;
  permissions?: IPermissionMap;
}

const AccountMenu: FC<IAccountMenuProps> = (
  {
    userId,
    userFullName,
    onLogoutClicked,
  }: IAccountMenuProps,
) => {
  const [isMenuOpen, setIsMenuOpen] = useState<boolean>();
  const history = useHistory();
  const menuAnchor = useRef() as React.MutableRefObject<HTMLInputElement>;
  const coords = useMenuSurfaceCoordinates(menuAnchor);
  const {
    logout: filevineLogout,
    client,
  } = useFilevineClient();

  const onAccountMenuItemClicked = () => {
    setIsMenuOpen(false);
    if (userId) {
      history.push(`/my-profile/${userId}`);
    }
  };

  const onConnectToFilevineMenuItemClicked = () => {
    setIsMenuOpen(false);
    history.push('/connect');
  };

  const onRegisterMenuItemClicked = () => {
    setIsMenuOpen(false);
    history.push('/register');
  };

  const onProjectsMenuItemClicked = () => {
    setIsMenuOpen(false);
    history.push(`/projects/${userId}`);
  };

  const onLogInMenuItemClicked = () => {
    setIsMenuOpen(false);
    history.push('/sign-in');
  };

  const onLogout = () => {
    filevineLogout();
    onLogoutClicked();
  };

  return (
    <div ref={menuAnchor}>
      <Button
        className="text-white"
        onClick={() => setIsMenuOpen(!isMenuOpen)}
        aria-haspopup="true"
        aria-expanded={isMenuOpen ? 'true' : 'false'}
        aria-label="Toggle Account Menu"
        aria-controls="account-menu-surface"
        role="button"
        data-cy="toggle-account-menu"
      >
        <div className="flex flex-row items-center gap-3">
          <MaterialIcon
            icon="account_circle"
            className="text-4xl"
          />
          <p className="capitalize">{userFullName}</p>
          <MaterialIcon
            icon={isMenuOpen ? 'expand_less' : 'expand_more'}
          />
        </div>
      </Button>
      <MenuSurface
        open={isMenuOpen}
        anchorCorner={Corner.BOTTOM_LEFT}
        onClose={() => setIsMenuOpen(false)}
        anchorElement={menuAnchor.current}
        id="account-menu-surface"
        className="fixed"
        style={{ top: coords?.y, left: coords?.x, zIndex: 11 }}
      >
        <MenuList>
          {
            userId ? (
              <>
                <MenuListItem
                  onClick={onAccountMenuItemClicked}
                >
                  <MenuListItemText primaryText="My Profile" />
                </MenuListItem>
                {client
                  ? (
                    <MenuListItem
                      onClick={onProjectsMenuItemClicked}
                    >
                      <MenuListItemText primaryText="Filevine Projects" />
                    </MenuListItem>
                  ) : (
                    <MenuListItem onClick={onConnectToFilevineMenuItemClicked}>
                      <MenuListItemText primaryText="Connect To Filevine" />
                    </MenuListItem>
                  )}
                <MenuListItem
                  onClick={onLogout}
                >
                  <MenuListItemText primaryText="Sign Out" />
                </MenuListItem>
              </>
            ) : (
              <>
                <MenuListItem
                  onClick={onLogInMenuItemClicked}
                >
                  <MenuListItemText primaryText="Sign In" />
                </MenuListItem>
                <MenuListItem
                  onClick={onRegisterMenuItemClicked}
                >
                  <MenuListItemText primaryText="Register" />
                </MenuListItem>
              </>
            )
          }
        </MenuList>
      </MenuSurface>
    </div>
  );
};

export default AccountMenu;

import React, {
  FC,
} from 'react';
import { IMatter } from '@models';
import { InlineOrgPicker } from '@components';
import MatterTextField from './MatterTextField';

interface IMatterProps {
  onUpdate: (matter?: IMatter) => void;
  matter?: IMatter;
  onEnterKey?: VoidFunction;
  isVericalLayout?: boolean;
  isConnectedToFilevine?: boolean;
  isAlreadyExportedToFilevine?: boolean;
  filevineProjectIdFromUrl?: string;
}

const Matter: FC<IMatterProps> = (
  {
    onUpdate,
    matter,
    isVericalLayout,
    isConnectedToFilevine,
    filevineProjectIdFromUrl,
  }: IMatterProps,
) => {
  const onOrgSelected = () => {
    onUpdate();
  };

  return (
    <div className={`flex ${isVericalLayout ? 'flex-col' : 'flex-row items-center'} gap-3`}>
      {isConnectedToFilevine && (
      <InlineOrgPicker
        onOrgSelected={onOrgSelected}
      />
      )}
      <MatterTextField
        matter={matter}
        onUpdate={onUpdate}
        isConnectedToFilevine={isConnectedToFilevine}
        filevineProjectIdFromUrl={filevineProjectIdFromUrl}
      />
    </div>
  );
};

export default Matter;

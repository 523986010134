import { useState, useCallback } from 'react';
import { getConfig } from '@src/config';

const { googleApiKey, googleApiUrl } = getConfig();

interface IGoogleEventTime {
  date: string; // must be in yyy-mm-dd format
  timeZone: string;
}

interface IGoogleEventReminderOverride {
  minutes: number;
  method: 'email' | 'popup'
}

interface IGoogleEventReminder {
  useDefault: boolean;
  overrides?: IGoogleEventReminderOverride[]
}

export interface IGoogleEvent {
  start: IGoogleEventTime;
  end: IGoogleEventTime;
  description: string;
  summary: string; // The title
  reminders?: IGoogleEventReminder;
  id?: string;
  status?: string;
  htmlLink?: string;
}

export interface IGoogleInsertEventHookProps {
  accessToken?: string;
}

interface IGoogleInsertEventHookResult {
  error?: boolean;
  loading?: boolean;
  insertEvents: (calendarId: string, events: IGoogleEvent[]) => Promise<Response> | null;
}

const mapGoogleEventsToBatchUploadString = (
  calendarId: string,
  events: IGoogleEvent[],
  key?: string,
): string => (
  events.reduce((multiPartString: string, event: IGoogleEvent, index: number) => (
    `--batch_timely
Content-Type: application/http
Content-ID: ${index}

POST /calendar/v3/calendars/${encodeURIComponent(calendarId)}/events?key=${key}
Content-Type: application/json
Content-Length: ${JSON.stringify(event).length}

${JSON.stringify(event)}

${multiPartString}`), '--batch_timely--')
);

const insertEvents = (accessToken: string, calendarId: string, events: IGoogleEvent[]) => (
  fetch(`${googleApiUrl}/batch/calendar/v3?key=${googleApiKey}`, {
    method: 'POST',
    mode: 'cors',
    headers: {
      'Content-Type': 'multipart/mixed; boundary=batch_timely',
      Authorization: `Bearer ${accessToken}`,
    },
    body: mapGoogleEventsToBatchUploadString(calendarId, events, googleApiKey),
  })
);

interface IGoogleInsertEventHookState {
  loading?: boolean;
  error?: boolean;
}

const useGoogleInputEvents = ({
  accessToken,
} : IGoogleInsertEventHookProps): IGoogleInsertEventHookResult => {
  const [state, setState] = useState<IGoogleInsertEventHookState>();

  const insert = useCallback((calendarId: string, events: IGoogleEvent[]) => {
    setState({ loading: true, error: false });
    if (accessToken) {
      const result = insertEvents(accessToken, calendarId, events);
      result.then(() => setState({ loading: false, error: false }))
        .catch(() => setState({ loading: false, error: true }));
      return result;
    }
    return null;
  },
  [accessToken, setState]);

  return {
    error: state?.error,
    loading: state?.loading,
    insertEvents: insert,
  };
};

export default useGoogleInputEvents;

import React, { FC, useState } from 'react';
import dayjs, { Dayjs } from 'dayjs';
import Years from './Years/Years';
import Months from './Months/Months';
import Calendar from './Calendar/Calendar';

const css = require('./DateSelector.module.css');

interface IDateSelectorProps {
  date?: Dayjs;
  onSelectedDate: (date: Dayjs) => void;
  onPartialUpdate: (date: Dayjs) => void;
  minDate?: Dayjs;
  maxDate?: Dayjs;
}

export enum DisplayMode {
    MONTHS,
    YEARS,
    CALENDAR
}

export const isAnyDateValid = (
  startDate: Dayjs,
  endDate: Dayjs,
  minDate?: Dayjs,
  maxDate?: Dayjs,
): boolean => (!maxDate || startDate <= maxDate)
  && (!minDate || endDate >= minDate);

const DateSelector: FC<IDateSelectorProps> = (
  {
    date: d,
    onSelectedDate,
    minDate,
    maxDate,
    onPartialUpdate,
  }: IDateSelectorProps,
) => {
  const [date, setDate] = useState(d || dayjs());
  const [displayMode, setDisplayMode] = useState(DisplayMode.CALENDAR);

  const onYearButtonClicked = () => {
    setDisplayMode(DisplayMode.YEARS);
  };
  const onYearSelected = (newDate: Dayjs) => {
    setDisplayMode(DisplayMode.MONTHS);
    setDate(newDate);
    onPartialUpdate(newDate);
  };
  const onCloseYearMonthPicker = () => {
    setDisplayMode(DisplayMode.CALENDAR);
  };
  const onMonthSelected = (newDate: Dayjs) => {
    setDisplayMode(DisplayMode.CALENDAR);
    setDate(newDate);
    onPartialUpdate(newDate);
  };

  return (
    <div className={css.container}>
      <Months
        onClose={onCloseYearMonthPicker}
        onMonthSelected={onMonthSelected}
        date={date}
        isVisible={displayMode === DisplayMode.MONTHS}
        minDate={minDate}
        maxDate={maxDate}
      />
      <Years
        onYearSelect={onYearSelected}
        date={date}
        onClose={onCloseYearMonthPicker}
        isVisible={displayMode === DisplayMode.YEARS}
        minDate={minDate}
        maxDate={maxDate}
      />
      <Calendar
        isVisible={displayMode === DisplayMode.CALENDAR}
        onYearButtonClicked={onYearButtonClicked}
        date={date}
        onSelectedDate={onSelectedDate}
        minDate={minDate}
        maxDate={maxDate}
      />
    </div>
  );
};

export default DateSelector;

import React, { FC } from 'react';
import ICategory from '@models/Deadlines/Categories/ICategory';
import { PillBox, Pill } from '@components';

interface IKeywordProps {
  categories?: ICategory[];
  onCategoryRemove: (category: ICategory) => void;
}
const Keywords: FC<IKeywordProps> = (
  {
    categories,
    onCategoryRemove,
  }: IKeywordProps,
) => (
  <span className="print:hidden">
    <PillBox>
      {
        categories?.map(
          (cat: ICategory) => (
            <div key={cat.id}>
              <Pill
                notSelectable
                text={cat.description || ''}
                item={cat}
                removable
                onRemove={onCategoryRemove}
              />
            </div>
          ),
        )
      }
    </PillBox>
  </span>
);

export default Keywords;

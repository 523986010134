import Switch from '@material/react-switch';
import './Switch.scss';

/**
 * Switch MCWR Component
 *
 * @version ./package.json
 */
export default Switch;


/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, {
  FC,
} from 'react';

const css = require('./Tooltip.module.css');

interface ITooltipProps {
  tooltipValue?: number | string;
  minWidth?: string;
  children: React.ReactNode;
}

const Tooltip: FC<ITooltipProps> = ({
  tooltipValue,
  children,
  minWidth,
}: ITooltipProps) => (
  <div className="group relative">
    {tooltipValue ? (
      <div
        className={[css.surface, 'hidden', 'group-hover:block', 'absolute', 'bottom-6', 'left-0', 'z-50'].join(' ')}
      >
        <div
          style={{ minWidth }}
          className={css.container}
        >
          <div className={css.popup}>{tooltipValue}</div>
          <div className={css.arrow} />
        </div>
      </div>
    ) : <></>}
    {children}
  </div>
);

export default Tooltip;

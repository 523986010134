import React, {
  FC, useEffect, useState, useCallback,
} from 'react';
import { proxy, snapshot } from 'valtio';
import SnackBar from '@fv-components/snackbar';

interface ISnackBarHookResult {
  SnackBar: FC;
}

interface ISnackBarService {
  isInitialized: boolean;
  showSnackBar: (
    message: string,
  ) => void;
}

const snackBarService = proxy<ISnackBarService>(
  {
    isInitialized: false,
    showSnackBar: () => { throw new Error('service has not been initialized'); },
  },
);

const getSnackBarService = () : ISnackBarService => snapshot(snackBarService);

const useSnackBar: () => ISnackBarHookResult = () => {
  const [message, setMessage] = useState<string>();
  const [isVisible, setIsVisible] = useState<boolean>(false);

  const snackBar = useCallback(() => (
    <SnackBar
      message={message}
      open={isVisible}
      onClose={() => setIsVisible(false)}
    />
  ), [isVisible, message]);

  const showSnackBar = useCallback((text: string) => {
    setMessage(text);
    setIsVisible(true);
  }, []);

  // I only want this to run once
  useEffect(() => {
    snackBarService.isInitialized = true;
    snackBarService.showSnackBar = showSnackBar;
  }, [showSnackBar]);

  return {
    SnackBar: snackBar,
  };
};

export { getSnackBarService, snackBarService };
export default useSnackBar;

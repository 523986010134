import React, { FC, useState, FormEvent } from 'react';
import TextField, { Input } from '@fv-components/text-field';
import Button from '@fv-components/button';
import MaterialIcon from '@fv-components/material-icon';
import { useHistory } from 'react-router-dom';
import KeywordAutoComplete from '@components/KeywordsAutoComplete';
import { IJurisdiction, ICategory } from '@models';
import { AltButton } from '@components';

interface IDeadlineSearch {
  onSearchUpdate: VoidFunction;
  jurisdiction: IJurisdiction;
}

const TriggerSearch: FC<IDeadlineSearch> = ({ onSearchUpdate, jurisdiction }) => {
  const [specReference, setSpecReference] = useState<string>();
  const [description, setDescription] = useState<string>();
  const [shortDescription, setShortDescription] = useState<string>();
  const [categoryIds, setCategoryIds] = useState<string[]>();
  const history = useHistory();

  const onSpecReferenceChange = (event: FormEvent<HTMLInputElement>) => {
    setSpecReference(event.currentTarget.value);
  };

  const onDescriptionChange = (event: FormEvent<HTMLInputElement>) => {
    setDescription(event.currentTarget.value);
  };

  const onShortDescriptionChange = (event: FormEvent<HTMLInputElement>) => {
    setShortDescription(event.currentTarget.value);
  };

  const onSearchClick = () => {
    history.push('/admin/deadlines/search', {
      specReference, description, shortDescription, categoryIds, jurisdiction, activeTab: 1,
    });
    onSearchUpdate();
  };

  const onKeywordSelect = (keyword?: ICategory) => {
    setCategoryIds(keyword?.id ? [keyword.id] : undefined);
  };

  return (
    <div className="flex flex-col gap-3">
      <div className="flex flex-col md:flex-row gap-3 md:items-end">
        <div className="flex flex-col flex-grow gap-1">
          <b>Description</b>
          <TextField className="w-full" label="Search" outlined>
            <Input
              value={description}
              onChange={onDescriptionChange}
            />
          </TextField>
        </div>
        <div className="flex flex-col flex-grow gap-1">
          <b>Short Description</b>
          <TextField className="w-full" label="Search" outlined>
            <Input
              value={shortDescription}
              onChange={onShortDescriptionChange}
            />
          </TextField>
        </div>
        <div className="flex flex-col flex-grow gap-1">
          <b>Spec Reference</b>
          <TextField label="Search" outlined>
            <Input
              value={specReference}
              onChange={onSpecReferenceChange}
            />
          </TextField>
        </div>
        <div className="flex flex-col flex-grow gap-1">
          <b>Keywords</b>
          <KeywordAutoComplete onSelect={onKeywordSelect} />
        </div>
        <Button
          onClick={onSearchClick}
          className="mb-3 flex-0"
          aria-label="Search"
          raised
          data-cy="deadline-search"
          icon={<MaterialIcon icon="search" />}
        >
          Search
        </Button>
      </div>
      <AltButton
        onClick={() => history.push('/admin/deadlines/new', { jurisdiction })}
        aria-label="New Deadline"
        data-cy="new-deadline"
        icon={<MaterialIcon icon="add" />}
      >
        Add Deadline
      </AltButton>
    </div>
  );
};

export default TriggerSearch;

import React, {
  FC, useState, useEffect,
} from 'react';
import dayjs, { Dayjs } from 'dayjs';
import Button from '@fv-components/button';
import Header from '../Header/Header';
import CalendarCell from './CalendarCell/CalendarCell';
import Grid from '../Grid/Grid';
import CalendarRow from './CalendarRow';
import { DisplayMode } from '../DateSelector';

const dsCss = require('../DateSelector.module.css');
const css = require('./Calendar.module.css');

interface ICalendarProps {
  date: Dayjs;
  onSelectedDate: (date: Dayjs) => void;
  onYearButtonClicked: VoidFunction;
  isVisible: boolean;
  minDate?: Dayjs;
  maxDate?: Dayjs;
}

const getCellDate = (
  cellPosition: number,
  offset: number,
  cursorDate: Dayjs,
): Dayjs | undefined => {
  // given the position of the cell we need to determine
  // is the cell is empty or if the cell get's a number
  // when the first day of the month is not on a sunday we need to leave empty cells
  // also when the end of the month is on a saturday we need to leave empty cells
  // in this case the offset is the cells we need to leave blank before the month begins
  if (cellPosition < offset
    || cursorDate.daysInMonth() < cellPosition + 1 - offset) {
    return undefined;
  }
  const date = cellPosition + 1 - offset;
  return cursorDate.set('date', date);
};

const getCellPosition = (row?: number, col?: number) => (row || 0) * 7 + (col || 0);

const days = ['S', 'M', 'T', 'W', 'T', 'F', 'S'];

const getRowVales = (
  row: number,
  offset: number,
  cursorDate: Dayjs,
  // adding a new line here makes it much harder to read
  // eslint-disable-next-line function-paren-newline
): (Dayjs | undefined)[] => [
  getCellDate(getCellPosition(row, 0), offset, cursorDate),
  getCellDate(getCellPosition(row, 1), offset, cursorDate),
  getCellDate(getCellPosition(row, 2), offset, cursorDate),
  getCellDate(getCellPosition(row, 3), offset, cursorDate),
  getCellDate(getCellPosition(row, 4), offset, cursorDate),
  getCellDate(getCellPosition(row, 5), offset, cursorDate),
  getCellDate(getCellPosition(row, 6), offset, cursorDate),
];

const getGridValues = (
  cursorDate: Dayjs): (Dayjs | undefined
)[][] => {
  const offset = dayjs(cursorDate)
    .date(1)
    .day();

  return [
    getRowVales(0, offset, cursorDate),
    getRowVales(1, offset, cursorDate),
    getRowVales(2, offset, cursorDate),
    getRowVales(3, offset, cursorDate),
    getRowVales(4, offset, cursorDate),
    getRowVales(5, offset, cursorDate),
  ];
};

const Calendar: FC<ICalendarProps> = (
  {
    date,
    onSelectedDate,
    onYearButtonClicked,
    isVisible,
    minDate,
    maxDate,
  }: ICalendarProps,
) => {
  const [cursorDate, setCursorDate] = useState(date);
  const [gridValues, setGridValues] = useState<(Dayjs | undefined)[][]>([]);
  const today = dayjs();

  useEffect(() => {
    setCursorDate(date);
    setGridValues(getGridValues(date));
  }, [date]);

  const onPrevButtonClicked = () => {
    const m = dayjs(cursorDate).add(-1, 'M');
    setGridValues(getGridValues(m));
    setCursorDate(m);
  };

  const onNextButtonClicked = () => {
    const m = dayjs(cursorDate).add(1, 'M');
    setGridValues(getGridValues(m));
    setCursorDate(m);
  };

  return (
    <div
      hidden={!isVisible}
      className={isVisible ? dsCss.column : dsCss.hide}
    >
      <Header
        buttonText={cursorDate.format('MMM YYYY')}
        onButtonClick={onYearButtonClicked}
        onNextButtonClick={onNextButtonClicked}
        onPrevButtonClick={onPrevButtonClicked}
        isExpanded={false}
        displayMode={DisplayMode.CALENDAR}
      />
      <div className={[dsCss.row, dsCss.spaceAround].join(' ')}>
        {days.map((d, index) => (
          // the days array is fixed.  there is no danger of it changing
          // eslint-disable-next-line react/no-array-index-key
          <Button key={index} disabled className={css.button}>
            {d}
          </Button>
        ))}
      </div>
      <Grid numberOfRows={6} cellsPerRow={7}>
        <CalendarRow>
          <CalendarCell
            onClick={onSelectedDate}
            gridValues={gridValues}
            today={today}
            minDate={minDate}
            maxDate={maxDate}
          />
        </CalendarRow>
      </Grid>
    </div>
  );
};

export default Calendar;

import React, {
  FC, useState, useEffect, useCallback,
} from 'react';
import TextField, { Input } from '@fv-components/text-field';
import { IMatter, IFilevineProject } from '@models';
import { filevineState } from '@libs/auth';
import useFilevineQueryProjects from '@hooks/Filevine/useQueryProjects';
import { AutoComplete } from '@components';
import FilevineLogo from '@src/App/FilevineLogo';
import { getSnackBarService } from '@hooks/useSnackBar';
import { snapshot } from 'valtio';

interface IMatterTextFieldProps {
  onUpdate: (matter: IMatter) => void;
  matter?: IMatter;
  isConnectedToFilevine?: boolean;
  filevineProjectIdFromUrl?: string;
}

const MatterTextField: FC<IMatterTextFieldProps> = (
  {
    onUpdate,
    matter,
    isConnectedToFilevine,
    filevineProjectIdFromUrl,
  }: IMatterTextFieldProps,
) => {
  const [selectedProject, setSelectedProject] = useState<IFilevineProject>();
  // for the normal text field
  const [inputValue, setInputValue] = useState<string | undefined>(matter?.description);
  // for the autocomplete
  const [searchTerm, setSearchTerm] = useState<string>();
  const snackBar = getSnackBarService();
  const [take, setTake] = useState(5);

  useEffect(() => {
    if (matter?.description) {
      setInputValue(matter?.description);
      setSearchTerm(matter?.description);
    }
    if (matter?.description && matter.filevineOrgId && matter.filevineProjectId) {
      setSelectedProject({
        projectId: {
          native: +matter.filevineProjectId,
        },
        projectOrClientName: matter.description,
        orgId: +matter?.filevineOrgId,
      });
    } else {
      setSelectedProject(undefined);
    }
  }, [matter]);

  const {
    items, loading, error, load, called, hasMore,
  } = useFilevineQueryProjects({
    queryObject: {
      skip: 0,
      take,
      searchTerm: filevineProjectIdFromUrl ? undefined : searchTerm,
      projectId: filevineProjectIdFromUrl,
    },
    fields: ['items', 'hasMore'],
    lazy: true,
  });

  // if someone has altered the search term and we need to
  // trigger the lazy loading if not already called
  useEffect(() => {
    if (!called && isConnectedToFilevine && searchTerm) {
      load();
    }
  }, [called, isConnectedToFilevine, load, searchTerm]);

  // if the project id has been supplied
  // but the search term is not set then the project id is probably
  // coming from a URL parm.  we need to find it
  useEffect(() => {
    if (!called && isConnectedToFilevine && filevineProjectIdFromUrl) {
      load();
    }
  }, [called, filevineProjectIdFromUrl, isConnectedToFilevine, load]);

  const onSearchTermChange = (text?: string) => {
    setSearchTerm(text);
    onUpdate({
      description: text,
    });
    if (!called && isConnectedToFilevine && text) {
      load();
    }
  };

  const onInputValueChange = (text?: string) => {
    setInputValue(text);
    onUpdate({
      description: text,
    });
  };

  const onLookupSelect = useCallback((p?: IFilevineProject) => {
    setSelectedProject(p);
    if (p?.projectOrClientName) {
      onUpdate({
        description: p.projectOrClientName,
        filevineOrgId: `${snapshot(filevineState).orgId}`,
        filevineProjectId: `${p.projectId.native}`,
      });
    }
  }, [onUpdate]);

  // this is here to do automatically select the project
  // if we were able to lookup the project via a project id
  // in the url parameters
  useEffect(() => {
    if (filevineProjectIdFromUrl && items.length === 1 && !searchTerm) {
      if (items[0]?.projectId?.native === +filevineProjectIdFromUrl
        && items[0]?.orgId === snapshot(filevineState).orgId) {
        onLookupSelect(items[0]);
      }
    }
  }, [items, onLookupSelect, filevineProjectIdFromUrl, searchTerm]);

  // if there was an error fetching projects
  // we show and error.  this will most likely be used
  // when we get a bad URL or if there is a problem with filevine
  useEffect(() => {
    if (error && snackBar.isInitialized) {
      snackBar.showSnackBar('There was an error finding your project.');
    }
  }, [error, snackBar]);

  return (
    <div className="flex flex-col justify-center py-1 w-96">
      {!isConnectedToFilevine ? (
        <>
          <TextField
            label="Matter"
            className="w-full"
            outlined
          >
            <Input
              // className="w-96"
              value={inputValue}
              onChange={
                (
                  event: React.FormEvent<HTMLInputElement>,
                ) => onInputValueChange(event.currentTarget.value)
            }
              data-cy="matter"
            />
          </TextField>
        </>
      ) : (
        <AutoComplete<IFilevineProject>
          descriptionField="projectOrClientName"
          keyField="uniqueKey"
          onSelect={onLookupSelect}
          onFilterChange={(filter?: string) => onSearchTermChange(filter)}
          items={items}
          label="Filevine Project"
          cypressLabel="matter"
          hasMore={hasMore}
          loading={loading}
          load={load}
          value={selectedProject}
          onLoadMore={() => setTake(take + 5)}
          trailingIcon={<FilevineLogo width={24} />}
        />
      )}
    </div>
  );
};

export default MatterTextField;

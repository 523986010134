import React, {
  FC, useEffect, useState,
} from 'react';
import { useHistory } from 'react-router-dom';
import {
  IDeadline, ITrigger, ICategory, ITriggerDeadline, ITriggerDeadlineQuery,
} from '@models';
import TextField, { Input } from '@fv-components/text-field';
import { AltButton, InputLinearProgress } from '@components';
import useTriggerDeadlineQuery from '@hooks/Trigger/useTriggerDeadlineQuery';
import MaterialIcon from '@fv-components/material-icon';
import Deadline from './Deadline';

const fields = [
  'id',
  'deadline { id description shortDescription specReference refLink calculation extensionCalculation categories { id description } jurisdiction { id description address } secondExtensionCalculation thirdExtensionCalculation fourthExtensionCalculation fifthExtensionCalculation }   ',
  'deadlineId',
  'triggerId',
  'serviceMethods { id description }',
  'serviceMethodGroups { id description serviceMethods { id description } }',
];

interface IDeadlineListProps {
  trigger?: ITrigger;
  parentTriggerIds?: string[];
  forceRefresh?: boolean;
  deadlineInheritanceBlocks?: string[];
}

const DeadlineList: FC<IDeadlineListProps> = ({
  trigger,
  parentTriggerIds,
  deadlineInheritanceBlocks,
}) => {
  const [inherited, setInherited] = useState<ITriggerDeadline[]>();
  const [direct, setDirect] = useState<ITriggerDeadline[]>();
  const [filter, setFilter] = useState<string>();
  const history = useHistory();

  const [
    queryObjects,
    setQueryObjects,
  ] = useState<{ id: number, query: ITriggerDeadlineQuery }[]>();
  const apolloWrapper = useTriggerDeadlineQuery({ queryObjects, fields, fetchPolicy: 'network-only' });

  useEffect(() => {
    setQueryObjects([{
      id: 1,
      query: {
        skip: 0,
        take: 500,
        triggerIds: [
          ...([trigger?.id || '']),
          ...(parentTriggerIds || []),
        ],
        blockedDeadlineIds: [
          ...(deadlineInheritanceBlocks || []),
        ],
      },
    }]);
  }, [trigger, parentTriggerIds, deadlineInheritanceBlocks]);

  // if someone does a search that resultrs new results we want to clear the filter
  useEffect(() => {
    if (!apolloWrapper.loading) {
      setFilter(undefined);
    }
  }, [apolloWrapper.loading]);

  const onFilterChange = (event: React.FormEvent<HTMLInputElement>) => {
    setFilter(event.currentTarget.value);
  };

  useEffect(() => {
    const lowerCaseFilter = filter?.toLowerCase();
    const filtered = lowerCaseFilter ? apolloWrapper.result?.[0].items?.filter(
      (x: ITriggerDeadline) => !!x.deadline?.refLink?.toLowerCase().includes(lowerCaseFilter)
        || !!x.deadline?.shortDescription?.toLowerCase().includes(lowerCaseFilter)
        || !!x.deadline?.specReference?.toLowerCase().includes(lowerCaseFilter)
        || !!x.deadline?.description?.toLowerCase().includes(lowerCaseFilter)
        || x.deadline?.categories?.find(
          (cat: ICategory) => cat.description?.toLowerCase().includes(lowerCaseFilter)),
    ) : apolloWrapper.result?.[0].items;

    setDirect(filtered?.filter((d: ITriggerDeadline) => d.triggerId === trigger?.id));
    setInherited(filtered?.filter((d: ITriggerDeadline) => d.triggerId !== trigger?.id));
  }, [apolloWrapper.result, deadlineInheritanceBlocks, filter, trigger]);

  return (
    <>
      <div className="my-4 flex md:flex-row flex-col justify-between md:items-center gap-3">
        {inherited && (
          <b className="text-xl">
            {`Direct Deadlines (${direct?.length || 0})`}
          </b>
        )}
        <TextField
          label="Filter"
          className="w-full rounded-t flex-1"
          onTrailingIconSelect={() => {
            setFilter(undefined);
          }}
          leadingIcon={<MaterialIcon className="overflow-hidden outline-none" icon="search" />}
          trailingIcon={filter ? <MaterialIcon className="overflow-hidden outline-none" role="button" icon="clear" /> : <></>}
        >
          <Input
            value={filter}
            onChange={onFilterChange}
            className="border-b-0"
          />
        </TextField>
        {!!trigger && (
          <div className="flex justify-end">
            <AltButton
              onClick={() => history.push('/admin/deadlines/new', { trigger, jurisdiction: trigger.jurisdiction })}
              aria-label="New Deadline"
              data-cy="new-deadline"
              icon={<MaterialIcon icon="add" />}
            >
              Add Deadline
            </AltButton>
          </div>
        )}
      </div>
      {apolloWrapper.loading && <InputLinearProgress isNotRounded />}
      {!direct && !inherited && !apolloWrapper.loading && <div className="justify-center flex">No Deadlines Found</div>}
      {apolloWrapper.result?.[0].hasMore && <div className="justify-center flex">Your search returned more than 500 results. Please narrow your search.</div>}
      <div className="flex flex-col gap-5">
        {direct?.map(
          (td: ITriggerDeadline) => (
            <Deadline
              key={td.deadline?.id}
              deadline={td.deadline as IDeadline}
              serviceMethodGroups={td.serviceMethodGroups}
              serviceMethods={td.serviceMethods}
            />
          ),
        )}
      </div>
      {!!inherited && (
        <div className="mt-4">
          <b className="text-xl">{`Inherited Deadlines (${inherited.length})`}</b>
          <div className="flex flex-col">
            <div className="flex flex-col gap-5 mt-4">
              {inherited.map(
                (td: ITriggerDeadline) => (
                  <Deadline
                    key={td.deadline?.id}
                    deadline={td.deadline as IDeadline}
                    serviceMethodGroups={td.serviceMethodGroups}
                    serviceMethods={td.serviceMethods}
                    warn
                  />
                ),
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default DeadlineList;

import React, {
  FC, useState, useRef,
} from 'react';
import MaterialIcon from '@fv-components/material-icon';
import TextField, { Input } from '@fv-components/text-field';
import { ICategory } from '@models';
import useCategoryQuery from '@hooks/Categories/useCategoryQuery';
import { LookupMenu, InputLinearProgress } from '@components';
import useLookupInput, { ISearchState } from '@hooks/useLookupInput';

interface IDeadlineSearchProps {
  onCategoryPicked: (category?: ICategory) => void;
  onFilterChange: (filter?: string) => void;
}

const DeadlineSearch: FC<IDeadlineSearchProps> = (
  {
    onCategoryPicked,
    onFilterChange,
  }: IDeadlineSearchProps,
) => {
  const [isFocused, setIsFocused] = useState(false);
  const [inputValue, setInputValue] = useState<string>();
  const [categoryDescription, setCategoryDescription] = useState<string>();
  const filterRef = useRef<any | null>(null);

  const {
    items, loading, called, load,
  } = useCategoryQuery({
    queryObject: {
      skip: 0,
      take: 25,
      description: categoryDescription,
    },
    fields: ['id', 'description'],
    lazy: true,
  });

  const onSearchStateChange = (searchState?: ISearchState, inputText?: string) => {
    if (searchState?.endPos && searchState?.startPos) {
      const filterValue = `${inputText?.substring(0, searchState.startPos - 1)}${inputText?.substring(searchState.endPos)}`;
      onFilterChange(filterValue);
      setCategoryDescription(searchState.searchTerm);
      if (!called) {
        load();
      }
    } else {
      onFilterChange(inputText);
      setCategoryDescription(undefined);
    }
  };

  const {
    inputOnChangeHandler,
    clear,
  } = useLookupInput({
    debounce: 500,
    inputRefCurrent: filterRef?.current,
    callback: onSearchStateChange,
    setInputValue,
    beginSearchChar: '#',
  });

  const handleCategoryPicked = (category: ICategory) => {
    if (category && categoryDescription) {
      onCategoryPicked(category);
      setInputValue(inputValue?.replace(`#${categoryDescription}`, ''));
      setCategoryDescription(undefined);
    }
  };

  // this is required because clicking into the input field
  // would randomly close the lookup menu for some reason i can't determine
  const onClick = (event: React.MouseEvent<HTMLInputElement, MouseEvent>) => {
    event.stopPropagation();
  };

  const showLookupMenu = isFocused && !!categoryDescription && !!items.length;

  return (
    <div className="flex flex-col">
      <TextField
        label="Search"
        className="rounded-md w-full"
        onTrailingIconSelect={clear}
        leadingIcon={<MaterialIcon className="overflow-hidden outline-none" icon="search" />}
        trailingIcon={inputValue ? <MaterialIcon className="overflow-hidden outline-none" role="button" icon="clear" /> : <></>}
        data-cy="action-search"
      >
        <Input
          value={inputValue}
          onChange={inputOnChangeHandler}
          ref={filterRef}
          className="border-b-0"
          onClick={onClick}
          onFocus={() => setIsFocused(true)}
        />
      </TextField>
      {loading && <InputLinearProgress />}
      <LookupMenu<ICategory>
        inputRef={filterRef}
        descriptionField="description"
        keyField="id"
        onMenuItemSelected={handleCategoryPicked}
        items={items}
        isOpen={showLookupMenu}
        onMenuClosed={() => setIsFocused(false)}
      />
    </div>
  );
};

export default DeadlineSearch;

import {
  useMutation, ApolloError, ApolloClient, DataProxy, gql, DocumentNode,
} from '@apollo/client';
import produce from 'immer';

export const removeFromCache = (ids: string[], type: string, client: ApolloClient<object>) => {
  const results = ids.map((id: string) => client.cache.evict({ id: `${type}:${id}` }));
  client.cache.gc();
  return !results.find((x: boolean) => x === false);
};

type DeleteHook = (props: IDeleteHookProps) => IDeleteHookResult;

export interface IDeleteResult {
  id: string;
  deleted: boolean;
}

export interface IDeleteHookResult {
  result: {
    error?: ApolloError;
    loading: boolean;
    called: boolean;
  };
  delete: (...ids: string[]) => Promise<IDeleteResult[]>,
}

interface IDeleteHookProps {
  capitalizedType: string;
}

export const useDelete: DeleteHook = ({
  capitalizedType,
}: IDeleteHookProps) => {
  const DELETE_GQL = gql`
    mutation ${capitalizedType}Mutation($id: String!) {
      delete${capitalizedType}(id: $id)
    }`;

  const [deleteFunc, {
    error, loading, called, client,
  }] = useMutation<{
    deleteTrigger: string;
  }>(DELETE_GQL);

  return {
    result: { error, loading, called },
    delete: (...ids: string[]) => Promise.all(ids.map(
      (id: string, index: number) => deleteFunc(
        {
          variables: { id },
          // this point of this is to only give the last delete call a update method
          // and then pass into it all of the ids for removal
          update: index === ids.length - 1
            ? () => removeFromCache(ids, `${capitalizedType}Type`, client) : undefined,
        },
      ).then(() => ({ id, deleted: true }))
        .catch(() => ({ id, deleted: false })),
    )).then((results: IDeleteResult[]) => {
      const deletedIds = results.filter(
        (result: IDeleteResult) => result.deleted,
      ).map((result: IDeleteResult) => result.id);
      if (deletedIds?.length) {
        removeFromCache(ids, `${capitalizedType}Type`, client);
      }
      return results;
    }),
  };
};

export const addItemToQueryCache = <T extends any>(
  cache: DataProxy,
  queryString: DocumentNode,
  queryObject: any,
  newItem: T[],
  cacheData: any,
  collection: string,
) => {
  if (newItem.length) {
    const cacheUpdate = produce({
      query: queryString,
      variables: { queryObject },
      data: cacheData,
    }, (draft) => {
      draft.data[collection].items.unshift(...newItem);
    });
    cache.writeQuery(cacheUpdate);
  }
};

// this is here to add new items to the query that was previously run
// after an insert has been made
export const insertItemIntoQuery = <T extends { id?: string }>(
  cache: DataProxy,
  queryObject: any,
  collection: string,
  queryString: DocumentNode,
  newItem?: T | T[] | null,
) => {
  const cacheData = cache.readQuery<any>({
    query: queryString,
    variables: { queryObject },
  });
  if (cacheData && newItem) {
    const newItemArray: T[] = [...(Array.isArray(newItem) ? newItem : [newItem])];
    const filtered = newItemArray.filter(
      (item: T) => !!item.id && !cacheData[collection].items.find((x: any) => x.id === item.id),
    );
    addItemToQueryCache<T>(cache, queryString, queryObject, filtered, cacheData, collection);
  }
};

export const handleSaveResultWithInputType = <T extends { id?: string }, X>(
  serverResponse: any,
  sendToServer: T,
  client: ApolloClient<object>,
  collection: string,
  queryObject?: any,
  queryString?: DocumentNode,
): X => {
  const withId = {
    ...sendToServer,
    ...serverResponse,
  };

  if (sendToServer.id
    && serverResponse.id
    && sendToServer.id === serverResponse.id
  ) {
    // if here that means we only need to modify the object
    const fields: { [key: string]: () => any } = {};
    Object.keys(withId).forEach(
      (key: string) => key !== 'id'
        && key !== '__typename'
        && Object.assign(fields, {
          [key]: () => (withId[key] !== undefined ? withId[key] : null),
        }),
    );
    client.cache.modify({
      id: client.cache.identify(serverResponse),
      fields,
    });
  } else if (queryString && queryObject) {
    insertItemIntoQuery<X>(
      client.cache,
      queryObject,
      collection,
      queryString,
      withId,
    );
  } else {
    // in this case you have saved an object but we don't
    // know where to update the cache.  we are just going to clear things out
    // to ensure you see the updated data.  I wish there was a less blunt tool
    // right now this should only happen on trigger updates
    client.resetStore();
  }
  return withId;
};

export const handleSaveResult = <T extends { id?: string }>(
  serverResponse: any,
  sendToServer: T,
  client: ApolloClient<object>,
  collection: string,
  queryObject?: any,
  queryString?: DocumentNode,
): T => handleSaveResultWithInputType<T, T>(
  serverResponse,
  sendToServer,
  client,
  collection,
  queryObject,
  queryString,
);

import React, {
  FC,
  useState,
  useEffect,
  useRef,
  useCallback,
} from 'react';
import useHistorySave from '@hooks/History/useHistorySave';
import { filevineState } from '@libs/auth';
import {
  IHistoryTriggerInput,
  IHistoryTriggerDeadlineInput,
  IHistoryTriggerDeadlineServiceMethodInput,
  IMatter,
  IHistory,
  IFilevineDeadline,
} from '@models';
import {
  IDeadlineCalculationState,
  ICalculationState,
  ITriggerCalculationState,
  IServiceMethodCalculationState,
} from '@src/App/Calculator/Calculator';
import { filterForExport } from '@libs/export';
import Button from '@fv-components/button';
import FilevineLogo from '@src/App/FilevineLogo';
import { useParams, useHistory } from 'react-router-dom';
import { snapshot } from 'valtio';
import MaterialIcon from '@fv-components/material-icon';
import { MenuList, MenuListItem, MenuListItemText } from '@fv-components/menu';
import MenuSurface from '@fv-components/menu-surface';
import GoogleLogo from '@src/App/GoogleLogo';
import { getCustomDialogService } from '@hooks/useCustomDialog';
import useExportIcs from '@hooks/useExportIcs';
import { getSnackBarService } from '@hooks/useSnackBar';
import { getConfirmationDialogService } from '@hooks/useConfirmationDialog';
import useGoogle, { toGoogleCalendarEvents, IGoogleCalendar } from '@hooks/Google/useGoogle';
import { useFilevineSyncDeadlines } from '@hooks/Filevine/useFilevineSyncDeadlines';
import { useMenuSurfaceCoordinates } from '@hooks';
import { IMatterModalResult } from './MatterModal';

export interface IExportDetails {
  exportId: string;
  exportName: string
}

export interface IPreSyncFilevineAnalysis {
  deletedFilevineDeadlineIds: string[];
  newDeadlineCount: number;
}

interface IExportControlsProps {
  matter?: IMatter;
  calculationState?: ICalculationState;
  filevineOrgId?: number;
  jurisdictionId: string;
  persistToLocalStorage: VoidFunction;
  onMatterUpdate: (matter?: IMatter) => void;
  onHistorySave: (history?: IHistory, isFvSyncComplete?: boolean) => void;
  filevineProjectIdFromUrl?: string;
}

interface ISelectedState {
  selected: number;
  total: number;
}

const mapToHistoryTriggerDeadlinesServiceMethods = (
  smcs?: IServiceMethodCalculationState[],
):
  IHistoryTriggerDeadlineServiceMethodInput[] => (
    smcs
    ?.filter((tcs: IServiceMethodCalculationState) => !tcs.toBeRemoved)
    ?.map((smc: IServiceMethodCalculationState) => (
      {
        id: smc.calculationId || '',
        serviceMethodId: smc.serviceMethod.id as string,
        isSelected: smc.isSelected,
        calculatedDate: smc.calculatedDate || new Date(),
        filevineDeadlineId: !smc.isSelected ? undefined : smc.filevineDeadlineId,
      }
    )) || []
);

const mapToHistoryTriggerDeadlines = (
  deadlineCalculationStates?: IDeadlineCalculationState[],
): IHistoryTriggerDeadlineInput[] => (
  deadlineCalculationStates?.map(
    (td: IDeadlineCalculationState): IHistoryTriggerDeadlineInput => ({
      id: td.historyTriggerDeadlineId || td.calculationId as string,
      deadlineId: td.deadline.id as string,
      triggerId: td.triggerId,
      calculatedDate: td.calculatedDate as Date,
      isSelected: td.isSelected,
      filevineDeadlineId: !td.toBeRemoved ? td.filevineDeadlineId : undefined,
      historyTriggerDeadlineServiceMethods:
          mapToHistoryTriggerDeadlinesServiceMethods(td.serviceMethods),
    })
) || []);

const mapToHistoryTriggerInput = (
  calculationState?: ICalculationState,
): IHistoryTriggerInput[] => (
    calculationState?.triggers
      ?.filter((tcs: ITriggerCalculationState) => !tcs.toBeRemoved)
      ?.map(
    (triggerCalculationState: ITriggerCalculationState): IHistoryTriggerInput => ({
      id: triggerCalculationState.historyTriggerId,
      triggerId: triggerCalculationState.trigger?.id as string,
      triggerDate: triggerCalculationState.triggerDate || new Date(),
      serviceMethodId: triggerCalculationState.serviceMethodId,
      triggerLabel: triggerCalculationState.triggerLabel,
      historyTriggerDeadlines: mapToHistoryTriggerDeadlines(triggerCalculationState.deadlines),
    }),
  ) || []);

const countDeadlines = (
  triggerCalculationStates?: ITriggerCalculationState[],
): number => triggerCalculationStates
    ?.filter((tcs: ITriggerCalculationState) => !tcs.toBeRemoved)
    ?.reduce(
  (
    tabCount: number,
    triggerCalculationState: ITriggerCalculationState,
  ) => tabCount + (triggerCalculationState.deadlines?.reduce(
    (count: number, dcs: IDeadlineCalculationState) => count
      + (dcs.serviceMethods?.filter(
        (smcs: IServiceMethodCalculationState) => !smcs.toBeRemoved)
        .length || 1), 0,
  ) || 0),
    0,
  ) || 0;

const staticMenuOptionLength = 4;

const ExportControls: FC<IExportControlsProps> = (
  {
    matter,
    calculationState,
    jurisdictionId,
    persistToLocalStorage,
    onMatterUpdate,
    onHistorySave,
    filevineProjectIdFromUrl,
  }: IExportControlsProps,
) => {
  const { historyId } = useParams<{ historyId?: string }>();
  const history = useHistory();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const menuAnchor = useRef() as React.MutableRefObject<HTMLInputElement>;
  const confirmationDialog = getConfirmationDialogService();
  const [highlightedIndex, setHighlightedIndex] = useState(0);
  const [selectedState, setSelectedState] = useState<ISelectedState>();
  const [isGoogleCalendarMenuOpen, setIsGoogleCalendarMenuOpen] = useState(false);
  const [exportDetails, setExportDetails] = useState<IExportDetails[]>([]);
  const isConnectedToFilevine = !!snapshot(filevineState).client;
  const [isFvSyncButtonDisabled, setIsFvSyncButtonDisabled] = useState<boolean>();
  const coords = useMenuSurfaceCoordinates(menuAnchor);

  const {
    insertEvents: insertGoogleEvents,
    isSignedIn: isSignedInToGoogle,
    signIn: signInToGoogle,
    calendars,
    isCalendarsLoading,
  } = useGoogle();
  const snackBar = getSnackBarService();

  const { exportIcsFile } = useExportIcs();
  const { saveHistory } = useHistorySave({
    historyId: calculationState?.historyId,
    jurisdictionId,
    historyTriggers: mapToHistoryTriggerInput(
      calculationState,
    ),
  });

  const [
    filteredTriggerDeadlines,
    setFilteredTriggerDeadlines,
  ] = useState<ITriggerCalculationState[]>([]);
  const { showDialog } = getCustomDialogService();

  const {
    loading: isSyncingWithFilevine,
    syncDeadlines,
    new: newDeadlineCount,
  } = useFilevineSyncDeadlines({
    calculationState,
    projectId: matter?.filevineProjectId,
  });

  useEffect(() => {
    if (calculationState) {
      const filtered = filterForExport(calculationState);
      setSelectedState({
        selected: countDeadlines(filtered),
        total: countDeadlines(calculationState.triggers?.filter(
          (tcs: ITriggerCalculationState) => !tcs.toBeRemoved)),
      });
      setFilteredTriggerDeadlines(filtered);
      setIsFvSyncButtonDisabled(!newDeadlineCount && (calculationState?.isSavedToFilevine
    && !calculationState?.isDirtySinceLastFilevineSync));
    }
  }, [calculationState, newDeadlineCount]);

  // only resolves if they press the ok button on the dialog
  // else will reject
  const updateMatter = useCallback(
    (isFilevineProjectRequired?: boolean, isExportedToFilevine?: boolean)
      : Promise<IMatterModalResult> => new Promise<IMatterModalResult>((resolve, reject) => {
        if (!isFilevineProjectRequired || (isFilevineProjectRequired && !isExportedToFilevine)) {
          return showDialog<IMatterModalResult>('matter', {
            matter,
            isConnectedToFilevine: isConnectedToFilevine && isFilevineProjectRequired,
            filevineProjectIdFromUrl,
            isFilevineProjectRequired,
          }).then(({ event, result }) => (event === 'ok' && !!result ? resolve(result) : reject())).catch(() => reject());
        }
        return resolve({ matter });
      }),
    [isConnectedToFilevine, matter, showDialog, filevineProjectIdFromUrl],
  );

  const closeMenu = () => {
    setIsMenuOpen(false);
    setIsGoogleCalendarMenuOpen(false);
  };

  const onFilevineClick = useCallback(() => {
    if (!isFvSyncButtonDisabled) {
      closeMenu();
      if (!isConnectedToFilevine) {
        if (historyId) {
          localStorage.setItem('historyIdParam', historyId);
        } else {
          persistToLocalStorage();
        }
        history.push('/connect');
      } else {
        updateMatter(true, !!matter?.id).then(async (result) => {
          if (result.matter?.filevineProjectId && result.matter.description) {
            onMatterUpdate(result.matter);
            const exportResult = await syncDeadlines(result.matter);
            if (exportResult?.saved) {
              const his = await saveHistory(
                result.matter,
                exportResult.saved,
                exportResult.deleted.filter((fvd: IFilevineDeadline) => fvd.isAlreadyDeleted),
              );
              onHistorySave(his, true);
              snackBar.showSnackBar('Filevine Sync Success');
              confirmationDialog.showDialog('Would you like to start a new deadline calculation?', undefined, 'New')
                .then((isConfirmed: boolean) => isConfirmed && history.push('/calculator/new'));
              // we only want to add if the same project is already in the list
              if (!exportDetails.find(
                (ed: IExportDetails) => ed.exportId === result.matter?.filevineProjectId,
              )) {
                setExportDetails((current: IExportDetails[]) => [
                  ...current,
                  {
                    exportId: `${result.matter?.filevineProjectId}`,
                    exportName: result.matter?.description || '',
                  },
                ]);
              }
            }
          }
        });
      }
    }
  }, [
    isFvSyncButtonDisabled,
    isConnectedToFilevine,
    historyId,
    history,
    persistToLocalStorage,
    updateMatter,
    matter,
    onMatterUpdate,
    syncDeadlines,
    saveHistory,
    onHistorySave,
    snackBar,
    confirmationDialog,
    exportDetails,
  ]);

  const onPrintClick = useCallback(() => {
    closeMenu();
    updateMatter().then(async (result) => {
      if (result.matter) {
        onMatterUpdate(result.matter);
        window.print();
      }
    });
  }, [onMatterUpdate, updateMatter]);

  const stopFurtherAction = (keyboardEvent: KeyboardEvent) => {
    keyboardEvent.stopPropagation();
    keyboardEvent.preventDefault();
  };

  const exportToGoogle = useCallback((calendar: IGoogleCalendar) => {
    updateMatter().then((result) => {
      if (result.matter?.description && calculationState?.jurisdiction) {
        onMatterUpdate(result.matter);
        const googleEvents = toGoogleCalendarEvents(
          filteredTriggerDeadlines,
          calendar,
          result.matter.description,
          calculationState.jurisdiction,
        );
        const promise = insertGoogleEvents(calendar.id, googleEvents);
        if (promise) {
          promise.then(async () => {
            // we only want to add if the same project is already in the list
            if (!exportDetails.find(
              (ed: IExportDetails) => ed.exportId === calendar.id,
            )
            ) {
              setExportDetails((current: IExportDetails[]) => [
                ...current,
                {
                  exportId: calendar.id,
                  exportName: calendar.summary,
                },
              ]);
            }
            snackBar.showSnackBar('You successfully saved your deadlines to Google.');
          }).catch(() => snackBar.showSnackBar('There was an error saving to Google Calendar.'));
        }
      }
    });
  }, [
    exportDetails,
    filteredTriggerDeadlines,
    insertGoogleEvents,
    onMatterUpdate,
    snackBar,
    updateMatter,
    calculationState,
  ]);

  const onCalendarSelected = useCallback((calendar: IGoogleCalendar) => {
    closeMenu();

    const previousGoogleExports = exportDetails
      .filter((ed: IExportDetails) => calendars.find((c: IGoogleCalendar) => c.id === ed.exportId))
      .map(((ed: IExportDetails) => ed.exportName));
    if (!previousGoogleExports.length) {
      exportToGoogle(calendar);
    } else {
      confirmationDialog.showDialog(`You have already exported to the following Google calendars: ${previousGoogleExports.join(', ')}. Do you wish to proceed?`)
        .then((isConfirmed: boolean) => {
          if (isConfirmed) {
            exportToGoogle(calendar);
          }
        });
    }
  }, [calendars, confirmationDialog, exportDetails, exportToGoogle]);

  const onIcsClick = useCallback(() => {
    closeMenu();
    updateMatter().then(async (result) => {
      if (result.matter) {
        onMatterUpdate(result.matter);
        if (filteredTriggerDeadlines.length) {
          filteredTriggerDeadlines.forEach(
            (tcs: ITriggerCalculationState) => {
              if (tcs.deadlines?.length
                && result.matter?.description
                && calculationState?.jurisdiction) {
                exportIcsFile(
                  tcs.deadlines, tcs, result.matter.description, calculationState.jurisdiction,
                );
              }
            },
          );
        }
      }
    });
  }, [
    exportIcsFile,
    filteredTriggerDeadlines,
    onMatterUpdate,
    updateMatter,
    calculationState,
  ]);

  const onExportToGoogleClick = useCallback((e?: React.SyntheticEvent<HTMLElement, Event>) => {
    if (e) {
      e.preventDefault();
    }
    if (!isGoogleCalendarMenuOpen) {
      if ((!isSignedInToGoogle || !calendars.length) && signInToGoogle) {
        signInToGoogle();
      }
      setIsGoogleCalendarMenuOpen(true);
    } else {
      setIsGoogleCalendarMenuOpen(false);
    }
  }, [calendars.length, isGoogleCalendarMenuOpen, isSignedInToGoogle, signInToGoogle]);

  const selectOption = useCallback(() => {
    if (highlightedIndex === 0) {
      if (!isFvSyncButtonDisabled) {
        onFilevineClick();
      }
    } else if (highlightedIndex === 1) {
      onPrintClick();
    } else if (highlightedIndex === 2) {
      onIcsClick();
    } else if (highlightedIndex === 3) {
      onExportToGoogleClick();
    } else {
      onCalendarSelected(calendars[highlightedIndex - staticMenuOptionLength]);
    }
  }, [
    calendars,
    highlightedIndex,
    isFvSyncButtonDisabled,
    onCalendarSelected,
    onExportToGoogleClick,
    onFilevineClick,
    onIcsClick,
    onPrintClick,
  ]);

  const handleInputKeyboardEvents = useCallback((e: any) => {
    const keyboardEvent = e as KeyboardEvent;
    if (isMenuOpen) {
      if (keyboardEvent.key === 'ArrowUp') {
        if (highlightedIndex !== 0) {
          setHighlightedIndex(highlightedIndex - 1);
        }
      } else if (keyboardEvent.key === 'ArrowDown') {
        if (highlightedIndex < (
          staticMenuOptionLength + (isGoogleCalendarMenuOpen ? calendars.length : 0)
        ) - 1) {
          setHighlightedIndex(highlightedIndex + 1);
        }
      } else if (keyboardEvent.key === 'Enter') {
        stopFurtherAction(e);
        if (highlightedIndex < 3) {
          setIsMenuOpen(false);
          setHighlightedIndex(0);
        }
        selectOption();
      } else if ((keyboardEvent.key === 'Escape') && isMenuOpen) {
        setIsMenuOpen(false);
        setHighlightedIndex(0);
      }
    }
  }, [calendars.length, highlightedIndex, isGoogleCalendarMenuOpen, isMenuOpen, selectOption]);

  return (
    <div className="flex flex-row justify-end gap-3 items-end text-gray">
      <div ref={menuAnchor}>
        <Button
          onClick={() => setIsMenuOpen(!isMenuOpen)}
          icon={<MaterialIcon icon="share" />}
          aria-haspopup="true"
          aria-expanded={isMenuOpen ? 'true' : 'false'}
          aria-label="Toggle Export Menu"
          aria-controls="export-menu-surface"
          disabled={isSyncingWithFilevine}
          onKeyDown={handleInputKeyboardEvents}
          data-cy="export-menu"
          raised
        >
          {`Export ${selectedState?.selected || 0} / ${selectedState?.total || 0} `}
        </Button>
        <MenuSurface
          open={isMenuOpen}
          onClose={() => { setIsMenuOpen(false); setHighlightedIndex(0); }}
          id="account-menu-surface"
          className="fixed"
          data-cy="export-menu-container"
          style={{ top: coords?.y, left: coords?.x }}
        >
          <MenuList wrapFocus>
            <MenuListItem
              className="flex flex-row gap-2"
              disabled={isFvSyncButtonDisabled}
              onClick={onFilevineClick}
              selected={highlightedIndex === 0}
            >
              <FilevineLogo className={`${!isFvSyncButtonDisabled ? 'fill-blue' : ''}`} style={{ width: 24 }} />
              <MenuListItemText primaryText="Filevine Sync" />
            </MenuListItem>
            <MenuListItem className="flex flex-row gap-2" onClick={onPrintClick} selected={highlightedIndex === 1}>
              <MaterialIcon icon="print" className="text-blue" />
              <MenuListItemText primaryText="Print" />
            </MenuListItem>
            <MenuListItem className="flex flex-row gap-2" onClick={onIcsClick} selected={highlightedIndex === 2}>
              <MaterialIcon icon="cloud_download" className="text-blue" />
              <MenuListItemText primaryText="Download" />
            </MenuListItem>
            <MenuListItem
              className="flex flex-row gap-2"
              selected={highlightedIndex === 3}
              aria-expanded={isGoogleCalendarMenuOpen ? 'true' : 'false'}
              aria-controls="export-to-google-menu"
              aria-label="Toggle Export Deadlines To Google Menu"
              data-cy="export-to-google"
              onClick={onExportToGoogleClick}
            >
              <GoogleLogo style={{ width: 24 }} />
              <MenuListItemText primaryText="Google" />
            </MenuListItem>
            {isCalendarsLoading && <MaterialIcon icon="sync" className="animate-spin" />}
            {isGoogleCalendarMenuOpen && (
            <>
              {calendars?.map((calendar: IGoogleCalendar, index: number) => (
                <MenuListItem
                  key={calendar.id}
                  onClick={() => onCalendarSelected(calendar)}
                  aria-label={`Export to Google Calendar ${calendar.summary}`}
                  selected={highlightedIndex === staticMenuOptionLength + index}
                  className="pl-10"
                >
                  <span
                    className="border-solid border border-gray rounded h-5 w-5 mr-3"
                    style={{ backgroundColor: calendar.backgroundColor }}
                  />
                  <MenuListItemText primaryText={calendar.summary} />
                </MenuListItem>
              ))}
            </>
            )}
          </MenuList>
        </MenuSurface>
      </div>
    </div>
  );
};

export default ExportControls;

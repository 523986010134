import { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import {
  ApolloError,
  gql,
  useLazyQuery,
  FetchPolicy,
} from '@apollo/client';
import ICalculationQuery from '@models/Deadlines/Calculation/ICalculationQuery';
import ICalculation from '@models/Deadlines/Calculation/ICalculation';

type CalculationQueryHook = (
  props: ICalculationQueryHookProps
) => IDeadlineQueryHookResult;

export interface ICalculationQueryHookProps {
  queryObjects?: ICalculationQuery[];
  lazy?: boolean;
  fetchPolicy?: FetchPolicy;
}

interface ICalculationQueryResponse {
  [key: string]: ICalculation;
}

interface IDeadlineQueryHookResult {
  loading: boolean;
  error?: ApolloError;
  calculations: ICalculation[];
  refetch: VoidFunction | undefined;
  load: VoidFunction;
  called: boolean;
  guid?: string;
}

const useCalculationQuery: CalculationQueryHook = (
  { queryObjects, lazy, fetchPolicy }: ICalculationQueryHookProps,
) => {
  const [calculations, setCalculations] = useState<ICalculation[]>([]);
  const [variables, setVariables] = useState<{ [key: string]: ICalculationQuery }>();

  useEffect(() => {
    if (queryObjects?.length) {
      setVariables(queryObjects
        .reduce((previous: any, current: any, index: number) => {
          // i absolutely need this
          // eslint-disable-next-line no-param-reassign
          previous[`calculation${index}`] = {
            ...current,
            triggerDate: dayjs(current.triggerDate).format('YYYY-MM-DD'),
          };
          return previous;
        }, {}));
    } else {
      setCalculations([]);
      setVariables(undefined);
    }
  }, [queryObjects]);

  const GET_GQL = variables && Object.keys(variables).length ? gql`query(${Object.keys(variables).map(
    (_, index: number) => `$calculation${index}: CalculationQueryInputType!,`,
  )}) {
    guid
     ${Object.keys(variables).map(
    (_, index: number) => (
      `calculation${index}: calculations( queryObject: $calculation${index}) {
        id
        date
        calculationSteps
        serviceMethodCalculations {
          id
          date
          serviceMethod {
            id
            description
            duration
          }
        }
      }`),
  )}
  }` : gql`query($q: CalculationQueryInputType!) { calculations(queryObject: $q) { id } }`;

  const [load, {
    loading, error, data, called, refetch,
  }] = useLazyQuery<ICalculationQueryResponse>(GET_GQL, { variables, fetchPolicy });

  useEffect(() => {
    if (!lazy && load && !called && variables) {
      load();
    }
  }, [called, lazy, load, variables]);

  useEffect(() => {
    if (data && !loading && !error) {
      const d = Object.keys(data || {})
        .filter((key: string) => key !== 'guid')
        .map((key: string) => (data ? data[key] : {
          date: new Date(),
          partnerId: '',
        })) || [];
      setCalculations(d);
    }
  }, [data, loading, error]);

  return {
    loading,
    // only send error messages to to caller if they have defined variables to query on
    // the backend will return an error if the query object is undefined
    error: variables && Object.keys(variables).length ? error : undefined,
    guid: data?.guid as string | undefined,
    calculations,
    refetch,
    load,
    called,
  };
};

export default useCalculationQuery;

import TabBar, {
  Tab,
} from '@material/react-tab-bar';
import './TabBar.scss';

/**
 * TabBar MCWR Component
 *
 * @version ./package.json
 */
export default TabBar;

export {
  Tab,
};

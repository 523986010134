import React, { FC, useState } from 'react';
import Dialog, {
  DialogTitle,
  DialogContent,
} from '@fv-components/dialog';
import LinearProgress from '@fv-components/linear-progress';
import TextField, { Input } from '@fv-components/text-field';
import Button from '@fv-components/button';
import IHoliday from '@models/Jurisdiction/Holidays/IHoliday';
import IJurisdiction from '@models/Jurisdiction/IJurisdiction';
import { IHolidaySaveHookResult } from '@hooks/Holidays/useHolidaySave';
import ModalBottomButtonRow from '@components/ModalBottomButtonRow';
import DatePicker from '@components/DatePicker/DatePicker';
import { JurisdictionAutoComplete, AltButton } from '@components';
import MaterialIcon from '@fv-components/material-icon';
import { discardChangesDialogService } from '@hooks/useConfirmationDialog';

interface IEditHolidayModalProps {
  isOpen: boolean;
  holiday?: IHoliday;
  onClose: VoidFunction;
  save: IHolidaySaveHookResult;
}

export enum HolidayEditResult {
  SAVE = 'SAVE',
  CANCEL = 'CANCEL'
}

const EditHolidayModal: FC<IEditHolidayModalProps> = (
  {
    holiday: h,
    isOpen,
    onClose,
    save,
  }: IEditHolidayModalProps,
) => {
  const [holiday, setHoliday] = useState<IHoliday | undefined>(h);
  const [isDirty, setIsDirty] = useState(false);

  const closeDialog = () => {
    setHoliday(undefined);
    onClose();
  };

  const saveHoliday = () => {
    if (holiday?.jurisdiction
      && holiday?.description
      && holiday?.date) {
      save.saveHoliday(
        holiday?.jurisdiction,
        holiday?.description,
        holiday?.date,
        holiday?.id,
      ).then(() => {
        setIsDirty(false);
        closeDialog();
      });
    }
  };

  const onCancel = () => {
    if (isDirty) {
      discardChangesDialogService.showDialog(closeDialog);
    } else {
      closeDialog();
    }
  };

  const onJurisdictionChange = (jurisdiction?: IJurisdiction) => {
    setIsDirty(true);
    setHoliday({
      ...holiday,
      jurisdiction,
    });
  };

  const onDescriptionChange = (description?: string) => {
    setIsDirty(true);
    setHoliday({
      ...holiday,
      description,
    });
  };

  const onDateChange = (date?: Date) => {
    setIsDirty(true);
    setHoliday({
      ...holiday,
      date,
    });
  };

  return (
    <Dialog
      onClose={closeDialog}
      open={isOpen}
    >
      <DialogTitle>
        {holiday?.id ? 'Edit ' : 'Add New '}
        Holiday
      </DialogTitle>
      <DialogContent style={{ overflow: 'visible' }}>
        <div className="flex flex-col gap-3 mt-1">
          <TextField label="Holiday" outlined>
            <Input
              required
              value={holiday?.description}
              onChange={(event: React.FormEvent<HTMLInputElement>) => {
                onDescriptionChange(event.currentTarget.value);
              }}
            />
          </TextField>
          <JurisdictionAutoComplete
            onSelect={onJurisdictionChange}
            value={holiday?.jurisdiction}
            isRequired
          />
          <DatePicker
            isRequired
            onDateChange={onDateChange}
            value={holiday?.date}
            dateFormat="MM/DD/YYYY"
          />
        </div>
        <ModalBottomButtonRow>
          <Button
            onClick={saveHoliday}
            role="button"
            aria-label="Save Holiday"
            raised
            disabled={!(holiday?.jurisdiction
      && holiday?.description
              && holiday?.date) || !isDirty || save.result.loading}
            icon={<MaterialIcon icon="save" />}
          >
            Save
          </Button>
          <AltButton
            onClick={onCancel}
            aria-label="Cancel Holiday Edit"
            disabled={save.result.loading}
            icon={<MaterialIcon icon="cancel" />}
          >
            Cancel
          </AltButton>
        </ModalBottomButtonRow>
      </DialogContent>
      <>
        {save.result.loading && <LinearProgress indeterminate />}
      </>
    </Dialog>
  );
};

export default EditHolidayModal;

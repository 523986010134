// TODO we will come back to this at some point when we start looking at accessibility
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, {
  FC, memo,
} from 'react';
import IColumnProps from '../Column';
import { IRowProps } from './Row';
import Tooltip from '../../Tooltip/Tooltip';

interface ICellProps {
  rowProps: IRowProps;
  colProps: IColumnProps;
}

const Cell: FC<ICellProps> = memo(({ rowProps, colProps }: ICellProps) => {
  // in the case where the field is on the property of a child object
  // we split on the . and the reduce to get the value to be displayed
  const value = colProps.field
    .split('.')
    .reduce((
      currentObj: any,
      nextProp: string,
    ) => (currentObj
        && currentObj[nextProp] !== undefined ? currentObj[nextProp] : ''
    ),
    rowProps.item);

  const display = colProps.formatter ? colProps.formatter(value) : (value?.toString() || '');

  return (
    <Tooltip tooltipValue={display}>
      <div
        className="overflow-hidden overflow-ellipsis whitespace-nowrap py-3"
        onClick={(
          event: React.MouseEvent<HTMLDivElement, MouseEvent>,
        ) => event.preventDefault()}
      >
        {display}
      </div>
    </Tooltip>
  );
});

export default Cell;

import React, { FC, useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import Button from '@fv-components/button';
import TextField, { Input, HelperText } from '@fv-components/text-field';
import LinearProgress from '@fv-components/linear-progress';
import MaterialIcon from '@fv-components/material-icon';
import { getSnackBarService } from '@hooks/useSnackBar';
import {
  Keywords,
  AltButton,
  JurisdictionAutoComplete,
} from '@components';
import {
  CalendarType,
  IDeadline,
  IJurisdiction,
  RoundingDirection,
  ICategory,
  Interval,
  ITrigger,
  ITriggerDeadline,
} from '@models';
import { useTimelyDelete } from '@hooks/useTimelyDelete';
import useDeadlineQuery from '@hooks/Deadline/useDeadlineQuery';
import { getConfirmationDialogService, discardChangesDialogService } from '@hooks/useConfirmationDialog';
import DeadlineProps from './DeadlineProps';
import KeywordSearch from '../../KeywordSearch';
import TriggerList from './TriggerList';

interface IDeadlineFormProps {
  jurisdiction?: IJurisdiction;
  trigger?: ITrigger;
}

const fields = [
  'id',
  'description',
  'shortDescription',
  'triggerDeadlines { id triggerId deadlineId trigger { id description childTriggers { id } parentTriggers { id } } serviceMethods { id description } serviceMethodGroups { id description serviceMethods { id description } } }',
  'specReference',
  'refLink',
  'interval',
  'duration',
  'calendar',
  'extensionDuration',
  'extensionInterval',
  'extensionCalendar',
  'roundingDirection',
  'extensionRoundingDirection',
  'categories { id description }',
  'jurisdiction { id description address }',
  'calculation',
  'secondExtensionDuration',
  'secondExtensionInterval',
  'secondExtensionCalendar',
  'secondExtensionRoundingDirection',
  'thirdExtensionDuration',
  'thirdExtensionInterval',
  'thirdExtensionCalendar',
  'thirdExtensionRoundingDirection',
  'fourthExtensionDuration',
  'fourthExtensionInterval',
  'fourthExtensionCalendar',
  'fourthExtensionRoundingDirection',
  'fifthExtensionDuration',
  'fifthExtensionInterval',
  'fifthExtensionCalendar',
  'fifthExtensionRoundingDirection',
];

const validateDeadlineProps = (
  duration?: number,
  interval?: Interval,
  calendar?: CalendarType,
  rounding?: RoundingDirection,
) => duration !== null
  && duration !== undefined
  && !!interval
  && !!calendar
  && ((calendar === CalendarType.CALENDAR && !!rounding)
    || (calendar === CalendarType.JUDICIAL && !rounding));

const areAllDeadlinePropsValid = (
  toBeChecked: IDeadline,
  extensions: number,
): boolean => validateDeadlineProps(
  toBeChecked.duration,
  toBeChecked.interval,
  toBeChecked.calendar,
  toBeChecked.roundingDirection,
)
  && (extensions > 0 ? validateDeadlineProps(
    toBeChecked.extensionDuration,
    toBeChecked.extensionInterval,
    toBeChecked.extensionCalendar,
    toBeChecked.extensionRoundingDirection,
  ) : true)
  && (extensions > 1 ? validateDeadlineProps(
    toBeChecked.secondExtensionDuration,
    toBeChecked.secondExtensionInterval,
    toBeChecked.secondExtensionCalendar,
    toBeChecked.secondExtensionRoundingDirection,
  ) : true)
  && (extensions > 2 ? validateDeadlineProps(
    toBeChecked.thirdExtensionDuration,
    toBeChecked.thirdExtensionInterval,
    toBeChecked.thirdExtensionCalendar,
    toBeChecked.thirdExtensionRoundingDirection,
  ) : true)
  && (extensions > 3 ? validateDeadlineProps(
    toBeChecked.fourthExtensionDuration,
    toBeChecked.fourthExtensionInterval,
    toBeChecked.fourthExtensionCalendar,
    toBeChecked.fourthExtensionRoundingDirection,
  ) : true)
  && (extensions > 4 ? validateDeadlineProps(
    toBeChecked.fifthExtensionDuration,
    toBeChecked.fifthExtensionInterval,
    toBeChecked.fifthExtensionCalendar,
    toBeChecked.fifthExtensionRoundingDirection,
  ) : true);

const validate = (
  toBeChecked: IDeadline,
  isRefLinkUrlValid: boolean,
  extensions: number,
): boolean => !!toBeChecked.description
&& !toBeChecked.triggerDeadlines?.find((td: ITriggerDeadline) => td.triggerId === undefined)
&& !!toBeChecked.jurisdiction
&& !!toBeChecked.shortDescription
&& !!toBeChecked.specReference
&& (toBeChecked.refLink ? isRefLinkUrlValid : true)
  && areAllDeadlinePropsValid(toBeChecked, extensions);

const DeadlineForm: FC<IDeadlineFormProps> = (
  {
    jurisdiction,
    trigger,
  }: IDeadlineFormProps,
) => {
  const snackBar = getSnackBarService();
  const history = useHistory();
  const [deadline, setDeadline] = useState<IDeadline>({
    roundingDirection: RoundingDirection.NONE,
    jurisdiction,
    jurisdictionId: jurisdiction?.id,
    triggerDeadlines: trigger ? [{ trigger, triggerId: trigger.id }] : [],
  });
  const [isDirty, setIsDirty] = useState(false);
  const [extensions, setExtensions] = useState(0);
  const [isRefLinkUrlValid, setIsRefLinkUrlValid] = useState(true);
  const deleteDeadline = useTimelyDelete({ capitalizedType: 'Deadline' });
  const confirmationDialog = getConfirmationDialogService();
  const isValid = validate(deadline, isRefLinkUrlValid, extensions);
  const { id } = useParams<{ id: string }>();

  const {
    load, called, result, save, loading,
  } = useDeadlineQuery({
    fetchPolicy: 'cache-first',
    fields,
    queryObjects: [{ id, skip: 0, take: 1 }],
    lazy: true,
  });

  // if we get an id from the params then we want to load the data
  // if no id from params then we are creating a new deadline
  useEffect(() => {
    if (id && !called) {
      load();
    }
  }, [called, id, load]);

  useEffect(() => {
    if (result.items[0]?.length && !deadline.id) {
      const item = result.items[0][0];
      // TODO this prop spreading can be removed in the following PR (or later)
      // because the it should have a script to NULL out these (newly) invalid
      // value combinations
      setDeadline({
        ...item,
        roundingDirection:
          item.calendar === CalendarType.JUDICIAL
            ? undefined : item.roundingDirection,
        extensionRoundingDirection:
          item.extensionCalendar === CalendarType.JUDICIAL
            ? undefined : item.extensionRoundingDirection,
        secondExtensionRoundingDirection:
          item.secondExtensionCalendar === CalendarType.JUDICIAL
            ? undefined : item.secondExtensionRoundingDirection,
        thirdExtensionRoundingDirection:
          item.thirdExtensionCalendar === CalendarType.JUDICIAL
            ? undefined : item.thirdExtensionRoundingDirection,
        fourthExtensionRoundingDirection:
          item.fourthExtensionCalendar === CalendarType.JUDICIAL
            ? undefined : item.fourthExtensionRoundingDirection,
        fifthExtensionRoundingDirection:
          item.fifthExtensionCalendar === CalendarType.JUDICIAL
            ? undefined : item.fifthExtensionRoundingDirection,
      });
      // this is just the initial set of the extensions number
      if (item.fifthExtensionInterval) {
        setExtensions(5);
      } else if (item.fourthExtensionInterval) {
        setExtensions(4);
      } else if (item.thirdExtensionInterval) {
        setExtensions(3);
      } else if (item.secondExtensionInterval) {
        setExtensions(2);
      } else if (item.extensionInterval) {
        setExtensions(1);
      } else {
        setExtensions(0);
      }
    }
  }, [deadline.id, result]);

  const onDescriptionChange = (event: React.FormEvent<HTMLInputElement>) => {
    const description = event.currentTarget.value;
    setIsDirty(true);
    setDeadline((current: IDeadline) => ({ ...current, description }));
  };

  const onShortDescriptionChange = (event: React.FormEvent<HTMLInputElement>) => {
    const shortDescription = event.currentTarget.value;
    if (shortDescription.length < 51) {
      setIsDirty(true);
      setDeadline((current: IDeadline) => (
        { ...current, shortDescription }));
    }
  };

  const onSpecReferenceChange = (event: React.FormEvent<HTMLInputElement>) => {
    const specReference = event.currentTarget.value;
    setIsDirty(true);
    setDeadline((current: IDeadline) => ({ ...current, specReference }));
  };

  const onDurationChange = (duration?: number) => {
    setIsDirty(true);
    setDeadline((current: IDeadline) => ({
      ...current,
      duration,
    }));
  };

  const onExtensionDurationChange = (extensionDuration?: number) => {
    setIsDirty(true);
    setDeadline((current: IDeadline) => ({
      ...current,
      extensionDuration,
    }));
  };

  const onExtensionIntervalChange = (extensionInterval?: Interval) => {
    setIsDirty(true);
    setDeadline((current: IDeadline) => ({ ...current, extensionInterval }));
  };

  const onExtensionCalendarTypeChange = (extensionCalendar?: CalendarType) => {
    setIsDirty(true);
    setDeadline((current: IDeadline) => ({
      ...current,
      extensionCalendar,
      extensionInterval: extensionCalendar === CalendarType.JUDICIAL
        ? Interval.DAY : current.extensionInterval,
      extensionRoundingDirection: extensionCalendar === CalendarType.JUDICIAL
        ? undefined : current.extensionRoundingDirection,
    }));
  };

  const onExtensionRoundingDirectionChange = (extensionRoundingDirection?: RoundingDirection) => {
    setIsDirty(true);
    setDeadline((current: IDeadline) => ({ ...current, extensionRoundingDirection }));
  };

  const onRefLinkChange = (event: React.FormEvent<HTMLInputElement>) => {
    const refLink = event.currentTarget.value;
    setIsDirty(true);
    setIsRefLinkUrlValid(event.currentTarget.validity.valid);
    setDeadline((current: IDeadline) => ({ ...current, refLink }));
  };

  const onIntervalChange = (interval?: Interval) => {
    setIsDirty(true);
    setDeadline((current: IDeadline) => ({ ...current, interval }));
  };

  const onCalendarTypeChange = (calendar?: CalendarType) => {
    setIsDirty(true);
    setDeadline((current: IDeadline) => ({
      ...current,
      calendar,
      interval: calendar === CalendarType.JUDICIAL
        ? Interval.DAY : current.interval,
      roundingDirection: calendar === CalendarType.JUDICIAL
        ? undefined : current.roundingDirection,
    }));
  };

  const onRoundingDirectionChange = (roundingDirection?: RoundingDirection) => {
    setIsDirty(true);
    setDeadline((current: IDeadline) => ({ ...current, roundingDirection }));
  };

  const onCategoryPicked = (category: ICategory) => {
    setIsDirty(true);
    setDeadline((current: IDeadline) => ({
      ...current,
      categories: [...(current.categories || []), category],
    }));
  };

  const onCategoryRemoved = (category: ICategory) => {
    if (deadline?.categories) {
      setIsDirty(true);
      setDeadline((current: IDeadline) => ({
        ...current,
        categories: current.categories?.filter((cat: ICategory) => cat.id !== category.id),
      }));
    }
  };

  const onTriggerUpdate = (index: number, triggerDeadline: ITriggerDeadline) => {
    setIsDirty(true);
    setDeadline((current: IDeadline) => ({
      ...current,
      triggerDeadlines: current?.triggerDeadlines?.map(
        (dl: ITriggerDeadline, i: number) => (i !== index ? dl : triggerDeadline)),
    }));
  };

  const onTriggerAdd = () => {
    setIsDirty(true);
    setDeadline((current: IDeadline) => ({
      ...current,
      triggerDeadlines: [
        ...(current?.triggerDeadlines || []),
        { deadlineId: current.id },
      ],
    }));
  };

  const onTriggerDelete = (index: number) => {
    setIsDirty(true);
    setDeadline((current: IDeadline) => ({
      ...current,
      triggerDeadlines: current.triggerDeadlines?.filter((_, i: number) => i !== index) || [],
    }));
  };

  const onSecondExtensionDurationChange = (secondExtensionDuration?: number) => {
    setIsDirty(true);
    setDeadline((current: IDeadline) => ({
      ...current,
      secondExtensionDuration,
    }));
  };

  const onSecondExtensionIntervalChange = (secondExtensionInterval?: Interval) => {
    setIsDirty(true);
    setDeadline((current: IDeadline) => ({ ...current, secondExtensionInterval }));
  };

  const onSecondExtensionCalendarTypeChange = (secondExtensionCalendar?: CalendarType) => {
    setIsDirty(true);
    setDeadline((current: IDeadline) => ({
      ...current,
      secondExtensionCalendar,
      secondExtensionInterval: secondExtensionCalendar === CalendarType.JUDICIAL
        ? Interval.DAY : current.secondExtensionInterval,
      secondExtensionRoundingDirection: secondExtensionCalendar === CalendarType.JUDICIAL
        ? undefined : current.secondExtensionRoundingDirection,
    }));
  };

  const onSecondExtensionRoundingDirectionChange = (
    secondExtensionRoundingDirection?: RoundingDirection,
  ) => {
    setIsDirty(true);
    setDeadline((current: IDeadline) => ({ ...current, secondExtensionRoundingDirection }));
  };

  const onThirdExtensionDurationChange = (thirdExtensionDuration?: number) => {
    setIsDirty(true);
    setDeadline((current: IDeadline) => ({
      ...current,
      thirdExtensionDuration,
    }));
  };

  const onThirdExtensionIntervalChange = (thirdExtensionInterval?: Interval) => {
    setIsDirty(true);
    setDeadline((current: IDeadline) => ({ ...current, thirdExtensionInterval }));
  };

  const onThirdExtensionCalendarTypeChange = (thirdExtensionCalendar?: CalendarType) => {
    setIsDirty(true);
    setDeadline((current: IDeadline) => ({
      ...current,
      thirdExtensionCalendar,
      thirdExtensionInterval: thirdExtensionCalendar === CalendarType.JUDICIAL
        ? Interval.DAY : current.thirdExtensionInterval,
      thirdExtensionRoundingDirection: thirdExtensionCalendar === CalendarType.JUDICIAL
        ? undefined : current.thirdExtensionRoundingDirection,
    }));
  };

  const onThirdExtensionRoundingDirectionChange = (
    thirdExtensionRoundingDirection?: RoundingDirection,
  ) => {
    setIsDirty(true);
    setDeadline((current: IDeadline) => ({ ...current, thirdExtensionRoundingDirection }));
  };

  const onFourthExtensionDurationChange = (fourthExtensionDuration?: number) => {
    setIsDirty(true);
    setDeadline((current: IDeadline) => ({
      ...current,
      fourthExtensionDuration,
    }));
  };

  const onFourthExtensionIntervalChange = (fourthExtensionInterval?: Interval) => {
    setIsDirty(true);
    setDeadline((current: IDeadline) => ({ ...current, fourthExtensionInterval }));
  };

  const onFourthExtensionCalendarTypeChange = (fourthExtensionCalendar?: CalendarType) => {
    setIsDirty(true);
    setDeadline((current: IDeadline) => ({
      ...current,
      fourthExtensionCalendar,
      fourthExtensionInterval: fourthExtensionCalendar === CalendarType.JUDICIAL
        ? Interval.DAY : current.fourthExtensionInterval,
      fourthExtensionRoundingDirection: fourthExtensionCalendar === CalendarType.JUDICIAL
        ? undefined : current.fourthExtensionRoundingDirection,
    }));
  };

  const onFourthExtensionRoundingDirectionChange = (
    fourthExtensionRoundingDirection?: RoundingDirection,
  ) => {
    setIsDirty(true);
    setDeadline((current: IDeadline) => ({ ...current, fourthExtensionRoundingDirection }));
  };

  const onFifthExtensionDurationChange = (fifthExtensionDuration?: number) => {
    setIsDirty(true);
    setDeadline((current: IDeadline) => ({
      ...current,
      fifthExtensionDuration,
    }));
  };

  const onFifthExtensionIntervalChange = (fifthExtensionInterval?: Interval) => {
    setIsDirty(true);
    setDeadline((current: IDeadline) => ({ ...current, fifthExtensionInterval }));
  };

  const onFifthExtensionCalendarTypeChange = (fifthExtensionCalendar?: CalendarType) => {
    setIsDirty(true);
    setDeadline((current: IDeadline) => ({
      ...current,
      fifthExtensionCalendar,
      fifthExtensionInterval: fifthExtensionCalendar === CalendarType.JUDICIAL
        ? Interval.DAY : current.fifthExtensionInterval,
      fifthExtensionRoundingDirection: fifthExtensionCalendar === CalendarType.JUDICIAL
        ? undefined : current.fifthExtensionRoundingDirection,
    }));
  };

  const onFifthExtensionRoundingDirectionChange = (
    fifthExtensionRoundingDirection?: RoundingDirection,
  ) => {
    setIsDirty(true);
    setDeadline((current: IDeadline) => ({ ...current, fifthExtensionRoundingDirection }));
  };

  const onSaveClick = () => {
    if (deadline && isValid) {
      if (deadline
        && deadline.description
        && deadline.shortDescription
        && deadline.calendar
        && deadline.interval
        && deadline.duration !== undefined
        && (deadline.refLink ? isRefLinkUrlValid : true)
        && deadline.specReference
        && deadline.triggerDeadlines
        && ((deadline.calendar === CalendarType.CALENDAR && deadline.roundingDirection)
          || (deadline.calendar === CalendarType.JUDICIAL && !deadline.roundingDirection))
        && deadline.jurisdiction
      ) {
        save.saveDeadline(
          deadline.description,
          deadline.shortDescription,
          deadline.calendar,
          deadline.interval,
          deadline.duration,
          deadline.specReference,
          deadline.jurisdiction,
          deadline.roundingDirection,
          deadline.refLink,
          deadline.triggerDeadlines,
          deadline.id,
          deadline.extensionCalendar,
          deadline.extensionInterval,
          deadline.extensionDuration,
          deadline.categories,
          deadline.extensionRoundingDirection,
          deadline.secondExtensionCalendar,
          deadline.secondExtensionInterval,
          deadline.secondExtensionDuration,
          deadline.secondExtensionRoundingDirection,
          deadline.thirdExtensionCalendar,
          deadline.thirdExtensionInterval,
          deadline.thirdExtensionDuration,
          deadline.thirdExtensionRoundingDirection,
          deadline.fourthExtensionCalendar,
          deadline.fourthExtensionInterval,
          deadline.fourthExtensionDuration,
          deadline.fourthExtensionRoundingDirection,
          deadline.fifthExtensionCalendar,
          deadline.fifthExtensionInterval,
          deadline.fifthExtensionDuration,
          deadline.fifthExtensionRoundingDirection,
        ).then(() => {
          history.goBack();
          snackBar.showSnackBar('Deadline was successfully saved.');
        }).catch(() => snackBar.showSnackBar('There was an error saving your deadline.'));
      } else {
        snackBar.showSnackBar('Deadline is not valid.');
      }
    } else {
      snackBar.showSnackBar('Deadline is not valid.');
    }
  };

  const onJurisdictionChange = (j?: IJurisdiction) => {
    setDeadline({
      ...deadline,
      jurisdiction: j,
      jurisdictionId: j?.id,
    });
  };

  const onCancelClick = () => {
    if (isDirty) {
      discardChangesDialogService.showDialog(history.goBack);
    } else {
      history.goBack();
    }
  };

  const onDelete = () => {
    if (deadline.id) {
      const warn = deadline?.triggerDeadlines?.length || 0 > 1;
      confirmationDialog.showDialog(
        `${warn ? `You are about to delete a deadline for ${deadline.triggerDeadlines?.length} triggers. This will also affect all other triggers that inherit this from these triggers.`
          : ''} Do you want to delete the deadline: ${deadline.description}`,
        warn ? 'Warning!' : 'Delete Deadline?',
        'Delete',
      ).then((isConfirmed: boolean) => {
        if (isConfirmed && deadline.id) {
          deleteDeadline.delete(deadline.id).then(() => {
            history.goBack();
            snackBar.showSnackBar('The deadline has been deleted');
          }).catch(() => snackBar.showSnackBar('There was an error deleting the deadline'));
        }
      });
    }
  };

  const removeExtension = () => {
    setIsDirty(true);
    if (extensions === 5) {
      setDeadline((current: IDeadline) => ({
        ...current,
        fifthExtensionCalendar: undefined,
        fifthExtensionDuration: undefined,
        fifthExtensionInterval: undefined,
        fifthExtensionCalculation: undefined,
        fifthExtensionRoundingDirection: undefined,
      }));
    } else if (extensions === 4) {
      setDeadline((current: IDeadline) => ({
        ...current,
        fourthExtensionCalendar: undefined,
        fourthExtensionDuration: undefined,
        fourthExtensionInterval: undefined,
        fourthExtensionCalculation: undefined,
        fourthExtensionRoundingDirection: undefined,
      }));
    } else if (extensions === 3) {
      setDeadline((current: IDeadline) => ({
        ...current,
        thirdExtensionCalendar: undefined,
        thirdExtensionDuration: undefined,
        thirdExtensionInterval: undefined,
        thirdExtensionCalculation: undefined,
        thirdExtensionRoundingDirection: undefined,
      }));
    } else if (extensions === 2) {
      setDeadline((current: IDeadline) => ({
        ...current,
        secondExtensionCalendar: undefined,
        secondExtensionDuration: undefined,
        secondExtensionInterval: undefined,
        secondExtensionCalculation: undefined,
        secondExtensionRoundingDirection: undefined,
      }));
    } else if (extensions === 1) {
      setDeadline((current: IDeadline) => ({
        ...current,
        extensionCalendar: undefined,
        extensionDuration: undefined,
        extensionInterval: undefined,
        extensionCalculation: undefined,
        extensionRoundingDirection: undefined,
      }));
    }
    setExtensions((current: number) => current - 1);
  };

  return (
    <>
      <div className="flex justify-between items-center">
        <b className="text-2xl mb-6">{`${deadline.id ? 'Edit' : 'New'} Deadline`}</b>
      </div>
      <div className="flex flex-col gap-3">
        <TextField className="w-full" label="Name" fullWidth textarea outlined>
          <Input
            value={deadline?.description}
            onChange={onDescriptionChange}
            required
          />
        </TextField>
        <b>Jurisdiction</b>
        <JurisdictionAutoComplete
          onSelect={onJurisdictionChange}
          value={deadline?.jurisdiction}
          isDisabled
        />
        <div className="flex flex-col md:flex-row gap-3">
          <div className="flex flex-col gap-3 md:flex-1">
            <b>Short Description</b>
            <div>
              <TextField
                label="Short Description"
                className="w-full"
                outlined
                helperText={<HelperText className="w-full text-right">{`${deadline.shortDescription?.length || 0} of 50`}</HelperText>}
              >
                <Input
                  value={deadline.shortDescription || undefined}
                  onChange={onShortDescriptionChange}
                  required
                />
              </TextField>
            </div>
          </div>
          <div className="flex flex-col gap-3 md:flex-1">
            <b>Keywords</b>
            <KeywordSearch onCategoryPicked={onCategoryPicked} />
            <Keywords
              categories={deadline.categories}
              onCategoryRemove={onCategoryRemoved}
            />
          </div>
        </div>
        <div className="flex flex-col md:flex-row gap-3">
          <div className="flex flex-col gap-3 md:flex-1">
            <b>Spec Reference</b>
            <TextField label="Reference" outlined className="w-full">
              <Input
                value={deadline.specReference || undefined}
                onChange={onSpecReferenceChange}
                required
              />
            </TextField>
          </div>
          <div className="flex flex-col gap-3 md:flex-1">
            <b>Spec Reference Link</b>
            <TextField
              label="Link"
              outlined
              className="w-full"
              helperText={(
                <HelperText validation>
                  Url is not in correct format
                </HelperText>
              )}
            >
              <Input
                value={deadline.refLink || undefined}
                onChange={onRefLinkChange}
                type="url"
              />
            </TextField>
          </div>
        </div>
        <DeadlineProps
          duration={deadline.duration}
          onDurationChange={onDurationChange}
          interval={deadline.interval}
          onIntervalChange={onIntervalChange}
          roundingDirection={deadline.roundingDirection}
          onRoundingChange={onRoundingDirectionChange}
          calendarType={deadline.calendar}
          onCalendarChange={onCalendarTypeChange}
        />
        {extensions > 0 ? (
          <DeadlineProps
            duration={deadline.extensionDuration}
            onDurationChange={onExtensionDurationChange}
            interval={deadline.extensionInterval}
            onIntervalChange={onExtensionIntervalChange}
            roundingDirection={deadline.extensionRoundingDirection}
            onRoundingChange={onExtensionRoundingDirectionChange}
            calendarType={deadline.extensionCalendar}
            onCalendarChange={onExtensionCalendarTypeChange}
          />
        ) : <></>}
        {extensions > 1 ? (
          <DeadlineProps
            duration={deadline.secondExtensionDuration}
            onDurationChange={onSecondExtensionDurationChange}
            interval={deadline.secondExtensionInterval}
            onIntervalChange={onSecondExtensionIntervalChange}
            roundingDirection={deadline.secondExtensionRoundingDirection}
            onRoundingChange={onSecondExtensionRoundingDirectionChange}
            calendarType={deadline.secondExtensionCalendar}
            onCalendarChange={onSecondExtensionCalendarTypeChange}
          />
        ) : <></>}
        {extensions > 2 ? (
          <DeadlineProps
            duration={deadline.thirdExtensionDuration}
            onDurationChange={onThirdExtensionDurationChange}
            interval={deadline.thirdExtensionInterval}
            onIntervalChange={onThirdExtensionIntervalChange}
            roundingDirection={deadline.thirdExtensionRoundingDirection}
            onRoundingChange={onThirdExtensionRoundingDirectionChange}
            calendarType={deadline.thirdExtensionCalendar}
            onCalendarChange={onThirdExtensionCalendarTypeChange}
          />
        ) : <></>}
        {extensions > 3 ? (
          <DeadlineProps
            duration={deadline.fourthExtensionDuration}
            onDurationChange={onFourthExtensionDurationChange}
            interval={deadline.fourthExtensionInterval}
            onIntervalChange={onFourthExtensionIntervalChange}
            roundingDirection={deadline.fourthExtensionRoundingDirection}
            onRoundingChange={onFourthExtensionRoundingDirectionChange}
            calendarType={deadline.fourthExtensionCalendar}
            onCalendarChange={onFourthExtensionCalendarTypeChange}
          />
        ) : <></>}
        {extensions > 4 ? (
          <DeadlineProps
            duration={deadline.fifthExtensionDuration}
            onDurationChange={onFifthExtensionDurationChange}
            interval={deadline.fifthExtensionInterval}
            onIntervalChange={onFifthExtensionIntervalChange}
            roundingDirection={deadline.fifthExtensionRoundingDirection}
            onRoundingChange={onFifthExtensionRoundingDirectionChange}
            calendarType={deadline.fifthExtensionCalendar}
            onCalendarChange={onFifthExtensionCalendarTypeChange}
          />
        ) : <></>}
        <div className="flex flex-row justify-end gap-3">
          <AltButton
            disabled={extensions === 0}
            onClick={removeExtension}
          >
            Remove Extension
          </AltButton>
          <AltButton
            disabled={extensions === 5 || !areAllDeadlinePropsValid(deadline, extensions)}
            onClick={() => setExtensions((current: number) => current + 1)}

          >
            Add Extension
          </AltButton>
        </div>
        {!!deadline.jurisdiction?.id && (
          <TriggerList
            onAdd={onTriggerAdd}
            onUpdate={onTriggerUpdate}
            triggerDeadlines={deadline.triggerDeadlines}
            jurisdictionId={deadline.jurisdiction.id}
            onDelete={onTriggerDelete}
          />
        )}
        <div className="flex flex-row justify-end gap-3">
          <Button
            onClick={onSaveClick}
            disabled={!isDirty || !isValid || save.result.loading}
            role="button"
            aria-label="Save Deadline"
            raised
            icon={<MaterialIcon icon="save" />}
          >
            Save
          </Button>
          <AltButton
            onClick={onCancelClick}
            aria-label="Cancel Deadline Edit"
            icon={<MaterialIcon icon="cancel" />}
          >
            Cancel
          </AltButton>
          {deadline?.id && (
            <AltButton
              onClick={onDelete}
              aria-label="Delete Deadline"
              data-cy="delete-deadline"
              icon={<MaterialIcon icon="delete" />}
            >
              Delete
            </AltButton>
          )}
        </div>
      </div>
      {(save.result.loading || loading) && <LinearProgress indeterminate />}
    </>
  );
};

export default DeadlineForm;

import React, { FC, useState } from 'react';
import TextField, { Input } from '@fv-components/text-field';
import {
  OrganizationAutoComplete,
  RoleTable,
  AltButton,
  InvitesDatePicker,
} from '@components';
import { IInvite, IOrganization, IRole } from '@models';
import { getIsExpired } from '@libs/utils';
import useRoleQuery, { IRoleQueryHookProps } from '@hooks/Identity/Role/useRoleQuery';
import { ITableState } from '@components/Table/Table';
import Button from '@fv-components/button';
import MaterialIcon from '@fv-components/material-icon';
import { discardChangesDialogService } from '@hooks/useConfirmationDialog';

interface IInviteAdminFormProps {
  invite: IInvite;
  onSave: (invite: IInvite) => void;
  onCancel: VoidFunction;
  isChangeOrganizationAllowed?: boolean;
}

const roleGraphQLQueryFields = [
  'id',
  'description',
];

const InviteAdminForm: FC<IInviteAdminFormProps> = (
  {
    invite: i,
    onSave,
    onCancel,
    isChangeOrganizationAllowed,
  }: IInviteAdminFormProps,
) => {
  const [invite, setInvite] = useState(i);
  const [isDirty, setIsDirty] = useState(false);
  const isDateValid = invite.expirationDate && getIsExpired(invite.expirationDate);

  const [roleRequestObject, setRoleRequestObject] = useState<IRoleQueryHookProps>({
    queryObject: { skip: 0, take: 100 },
    fields: roleGraphQLQueryFields,
    fetchPolicy: 'network-only',
  });

  const roleApolloWrapper = useRoleQuery(roleRequestObject);

  const onDateSelect = (expirationDate?: Date) => {
    setIsDirty(true);
    setInvite(
      {
        ...invite,
        expirationDate,
      },
    );
  };

  const onOrganizationSelect = (organization?: IOrganization) => {
    setIsDirty(true);
    setInvite((current: IInvite) => ({
      ...current,
      organization,
      organizationId: organization?.id,
    }));
  };

  const roleTableStateChanged = (tableState: ITableState) => {
    setRoleRequestObject({
      queryObject: {
        ...tableState,
        ...tableState.filter,
        filter: undefined,
      },
      fields: roleGraphQLQueryFields,
    });
  };

  const onRoleSelected = (role: IRole, isSelected: boolean) => {
    setIsDirty(true);
    if (isSelected) {
      setInvite((current: IInvite) => ({
        ...invite,
        roles: [...(current.roles || []), role],
      }));
    } else {
      setInvite((current: IInvite) => ({
        ...current,
        roles: [...(invite.roles?.filter((r: IRole) => r.id !== role.id) || [])],
      }));
    }
  };

  const onCancelClick = () => {
    if (isDirty) {
      discardChangesDialogService.showDialog(onCancel);
    } else {
      onCancel();
    }
  };

  return (
    <div className="flex flex-col flex-1 gap-3">
      <b className="text-2xl">
        Edit Invite
      </b>
      <div className="flex md:flex-row flex-col gap-3">
        <div className="flex flex-col gap-3">
          {isChangeOrganizationAllowed
            && (
            <OrganizationAutoComplete
              onSelect={onOrganizationSelect}
              value={invite.organization}
              isRequired
            />
            )}
          <TextField
            label="Email"
            outlined
          >
            <Input
              value={invite?.emailAddress}
              type="email"
              required
              data-cy="email-input"
              disabled
            />
          </TextField>
          <InvitesDatePicker
            onDateChange={onDateSelect}
            value={invite.expirationDate}
          />
        </div>
        <RoleTable
          roles={roleApolloWrapper.result.items}
          hasMore={roleApolloWrapper.result.hasMore}
          onTableStateChanged={roleTableStateChanged}
          onRoleSelect={onRoleSelected}
          selectedRoles={invite.roles}
          loading={roleApolloWrapper.loading}
        />
      </div>
      <div className="flex flex-row justify-end gap-3">
        <Button
          onClick={() => onSave(invite)}
          role="button"
          aria-label="Save Invite"
          icon={<MaterialIcon icon="save" />}
          raised
          disabled={!(isDirty && isDateValid && invite.organization?.id)}
        >
          Save Invite
        </Button>
        <AltButton
          onClick={onCancelClick}
          aria-label="Cancel Save Invites"
          icon={<MaterialIcon icon="cancel" />}
        >
          Cancel
        </AltButton>
      </div>
    </div>
  );
};

export default InviteAdminForm;

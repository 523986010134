import { ChangeEvent, useRef } from 'react';

interface IUseDebounceHook {
  handler: (event: ChangeEvent<HTMLInputElement>) => void;
}

interface IUseDebounceHookProps {
  immediateCallback?: (text: string) => void;
  timeout: number;
  debouncedCallback: (text: string) => void;
}

type UseDebounceHook = (props: IUseDebounceHookProps) => IUseDebounceHook;

const useDebounce: UseDebounceHook = ({
  debouncedCallback,
  immediateCallback,
  timeout,
}): IUseDebounceHook => {
  const expiration = useRef(Date.now());

  const execute = (text: string) => {
    if (immediateCallback) {
      immediateCallback(text);
    }
    setTimeout(() => {
      if (expiration.current && Date.now() > expiration.current) {
        debouncedCallback(text);
      }
    }, timeout + 10);
  };

  const handler = (event: ChangeEvent<HTMLInputElement>) => {
    expiration.current = Date.now() + timeout;
    execute(event.target.value);
  };

  return { handler };
};

export default useDebounce;

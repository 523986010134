import React, { FC } from 'react';
import Table, { ITableState } from '@components/Table/Table';
import { IRowMenuOption } from '@components/Table/Menu/RowMenu';
import { IInvite } from '@models';
import { ITableMenuOption } from '@components/Table/Menu/TableMenu';

interface IInviteTableProps {
  invites?: IInvite[];
  onTableStateChanged: (state: ITableState) => void;
  hasMore?: boolean;
  onInviteEdit: (invite: IInvite) => void;
  loading: boolean;
  onDelete: (toBeDeleted?: IInvite[]) => void;
  onResend: (invite: IInvite) => void;
  onAddClicked?: VoidFunction;
  isOrgColumnVisible?: boolean;
}

const InviteTable: FC<IInviteTableProps> = (
  {
    invites,
    onTableStateChanged,
    hasMore,
    onInviteEdit,
    loading,
    onDelete,
    onResend,
    onAddClicked,
    isOrgColumnVisible,
  },
) => {
  const rowOptions: IRowMenuOption[] = [
    {
      key: 'edit',
      text: 'Edit',
      onClick: onInviteEdit,
    },
    {
      key: 'resend',
      text: 'Resend Invite',
      onClick: onResend,
    },
  ];

  const tableMenuOptions: ITableMenuOption[] = [{
    key: 'delete',
    text: 'Delete Selected',
    onClick: onDelete,
    icon: 'trash',
  },
  ];

  return (
    <Table
      dataSource={invites}
      rowMenuOptions={rowOptions}
      minHeight={150}
      maxHeight={700}
      onStateChange={onTableStateChanged}
      hasMore={hasMore}
      takeOptions={[25, 50, 100, 500]}
      isPagerVisible
      loading={loading}
      tableMenuOptions={tableMenuOptions}
      isMultiSelect
      isFabVisible={!!onAddClicked}
      fabIcon={onAddClicked && 'add'}
      fabText={onAddClicked && 'Invite Users'}
      fabAriaLabel={onAddClicked && 'Add New Invites'}
      cypressId={onAddClicked && 'add-new-invite'}
      onFabClick={onAddClicked}
      title="Invites"
    >
      {[
        {
          key: 'id',
          header: 'Id',
          field: 'id',
        },
        {
          key: 'emailAddress',
          header: 'Email',
          field: 'emailAddress',
          isFilterable: true,
          isSortable: true,
        },
        ...(isOrgColumnVisible ? [{
          key: 'organization',
          header: 'Organization',
          field: 'organization.description',
          isSortable: true,
        }] : []),
        {
          key: 'expirationDate',
          header: 'Expires',
          field: 'expirationDate',
          isSortable: true,
        },
      ]}
    </Table>
  );
};

export default InviteTable;

import React, { FC } from 'react';
import BaseMenu from './BaseMenu';

export interface ITableMenuOption {
  key: string | number;
  text: string;
  icon?: string;
  onClick: (selectedItems: any[] | undefined) => void;
}

interface ITableMenuProps {
  options?: ITableMenuOption[];
  selectedItems?: any[];
  disabled?: boolean;
}

const TableMenu: FC<ITableMenuProps> = ({
  options,
  selectedItems,
  disabled,
}: ITableMenuProps) => {
  const onClick = (optionIndex: number) => {
    if (options && options[optionIndex]) {
      options[optionIndex].onClick(selectedItems);
    }
  };

  return (
    <BaseMenu
      onOptionClick={onClick}
      options={options}
      disabled={disabled}
      ariaLabel="Table Main Menu"
      icon="more_vert"
    />
  );
};

export default TableMenu;

import React, {
  FC,
} from 'react';
import {
  Switch, Route, RouteComponentProps, Redirect,
} from 'react-router-dom';
import { ITrigger, IJurisdiction, IDeadline } from '@models';
import TriggerView from './TriggerView';
import TriggerForm from './TriggerForm/TriggerForm';
import SearchPage from './SearchPage';
import DeadlineSearchResultsView from './DeadlineSearchResultsView';
import DeadlineForm from './DeadlineList/DeadlineForm/DeadlineForm';

export interface IDeadlineState {
  trigger?: ITrigger;
  jurisdiction?: IJurisdiction;
  deadline?: IDeadline;
  specReference?: string;
  shortDescription?: string;
  description?: string;
  categoryIds?: string[];
  forceDeadlineRefresh?: boolean;
}

const DeadlineAdminPage: FC = () => (
  <Switch>
    <Route
      exact
      path="/admin/deadlines/triggers/new"
      render={({ location: { state } }: RouteComponentProps) => {
        const { jurisdiction } = state as IDeadlineState || {};
        return <TriggerForm jurisdiction={jurisdiction} />;
      }}
    />
    <Route
      exact
      path="/admin/deadlines/new"
      render={({ location: { state } }: RouteComponentProps) => {
        const { jurisdiction, trigger } = state as IDeadlineState || {};
        if (jurisdiction) {
          return (<DeadlineForm jurisdiction={jurisdiction} trigger={trigger} />);
        }
        return <Redirect to="/admin/deadlines" />;
      }}
    />
    <Route
      exact
      path="/admin/deadlines/search"
      render={({ location: { state } }: RouteComponentProps) => {
        const {
          specReference, shortDescription, description, categoryIds, jurisdiction,
        } = state as IDeadlineState || {};
        if (jurisdiction) {
          return (
            <div className="flex flex-col gap-3">
              <DeadlineSearchResultsView
                queryObject={{
                  specReference,
                  shortDescription,
                  description,
                  categoryIds,
                  jurisdictionIds: jurisdiction?.id ? [jurisdiction.id] : [],
                }}
                jurisdiction={jurisdiction}
              />
            </div>
          );
        }
        return <Redirect to="/admin/deadlines" />;
      }}
    />
    <Route
      exact
      path="/admin/deadlines/triggers/:id"
    >
      <TriggerView />
    </Route>
    <Route
      exact
      path="/admin/deadlines/triggers/:id/edit"
    >
      <TriggerForm />
    </Route>
    <Route
      exact
      path="/admin/deadlines/:id/edit"
    >
      <DeadlineForm />
    </Route>
    <Route
      exact
      path="/admin/deadlines/"
      render={({ location: { state } }: RouteComponentProps) => {
        const { jurisdiction } = state as IDeadlineState || {};
        return (
          <SearchPage jurisdiction={jurisdiction} />
        );
      }}
    />
  </Switch>
);
export default DeadlineAdminPage;

/* eslint-disable max-len */
import React, {
  FC,
} from 'react';

const css = require('./TermsOfService.module.css');

const TermsOfService: FC = () => (
  <>
    <div className={css.topHeader} data-cy="terms-top-header">
      Filevine, Inc. Product and Services Terms
    </div>
    <div className={css.caption}>
      Last Updated: August 17, 2020
    </div>
    <div className={css.caption}>
      v.3.0
    </div>
    <div className={css.sectionHeader}>
      Timely:
    </div>
    <ol>
      <li>Subscriber understands and acknowledges that it is entirely responsible for the proper data entry into the Timely Product.</li>
      <li>Timely will generate a list of deadline chains which can be added as “Reminders” in the Filevine System or as calendar reminders. Subscriber expressly understands and acknowledges that it is entirely responsible for configuring its own “Reminder” notifications within the Filevine System, or Subscriber’s reminder notifications within Subscriber’s electronic calendar. </li>
      <li>Subscriber understands and acknowledges that the use of the Timely Product is prohibited unless the user is: (a) a licensed attorney; or (b) under the direct supervision of a licensed attorney.</li>
      <li>
        Subscriber understands and acknowledges that: (a) the court rules and other laws underlying the Timely Product may change from time to time; and (b) that Subscriber’s use of the Timely Product is not intended, nor should it be considered to substitute for its compliance with professional duties or the use of professional judgment in reading and interpreting the court rules, which duties include an obligation on your part to do the following:
        <ol>
          <li className={css.lowerAlpha}>Obtain a copy of the current applicable court rules for the applicable courts, and compare and evaluate the information retrieved by the use of the Timely Product, in light of information about, and interpretation of, those court rules; and then</li>
          <li className={css.lowerAlpha}>Obtain a copy of the holiday/court closure list for the applicable courts and apply the court rules obtained in accordance with Section 2(a) above in light of such holiday list and further test the accuracy of the information.</li>
        </ol>
      </li>
      <li>SUBSCRIBER EXPRESSLY UNDERSTANDSAND ACKNOWLEDGES THAT FILEVINE PROVIDES THE TIMELY PRODUCT FOR THE CONVENIENCE OF THE SUBSCRIBER, AND IN NO EVENT SHALL FILEVINE BE LIABLE FOR ANY LOSSES OR EXPENSES INCURRED AS A RESULT OF SUBSCRIBER’S FAILURE TO COMPLY WITH PROFESSIONAL DUTIES OR FAILURE TO PROPERLY READ, REVIEW AND INTERPRET THE COURT RULES. SUBSCRIBER ACKNOWLEDGES THAT INTERVENING CIRCUMSTANCES MAY ARISE AFTER THE DATE THAT FILEVINE PROVIDES YOU ACCESS TO THE SERVICES THAT MAY CAUSE THE SERVICE TO CEASE BEING ACCURATE AND IN NO EVENT SHALL FILEVINE BE LIABLE FOR ANY LOSSES OR EXPENSES INCURRED AS A RESULT OF SUCH INTERVENING CIRCUMSTANCES.</li>
    </ol>
  </>
);

export default TermsOfService;

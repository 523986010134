import { ApolloError, FetchPolicy } from '@apollo/client';
import useTimelyQuery from '@hooks/useTimelyQuery';
import ICategory from '@models/Deadlines/Categories/ICategory';
import ICategoryQuery from '@models/Deadlines/Categories/ICategoryQuery';
import { IDeleteHookResult, useTimelyDelete } from '@hooks/useTimelyDelete';
import useCategorySave, { ICategorySaveHookResult } from './useCategorySave';

type CategoryQueryHook = (
  props: ICategoryQueryHookProps
) => ICategoryQueryHookResult;

export interface ICategoryQueryHookProps {
  queryObject: ICategoryQuery;
  fields: string[];
  lazy?: boolean;
  fetchPolicy?: FetchPolicy;
}

interface ICategoryQueryHookResult {
  loading: boolean;
  error?: ApolloError;
  hasMore: boolean;
  items: ICategory[];
  saveCategoryHook: ICategorySaveHookResult;
  deleteCategoryHook: IDeleteHookResult;
  called: boolean;
  load: VoidFunction;
}

const useCategoryQuery: CategoryQueryHook = (
  {
    fields, queryObject, lazy, fetchPolicy,
  }: ICategoryQueryHookProps,
) => {
  const {
    loading, error, called, result: { hasMore, items }, load, queryString,
  } = useTimelyQuery<ICategory>({
    queryInputType: 'CategoryQueryInputType',
    fields,
    variables: { queryObject },
    collection: 'categories',
    lazy,
    fetchPolicy,
  });

  return {
    load,
    loading,
    error,
    called,
    hasMore,
    items,
    saveCategoryHook: useCategorySave({ queryString, queryObject }),
    deleteCategoryHook: useTimelyDelete({ capitalizedType: 'Category' }),
  };
};

export default useCategoryQuery;

import React, { FC } from 'react';
import { useHistory } from 'react-router-dom';

const css = require('./Footer.module.scss');

const Footer: FC = () => {
  const history = useHistory();

  const onShowTermsClick = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    event.preventDefault();
    history.push('/terms', {});
  };

  return (
    <div className={css.footer}>
      <div className={css.footerContainer}>
        <a onClick={onShowTermsClick} href="/terms" data-cy="footer-terms">Terms</a>
      </div>
    </div>
  );
};

export default Footer;

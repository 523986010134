import React, { CSSProperties, FC } from 'react';

interface IFilevineLogoProps {
  style?: CSSProperties;
}
// i left these styles in here on purose so it would all be in 1 file.
// there are plenty of stles in the svg string anyway
const FilevineLogo: FC<IFilevineLogoProps> = (
  {
    style,
  }: IFilevineLogoProps,
) => (
  <div
    style={style}
    // this is perfectly safe as we completely control the string
    // eslint-disable-next-line react/no-danger
    dangerouslySetInnerHTML={{ __html: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 261.06 54.09"><defs><style>.cls-1,.cls-2{fill:#231f20;}.cls-1{opacity:0;}</style></defs><g id="Layer_2" data-name="Layer 2"><g id="Layer_1-2" data-name="Layer 1"><rect class="cls-1" x="211.46" y="0.29" width="49.6" height="53.43"/><g id="Layer_2-2" data-name="Layer 2"><g id="Layer_1-2-2" data-name="Layer 1-2"><path class="cls-2" d="M22.4,0,0,22.4l3.94,4L22.4,7.89,40.86,26.35l3.94-4ZM5.57,28l3.87,3.88,13-13,13,13L39.24,28,22.4,11.14Z"/><path class="cls-2" d="M75.43,16.35v7.46H57.94v7.56H74.1v7.06H57.94v15H49.6V16.35Z"/><path class="cls-2" d="M85.1,53.44H77.39V28H85.1Zm-.16-29.88H77.56v-7h7.38Z"/><path class="cls-2" d="M95.23,53.44H87.52V16.35h7.71Z"/><path class="cls-2" d="M122.89,41.44V43H104a6.36,6.36,0,0,0,2,4,5.64,5.64,0,0,0,3.9,1.39q3.75,0,5.14-2.87h7.31a10.63,10.63,0,0,1-4.4,6.25,13.92,13.92,0,0,1-8,2.3A12.56,12.56,0,0,1,96.66,40.73q0-6.07,3.6-9.68a12.71,12.71,0,0,1,9.54-3.66,12.72,12.72,0,0,1,9.58,3.8Q122.89,35,122.89,41.44ZM109.65,33q-4.74,0-5.58,5h11.26a6.07,6.07,0,0,0-1.88-3.7A5.42,5.42,0,0,0,109.65,33Z"/><path class="cls-2" d="M140.47,28h7.91l-9.09,25.49H130.1L121,28h8.3s5.48,17.66,5.58,18Z"/><path class="cls-2" d="M175,27.36a8.61,8.61,0,0,1,6.45,2.45c1.6,1.63,2.39,3.94,2.39,6.94V53.44h-7.7V38.28q0-4.35-4.1-4.35a4.67,4.67,0,0,0-3.46,1.38,5.3,5.3,0,0,0-1.38,3.91V53.44h-7.71V28H167v3.61A9.31,9.31,0,0,1,175,27.36Z"/><path class="cls-2" d="M211.46,41.44V43H192.59a6.47,6.47,0,0,0,1.92,4,5.62,5.62,0,0,0,3.91,1.39q3.75,0,5.13-2.87h7.31a10.54,10.54,0,0,1-4.39,6.25,13.92,13.92,0,0,1-8.05,2.3,12.56,12.56,0,0,1-13.25-11.83,13.19,13.19,0,0,1,0-1.54q0-6.08,3.6-9.68a14.12,14.12,0,0,1,19.12.14Q211.46,35,211.46,41.44ZM198.22,33c-3.16,0-5,1.68-5.58,5H203.9A6.15,6.15,0,0,0,202,34.3,5.44,5.44,0,0,0,198.22,33Z"/><path class="cls-2" d="M157.07,53.44h-7.7V28h7.7Zm-.16-29.88h-7.38v-7h7.38Z"/></g></g></g></g></svg>' }}
  />
);

export default FilevineLogo;

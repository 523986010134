import React, {
  FC, useEffect, useRef, useCallback,
} from 'react';
import dayjs from 'dayjs';
import { ICategory } from '@models';
import DeadlineListItem from './DeadlineListItem/DeadlineListItem';
import { ITriggerCalculationState, IDeadlineCalculationState } from '../../Calculator';

const css = require('./DeadlineList.module.scss');

const generateHeader = (
  tcs: ITriggerCalculationState,
) => {
  if (tcs.deadlines?.length) {
    return (
      <div className="flex flex-row gap-3">
        <div className="whitespace-nowrap">{dayjs(tcs.triggerDate).format('MMM DD YYYY')}</div>
        <div>-</div>
        <div>
          {tcs.trigger?.description}
          {tcs.triggerLabel ? ` - ${tcs.triggerLabel}` : ''}
        </div>
      </div>
    );
  }
  return <></>;
};

const generateKey = (
  tcs: ITriggerCalculationState,
) => `${dayjs(tcs.triggerDate).format('MMM DD YYYY')} ${tcs.trigger?.description} - ${tcs.triggerLabel}`;

const doesTriggerDeadlineMatchFilter = (
  deadlineCalculationState: IDeadlineCalculationState,
  filter?: string,
): boolean => !filter || !!deadlineCalculationState.deadline
  .description?.toLowerCase().includes(filter.toLowerCase())
  || !!deadlineCalculationState.deadline
    .specReference?.toLowerCase().includes(filter.toLowerCase());

const doesTriggerDeadlineHaveCategory = (
  deadlineCalculationState: IDeadlineCalculationState,
  category: ICategory,
): boolean => !!deadlineCalculationState.deadline?.categories?.find(
    (deadlineCategory: ICategory) => deadlineCategory.id === category.id
  );

const doesTriggerDeadlineHaveAllCategories = (
  deadlineCalculationState: IDeadlineCalculationState,
  categories?: ICategory[],
): boolean => !categories?.length || categories.every(
  (category: ICategory) => doesTriggerDeadlineHaveCategory(deadlineCalculationState, category),
);

const isTriggerDeadlineHidden = (
  deadlineCalculationState: IDeadlineCalculationState,
  filter?: string,
  categories?: ICategory[],
) => !doesTriggerDeadlineMatchFilter(deadlineCalculationState, filter)
    || !doesTriggerDeadlineHaveAllCategories(deadlineCalculationState, categories);

interface IDeadlineListProps {
  triggerCalculationStates: ITriggerCalculationState[];
  disabled?: boolean;
  filter?: string;
  categories?: ICategory[];
  onHeadlineChange?: (text?: string) => void;
  onServiceMethodDeadlineClick: (
     deadlineId: string,
     serviceMethodId: string,
     isSelected: boolean,
     triggerIndex: number,
  ) => void;
  onDeadlineClick: (deadlineId: string, isSelected: boolean, triggerIndex: number) => void;
}

const DeadlineList: FC<IDeadlineListProps> = (
  {
    triggerCalculationStates,
    disabled,
    categories,
    filter,
    onHeadlineChange,
    onServiceMethodDeadlineClick,
    onDeadlineClick,
  }: IDeadlineListProps,
) => {
  const headerRefs = useRef<(HTMLDivElement | null)[]>([]);

  const onRef = (element: HTMLDivElement | null, index: number) => {
    if (element) {
      headerRefs.current[index] = element;
    }
  };

  const onScroll = useCallback(() => {
    const last = headerRefs.current.filter(
      (div: HTMLElement | null) => div && div.getBoundingClientRect().top <= 175,
    ).pop();
    if (onHeadlineChange) {
      onHeadlineChange(`${last?.firstChild?.childNodes[0].textContent || ''}${last?.firstChild?.childNodes[0].textContent ? ' - ' : ''}${last?.firstChild?.childNodes[2].textContent || ''}`);
    }
  }, [onHeadlineChange, headerRefs]);

  useEffect(() => {
    window.addEventListener('scroll', onScroll);
    return () => {
      window.removeEventListener('scroll', onScroll);
    };
  }, [onScroll]);

  return (
    <div
      className={css.container}
      data-cy="deadline-container"
    >
      {triggerCalculationStates.map(
        (triggerCalculationState: ITriggerCalculationState, triggerIndex: number) => (
          <div
            key={generateKey(triggerCalculationState)}
            className={triggerCalculationState.toBeRemoved ? 'hidden' : ''}
          >
            <div
              className={css.header}
              ref={(element: HTMLDivElement | null) => !triggerCalculationState.toBeRemoved
                && onRef(element, triggerIndex)}
              data-cy="deadline-header"
            >
              {generateHeader(triggerCalculationState)}
            </div>
            <div>
              {triggerCalculationState.deadlines?.map(
                (deadlineCalculationState: IDeadlineCalculationState) => (
                  !isTriggerDeadlineHidden(deadlineCalculationState, filter, categories) && (
                  <DeadlineListItem
                    key={deadlineCalculationState.deadline.id}
                    deadlineCalculationState={deadlineCalculationState}
                    onItemClick={(deadlineId: string, isSelected: boolean) => (
                      onDeadlineClick(deadlineId, isSelected, triggerIndex)
                    )}
                    onServiceMethodDeadlineClick={
                      (deadlineId: string, serviceMethodId: string, isSelected: boolean) => (
                        onServiceMethodDeadlineClick(
                          deadlineId,
                          serviceMethodId,
                          isSelected,
                          triggerIndex,
                        )
                      )
                  }
                    disabled={disabled}
                  />
                  )
                ),
              )}
            </div>
          </div>
        ),
      )}
    </div>
  );
};

export default DeadlineList;

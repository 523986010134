import React, { FC } from 'react';

interface IGridProps {
  numberOfRows: number;
  cellsPerRow: number;
  children: React.ReactElement<IRowProps>;
}

interface IRowProps {
  row?: number;
  children: React.ReactElement<ICellProps> | React.ReactElement<ICellProps>[];
}

export interface ICellProps {
  row: number;
  col: number;
}

const Grid: FC<IGridProps> = ({ numberOfRows, cellsPerRow, children }: IGridProps) => {
  const rows = Array.from(Array(numberOfRows).keys());
  const cells = Array.from(Array(cellsPerRow).keys());
  return (
    <>
      {rows.map((rv: any, row: number) => React.cloneElement(children, {
        row,
        key: JSON.stringify(rv),
        children: cells.map((cv: any, col: number) => React.cloneElement(
          children.props.children as React.ReactElement<ICellProps>,
          {
            row,
            col,
            key: JSON.stringify(cv),
          },
        )),
      }))}
    </>
  );
};

export default Grid;

import React, { FC } from 'react';
import Button from '@fv-components/button';
import dayjs, { Dayjs } from 'dayjs';
import { isAnyDateValid } from '../DateSelector';

export interface IYearCellProps {
  row?: number;
  col?: number;
  date: Dayjs;
  baseYear: number;
  onYearSelect: (date: Dayjs) => void;
  minDate?: Dayjs;
  maxDate?: Dayjs;
}

const YearCell: FC<IYearCellProps> = (
  {
    row,
    col,
    date: d,
    baseYear,
    onYearSelect,
    minDate,
    maxDate,
  }: IYearCellProps,
) => {
  const year = baseYear + (row || 0) * 4 + (col || 0);
  const date = dayjs(d).year(year);

  return (
    <Button
      className="flex-1"
      key={col}
      role="button"
      disabled={!isAnyDateValid(date.set('date', 1).set('month', 0), date.set('month', 11).set('date', 31), minDate, maxDate)}
      aria-label={`Select Year ${year}`}
      onClick={() => onYearSelect(date)}
    >
      {year}
    </Button>
  );
};

export default YearCell;

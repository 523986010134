import {
  useMutation, ApolloError, gql,
} from '@apollo/client';
import { removeFromCache } from '@libs/apollo';

type DeleteHook = (props: IDeleteHookProps) => IDeleteHookResult;

export interface IDeleteResult {
  id: string;
  deleted: boolean;
}

export interface IDeleteHookResult {
  result: {
    error?: ApolloError;
    loading: boolean;
    called: boolean;
  };
  delete: (...ids: string[]) => Promise<IDeleteResult[]>,
}

interface IDeleteHookProps {
  capitalizedType: string;
}

export const useTimelyDelete: DeleteHook = ({
  capitalizedType,
}: IDeleteHookProps) => {
  const DELETE_GQL = gql`
    mutation ${capitalizedType}Mutation($id: String!) {
      delete${capitalizedType}(id: $id)
    }`;

  const [deleteFunc, {
    error, loading, called, client,
  }] = useMutation<{
    deleteTrigger: string;
  }>(DELETE_GQL);

  return {
    result: { error, loading, called },
    delete: (...ids: string[]) => Promise.all(ids.map(
      (id: string, index: number) => deleteFunc(
        {
          variables: { id },
          // this point of this is to only give the last delete call a update method
          // and then pass into it all of the ids for removal
          update: index === ids.length - 1
            ? () => removeFromCache(ids, `${capitalizedType}Type`, client) : undefined,
        },
      ).then(() => ({ id, deleted: true }))
        .catch(() => ({ id, deleted: false })),
    )).then((results: IDeleteResult[]) => {
      const deletedIds = results.filter(
        (result: IDeleteResult) => result.deleted,
      ).map((result: IDeleteResult) => result.id);
      if (deletedIds?.length) {
        removeFromCache(ids, `${capitalizedType}Type`, client);
      }
      const failed = results.filter(
        (x: IDeleteResult) => x.deleted === false,
      ).map((x: IDeleteResult) => x.id);
      if (failed.length) {
        throw new Error(`At least one of the deletes failed... ${failed.join(', ')}`);
      }
      return results;
    }),
  };
};

import {
  ApolloClient, NormalizedCacheObject, InMemoryCache, ApolloLink,
} from '@apollo/client';
import { RestLink } from 'apollo-link-rest';
import { useState } from 'react';

import {
  GoogleLoginResponse,
  useGoogleLogin,
  GoogleLoginResponseOffline,
} from 'react-google-login';
import { onError } from '@apollo/client/link/error';
import { setContext } from '@apollo/client/link/context';
import { getConfig } from '@src/config';

const { googleApiClientId, googleApiUrl } = getConfig();

interface IGoogleClientHook {
  client?: ApolloClient<NormalizedCacheObject>;
  restLink?: RestLink;
  signIn?: VoidFunction;
  accessToken?: string;
}

const getErrorLink = () => onError(
  ({
    networkError,
    operation,
  }) => {
    // eslint-disable-next-line no-console
    console.log(`[Network error] Operation: ${operation.operationName} Network Error: ${networkError}`);
  },
);

const getRestLink = (): RestLink => new RestLink({ uri: googleApiUrl });

const getAuthLink = (accessToken: string) => setContext(async (_, { headers, ...rest }) => ({
  ...rest,
  headers: {
    Authorization: `Bearer ${accessToken}`,
  },
}));

interface IGoogleClientHookProps {
  onSuccess?: VoidFunction;
}

const useGoogleClient = ({ onSuccess }:IGoogleClientHookProps): IGoogleClientHook => {
  const [accessToken, setAccessToken] = useState(localStorage.getItem('googleAccessToken'));

  const getClient = (token: string) => new ApolloClient({
    cache: new InMemoryCache(),
    link: ApolloLink.from([getErrorLink(), getAuthLink(token), getRestLink()]),
  });

  const [state, setState] = useState<IGoogleClientHook>({
    restLink: getRestLink(),
    client: accessToken ? getClient(accessToken) : undefined,
  });

  const onLogin = (
    response: GoogleLoginResponse | GoogleLoginResponseOffline,
  ) => {
    if ((response as GoogleLoginResponse).accessToken) {
      setAccessToken((response as GoogleLoginResponse).accessToken);
      setState((previousState) => ({
        ...previousState,
        client: getClient((response as GoogleLoginResponse).accessToken),
        accessToken: (response as GoogleLoginResponse).accessToken,
      }));
      if (onSuccess) {
        onSuccess();
      }
    }
  };

  const { signIn } = useGoogleLogin({
    onSuccess: onLogin,
    scope: 'https://www.googleapis.com/auth/calendar',
    clientId: googleApiClientId || '',
    onFailure: (error: any) => {
      // eslint-disable-next-line no-console
      console.log('failed to login to google', error);
    },
    accessType: 'online',
    isSignedIn: true,
    autoLoad: false,
  });

  return {
    ...state,
    signIn,
  };
};

export default useGoogleClient;

import React, { FC, useState, useEffect } from 'react';
import Button from '@fv-components/button';
import MaterialIcon from '@fv-components/material-icon';
import { useHistory } from 'react-router-dom';
import useResetPassword from '@hooks/Identity/useResetPassword';
import SetPassword from '@components/SetPassword/SetPassword';

const css = require('./ResetPassword.module.scss');
const appCss = require('../../App.module.scss');
const notLoggedInCss = require('../NotLoggedIn.module.scss');

interface IResetPasswordState {
  password?: string;
  isPasswordValid?: boolean;
}

interface IResetPasswordProps {
  token?: string;
}

const ResetPassword: FC<IResetPasswordProps> = ({ token }) => {
  const [state, setState] = useState<IResetPasswordState>({});
  const {
    resetPassword, result,
  } = useResetPassword();
  const history = useHistory();

  useEffect(() => {
    if (result.data?.resetPassword) {
      history.push('/sign-in');
    }
  }, [result, history]);

  const onPasswordChange = (password?: string, isPasswordValid?: boolean) => {
    setState({
      password,
      isPasswordValid,
    });
  };

  const onSubmitClicked = () => {
    if (state.password && state.isPasswordValid && token) {
      resetPassword(state.password, token);
    }
  };

  const onBackClicked = () => {
    history.push('/sign-in');
  };

  return (
    <>
      <h2>Reset Password</h2>
      <SetPassword onPasswordChange={onPasswordChange} autoFocus isRequired />

      <div className={notLoggedInCss.buttonRow}>
        <Button
          onClick={onBackClicked}
          icon={<MaterialIcon icon="arrow_back" />}
          role="button"
          aria-label="Back To Sign In Screen"
          data-cy="back-to-login"
          outlined
          className={`${appCss.secondaryButton} ${notLoggedInCss.backButton}`}
        />
        <Button
          onClick={onSubmitClicked}
          disabled={!state.password || !state.isPasswordValid || result.loading}
          role="button"
          aria-label="Submit Password Reset"
          data-cy="submit-reset"
          className={notLoggedInCss.submitButton}
          raised
        >
          Submit
        </Button>
      </div>

      {(result.error
          || (result.called && !result.loading && result.data?.resetPassword === false)) && (
          <p className={css.error}>
            There was an error resetting your password.
          </p>
      )}
    </>
  );
};

export default ResetPassword;

import React, { useState, useEffect } from 'react';
import TextField, { Input } from '@fv-components/text-field';

interface IProps {
  onChange: (duration?: number) => void,
  value?: number;
  isDisabled?: boolean;
  label?: string;
  isRequired?: boolean;
  isNegativeAllowed?: boolean
}

const DurationInput = (
  {
    onChange,
    value,
    isDisabled,
    label,
    isRequired,
    isNegativeAllowed,
  }: IProps,
) => {
  const [inputText, setInputText] = useState<string>(value?.toString() || '');

  const onInputChange = (event: React.FormEvent<HTMLInputElement>) => {
    if ((event.currentTarget.validity.valid || event.currentTarget.value === '')) {
      setInputText(event.currentTarget.value);
      onChange(event.currentTarget.value !== '' ? +event.currentTarget.value : undefined);
    } else if (event.currentTarget.value === '-' && isNegativeAllowed) {
      setInputText('-');
      onChange(undefined);
    }
  };

  useEffect(() => {
    if (value !== undefined && value !== null && !inputText) {
      setInputText(value.toString());
    }
  }, [value, inputText]);

  return (
    <TextField
      label={label || 'Duration'}
      outlined
      className="w-full"
    >
      <Input
        type="text"
        value={inputText}
        onChange={onInputChange}
        pattern={isNegativeAllowed ? '(0|-?[1-9][0-9]{0,4})' : '(0|[1-9][0-9]{0,4})'}
        required={isRequired}
        disabled={isDisabled}
      />
    </TextField>
  );
};

export default DurationInput;

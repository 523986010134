import React, { FC, useState, useCallback } from 'react';
import Snackbar from '@fv-components/snackbar';
import { ITableMenuOption } from '@components/Table/Menu/TableMenu';
import { ConfirmationDialog, JurisdictionAutoComplete } from '@components';
import useHolidayQuery, { IHolidayQueryHookProps } from '@hooks/Holidays/useHolidayQuery';
import Table, { ITableState } from '@components/Table/Table';
import { IRowMenuOption } from '@components/Table/Menu/RowMenu';
import { IJurisdiction, IHoliday } from '@models';
import dayjs from 'dayjs';
import EditHolidayModal from './EditHolidayModal/EditHolidayModal';

const HolidayAdminPage: FC = () => {
  const graphQLQueryFields = [
    'id',
    'description',
    'jurisdiction { address hasChildren description id parent { description id address } }',
    'jurisdictionId',
    'date',
  ];
  const [isEditorModalVisible, setIsEditorModalVisible] = useState(false);
  const [isConfirmationDialogVisible, setIsConfirmationDialogVisible] = useState(false);
  const [selectedHolidays, setSelectedHolidays] = useState<IHoliday[]>([]);
  const [holiday, setHoliday] = useState<IHoliday>();
  const [jurisdiction, setJurisdiction] = useState<IJurisdiction>();

  const [
    HolidayRequestObject,
    setHolidayRequestObject,
  ] = useState<IHolidayQueryHookProps>({
    queryObject: { skip: 0, take: 100, jurisdictionId: jurisdiction?.id },
    fields: graphQLQueryFields,
    fetchPolicy: 'network-only',
  });

  const apolloWrapper = useHolidayQuery(HolidayRequestObject);

  const options: ITableMenuOption[] = [
    {
      key: 'deleteSelected',
      text: 'Delete Selected',
      onClick: (selectedItems?: IHoliday[]) => {
        if (selectedItems && selectedItems.length) {
          setSelectedHolidays(selectedItems);
          setIsConfirmationDialogVisible(true);
        }
      },
    },
  ];

  const rowOptions: IRowMenuOption[] = [
    {
      key: 'edit',
      text: 'Edit',
      onClick: (item: IHoliday) => {
        setHoliday(item);
        setIsEditorModalVisible(true);
      },
    },
  ];

  const onDeleteConfirmationClose = (deleteConfirmed: boolean) => {
    if (deleteConfirmed) {
      if (selectedHolidays && selectedHolidays.length > 0) {
        apolloWrapper.delete.delete(
          ...selectedHolidays.filter((x: IHoliday) => x.id)
            .map((x: IHoliday) => x.id as string),
        );
      }
      setSelectedHolidays([]);
    }
    setIsConfirmationDialogVisible(false);
  };

  const onFabClick = () => {
    setHoliday({
      jurisdictionId: jurisdiction?.id,
      jurisdiction,
    });
    setIsEditorModalVisible(true);
  };

  const onEditorModalClosed = () => {
    setIsEditorModalVisible(false);
  };

  const tableStateChanged = (state: ITableState) => {
    setHolidayRequestObject({
      queryObject: {
        ...state,
        ...state.filter,
        filter: undefined,
        jurisdictionId: jurisdiction?.id,
      },
      fields: graphQLQueryFields,
    });
  };

  const onJurisdictionSelected = useCallback((j?: IJurisdiction) => {
    setJurisdiction(j);
    setHolidayRequestObject((current: IHolidayQueryHookProps) => ({
      ...current,
      queryObject: {
        ...current.queryObject,
        jurisdictionId: j?.id,
      },
    }));
  },
  []);

  const deleteSuccessful = apolloWrapper.delete.result.called
    && !apolloWrapper.delete.result.loading
    && !apolloWrapper.delete.result.error;

  const deleteError = apolloWrapper.delete.result.called
    && !apolloWrapper.delete.result.loading
    && apolloWrapper.delete.result.error;

  const saveSuccessful = apolloWrapper.save.result.called
    && !apolloWrapper.save.result.loading
    && !apolloWrapper.save.result.error;

  const saveError = apolloWrapper.save.result.called
    && !apolloWrapper.save.result.loading
    && apolloWrapper.save.result.error;

  return (
    <div>
      <JurisdictionAutoComplete
        value={jurisdiction}
        onSelect={onJurisdictionSelected}
      />
      <Table
        dataSource={apolloWrapper.result.items}
        isMultiSelect
        tableMenuOptions={options}
        rowMenuOptions={rowOptions}
        minHeight={400}
        maxHeight={700}
        isFabVisible
        onFabClick={onFabClick}
        fabIcon="add"
        fabAriaLabel="Add New Holiday"
        onStateChange={tableStateChanged}
        hasMore={apolloWrapper.result.hasMore}
        takeOptions={[100, 500]}
        isPagerVisible
        loading={apolloWrapper.loading || apolloWrapper.delete.result.loading}
        fabText="Add Holiday"
        title="Holidays"
      >
        {[
          {
            key: 'description',
            header: 'Holiday',
            field: 'description',
            isFilterable: true,
            isSortable: true,
          },
          {
            key: 'jurisdiction.description',
            header: 'Jurisdiction',
            field: 'jurisdiction.description',
            isFilterable: false,
            isSortable: false,
          },
          {
            key: 'date',
            header: 'Date',
            field: 'date',
            isSortable: true,
            formatter: (value) => (value ? dayjs(value).format('YYYY-MM-DD') : ''),
          },
        ]}
      </Table>
      {isEditorModalVisible
        && (
          <EditHolidayModal
            isOpen
            holiday={holiday}
            onClose={onEditorModalClosed}
            save={apolloWrapper.save}
          />
        )}
      <Snackbar open={deleteSuccessful} message="Delete successful." />
      <Snackbar open={!!deleteError} message="There was an error deleting the holiday." />
      <Snackbar open={saveSuccessful} message="Save successful." />
      <Snackbar open={!!saveError} message="Error! Unable to save the holiday." />
      <ConfirmationDialog
        isOpen={isConfirmationDialogVisible}
        text="Do you really want to delete these holidays?"
        onClose={onDeleteConfirmationClose}
      />
    </div>
  );
};

export default HolidayAdminPage;

import React, { FC } from 'react';
import { IYearCellProps } from './YearCell';

interface IYearRowProps {
  row?: number;
  children: React.ReactElement<IYearCellProps>;
}

const YearRow: FC<IYearRowProps> = ({ row, children }: IYearRowProps) => (
  <div className="flex flex-row" key={row}>
    {children}
  </div>
);

export default YearRow;

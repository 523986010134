import {
  useMutation, ApolloError, FetchResult, DocumentNode, gql,
} from '@apollo/client';
import IServiceMethod from '@models/Deadlines/ServiceMethods/IServiceMethod';
import IServiceMethodQuery from '@models/Deadlines/ServiceMethods/IServiceMethodQuery';
import Interval from '@models/Deadlines/Interval';
import { handleSaveResult } from '@libs/apollo';
import IJurisdiction from '@models/Jurisdiction/IJurisdiction';
import { CalendarType } from '@models';

type ServiceMethodSaveHook = (
  props: IServiceMethodSaveHookProps
) => IServiceMethodSaveHookResult;

interface IServiceMethodSaveHookProps {
  queryObject?: IServiceMethodQuery;
  queryString?: DocumentNode;
}

export interface IServiceMethodSaveHookResult {
  result: {
    error?: ApolloError;
    loading: boolean;
    called: boolean;
  };
  saveServiceMethod: (
    description: string,
    jurisdiction: IJurisdiction,
    duration: number,
    interval: Interval,
    calendar: CalendarType,
    id?: string,
  ) => Promise<FetchResult<IServiceMethod>>;
}

interface ISaveServiceMethodResult {
  saveServiceMethod: IServiceMethod;
}

const useServiceMethodSave: ServiceMethodSaveHook = (
  { queryObject, queryString }: IServiceMethodSaveHookProps,
) => {
  const SAVE_GQL = gql`
    mutation ServiceMethodMutation($serviceMethod: ServiceMethodInputType!) {
      saveServiceMethod(item: $serviceMethod) {
        id
      }
    }
  `;

  const [saveFunc, {
    error, loading, called, client,
  }] = useMutation<ISaveServiceMethodResult>(SAVE_GQL);

  return {
    result: {
      error,
      loading,
      called,
    },
    saveServiceMethod: (
      description: string,
      jurisdiction: IJurisdiction,
      duration: number,
      interval: Interval,
      calendar: CalendarType,
      id?: string,
    ) => {
      const serviceMethod = {
        id,
        description,
        jurisdiction,
        jurisdictionId: jurisdiction.id,
        duration,
        interval,
        calendar,
      };

      return saveFunc(
        {
          variables: {
            serviceMethod: {
              ...serviceMethod,
              jurisdictionId: jurisdiction.id,
              jurisdiction: undefined,
            },
          },
        },
      ).then((result: FetchResult<ISaveServiceMethodResult>) => ({
        ...result,
        data: {
          ...handleSaveResult<IServiceMethod>(
            result.data?.saveServiceMethod || '',
            serviceMethod,
            client,
            'serviceMethods',
            queryObject,
            queryString,
          ),
        },
      }));
    },
  };
};

export default useServiceMethodSave;

import React, {
  FC,
  useEffect,
  useState,
  useCallback,
} from 'react';
import { useParams, useHistory } from 'react-router-dom';
import MaterialIcon from '@fv-components/material-icon';
import Button from '@fv-components/button';
import Grid, { Cell, Row } from '@fv-components/layout-grid';
import { Caption } from '@fv-components/typography';
import { AltButton, JurisdictionAutoComplete } from '@components';
import { IJurisdiction, ITrigger } from '@models';
import TriggerListItem from './TriggerListItem/TriggerListItem';
import { ITriggerCalculationState } from '../Calculator';
import CalculatorHeader from '../CalculatorHeader/CalculatorHeader';

const calculatorCss = require('../Calculator.module.scss');
const css = require('./Intake.module.css');

interface IIntakeProps {
  jurisdiction?: IJurisdiction;
  triggerCalculationStates?: ITriggerCalculationState[];
  loadDeadlines: VoidFunction;
  onJurisdictionChange: (jurisdiction?: IJurisdiction) => void;
  onDeleteTriggerSelection: (index: number) => void;
  onTriggerChange: (index: number, trigger?: ITrigger) => void;
  onDateChange: (index: number, date?: Date) => void;
  onServiceMethodChange: (index: number, id?: string) => void;
  onAddClicked: VoidFunction;
  onReset: VoidFunction;
  onTriggerLabelChange: (index: number, triggerLabel?: string) => void;
}

const getIsSelectionValid = (
  tcs: ITriggerCalculationState,
  isServiceRequired: boolean,
): boolean => !!tcs.trigger?.id
&& !!tcs.triggerDate
  && ((isServiceRequired && !!tcs.serviceMethodId)
    || !isServiceRequired);

const Intake: FC<IIntakeProps> = (
  {
    jurisdiction,
    triggerCalculationStates,
    onJurisdictionChange,
    onDeleteTriggerSelection,
    onTriggerChange,
    onServiceMethodChange,
    onDateChange,
    onAddClicked,
    loadDeadlines,
    onReset,
    onTriggerLabelChange,
  }: IIntakeProps,
) => {
  const [triggerSelectionsAreValid, setTriggerSelectionsAreValid] = useState<boolean>(false);
  const { historyId } = useParams<{ historyId?: string }>();
  const history = useHistory();

  const [isServiceRequired, setIsServiceRequired] = useState<boolean>(false);

  useEffect(() => {
    setTriggerSelectionsAreValid(
      !triggerCalculationStates
        ?.filter(
          (tcs: ITriggerCalculationState) => !tcs.toBeRemoved
            && !getIsSelectionValid(tcs, isServiceRequired)).length,
    );
  }, [isServiceRequired, triggerCalculationStates]);

  const disableDeadlinesButton = !jurisdiction?.id
    || !triggerCalculationStates?.length
    || !triggerSelectionsAreValid;

  const onCalculateClick = useCallback(() => {
    loadDeadlines();
    if (historyId) {
      history.push(`/calculator/deadlines/${historyId}`);
    } else {
      history.push('/calculator/deadlines');
    }
  }, [history, historyId, loadDeadlines]);

  // if we see the new keyword we need to remove the new from the url param so that we wont
  // accidentally renew in the future and send a message to the calculator that it needs to
  // reset
  useEffect(() => {
    if (historyId === 'new') {
      onReset();
      history.push('/calculator');
    }
  }, [history, historyId, onReset]);

  return (
    <>
      <CalculatorHeader
        title="Matter Details"
      />
      <div className={`${calculatorCss.content} mt-14`}>
        <Grid>
          <Row>
            <Cell columns={12}>
              <h4 className={css.header}>Jurisdiction</h4>
            </Cell>
          </Row>
          <Row className={calculatorCss.row}>
            <Cell columns={12}>
              {/* This seems weird but I need to destroy the jurisdiction component when the new
              button is hit because the auto complete intentionally holds on to it's text even
              if you clear the value */}
              {historyId !== 'new' && (
                <JurisdictionAutoComplete
                  onSelect={onJurisdictionChange}
                  value={jurisdiction}
                />
              )}
            </Cell>
          </Row>
          <Row>
            <Cell columns={12} className="flex flex-row justify-between items-baseline">
              <h4 className={css.header}>
                Triggers
                <br />
                <Caption>Select the triggering event(s) to calculate your deadlines.</Caption>
              </h4>
            </Cell>
          </Row>
          {triggerCalculationStates
            ?.map((tcs: ITriggerCalculationState, index: number) => (
              // this key has to specifically be in this order or it won't work properly
              <Row key={tcs.historyTriggerId || tcs.id} className={`mb-3${tcs.toBeRemoved ? ' hidden' : ''}`}>
                <Cell columns={12}>
                  <TriggerListItem
                    jurisdiction={jurisdiction}
                    onTriggerSelect={(t?: ITrigger) => onTriggerChange(index, t)}
                    onServiceMethodSelect={(id?: string) => onServiceMethodChange(index, id)}
                    onDateSelect={(d?: Date) => onDateChange(index, d)}
                    trigger={tcs.trigger}
                    serviceMethodId={tcs.serviceMethodId}
                    date={tcs.triggerDate}
                    onDelete={() => onDeleteTriggerSelection(index)}
                    isSavedToFilevine={tcs.isSavedToFilevine}
                    triggerLabel={tcs.triggerLabel}
                    onTriggerLabelChange={
                      (custom?: string) => onTriggerLabelChange(index, custom)
                    }
                    setIsServiceRequired={setIsServiceRequired}
                    blockedTriggerIds={triggerCalculationStates.filter(
                      (t: ITriggerCalculationState) => t.id !== tcs.id && t.trigger?.id,
                    ).map(
                      (t: ITriggerCalculationState) => t.trigger?.id || '',
                    )}
                  />
                </Cell>
              </Row>
            ))}
          <Row className="flex flex-row justify-end">
            <Cell>
              <AltButton
                onClick={onAddClicked}
                icon={<MaterialIcon icon="add" />}
                aria-label="Add New Trigger"
                data-cy="add-trigger"
              >
                Trigger
              </AltButton>
            </Cell>
            <Cell>
              <Button
                onClick={onCalculateClick}
                icon={<MaterialIcon icon="navigate_next" />}
                disabled={disableDeadlinesButton}
                role="button"
                aria-label="Navigate To Deadlines Screen"
                data-cy="deadlines"
                raised
              >
                Deadlines
              </Button>
            </Cell>
          </Row>
        </Grid>
      </div>
    </>
  );
};

export default Intake;

import React, { FC } from 'react';
import Card from '@fv-components/card';

const css = require('./LoggedInContentContainer.module.scss');

interface LoggedInContentContainerProps {
  children?: JSX.Element | JSX.Element[];
}

const LoggedInContentContainer: FC<LoggedInContentContainerProps> = ({ children }) => (
  <div className={css.container}>
    <Card className={css.card}>
      <div className={css.column}>
        {children}
      </div>
    </Card>
  </div>
);

export default LoggedInContentContainer;

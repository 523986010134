import React, { FC } from 'react';
import { useHistory } from 'react-router-dom';
import { IJurisdiction, ITrigger } from '@models';
import { TriggerAutoComplete, AltButton } from '@components';
import MaterialIcon from '@fv-components/material-icon';

interface ITriggerSearchProps {
  jurisdiction?: IJurisdiction;
  onSearchUpdate: VoidFunction
}

const SearchBar: FC<ITriggerSearchProps> = ({
  jurisdiction,
  onSearchUpdate,
}) => {
  const history = useHistory();

  const onTriggerSelect = (trigger?: ITrigger) => {
    if (trigger?.id) {
      history
        .push(`/admin/deadlines/triggers/${trigger.id}`, { trigger, jurisdiction: trigger?.jurisdiction, activeTab: 0 });
      onSearchUpdate();
    }
  };

  return (
    <div className="flex md:flex-row flex-col gap-3">
      <div className="flex flex-col flex-1 gap-1">
        <b>Select Trigger</b>
        {jurisdiction?.id
        && (
        <TriggerAutoComplete
          onSelect={onTriggerSelect}
          jurisdictionIds={[jurisdiction?.id]}
        />
          )}
        <AltButton
          onClick={() => history.push('/admin/deadlines/triggers/new', { jurisdiction })}
          aria-label="Show Trigger Search Bar"
          data-cy="search-trigger"
          icon={<MaterialIcon icon="add" />}
        >
          Add Trigger
        </AltButton>
      </div>
    </div>
  );
};

export default SearchBar;

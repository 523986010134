import React, { FC, useState } from 'react';
import {
  BrowserRouter,
  Redirect,
  Route, RouteComponentProps, Switch,
} from 'react-router-dom';
import Card from '@fv-components/card';
import ILogin from '@models/Identity/ILogin';
import Login from './Login/Login';
import Register from './Register/Register';
import ForgotPassword from './ForgotPassword/ForgotPassword';
import ResetPassword from './ResetPassword/ResetPassword';
import TimelyLogo from '../TimelyLogo';
import Health from './Health/Health';
import TermsOfService from '../TermsOfService/TermsOfService';
import Footer from '../Footer/Footer';

const css = require('./NotLoggedIn.module.scss');

interface NotLoggedInProps {
  onLoginSuccess: (login: ILogin) => void;
}

const NotLoggedIn: FC<NotLoggedInProps> = ({ onLoginSuccess }) => {
  const [redirect, setRedirect] = useState<string>();

  return (
    <BrowserRouter>
      <div className={css.container}>
        <Card className={css.card}>
          <div className={css.column} data-cy="login-form-container">
            <div className={css.header}>
              <TimelyLogo isBlack width={200} />
            </div>
            <Switch>
              <Route
                exact
                path="/accept-invitation/:inviteId"
                render={(props: RouteComponentProps) => (
                  <Register inviteId={(props.match.params as any).inviteId} />
                )}
              />
              <Route exact path="/forgot">
                <ForgotPassword />
              </Route>
              <Route
                exact
                path="/reset-password/:token"
                render={(props: RouteComponentProps) => (
                  <ResetPassword token={(props.match.params as any).token} />
                )}
              />
              <Route
                exact
                path="/health"
                render={() => (
                  <Health />
                )}
              />
              <Route
                exact
                path="/terms"
                render={() => (
                  <TermsOfService />
                )}
              />
              <Route
                path="/sign-in"
                exact
                render={() => (
                  <Login
                    onLoginSuccess={onLoginSuccess}
                    redirect={redirect}
                  />
                )}
              />
              {/* If they have some crazy path I'm going to redirect back to /sign-in */}
              <Route
                render={(props: RouteComponentProps) => {
                  setRedirect(`${props.location.pathname}${props.location.search}`);
                  return (
                    <Redirect
                      to="/sign-in"
                    />
                  );
                }}
              />
            </Switch>
          </div>
        </Card>
        <Footer />
      </div>
    </BrowserRouter>
  );
};

export default NotLoggedIn;

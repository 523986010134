import React, { FC } from 'react';
import Table, { ITableState } from '@components/Table/Table';
import { IRowMenuOption } from '@components/Table/Menu/RowMenu';
import { ITableMenuOption } from '@components/Table/Menu/TableMenu';
import { IPermission } from '@models';

interface IActionTableProps {
  permissions?: IPermission[];
  onTableStateChanged: (state: ITableState) => void;
  hasMore?: boolean;
  onEdit: (deadline: IPermission) => void;
  onFabClick: VoidFunction;
  onDelete: (toBeDeleted: IPermission[]) => void;
  loading: boolean;
}

const RoleTable: FC<IActionTableProps> = (
  {
    permissions: actions,
    onTableStateChanged,
    hasMore,
    onEdit,
    onFabClick,
    onDelete,
    loading,
  },
) => {
  const rowOptions: IRowMenuOption[] = [
    {
      key: 'edit',
      text: 'Edit',
      onClick: onEdit,
    },
  ];

  const tableMenuOptions: ITableMenuOption[] = [
    {
      key: 'deleteSelected',
      text: 'Delete Selected',
      onClick: (selectedItems?: IPermission[]) => {
        if (selectedItems) {
          onDelete(selectedItems);
        }
      },
    },
  ];

  return (
    <Table
      dataSource={actions}
      rowMenuOptions={rowOptions}
      minHeight={400}
      maxHeight={700}
      onStateChange={onTableStateChanged}
      hasMore={hasMore}
      takeOptions={[100, 500]}
      isPagerVisible
      isFabVisible
      isMultiSelect
      onFabClick={onFabClick}
      fabIcon="add"
      fabAriaLabel="Add New Permission"
      tableMenuOptions={tableMenuOptions}
      loading={loading}
      fabText="Add Permission"
      title="Permissions"
    >
      {[
        {
          key: 'permissionObject',
          header: 'Object',
          field: 'permissionObject',
          isFilterable: true,
          isSortable: true,
        },
        {
          key: 'permssionAction',
          header: 'Action',
          field: 'permissionAction',
          isFilterable: true,
          isSortable: true,
        },
      ]}
    </Table>
  );
};

export default RoleTable;

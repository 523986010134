import React, { FC, useCallback } from 'react';
import Snackbar from '@fv-components/snackbar';
import useJurisdictionQuery from '@hooks/Jurisdiction/useJurisdictionQuery';
import IServiceMethod from '@models/Deadlines/ServiceMethods/IServiceMethod';
import MultiSelectDropDown from '../MultiSelectDropDown/MultiSelectDropDown';

interface IJurisdictionServiceMethodDropDownProps {
  onSelect: (serviceMethods: IServiceMethod[]) => void;
  jurisdictionId?: string;
  disabled?: boolean;
  autoFocus?: boolean;
  values?: IServiceMethod[];
  filterServiceMethodIds?: string[];
  lazy?: boolean;
}

const JurisdictionServiceMethodDropDown: FC<IJurisdictionServiceMethodDropDownProps> = (
  {
    onSelect,
    jurisdictionId,
    autoFocus,
    values,
    filterServiceMethodIds,
    lazy,
    disabled,
  }: IJurisdictionServiceMethodDropDownProps,
) => {
  const {
    result,
    error,
    load,
    called,
  } = useJurisdictionQuery({
    queryObject: {
      ids: jurisdictionId ? [jurisdictionId] : undefined,
      skip: 0,
      take: 1,
    },
    fields: ['serviceMethods { id description }'],
    lazy,
  });

  let items: IServiceMethod[] = [];
  if (result && result.items && result.items.length > 0
    && result.items[0].serviceMethods
    && result.items[0].serviceMethods.length > 0
  ) {
    if (filterServiceMethodIds) {
      const filtered = result.items[0].serviceMethods.filter(
        (sm: IServiceMethod) => sm.id
          && filterServiceMethodIds.includes(sm.id),
      );
      items = items.concat(filtered);
    } else {
      items = items.concat(result.items[0].serviceMethods);
    }
  }

  const onFocus = useCallback(() => {
    if (!called) {
      load();
    }
  }, [called, load]);

  return (
    <>
      <MultiSelectDropDown
        display="description"
        label="Service Method"
        onChange={onSelect}
        items={items}
        autoFocus={autoFocus}
        values={values || []}
        onFocus={onFocus}
        disabled={disabled || !items.length}
      />
      {!!error && (
      <Snackbar
        message="There was an error fetching the service methods for this jurisdiction"
      />
      )}
    </>
  );
};

export default JurisdictionServiceMethodDropDown;

import React, {
  FC, useState, useEffect,
} from 'react';
import { ITrigger } from '@models';
import useTriggerQuery from '@hooks/Trigger/useTriggerQuery';
import { AutoComplete } from '@components';
import { timelyClient } from '@src/App/App';
import { gql } from '@apollo/client';
import { IQueryResponse } from '@hooks';

interface ITriggerAutoCompleteProps {
  onSelect: (trigger?: ITrigger) => void;
  jurisdictionIds?: string[];
  value?: ITrigger;
  blockIdsFromSelection?: string[];
  isDisabled?: boolean;
}

const TRIGGER_QUERY_GQL = gql`
query($queryObject: TriggerQueryInputType!) {
    triggers(
        queryObject: $queryObject
      ) {
          hasMore
          items {
            id
            description
            isFilingRequired
            parentTriggers { id description jurisdictionId jurisdiction { id description address } }
            jurisdictionId
            jurisdiction { address description id parent { description id } }
            serviceMethods { id description jurisdictionId }
            serviceMethodGroups { id description jurisdictionId serviceMethods { id description jurisdictionId } }
            categories { id description }
            childTriggers { id description jurisdictionId jurisdiction { id description address } }
            triggerDeadlines { id deadlineId triggerId deadline { description specReference } trigger { description jurisdiction { description } } }
            deadlineInheritanceBlocks
          }
      }
    }`;

const TriggerAutoComplete: FC<ITriggerAutoCompleteProps> = (
  {
    jurisdictionIds,
    onSelect,
    value,
    blockIdsFromSelection,
    isDisabled,
  }: ITriggerAutoCompleteProps,
) => {
  const [items, setItems] = useState<ITrigger[]>();
  const [filter, setFilter] = useState<string>();

  const {
    result, loading, load, called,
  } = useTriggerQuery({
    queryObject: {
      description: filter,
      jurisdictionIds,
      skip: 0,
      take: jurisdictionIds ? 500 : 0,
    },
    fields: [
      'id',
      'description',
      'jurisdiction { address }',
    ],
    lazy: true,
  });

  useEffect(() => {
    if (!loading) {
      const withBlockedRemoved = result.items.filter(
        (t: ITrigger) => !blockIdsFromSelection || (t.id && !blockIdsFromSelection.includes(t?.id)),
      );

      const map: { [key: string]: { trigger: ITrigger, index: number } } = {};
      const withoutDuplicates: ITrigger[] = [];

      withBlockedRemoved.forEach((trigger: ITrigger) => {
        if (trigger.description) {
          const fromList = map[trigger.description];
          if (!fromList) {
            map[trigger.description] = { trigger, index: withoutDuplicates.length };
            withoutDuplicates.push(trigger);
          } else if ((fromList?.trigger?.jurisdiction?.address?.length || 0)
            < (trigger?.jurisdiction?.address?.length || 0)) {
            map[trigger.description] = { trigger, index: fromList.index };
            withoutDuplicates.splice(fromList.index, 1, trigger);
          }
        }
      });
      setItems(withBlockedRemoved);
    }
  }, [loading, result.items, blockIdsFromSelection]);

  const onAutoCompleteSelect = (trigger?: ITrigger) => {
    if (trigger?.id) {
      timelyClient.query<IQueryResponse<ITrigger>>({
        query: TRIGGER_QUERY_GQL,
        variables: { queryObject: { id: trigger.id, skip: 0, take: 1 } },
      }).then((response) => onSelect(response.data.triggers.items[0]));
    } else {
      onSelect(undefined);
    }
  };

  return (
    <AutoComplete<ITrigger>
      label="Select Trigger"
      value={value}
      isDisabled={isDisabled || !jurisdictionIds?.length}
      descriptionField="description"
      cypressLabel="trigger"
      called={called}
      load={load}
      hasMore={result.hasMore}
      items={items}
      onSelect={onAutoCompleteSelect}
      loading={loading}
      onFilterChange={(f?: string) => setFilter(f)}
    />
  );
};

export default TriggerAutoComplete;

import React, { FC } from 'react';
import {
  Route, Switch,
} from 'react-router-dom';
import JurisdictionForm from './JurisdictionForm/JurisdictionForm';
import JurisdictionTable from './JurisdictionTable';

const ServiceMethodAdminPage: FC = () => (
  <div className="container mx-auto">
    <Switch>
      <Route
        exact
        path="/admin/jurisdiction/new"
      >
        <JurisdictionForm />
      </Route>
      <Route
        exact
        path="/admin/jurisdiction/:id/edit"
      >
        <JurisdictionForm />
      </Route>
      <Route
        exact
        path="/admin/jurisdiction/"
      >
        <JurisdictionTable />
      </Route>
    </Switch>
  </div>
);

export default ServiceMethodAdminPage;

import React, {
  FC, useState, useEffect, useCallback,
} from 'react';
import LinearProgress from '@fv-components/linear-progress';
import IColumnProps from './Column';
import Header, { IHeaderState } from './Header/Header';
import Footer from './Footer';
import TitleBar from './TitleBar';
import Row from './Row/Row';
import { IPagerState } from '../Pager/Pager';
import { IRowMenuOption } from './Menu/RowMenu';
import { ITableMenuOption } from './Menu/TableMenu';
import { SortDirection } from './Header/HeaderCell/HeaderCell';

interface ITableProps {
  isFabVisible?: boolean;
  fabIcon?: string;
  fabText?: string;
  fabAriaLabel?: string;
  onFabClick?: VoidFunction;
  isPagerVisible?: boolean;
  hasMore?: boolean;
  takeOptions?: number[];
  tableMenuOptions?: ITableMenuOption[];
  rowMenuOptions?: IRowMenuOption[];
  dataSource?: any[];
  children: IColumnProps[];
  isSingleSelect?: boolean;
  isMultiSelect?: boolean;
  onStateChange?: (state: ITableState) => void;
  minHeight?: number;
  maxHeight?: number;
  onRowSelect?: (selectedItem: any, isSelected: boolean) => void;
  onSelectAll?: (isSelected: boolean) => void;
  onRowClick?: (item: any) => void;
  selected?: any[];
  loading?: boolean;
  cypressId?: string;
  title?: JSX.Element | string;
  tableState?: ITableState
}

export interface ITableState {
  skip?: number;
  take?: number;
  filter?: any;
  sortBy?: string;
  sortDirection?: SortDirection;
}

const Table: FC<ITableProps> = ({
  isFabVisible,
  fabIcon,
  fabText,
  fabAriaLabel,
  onFabClick,
  isPagerVisible,
  hasMore,
  takeOptions,
  tableMenuOptions,
  rowMenuOptions,
  dataSource,
  children,
  isSingleSelect,
  isMultiSelect,
  onStateChange,
  minHeight,
  maxHeight,
  onRowSelect,
  onSelectAll,
  onRowClick,
  selected,
  loading,
  cypressId,
  title,
  tableState: ts,
}: ITableProps) => {
  const [selectedItems, setSelectedItems] = useState<any[]>(selected || []);
  const [isSelectAllChecked, setIsSelectAllChecked] = useState<boolean>(false);
  const [tableState, setTableState] = useState<ITableState>(ts || {
    skip: 0,
    take: takeOptions && takeOptions[0] ? takeOptions[0] : 0,
  });

  const updateSelectedItems = useCallback(
    () => {
      // if someone provides the selected property I'm going to assume
    // that they will be managing the selected state outside of the component
    // this is the way ir probably should have been from the beginning but it
    // would break things to take it away now
      if (selected) {
        const items = selected.filter((x) => dataSource?.find((z) => z.id === x.id));
        setSelectedItems(items);
      } else {
        setSelectedItems((s) => s.filter((x) => dataSource?.find((z) => z.id === x.id)));
      }
    },
    [dataSource, selected],
  );

  useEffect(() => {
    updateSelectedItems();
  }, [dataSource, selected, updateSelectedItems]);

  const onSelect = (item: any, checked: boolean) => {
    // if someone provided a selected list I'm going to assume they are
    // managing the selected list outside of the component
    if (!selected) {
      if (!checked) {
        setSelectedItems(
          selectedItems.filter((x) => JSON.stringify(x) !== JSON.stringify(item)),
        );
      } else if (isSingleSelect) {
        setSelectedItems([item]);
      } else if (isMultiSelect) {
        setSelectedItems(selectedItems.concat(item));
      }
    }
    if (onRowSelect) {
      onRowSelect(item, checked);
    }
  };

  const onSelectAllClick = (checked: boolean) => {
    // if someone provided a selected list I'm going to assume they are
    // managing the selected list outside of the component
    if (!selected) {
      setSelectedItems(checked && dataSource ? dataSource : []);
    } else if (onSelectAll) {
      onSelectAll(checked);
    }

    setIsSelectAllChecked(checked);
  };

  const onHeaderStateChange = (headerState: IHeaderState) => {
    const newTableState = {
      skip: tableState.skip,
      take: tableState.take,
      sortBy: headerState.sortBy,
      sortDirection: headerState.sortDirection,
      filter: headerState.filter,
    };
    setTableState(newTableState);
    if (onStateChange) {
      onStateChange(newTableState);
    }
    setIsSelectAllChecked(false);
    updateSelectedItems();
  };

  const onPagerStateChange = (pagerState: IPagerState) => {
    const newTableState : ITableState = {
      skip: pagerState.skip,
      take: pagerState.take,
      sortBy: tableState.sortBy,
      sortDirection: tableState.sortDirection,
      filter: tableState.filter,
    };
    setTableState(newTableState);
    if (onStateChange) {
      onStateChange(newTableState);
    }
    setIsSelectAllChecked(false);
    updateSelectedItems();
  };

  const style: any = { };
  if (minHeight) {
    style.minHeight = `${minHeight}px`;
  }
  if (maxHeight) {
    style.maxHeight = `${maxHeight}px`;
  }

  return (
    <div
      className="m-0 p-0 flex flex-col gap-3"
      style={style}
    >
      <TitleBar
        isFabVisible={isFabVisible}
        onFabClick={onFabClick}
        fabIcon={fabIcon}
        fabText={fabText}
        fabAriaLabel={fabAriaLabel}
        cypressId={cypressId}
        disabled={loading}
        title={title}
      />
      <div
        className="overflow-y-auto overflow-x-hidden bg-white"
        data-cy={`table-body-container-${cypressId || ''}`}
      >
        <Header
          key="header"
          columnProps={children}
          tableMenuOptions={tableMenuOptions}
          isCheckboxVisible={isMultiSelect}
          onSelect={onSelectAllClick}
          selectedItems={selectedItems}
          onHeaderStateChanged={onHeaderStateChange}
          disabled={loading}
          isSelectAllChecked={isSelectAllChecked}
          headerState={{
            filter: tableState?.filter,
            sortBy: tableState?.sortBy,
            sortDirection: tableState?.sortDirection,
          }}
        />
        {dataSource && dataSource.map((item, index: number) => (
          <Row
            key={item.id}
            columnProps={children}
            item={item}
            isCheckboxVisible={
                  isSingleSelect || isMultiSelect
                }
            isSelected={!!selectedItems.find((x) => x.id === item.id)}
            onSelect={onSelect}
            options={rowMenuOptions}
            disabled={loading}
            cypressId={cypressId}
            onRowClick={onRowClick}
            tabIndex={onRowClick && index}
          />
        ))}
      </div>
      {loading && <LinearProgress className="-mt-3 mb-2" indeterminate />}
      <Footer

        skip={tableState.skip}
        take={tableState.take}
        hasMore={hasMore}
        isPagerVisible={isPagerVisible}
        takeOptions={takeOptions}
        onPagerStateChange={onPagerStateChange}
        disabled={loading}
        numberItemsCurrentlyDisplayed={dataSource?.length || 0}

        sortBy={children.find(
          (columnProp: IColumnProps) => tableState.sortBy === (columnProp.sortFilterField
            || columnProp.field),
        )?.header}
        sortDirection={tableState.sortDirection}
      />
    </div>
  );
};

export default Table;

import React, { FC, useEffect, useState } from 'react';
import Button from '@fv-components/button';
import MaterialIcon from '@fv-components/material-icon';
import TextField, { Input } from '@fv-components/text-field';
import { IServiceMethodGroup, IServiceMethod, IServiceMethodQuery } from '@models';
import { AltButton, Table } from '@components';
import { getGuid } from '@libs/utils';
import { timelyClient } from '@src/App/App';
import { ITableState } from '@components/Table/Table';
import useServiceMethodQuery from '@hooks/ServiceMethod/useServiceMethodQuery';

interface IServiceMethodGroupFormProps {
  serviceMethodGroup: IServiceMethodGroup;
  onDone: (serviceMethodGroup?: IServiceMethodGroup) => void;
  jurisdictionAddress?: string;
  serviceMethods?: IServiceMethod[];
  jurisdictionId: string;
}

const fields = [
  'id',
  'description',
  'duration',
  'interval',
  'calendar',
  'jurisdictionId',
  'jurisdiction { description }',
];

const ServiceMethodGroupForm: FC<IServiceMethodGroupFormProps> = (
  {
    serviceMethodGroup: smg,
    onDone,
    jurisdictionAddress,
    serviceMethods,
    jurisdictionId,
  }: IServiceMethodGroupFormProps,
) => {
  const [serviceMethodGroup, setServiceMethodGroup] = useState<IServiceMethodGroup>(smg);
  const [isDirty, setIsDirty] = useState(false);
  const [queryObject, setQueryObject] = useState<IServiceMethodQuery>({
    skip: 0,
    take: 100,
    jurisdictionAddress,
    includeInherited: false,
  });

  // this query will include sub jurisdiction service methods
  const {
    result, called, load, loading,
  } = useServiceMethodQuery({
    fields,
    queryObject,
    fetchPolicy: 'network-only',
    lazy: true,
  });

  const onTableStateChange = (tableState: ITableState) => {
    setQueryObject((current: IServiceMethodQuery) => ({
      ...current,
      ...tableState,
      ...tableState.filter,
      skip: 0,
      take: 100,
      filter: undefined,
      description: tableState.filter?.description,
    }));
  };

  useEffect(() => {
    if (!called && jurisdictionAddress) {
      load();
    }
  }, [jurisdictionAddress, called, load]);

  useEffect(() => {
    if (!smg.id) {
      getGuid(timelyClient).then(
        (id: string) => setServiceMethodGroup((current: IServiceMethod) => ({
          ...current,
          id,
        })),
      );
    }
  }, [smg]);

  const onDescriptionChange = (description: string) => {
    setIsDirty(true);
    setServiceMethodGroup({
      ...serviceMethodGroup,
      description,
    });
  };

  const onRowSelect = (serviceMethod: IServiceMethod, isSelected: boolean) => {
    setIsDirty(true);
    setServiceMethodGroup({
      ...serviceMethodGroup,
      serviceMethods: isSelected ? [...(serviceMethodGroup.serviceMethods || []), serviceMethod]
        : (serviceMethodGroup?.serviceMethods?.filter(
          (sm: IServiceMethod) => sm.id !== serviceMethod.id) || []),
    });
  };

  const filteredAndSortedServiceMethods = [
    // here we are excluding service methods from the current jurisdiction
    ...(result.items.filter(
      (sm: IServiceMethod) => sm.jurisdictionId !== jurisdictionId,
    )),
    // adding back in service methods from the current jurisdiction
    // that have potentially been modified
    ...(
      (!queryObject.description
        ? serviceMethods
        : serviceMethods?.filter(
          (psm: IServiceMethod) => psm.description?.toLowerCase().includes(
            (queryObject.description as string).toLowerCase()))) || []),
  ].sort(
    (a: IServiceMethod, b: IServiceMethod) => (
      queryObject.sortDirection === 'DESC'
        ? (b.description || '').localeCompare(a.description || '')
        : (a.description || '').localeCompare(b.description || '')),
  );

  return (
    <div className="flex flex-col gap-3">
      <b>Edit Service Method Group</b>
      <div className="grid grid-cols-1 xl:grid-cols-2 gap-3">
        <div className="flex flex-col gap-3 flex-1">
          <TextField label="Description" outlined>
            <Input
              value={serviceMethodGroup?.description}
              onChange={(event: React.FormEvent<HTMLInputElement>) => {
                onDescriptionChange(event.currentTarget.value);
              }}
            />
          </TextField>
        </div>
        <div className="flex-1">
          <Table
            dataSource={filteredAndSortedServiceMethods}
            isMultiSelect
            minHeight={350}
            maxHeight={350}
            onRowSelect={onRowSelect}
            selected={serviceMethodGroup.serviceMethods}
            onStateChange={onTableStateChange}
            loading={loading}
          >
            {[
              {
                key: 'description',
                header: 'Description',
                field: 'description',
                isFilterable: true,
                isSortable: true,
              },
              {
                key: 'jurisdiction.description',
                header: 'Jurisdiction',
                field: 'jurisdiction.description',
              },
            ]}
          </Table>
        </div>
      </div>
      <div className="flex flex-row justify-end gap-3">
        <Button
          onClick={() => onDone(serviceMethodGroup)}
          disabled={!isDirty || !serviceMethodGroup?.description}
          role="button"
          aria-label="Done Service Method Group Edit"
          icon={<MaterialIcon icon="edit" />}
          raised
        >
          Done
        </Button>
        <AltButton
          onClick={() => onDone()}
          aria-label="Cancel Service Method Group Edit"
          icon={<MaterialIcon icon="cancel" />}
        >
          Cancel
        </AltButton>
      </div>
    </div>
  );
};

export default ServiceMethodGroupForm;

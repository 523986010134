import React, { FC, useState } from 'react';
import TextField, { Input } from '@fv-components/text-field';
import Button from '@fv-components/button';
import { useHistory } from 'react-router-dom';
import MaterialIcon from '@fv-components/material-icon';
import useForgotPassword from '@hooks/Identity/useForgotPassword';
import InputLinearProgress from '@components/InputLinearProgress/InputLinearProgress';

const css = require('./ForgotPassword.module.scss');
const appCss = require('../../App.module.scss');
const notLoggedInCss = require('../NotLoggedIn.module.scss');

interface IForgotPasswordState {
  email?: string;
}

const ForgotPassword: FC = () => {
  const [state, setState] = useState<IForgotPasswordState>({});
  const history = useHistory();
  const {
    forgotPassword, result,
  } = useForgotPassword();

  const onEmailChange = (event: React.FormEvent<HTMLInputElement>) => {
    setState({
      ...state,
      email: event.currentTarget.value,
    });
  };

  const onSubmitClicked = () => {
    if (state.email) {
      forgotPassword(state.email);
    }
  };

  const onBackClicked = () => {
    history.push('/sign-in');
  };

  const onKeyUp = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.keyCode === 13) {
      onSubmitClicked();
    }
  };

  const resetError = result.called && !result.loading && result.error;

  return (
    <>

      <h2>Forgot your Password?</h2>
      {
        (!result.called || result.loading || result.error) ? (
          <>
            <p>{'Enter your email address and we\'ll send you a link to reset your password.'}</p>
            <TextField
              label="Email"
              outlined
              className={css.textField}
            >
              <Input
                onChange={onEmailChange}
                value={state.email}
                disabled={result.loading}
                autoFocus
                data-cy="email"
                onKeyUp={onKeyUp}
              />
            </TextField>
            <div className={notLoggedInCss.buttonRow}>
              <Button
                onClick={onBackClicked}
                icon={<MaterialIcon icon="arrow_back" />}
                role="button"
                aria-label="Back To Sign In Screen"
                outlined
                className={`${appCss.secondaryButton} ${notLoggedInCss.backButton}`}
                data-cy="back"
              />
              <Button
                onClick={onSubmitClicked}
                disabled={!state.email}
                role="button"
                aria-label="Submit Forgot Password Request"
                raised
                className={notLoggedInCss.submitButton}
                data-cy="submit"
              >
                Submit
              </Button>
            </div>
            {result.loading && <InputLinearProgress />}

            {resetError && (
              <p className={css.error}>
                There was an error resetting your password.
              </p>
            )}
          </>
        ) : (
          <>
            <p data-cy="confirm-message">
              You should receive an email shortly.
            </p>
            <Button
              onClick={onBackClicked}
              disabled={!state.email}
              role="button"
              aria-label="Go To Timely Sign In Page"
              raised
              data-cy="go-to-signin"
            >
              Sign In
            </Button>
          </>
        )
      }
    </>
  );
};

export default ForgotPassword;

import React, {
  FC, useState, useRef, useEffect,
} from 'react';
import MaterialIcon from '@fv-components/material-icon';
import TextField, { Input } from '@fv-components/text-field';
import { ICategory } from '@models';
import useCategoryQuery from '@hooks/Categories/useCategoryQuery';
import { InputLinearProgress, LookupMenu } from '@components';
import useLookupInput, { ISearchState } from '@hooks/useLookupInput';
import { FetchResult } from '@apollo/client';
import { getSnackBarService } from '@hooks/useSnackBar';

interface IKeywordSearchProps {
  onCategoryPicked: (category: ICategory) => void;
}

const KeywordSearch: FC<IKeywordSearchProps> = (
  {
    onCategoryPicked,
  }: IKeywordSearchProps,
) => {
  const [inputValue, setInputValue] = useState<string>();
  const filterRef = useRef<any | null>(null);
  const [searchState, setSearchState] = useState<ISearchState>();
  const [lookupItems, setLookupItems] = useState<ICategory[]>();
  const snackBar = getSnackBarService();
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const {
    items,
    loading,
    called,
    saveCategoryHook,
    load,
  } = useCategoryQuery({
    queryObject: {
      skip: 0,
      take: 25,
      description: searchState?.searchTerm,
    },
    fields: ['id', 'description'],
    lazy: true,
  });

  const onSearchUpdate = (categorySearch?: ISearchState) => {
    if (!called) {
      load();
    }
    setSearchState(categorySearch);
  };

  const {
    inputOnChangeHandler,
    clear,
  } = useLookupInput({
    debounce: 500,
    inputRefCurrent: filterRef?.current,
    callback: onSearchUpdate,
    setInputValue,
  });

  const handleCategoryPicked = (category: ICategory) => {
    if (category.id) {
      onCategoryPicked(category);
    } else if (!category.id && inputValue) {
      saveCategoryHook.saveCategory(inputValue).then(
        (result: FetchResult<ICategory>) => {
          if (result.data) {
            snackBar.showSnackBar(`Keyword ${result.data.description} has been created`);
            onCategoryPicked(result.data);
          }
        },
      ).catch(() => snackBar.showSnackBar('There was an error saving this keyword.'));
    }
    setInputValue('');
    setIsMenuOpen(false);
  };
  useEffect(() => {
    if (inputValue && !loading && called) {
      setIsMenuOpen(true);
      const exactMatch = !!items.find(
        (
          existingCategory: ICategory,
        ) => existingCategory.description?.toLowerCase() === inputValue.toLowerCase(),
      );
      if (!exactMatch) {
        setLookupItems([
          { description: `${inputValue} ⬅️ Add New Keyword`, id: '' },
          ...items,
        ]);
      } else {
        setLookupItems(items);
      }
    } else if (!inputValue) {
      setLookupItems(undefined);
    }
  }, [called, inputValue, items, loading]);

  const onMenuClosed = () => {
    setIsMenuOpen(false);
  };

  return (
    <div className="flex flex-col">
      <TextField
        label="Search Keywords"
        className="w-full rounded-t"
        onTrailingIconSelect={clear}
        leadingIcon={<MaterialIcon className="overflow-hidden outline-none" icon="search" />}
        trailingIcon={inputValue ? <MaterialIcon className="overflow-hidden outline-none" role="button" icon="clear" /> : <></>}
      >
        <Input
          value={inputValue}
          onChange={inputOnChangeHandler}
          ref={filterRef}
          className="border-b-0"
        />
      </TextField>
      {loading && <InputLinearProgress />}
      <LookupMenu<ICategory>
        inputRef={filterRef}
        descriptionField="description"
        keyField="id"
        onMenuItemSelected={handleCategoryPicked}
        items={lookupItems}
        onMenuClosed={onMenuClosed}
        isOpen={isMenuOpen}
      />
    </div>
  );
};

export default KeywordSearch;

import React, { useState, useCallback } from 'react';
import TopAppBar, {
  TopAppBarFixedAdjust,
  TopAppBarIcon,
  TopAppBarRow,
  TopAppBarSection,
  TopAppBarTitle,
} from '@fv-components/top-app-bar';
import MaterialIcon from '@fv-components/material-icon';
import Drawer, {
  DrawerHeader,
  DrawerTitle,
  DrawerContent,
  DrawerAppContent,
} from '@fv-components/drawer';
import {
  ApolloProvider, ApolloClient, InMemoryCache, createHttpLink,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import {
  Switch, Route, BrowserRouter, Redirect,
} from 'react-router-dom';
import { ILogin, IOrganization, IPermissionMap } from '@models';
import useConfirmationDialog from '@hooks/useConfirmationDialog';
import useCustomDialog from '@hooks/useCustomDialog';
import useSnackBar from '@hooks/useSnackBar';
import { filevineState, handleAmplifyConfigure } from '@libs/auth';

import TimelyLogo from './TimelyLogo';
import Menu from './Menu/Menu';
import Admin from './Admin/Admin';
import Calculator from './Calculator/Calculator';
import AccountMenu from './AccountMenu/AccountMenu';
import NotLoggedIn from './NotLoggedIn/NotLoggedIn';
import Account from './Account/Account';
import Projects from './Projects/Projects';
import NoRoles from './NoRoles/NoRoles';
import TermsOfService from './TermsOfService/TermsOfService';
import LoggedInContentContainer from './LoggedInContentContainer/LoggedInContentContainer';
import CognitoSuccess from './Auth/CognitoSuccess';
import CognitoLogin from './Auth/CognitoLogin';
import UserManager from './UserManager/UserManager';
import Pendo from './Pendo';
import RefreshNotice from './RefreshNotice';

const css = require('./App.module.scss');

const httpLink = createHttpLink({
  uri: '/graphql',
  fetch: (input: RequestInfo, init?: RequestInit): Promise<Response> => {
    const originalFetch = fetch(input, init);

    originalFetch.then((response: Response) => {
      if (response.headers.get('token-expired')) {
        const expiredToken = response.headers.get('token-expired');
        const currentToken = localStorage.getItem('token');

        // if we make multiple calls and the first one uses the refresh token to get a new token
        // the next call will still try to use the old token
        // (it doesn't wait for the first call to succeed)
        // in this case the call will fail saying it's expired
        // we need to check to not clear out the cache
        // if the token that was used during th call does not
        // match what is in the local store now.
        if (currentToken === expiredToken) {
          localStorage.clear();
          window.location.href = '/';
        }
      } else if (response.headers.get('token-refreshed')) {
        localStorage.setItem(
          'token',
          response.headers.get('token-Refreshed') as string,
        );
      }
    });

    return originalFetch;
  },
});

// amplify should only be configured with SAML props if on the success page
// reset here if the user is in a partial state
if (window.location.pathname !== '/success') {
  filevineState.SAMLAuthProvider = '';
}
// if FV user is set / already logged in from last visit configure amplify
if (filevineState.userId) {
  handleAmplifyConfigure();
}

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = localStorage.getItem('token');
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      Authorization: token ? `Bearer ${token}` : null,
    },
  };
});

export const timelyClient = new ApolloClient({
  cache: new InMemoryCache(),
  link: authLink.concat(httpLink),
});

interface IAppState {
  userFullName?: string;
  userId?: string;
  permissions?: IPermissionMap;
  email?: string;
  organization?: IOrganization;
  organizationName?: string;
  canUseCalculator?: boolean;
  canUseUserManager?: boolean;
}

const loginToAppState = (login: ILogin): IAppState => ({
  userFullName: login.name,
  userId: login.userId,
  permissions: login.permissionMap,
  email: login.email,
  organization: { id: login.organizationId, description: login.organizationName },
  canUseCalculator: !!login.permissionMap?.Calculation?.Read
      || !!login.permissionMap?.Calculation?.ReadLimited,
  canUseUserManager: !!login.organizationId
      && (!!login.permissionMap?.User?.ReadLimited || !!login.permissionMap?.User?.Read)
      && (!!login.permissionMap?.Invite?.ReadLimited || !!login.permissionMap?.Invite?.Read)
      && (!!login.permissionMap?.Invite?.Write || !!login.permissionMap?.Invite?.WriteLimited),
});

const getAppStateFromLocalStorage = () : IAppState => {
  const loginObjectString = localStorage.getItem('login');
  if (loginObjectString) {
    const login = JSON.parse(loginObjectString) as ILogin;
    if (login.token) {
      return loginToAppState(login);
    }
  }
  return {};
};

const App: React.FC = () => {
  const [state, setState] = useState<IAppState>(getAppStateFromLocalStorage());
  // For reasons beyond my understanding you cannot combine the isMenuVisible state
  // with the rest of the state or it will lose all of its state when the menu closes
  const [isMenuVisible, setIsMenuVisible] = useState(false);
  const { ConfirmationDialog } = useConfirmationDialog();
  const { CustomDialog } = useCustomDialog();
  const { SnackBar } = useSnackBar();

  const onLoginSuccess = useCallback((login: ILogin) => {
    if (login.token) {
      localStorage.setItem('token', login.token);
      localStorage.setItem('login', JSON.stringify(login));
      setState(loginToAppState(login));
    }
  }, []);

  const logOut = () => {
    setState({ permissions: undefined });
    localStorage.clear();
    timelyClient.resetStore();
  };

  const showMenu = useCallback((show: boolean) => {
    setIsMenuVisible(show);
  }, []);

  const onAccountUpdate = (
    firstName: string,
    lastName: string,
    email: string,
    token: string,
  ) => {
    setState({
      ...state,
      userFullName: `${firstName} ${lastName}`,
      email,
    });
    const login: ILogin = JSON.parse(localStorage.getItem('login') || '');
    localStorage.setItem('login', JSON.stringify({
      ...login,
      name: `${firstName} ${lastName}`,
      email,
      token,
    }));
    localStorage.setItem('token', token);
  };

  return (
    <>
      <BrowserRouter>
        <ApolloProvider client={timelyClient}>
          {!state.userId
            ? (
              <NotLoggedIn
                onLoginSuccess={onLoginSuccess}
              />
            ) : (
              <>
                <div className={css.printLogo}>
                  <TimelyLogo isBlack={false} width={96} />
                </div>
                <TopAppBar className={css.appBar}>
                  <TopAppBarRow>
                    <TopAppBarSection align="start">
                      <TopAppBarIcon navIcon tabIndex={0}>
                        <MaterialIcon
                          hasRipple
                          icon="menu"
                          onClick={() => showMenu(!isMenuVisible)}
                          aria-label="Menu"
                          data-cy="menu"
                        />
                      </TopAppBarIcon>
                      <TopAppBarTitle>
                        <TimelyLogo isBlack={false} width={96} />
                      </TopAppBarTitle>
                    </TopAppBarSection>
                    <TopAppBarSection align="end" role="toolbar">
                      <TopAppBarIcon actionItem tabIndex={0}>
                        <AccountMenu
                          userFullName={state.userFullName}
                          userId={state.userId}
                          onLogoutClicked={logOut}
                          permissions={state.permissions}
                        />
                      </TopAppBarIcon>
                    </TopAppBarSection>
                  </TopAppBarRow>
                </TopAppBar>
                <TopAppBarFixedAdjust>
                  <Drawer
                    modal
                    open={isMenuVisible}
                    onClose={() => showMenu(false)}
                    data-cy="menu-container"
                  >
                    {
              state.userFullName && (
              <DrawerHeader>
                <DrawerTitle tag="h2" data-cy="active-full-user-name">{state.userFullName}</DrawerTitle>
              </DrawerHeader>
              )
            }
                    <DrawerContent>
                      <Menu
                        showMenu={showMenu}
                        permissions={state.permissions}
                        organizationId={state.organization?.id}
                      />
                    </DrawerContent>
                  </Drawer>
                  <DrawerAppContent>
                    <Switch>
                      {state.permissions && (
                      <Route
                        path="/admin"
                      >
                        <Admin permissions={state.permissions} />
                      </Route>
                      )}
                      <Route
                        exact
                        path="/my-profile/:userId"
                      >
                        <Account onAccountUpdate={onAccountUpdate} loggedInUserId={state.userId} />
                      </Route>
                      {!!state.permissions?.History?.ReadLimited && (
                        <Route
                          exact
                          path="/projects/:userId"
                        >
                          <Projects timelyClient={timelyClient} loggedInUserId={state.userId} />
                        </Route>
                      )}
                      <Route
                        exact
                        path="/terms"
                        render={() => (
                          <LoggedInContentContainer>
                            <TermsOfService />
                          </LoggedInContentContainer>
                        )}
                      />
                      <Route
                        exact
                        path="/connect"
                      >
                        <CognitoLogin />
                      </Route>
                      <Route
                        exact
                        path="/success"
                      >
                        <CognitoSuccess />
                      </Route>
                      <Route
                        exact
                        path="/users"
                        render={() => {
                          if (state.canUseUserManager) {
                            return (
                              <UserManager
                                organization={state.organization}
                              />
                            );
                          }
                          return <Redirect to="/calculator" />;
                        }}
                      />
                      <Route
                        exact
                        path={['calculator/deadlines/', '/calculator/new', '/calculator/deadlines/:historyId', '/calculator/:historyId', '/calculator']}
                        render={() => {
                          if (state.canUseCalculator) {
                            return (
                              <Calculator />
                            );
                          }
                          return <NoRoles />;
                        }}
                      />
                      {/* If they have some crazy path I'm going to redirect back to / */}
                      <Route>
                        <Redirect
                          to="/calculator"
                        />
                      </Route>
                    </Switch>
                  </DrawerAppContent>
                </TopAppBarFixedAdjust>
              </>
            )}
        </ApolloProvider>
      </BrowserRouter>
      <ConfirmationDialog />
      <CustomDialog />
      <SnackBar />
      <RefreshNotice />
      <Pendo
        userId={state.userId}
        email={state.email}
        organizationId={state.organization?.id}
        organizationName={state.organizationName}
        userFullName={state.userFullName}
      />
    </>
  );
};

export default App;

import React, { FC, useState } from 'react';
import dayjs, { Dayjs } from 'dayjs';
import Button from '@fv-components/button';
import TimeSelectorHeader from './TimeSelectorHeader/TimeSelectorHeader';
import TimeSelectorClock from './TimeSelectorClock/TimeSelectorClock';

const css = require('./TimeSelector.module.css');

interface ITimeSelectorProps {
  date: Dayjs;
  onSelectedTime: (date: Dayjs) => void;
}

export enum TimeSelectorDisplayMode {
    HOURS,
    MINUTES,
}

export enum MeridianMode {
    AM,
    PM,
  }

interface ITimeSelectorState {
  hour: number,
  minute: number,
  meridianMode: MeridianMode,
  displayMode: TimeSelectorDisplayMode,
}

const timeFormat = 'h:m A';
const dateFormat = 'YYYY-MM-DD ';

const TimeSelector: FC<ITimeSelectorProps> = (
  { date, onSelectedTime }: ITimeSelectorProps,
) => {
  const [state, setState] = useState<ITimeSelectorState>({
    hour: date.format('A') === 'AM' ? date.hour() : date.hour() - 12,
    minute: date.minute(),
    meridianMode: date.format('A') === 'AM' ? MeridianMode.AM : MeridianMode.PM,
    displayMode: TimeSelectorDisplayMode.HOURS,
  });

  const onHourChanged = (hour: number) => {
    setState({
      ...state,
      hour,
    });
  };

  const onHourDragEnded = () => {
    setState({
      ...state,
      displayMode: TimeSelectorDisplayMode.MINUTES,
    });
  };

  const onMinuteChanged = (minute: number) => {
    setState({
      ...state,
      minute,
    });
  };

  const onHeaderHourButtonClicked = () => {
    setState({
      ...state,
      displayMode: TimeSelectorDisplayMode.HOURS,
    });
  };

  const onHeaderMinuteButtonClicked = () => {
    setState({
      ...state,
      displayMode: TimeSelectorDisplayMode.MINUTES,
    });
  };

  const onHeaderAmButtonClicked = () => {
    setState({
      ...state,
      meridianMode: MeridianMode.AM,
    });
  };

  const onHeaderPmButtonClicked = () => {
    setState({
      ...state,
      meridianMode: MeridianMode.PM,
    });
  };

  const onSave = () => {
    const dateString = `${date.format(dateFormat)} ${state.hour}:${state.minute} ${state.meridianMode === MeridianMode.AM ? 'AM' : 'PM'}`;
    const newDate = dayjs(dateString, { format: `${dateFormat} ${timeFormat}` });
    onSelectedTime(newDate);
  };

  const onCancel = () => {
    onSelectedTime(date);
  };

  return (
    <div className={css.container}>
      <div className={css.row}>
        <TimeSelectorHeader
          onAmClicked={onHeaderAmButtonClicked}
          onPmClicked={onHeaderPmButtonClicked}
          onHoursClicked={onHeaderHourButtonClicked}
          onMinutesClicked={onHeaderMinuteButtonClicked}
          hour={state.hour}
          minute={state.minute}
          meridianMode={state.meridianMode}
          mode={state.displayMode}
          onMinuteChanged={onMinuteChanged}
          onHourChanged={onHourChanged}
        />
      </div>
      <hr className={css.divider} />
      <div className={css.clockRow}>
        <TimeSelectorClock
          hour={state.hour}
          minute={state.minute}
          mode={state.displayMode}
          onHourChanged={onHourChanged}
          onMinuteChanged={onMinuteChanged}
          onHourDragEnd={onHourDragEnded}
        />
      </div>
      <div className={css.row}>
        <div className={css.grow} />
        <Button
          onClick={onSave}
          role="button"
          aria-label="Save Time Selection"
        >
          SAVE
        </Button>
        <Button
          onClick={onCancel}
          role="button"
          aria-label="Cancel Time Selection Edit"
        >
          CANCEL
        </Button>
      </div>

    </div>
  );
};

export default TimeSelector;

import React, { FC } from 'react';

interface ModalBottomButtonRowProps {
  children: JSX.Element[]
}

const ModalBottomButtonRow: FC<ModalBottomButtonRowProps> = ({ children }) => (
  <>
    <div className="flex flex-row mt-3 justify-end gap-3">
      {children}
    </div>
    <span />
  </>
);

export default ModalBottomButtonRow;

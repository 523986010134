import React, { FC, useState } from 'react';
import Dialog, {
  DialogTitle,
  DialogContent,
} from '@fv-components/dialog';
import Button from '@fv-components/button';
import { AltButton, ModalBottomButtonRow } from '@components';
import { IMatter } from '@models';
import Matter from '../Matter/Matter';

export interface IMatterModalResult {
  matter?: IMatter;
}

interface IMatterModalProps {
  isVisible?: boolean;
  matter?: IMatter;
  onClose?: (event: string, result?: IMatterModalResult) => void;
  isFilevineProjectRequired?: boolean;
  isConnectedToFilevine?: boolean;
  filevineProjectIdFromUrl?: string;
}

const MatterModal: FC<IMatterModalProps> = (
  {
    isVisible,
    onClose,
    matter: m,
    isFilevineProjectRequired,
    isConnectedToFilevine,
    filevineProjectIdFromUrl,
  }: IMatterModalProps,
) => {
  const [matter, setMatter] = useState<IMatter | undefined>(m);

  const closeDialog = () => {
    setMatter(undefined);
    if (onClose) {
      onClose('cancel');
    }
  };

  return (
    <Dialog
      onClose={closeDialog}
      open={isVisible}
    >
      <DialogTitle>
        Matter
      </DialogTitle>
      <DialogContent
        style={{ overflow: 'visible' }}
      >
        <Matter
          onUpdate={setMatter}
          matter={matter}
          isVericalLayout
          isConnectedToFilevine={isConnectedToFilevine}
          filevineProjectIdFromUrl={filevineProjectIdFromUrl}
        />
        <ModalBottomButtonRow>
          <Button
            onClick={() => onClose && onClose('ok', { matter })}
            role="button"
            aria-label="Export"
            data-cy="export"
            raised
            disabled={!matter?.description
              || (isFilevineProjectRequired && !matter.filevineProjectId)}
          >
            Export
          </Button>
          <AltButton
            onClick={closeDialog}
            aria-label="Cancel Export"
          >
            Cancel
          </AltButton>
        </ModalBottomButtonRow>
      </DialogContent>
    </Dialog>
  );
};

export default MatterModal;

/* eslint-disable jsx-a11y/label-has-associated-control */
import React, {
  FC, useState, useEffect, useCallback, Fragment,
} from 'react';
import { useHistory, useParams, useLocation } from 'react-router-dom';
import LinearProgress from '@fv-components/linear-progress';
import IconButton from '@fv-components/icon-button';
import Button from '@fv-components/button';
import MaterialIcon from '@fv-components/material-icon';
import TextField, { Input } from '@fv-components/text-field';
import {
  IJurisdiction, IServiceMethod, IServiceMethodGroup, IDeadlineInheritanceBlock,
} from '@models';
import {
  AltButton, JurisdictionAutoComplete,
} from '@components';
import useJurisdictionQuery from '@hooks/Jurisdiction/useJurisdictionQuery';
import { getSnackBarService, snackBarService } from '@hooks/useSnackBar';
import Switch from '@fv-components/switch';
import { discardChangesDialogService } from '@hooks/useConfirmationDialog';
import Inherited from './Inherited/Inherited';
import ServiceMethods from './ServiceMethods/ServiceMethods';
import ServiceMethodGroups from './ServiceMethodGroups/ServiceMethodGroups';
import TriggerInheritanceDropDown from './TriggerInheritanceDropDown';
import DeadlineInheritanceTable from './DeadlineInheritanceTable';

const JurisdictionForm: FC = () => {
  const [jurisdiction, setJurisdiction] = useState<IJurisdiction>({});
  const routerState = useLocation();
  const [isServiceMethodEditorOpen, setIsServiceMethodEditorOpen] = useState(false);
  const [isServiceMethodGroupEditorOpen, setIsServiceMethodGroupEditorOpen] = useState(false);
  const snackBar = getSnackBarService();
  const history = useHistory();
  const [inheritIsRoundBeforeCalculation, setInheritIsRoundBeforeCalculation] = useState<boolean>();
  const [
    inheritIsServiceCalculatedBeforeDeadline,
    setInheritIsServiceCalculatedBeforeDeadline,
  ] = useState<boolean>();
  const [
    inheritIsRoundedBetweenServiceAndDeadlineCalculation,
    setInheritIsRoundedBetweenServiceAndDeadlineCalculation,
  ] = useState<boolean>();

  const { id } = useParams<{ id: string }>();

  const {
    called,
    result: { items },
    load,
    loading: isLoading,
    save: { saveJurisdiction, result: { loading: isSaving } },
  } = useJurisdictionQuery({
    fields: [
      'id',
      'description',
      'notes',
      'isRoundBeforeCalculation',
      'isRoundedBetweenServiceAndDeadlineCalculation',
      'isServiceCalculatedBeforeDeadline',
      'parentId',
      'parent { address description id hasChildren }',
      'address',
      'serviceMethods(includeInherited: false) { id description duration interval calendar jurisdictionId jurisdiction { id description } }',
      'serviceMethodGroups(includeInherited: false) { id description serviceMethods { id description duration interval calendar jurisdictionId } jurisdiction { id description } }',
      'serviceMethodGroupInheritanceBlocks',
      'serviceMethodInheritanceBlocks',
      'triggerInheritanceBlocks',
      'deadlineInheritanceBlocks { jurisdictionId deadlineId } ',
      'children { id, description }',
    ],
    lazy: true,
    queryObject: {
      skip: 0,
      take: 1,
      ids: [id],
    },
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    if (items.length) {
      const {
        isRoundBeforeCalculation,
        isServiceCalculatedBeforeDeadline,
        isRoundedBetweenServiceAndDeadlineCalculation,
        parentId,
      } = items[0];

      setJurisdiction(items[0]);
      setInheritIsRoundBeforeCalculation(
        !parentId
          ? false
          : isRoundBeforeCalculation === null
          || isRoundBeforeCalculation === undefined,
      );

      setInheritIsServiceCalculatedBeforeDeadline(
        !parentId
          ? false
          : isServiceCalculatedBeforeDeadline === null
          || isServiceCalculatedBeforeDeadline === undefined,
      );

      setInheritIsRoundedBetweenServiceAndDeadlineCalculation(
        !parentId
          ? false
          : isRoundedBetweenServiceAndDeadlineCalculation === null
          || isRoundedBetweenServiceAndDeadlineCalculation === undefined,
      );
    }
  }, [items]);

  useEffect(() => {
    if (!called && id) {
      load();
    }
  }, [called, id, load]);

  const [isDirty, setIsDirty] = useState(false);

  const onParentJurisdictionChange = (pj?: IJurisdiction) => {
    setIsDirty(true);
    if (pj) {
      setInheritIsRoundedBetweenServiceAndDeadlineCalculation(true);
      setInheritIsRoundBeforeCalculation(true);
      setInheritIsServiceCalculatedBeforeDeadline(true);
      setJurisdiction((current?: IJurisdiction) => ({
        ...current,
        parent: pj,
        parentId: pj?.id,
        isRoundBeforeCalculation: undefined,
        isRoundedBetweenServiceAndDeadlineCalculation: undefined,
        isServiceCalculatedBeforeDeadline: undefined,
        triggerInheritanceBlocks: [],
        deadlineInheritanceBlocks: [],
      }));
    } else {
      setInheritIsRoundedBetweenServiceAndDeadlineCalculation(false);
      setInheritIsRoundBeforeCalculation(false);
      setInheritIsServiceCalculatedBeforeDeadline(false);
      setJurisdiction((current?: IJurisdiction) => ({
        ...current,
        parent: undefined,
        parentId: undefined,
        isRoundBeforeCalculation:
          !!current?.isRoundBeforeCalculation,
        isRoundedBetweenServiceAndDeadlineCalculation:
          !!current?.isRoundedBetweenServiceAndDeadlineCalculation,
        isServiceCalculatedBeforeDeadline:
          !!current?.isServiceCalculatedBeforeDeadline,
        triggerInheritanceBlocks: [],
        deadlineInheritanceBlocks: [],
      }));
    }
  };

  const onDescriptionChange = (description: string) => {
    setIsDirty(true);
    setJurisdiction((current?: IJurisdiction) => ({
      ...current,
      description,
    }));
  };

  const onInheritIsRoundBeforeCalculationSwitchChange = () => {
    const updatedInherit = !inheritIsRoundBeforeCalculation;
    setIsDirty(true);
    setInheritIsRoundBeforeCalculation(!inheritIsRoundBeforeCalculation);
    setJurisdiction((current?: IJurisdiction) => ({
      ...current,
      isRoundBeforeCalculation: updatedInherit ? undefined : false,
    }));
  };

  const onInheritIsRoundedBetweenServiceAndDeadlineCalculationSwitchChange = () => {
    const updatedInherit = !inheritIsRoundedBetweenServiceAndDeadlineCalculation;
    setIsDirty(true);
    setInheritIsRoundedBetweenServiceAndDeadlineCalculation(updatedInherit);
    setJurisdiction((current?: IJurisdiction) => ({
      ...current,
      isRoundedBetweenServiceAndDeadlineCalculation: updatedInherit ? undefined : false,
    }));
  };

  const onInheritIsServiceCalculatedBeforeDeadlineSwitchChange = () => {
    const updatedInherit = !inheritIsServiceCalculatedBeforeDeadline;
    setIsDirty(true);
    setInheritIsServiceCalculatedBeforeDeadline(!inheritIsServiceCalculatedBeforeDeadline);
    setJurisdiction((current?: IJurisdiction) => ({
      ...current,
      isServiceCalculatedBeforeDeadline: updatedInherit ? undefined : false,
    }));
  };

  const onIsRoundBeforeCalculationSwitchChange = () => {
    setIsDirty(true);
    setJurisdiction((current?: IJurisdiction) => ({
      ...current,
      isRoundBeforeCalculation:
        !current?.isRoundBeforeCalculation,
    }));
  };

  const onIsRoundedBetweenServiceAndDeadlineCalculationSwitchChange = () => {
    setIsDirty(true);
    setJurisdiction((current?: IJurisdiction) => ({
      ...current,
      isRoundedBetweenServiceAndDeadlineCalculation:
        !current?.isRoundedBetweenServiceAndDeadlineCalculation,
    }));
  };

  const onIsServiceCalculatedBeforeDeadlineSwitchChange = () => {
    setIsDirty(true);
    setJurisdiction((current?: IJurisdiction) => ({
      ...current,
      isServiceCalculatedBeforeDeadline:
        !current?.isServiceCalculatedBeforeDeadline,
    }));
  };

  const onNotesChange = (notes: string) => {
    setIsDirty(true);
    setJurisdiction((current?: IJurisdiction) => ({
      ...current,
      notes,
    }));
  };

  const save = async () => {
    if (jurisdiction?.description) {
      try {
        const { data } = await saveJurisdiction(
          jurisdiction.description,
          jurisdiction.id,
          jurisdiction.parent,
          jurisdiction.isRoundBeforeCalculation,
          jurisdiction.isRoundedBetweenServiceAndDeadlineCalculation,
          jurisdiction.isServiceCalculatedBeforeDeadline,
          jurisdiction.notes,
          jurisdiction.serviceMethods,
          jurisdiction.serviceMethodGroups,
          jurisdiction.serviceMethodInheritanceBlocks,
          jurisdiction.serviceMethodGroupInheritanceBlocks,
          jurisdiction.triggerInheritanceBlocks,
          jurisdiction.deadlineInheritanceBlocks?.filter(
            (x: IDeadlineInheritanceBlock) => x.jurisdictionId === jurisdiction.id)
            .map((x: IDeadlineInheritanceBlock) => x.deadlineId),
        );
        if (data?.id) {
          snackBar.showSnackBar('Save successful.');
          history.push('/admin/jurisdiction', routerState);
        } else {
          snackBar.showSnackBar('Error! Unable to save jurisdiction.');
        }
      } catch (error) {
        snackBar.showSnackBar('Error! Unable to save jurisdiction.');
      }
    }
  };

  const onCancelClick = () => {
    if (isDirty) {
      discardChangesDialogService.showDialog(() => history.push('/admin/jurisdiction', routerState));
    } else {
      history.push('/admin/jurisdiction', routerState);
    }
  };

  const onNavigateToRelationshipClick = (relationId?: string) => {
    if (relationId) {
      if (isDirty) {
        discardChangesDialogService.showDialog(() => {
          setIsDirty(false);
          // have to set jurisdiction to something like this in order to get the
          // parent jurisdiction auto complete to not automatically select an
          // option if the parent is null
          setJurisdiction({ id: relationId, parent: { id: '' } });
          history.push(`/admin/jurisdiction/${relationId}/edit`, routerState);
        });
      } else {
        setIsDirty(false);
        setJurisdiction({ id: relationId, parent: { id: '' } });
        history.push(`/admin/jurisdiction/${relationId}/edit`, routerState);
      }
    }
  };

  const onServiceMethodDoneEditing = useCallback(
    (serviceMethod?: IServiceMethod) => {
      const hasDuplicate = !!jurisdiction.serviceMethods?.find(
        (sm: IServiceMethod) => sm.id !== serviceMethod?.id
          && sm.description === serviceMethod?.description);
      setIsServiceMethodEditorOpen(false);
      if (!hasDuplicate) {
        if (serviceMethod) {
          const smIndex = jurisdiction.serviceMethods?.findIndex(
            (sm: IServiceMethod) => sm.id === serviceMethod.id);
          if (smIndex !== undefined && smIndex > -1) {
            setJurisdiction({
              ...jurisdiction,
              serviceMethods: jurisdiction.serviceMethods?.map(
                (sm: IServiceMethod, index: number) => (index === smIndex ? serviceMethod : sm))
                .sort(
                  (a: IServiceMethod, b: IServiceMethod) => (a.description || '').localeCompare(b.description || ''),
                ),
            });
          } else {
            setJurisdiction({
              ...jurisdiction,
              serviceMethods: [
                ...(jurisdiction.serviceMethods || []),
                serviceMethod,
              ].sort(
                (a: IServiceMethod, b: IServiceMethod) => (a.description || '').localeCompare(b.description || ''),
              ),
            });
          }
          setIsDirty(true);
        }
      } else {
        snackBarService.showSnackBar('Error! Duplicate Service Method.');
      }
    },
    [jurisdiction],
  );

  const onServiceMethodGroupDoneEditing = useCallback(
    (serviceMethodGroup?: IServiceMethod) => {
      setIsServiceMethodGroupEditorOpen(false);
      const hasDuplicate = !!jurisdiction.serviceMethodGroups?.find(
        (smg: IServiceMethodGroup) => smg.id !== serviceMethodGroup?.id
          && smg.description === serviceMethodGroup?.description);
      if (!hasDuplicate) {
        if (serviceMethodGroup) {
          setIsDirty(true);
          const smIndex = jurisdiction.serviceMethodGroups?.findIndex(
            (smg: IServiceMethodGroup) => smg.id === serviceMethodGroup.id);
          if (smIndex !== undefined && smIndex > -1) {
            setJurisdiction({
              ...jurisdiction,
              serviceMethodGroups: jurisdiction.serviceMethodGroups?.map(
                (
                  smg: IServiceMethodGroup,
                  index: number,
                ) => (index === smIndex ? serviceMethodGroup : smg))
                .sort(
                  (a: IServiceMethodGroup, b: IServiceMethodGroup) => (a.description || '').localeCompare(b.description || ''),
                )
              ,
            });
          } else {
            setJurisdiction({
              ...jurisdiction,
              serviceMethodGroups: [
                ...(jurisdiction.serviceMethodGroups || []),
                serviceMethodGroup,
              ].sort(
                (a: IServiceMethodGroup, b: IServiceMethodGroup) => (a.description || '').localeCompare(b.description || ''),
              ),
            });
          }
        }
      } else {
        snackBarService.showSnackBar('Error! Duplicate Service Method Group.');
      }
    },
    [jurisdiction],
  );

  const onServiceMethodGroupsDeleted = (groups?: IServiceMethodGroup[]) => {
    if (groups) {
      setIsDirty(true);
      setJurisdiction((current: IJurisdiction) => ({
        ...current,
        serviceMethodGroups: current.serviceMethodGroups?.filter(
          (smg: IServiceMethodGroup) => !groups.find((g: IServiceMethodGroup) => g.id === smg.id)),
      }));
    }
  };

  const onServiceMethodDeleted = (methods?: IServiceMethod[]) => {
    if (methods) {
      setIsDirty(true);
      setJurisdiction((current: IJurisdiction) => ({
        ...current,
        serviceMethods: current.serviceMethods?.filter(
          (sm: IServiceMethod) => !methods.find((g: IServiceMethod) => g.id === sm.id)),
        serviceMethodGroups: current.serviceMethodGroups?.map((smg: IServiceMethodGroup) => ({
          ...smg,
          serviceMethods: smg.serviceMethods?.filter(
            (sm: IServiceMethod) => !methods.find((g: IServiceMethod) => g.id === sm.id)),
        })),
      }));
    }
  };

  // in this case if a row is selected that means that
  // it should be inherited which means we remove the block
  // if it exists
  const onServiceMethodBlockChange = (method: IServiceMethod, isSelected: boolean) => {
    setIsDirty(true);
    setJurisdiction((current: IJurisdiction) => ({
      ...current,
      serviceMethodInheritanceBlocks: isSelected ? current.serviceMethodInheritanceBlocks?.filter(
        (serviceMethodId: string) => serviceMethodId !== method.id) : [
        ...(current.serviceMethodInheritanceBlocks || []),
        method.id || '',
      ],
    }));
  };

  // in this case if a row is selected that means that
  // it should be inherited which means we remove the block
  // if it exists
  const onServiceMethodGroupBlockChange = (group: IServiceMethodGroup, isSelected: boolean) => {
    setIsDirty(true);
    setJurisdiction((current: IJurisdiction) => ({
      ...current,
      serviceMethodGroupInheritanceBlocks: isSelected
        ? current.serviceMethodGroupInheritanceBlocks?.filter(
          (serviceMethodGroupId: string) => serviceMethodGroupId !== group.id) : [
          ...(current.serviceMethodGroupInheritanceBlocks || []),
          group.id || '',
        ],
    }));
  };

  const onTriggerBlockChange = (triggerInheritanceBlocks: string[]) => {
    setIsDirty(true);
    setJurisdiction((current: IJurisdiction) => ({
      ...current,
      triggerInheritanceBlocks,
    }));
  };

  const onDeadlineBlockChange = (deadlineInheritanceBlocks: string[]) => {
    setIsDirty(true);
    setJurisdiction((current: IJurisdiction) => ({
      ...current,
      deadlineInheritanceBlocks: [
        ...(current.deadlineInheritanceBlocks?.filter(
          (x: IDeadlineInheritanceBlock) => x.jurisdictionId !== current.id) || []),
        ...deadlineInheritanceBlocks.map((deadlineId: string) => ({ deadlineId, jurisdictionId: current.id || '' })),
      ],
    }));
  };

  const onServiceMethodGroupBlockSelectAll = (isSelected: boolean) => {
    setIsDirty(true);
    setJurisdiction((current: IJurisdiction) => ({
      ...current,
      serviceMethodGroupInheritanceBlocks: isSelected
        ? [] : current.serviceMethodGroupInheritanceBlocks,
    }));
  };

  const onServiceMethodBlockSelectAll = (isSelected: boolean) => {
    setIsDirty(true);
    setJurisdiction((current: IJurisdiction) => ({
      ...current,
      serviceMethodInheritanceBlocks: isSelected
        ? [] : current.serviceMethodInheritanceBlocks,
    }));
  };

  const sortedChildren = [...(jurisdiction.children || [])]?.sort(
    (a: IJurisdiction, b: IJurisdiction) => (a.description || '').localeCompare(b.description || ''));

  return (
    <div className="flex flex-col gap-5">
      <b className="text-2xl">{`${jurisdiction?.id ? 'Edit' : 'New'} Jurisdiction`}</b>
      <TextField label="Description" outlined>
        <Input
          data-cy="new-description"
          value={jurisdiction?.description || ''}
          onChange={(event: React.FormEvent<HTMLInputElement>) => {
            onDescriptionChange(event.currentTarget.value);
          }}
        />
      </TextField>
      <div className="flex flex-row">
        <div className="w-full">
          <JurisdictionAutoComplete
            value={jurisdiction.parent}
            onSelect={onParentJurisdictionChange}
            label="Parent Jurisdiction"
            data-cy="parent-jurisdiction"
          />
        </div>
        <IconButton
          className={jurisdiction.parent?.id ? 'text-blue' : ''}
          onClick={() => onNavigateToRelationshipClick(jurisdiction.parent?.id)}
          role="button"
          disabled={!jurisdiction.parent?.id}
        >
          <MaterialIcon
            icon="arrow_upward"
          />
        </IconButton>
      </div>
      <div className="flex flex-col">
        <b>
          {`Child Jurisdictions (${sortedChildren.length})`}
        </b>
        <div className="flex gap-2 max-h-36 overflow-y-auto flex-wrap overflow-x-hidden text-sm">
          {
            sortedChildren.map((c: IJurisdiction, index: number) => (
              <Fragment key={c.id}>
                <a
                  className="hover:no-underline"
                  href={`/admin/jurisdiction/${c.id}/edit`}
                  onClick={(event) => {
                    event.preventDefault();
                    onNavigateToRelationshipClick(c?.id);
                  }}
                >
                  {c.description}
                </a>
                {index !== sortedChildren.length - 1 && (
                  <div>
                    -
                  </div>
                )}
              </Fragment>
            ))
          }
        </div>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-3">
        <div className="flex flex-col gap-3">
          <div>Round before starting to calculate?</div>
          <div className="flex flex-col md:flex-row gap-3">
            {jurisdiction.parent?.id && (
              <div>
                <Switch
                  nativeControlId="inheritIsRoundBeforeCalculation"
                  checked={inheritIsRoundBeforeCalculation}
                  onChange={onInheritIsRoundBeforeCalculationSwitchChange}
                />
                <label className="ml-3" htmlFor="isRoundBeforeCalculationSwitch">Inherited?</label>
              </div>
            )}
            {!inheritIsRoundBeforeCalculation && (
              <div>
                <Switch
                  nativeControlId="isRoundBeforeCalculationSwitch"
                  checked={jurisdiction?.isRoundBeforeCalculation}
                  onChange={onIsRoundBeforeCalculationSwitchChange}
                />
                <label className="ml-3" htmlFor="isRoundBeforeCalculationSwitch">Round Before?</label>
              </div>
            )}
          </div>
          <div>
            Round in between trigger service and calculation?
          </div>
          <div className="flex flex-col md:flex-row gap-3">
            {jurisdiction.parent?.id && (
              <div>
                <Switch
                  nativeControlId="inheritIsRoundedBetweenServiceAndDeadlineCalculation"
                  checked={inheritIsRoundedBetweenServiceAndDeadlineCalculation}
                  onChange={onInheritIsRoundedBetweenServiceAndDeadlineCalculationSwitchChange}
                />
                <label className="ml-3" htmlFor="inheritIsRoundedBetweenServiceAndDeadlineCalculation">Inherited?</label>
              </div>
            )}
            {!inheritIsRoundedBetweenServiceAndDeadlineCalculation && (
              <div>
                <Switch
                  nativeControlId="isRoundedBetweenServiceAndDeadlineCalculationSwitch"
                  checked={jurisdiction?.isRoundedBetweenServiceAndDeadlineCalculation}
                  onChange={onIsRoundedBetweenServiceAndDeadlineCalculationSwitchChange}
                />
                <label className="ml-3" htmlFor="isRoundedBetweenServiceAndDeadlineCalculationSwitch">Round In Between?</label>
              </div>
            )}
          </div>
          <div>
            Calculate the service before the deadline?
          </div>
          <div className="flex flex-col md:flex-row gap-3">
            {jurisdiction.parent?.id && (
              <div>
                <Switch
                  nativeControlId="inheritIsDeadlineCalculatedBeforeService"
                  checked={inheritIsServiceCalculatedBeforeDeadline}
                  onChange={onInheritIsServiceCalculatedBeforeDeadlineSwitchChange}
                />
                <label className="ml-3" htmlFor="inheritIsDeadlineCalculatedBeforeService">Inherited?</label>
              </div>
            )}
            {!inheritIsServiceCalculatedBeforeDeadline && (
              <div>

                <Switch
                  nativeControlId="isDeadlineCalculatedBeforeServiceSwitch"
                  checked={jurisdiction?.isServiceCalculatedBeforeDeadline}
                  onChange={onIsServiceCalculatedBeforeDeadlineSwitchChange}
                />
                <label className="ml-3" htmlFor="isDeadlineCalculatedBeforeServiceSwitch">Service First?</label>
              </div>
            )}
          </div>
        </div>
        <TextField textarea label="Notes" outlined>
          <Input
            inputType="textarea"
            rows={5}
            value={jurisdiction?.notes || ''}
            onChange={(event: React.FormEvent<HTMLInputElement>) => {
              onNotesChange(event.currentTarget.value);
            }}
            data-cy="jursidiction-notes"
          />
        </TextField>
      </div>
      <TriggerInheritanceDropDown
        parentJurisdictionAddress={jurisdiction.parent?.address}
        onChange={onTriggerBlockChange}
        blockedIds={jurisdiction.triggerInheritanceBlocks}
      />
      <DeadlineInheritanceTable
        parentJurisdictionId={jurisdiction.parentId}
        onChange={onDeadlineBlockChange}
        blockedIds={jurisdiction.deadlineInheritanceBlocks?.map(
          (x: IDeadlineInheritanceBlock) => x.deadlineId)}
      />
      <Inherited
        jurisdiction={jurisdiction}
        onServiceMethodGroupRowChange={onServiceMethodGroupBlockChange}
        onServiceMethodRowChange={onServiceMethodBlockChange}
        onServiceMethodGroupSelectAll={onServiceMethodGroupBlockSelectAll}
        onServiceMethodSelectAll={onServiceMethodBlockSelectAll}
      />
      <ServiceMethodGroups
        serviceMethods={jurisdiction.serviceMethods}
        jurisdiction={jurisdiction}
        serviceMethodGroups={jurisdiction.serviceMethodGroups}
        onDoneEditing={onServiceMethodGroupDoneEditing}
        onEditingStart={() => setIsServiceMethodGroupEditorOpen(true)}
        onGroupsDeleted={onServiceMethodGroupsDeleted}
      />
      <ServiceMethods
        serviceMethods={jurisdiction.serviceMethods}
        jurisdiction={jurisdiction}
        onDelete={onServiceMethodDeleted}
        onDoneEditing={onServiceMethodDoneEditing}
        onEditingStart={() => setIsServiceMethodEditorOpen(true)}
      />
      <div className="flex flex-row justify-end gap-3">
        <Button
          onClick={save}
          disabled={!isDirty
            || !jurisdiction?.description
            || isLoading
            || isServiceMethodEditorOpen
            || isServiceMethodGroupEditorOpen}
          role="button"
          aria-label="Save Jurisdiction"
          icon={<MaterialIcon icon="save" />}
          raised
          data-cy="save-jurisdiction"
        >
          Save
        </Button>
        <AltButton
          onClick={onCancelClick}
          aria-label="Cancel Jurisdiction Edit"
          icon={<MaterialIcon icon="cancel" />}
          data-cy="cancel-jurisdiction"
          disabled={isServiceMethodEditorOpen || isServiceMethodGroupEditorOpen || isSaving}
        >
          Cancel
        </AltButton>
      </div>
      {(isLoading || isSaving)
        && <LinearProgress indeterminate />}
    </div>
  );
};

export default JurisdictionForm;

import React, {
  FC,
  useEffect,
  useState,
  useCallback,
} from 'react';
import { useHistory } from 'react-router-dom';
import List, {
  ListItem,
  ListItemGraphic,
  ListItemText,
} from '@fv-components/list';
import MaterialIcon from '@fv-components/material-icon';
import IPermissionMap from '@models/Identity/Role/Permission/IPermissionMap';

interface IMenuProps {
  showMenu: (showMenu: boolean) => void;
  permissions?: IPermissionMap;
  organizationId?: string;
}

interface IMenuItem {
  index: number;
  text?: string;
  path?: string;
  icon?: string;
  expandedIcon?: string;
  subMenu?: IMenuItem[];
  evaluatePermissions?: (map?: IPermissionMap, organizationId?: string) => boolean;
  cypressId?: string;
}

const availableMenuItems: IMenuItem[] = [
  {
    index: 0,
    path: '/calculator/new',
    icon: 'event',
    text: 'New Calculation',
    cypressId: 'calculator',
    evaluatePermissions:
      (map?: IPermissionMap) => !!map?.Deadline?.ReadLimited || !!map?.Deadline?.Read,
  },
  {
    index: 15,
    path: '/users',
    icon: 'build',
    text: 'Manage Users',
    evaluatePermissions:
      (map?: IPermissionMap, organizationId?: string) => !!organizationId
        && (!!map?.User?.ReadLimited || !!map?.User?.Read)
        && (!!map?.Invite?.ReadLimited || !!map?.Invite?.Read)
        && (!!map?.Invite?.Write || !!map?.Invite?.WriteLimited),
  },
  {
    index: 1,
    icon: 'lock',
    expandedIcon: 'lock_open',
    text: 'Admin',
    cypressId: 'admin',
    evaluatePermissions: (map?: IPermissionMap) => !!map?.Deadline?.Write || !!map?.Deadline?.Delete
      || !!map?.Category?.Write || !!map?.Category?.Delete
      || !!map?.FilingMethod?.Write || !!map?.FilingMethod?.Delete
      || !!map?.Holiday?.Write || !!map?.Holiday?.Delete
      || !!map?.Jurisdiction?.Write || !!map?.Jurisdiction?.Delete
      || !!map?.Permission?.Write || !!map?.Permission?.Delete
      || !!map?.Role?.Write || !!map?.Role?.Delete
      || !!map?.Organization?.Write || !!map?.Organization?.Delete,
    subMenu: [
      {
        index: 2,
        icon: 'account_balance',
        text: 'Jurisdictions',
        cypressId: 'jurisdictions',
        path: '/admin/jurisdiction',
        evaluatePermissions:
          (map?: IPermissionMap) => !!map?.Jurisdiction?.Read || !!map?.Jurisdiction?.Delete,
      },
      {
        index: 5,
        icon: 'move_to_inbox',
        text: 'Filing Methods',
        cypressId: 'filing-methods',
        path: '/admin/filing-method',
        evaluatePermissions:
          (map?: IPermissionMap) => !!map?.FilingMethod?.Read || !!map?.Jurisdiction?.Delete,
      },
      {
        index: 6,
        icon: 'event_note',
        text: 'Deadlines',
        cypressId: 'deadlines',
        path: '/admin/deadlines',
        evaluatePermissions:
          (map?: IPermissionMap) => !!map?.Deadline?.Read || !!map?.Deadline?.Delete,
      },
      {
        index: 7,
        icon: 'verified_user',
        text: 'Roles',
        cypressId: 'roles',
        path: '/admin/role',
        evaluatePermissions:
          (map?: IPermissionMap) => !!map?.Role?.Read || !!map?.Role?.Delete,
      },
      {
        index: 8,
        icon: 'face',
        text: 'Users',
        cypressId: 'users',
        path: '/admin/user',
        evaluatePermissions:
          (map?: IPermissionMap) => !!map?.User?.Read || !!map?.User?.Delete,
      },
      {
        index: 9,
        icon: 'widgets',
        text: 'Keywords',
        cypressId: 'categories',
        path: '/admin/keywords',
        evaluatePermissions:
          (map?: IPermissionMap) => !!map?.Category?.Read || !!map?.Category?.Delete,
      },
      {
        index: 10,
        icon: 'beach_access',
        text: 'Holidays',
        cypressId: 'holidays',
        path: '/admin/holidays',
        evaluatePermissions:
          (map?: IPermissionMap) => !!map?.Holiday?.Read || !!map?.Holiday?.Delete,
      },
      {
        index: 11,
        icon: 'lock',
        text: 'Permissions',
        cypressId: 'permissions',
        path: '/admin/permissions',
        evaluatePermissions:
          (map?: IPermissionMap) => !!map?.Permission?.Read || !!map?.Permission?.Delete,
      },
      {
        index: 12,
        icon: 'people',
        text: 'Organization',
        cypressId: 'organization',
        path: '/admin/organization',
        evaluatePermissions:
          (map?: IPermissionMap) => !!map?.Organization?.Read || !!map?.Organization?.Delete,
      },
      {
        index: 13,
        icon: 'drafts',
        text: 'Invites',
        cypressId: 'invites',
        path: '/admin/invites',
        evaluatePermissions:
          (map?: IPermissionMap) => !!map?.Invite?.Read || !!map?.Invite?.Delete,
      },
    ],
  },
];

const getOnlyVisibleMenuItems = (
  map?: IPermissionMap,
  organizationId?: string,
  items?: IMenuItem[],
): IMenuItem[] | undefined => items?.filter(
  (mi: IMenuItem) => mi.evaluatePermissions && mi.evaluatePermissions(map, organizationId))
  .map((mi: IMenuItem) => (
    {
      ...mi,
      subMenu: getOnlyVisibleMenuItems(map, organizationId, mi.subMenu),
    }
  ));

const Menu: FC<IMenuProps> = ({
  showMenu,
  permissions,
  organizationId,
}: IMenuProps) => {
  const history = useHistory();
  const [selectedIndex, setSelectedIndex] = useState<number>();
  const [expandedMenuItems, setExpandedMenuItems] = useState<number[]>([]);
  const [menuItems, setMenuItems] = useState<IMenuItem[]>([]);

  history.listen(() => navigator.serviceWorker.ready.then(
    (registration: ServiceWorkerRegistration) => registration.update(),
  ));

  // when permissions update we will take the original available menu items
  // and create a set of ones the user should be able to access
  useEffect(() => {
    setMenuItems(getOnlyVisibleMenuItems(permissions, organizationId, availableMenuItems) || []);
  }, [organizationId, permissions]);

  const onMenuItemClicked = useCallback((menuItem: IMenuItem) => {
    setSelectedIndex(menuItem.index);
    if (menuItem.path) {
      history.push(menuItem.path);
      showMenu(false);
    }
    const expandedIndex = expandedMenuItems.indexOf(menuItem.index);

    if (expandedIndex >= 0) {
      setExpandedMenuItems(expandedMenuItems.splice(expandedIndex, 0));
    } else if (menuItem.subMenu?.length) {
      setExpandedMenuItems([...expandedMenuItems, menuItem.index]);
    }
  }, [expandedMenuItems, history, showMenu]);

  const onShowTermsClick = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    event.preventDefault();
    history.push('/terms', {});
    showMenu(false);
  };

  const toListItem = (menuItem: IMenuItem): JSX.Element => {
    const isExpanded = expandedMenuItems.includes(menuItem.index);
    if (menuItem.subMenu) {
      return (
        <div
          data-cy={`menu-item-${menuItem.cypressId || ''}`}
          key={menuItem.index}
        >
          <ListItem
            onClick={() => onMenuItemClicked(menuItem)}
            role="button"
            aria-label={menuItem.text}
            aria-expanded={isExpanded ? 'true' : 'false'}
          >
            <ListItemGraphic
              graphic={<MaterialIcon icon={isExpanded ? menuItem.expandedIcon : menuItem.icon} />}
            />
            <ListItemText primaryText={menuItem.text} />
          </ListItem>
          <div className="ml-10">
            {isExpanded && menuItem.subMenu?.map(toListItem)}
          </div>
        </div>
      );
    }
    return (
      <div
        key={menuItem.index}
        data-cy={`menu-item-${menuItem.cypressId || ''}`}
      >
        <ListItem
          onClick={() => onMenuItemClicked(menuItem)}
          role="menuitem"
          aria-label={menuItem.text}
          className={`cypress-${menuItem.cypressId || ''}`}
          data-cy="menu-item"
        >
          <ListItemGraphic graphic={<MaterialIcon icon={menuItem.icon} />} />
          <ListItemText primaryText={menuItem.text} />
        </ListItem>
      </div>
    );
  };

  return (
    <div className="flex flex-col h-full justify-between z-20">
      <div>
        <List singleSelection selectedIndex={menuItems.indexOf({ index: selectedIndex || 0 })}>
          {menuItems.map(toListItem)}
        </List>
      </div>
      <div className="h-24 mx-3">
        <a className="text-sm text-gray" onClick={onShowTermsClick} href="/terms" data-cy="footer-terms">Terms</a>
      </div>
    </div>
  );
};

export default Menu;

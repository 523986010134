/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { FC, useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import TextField, { Input } from '@fv-components/text-field';
import Button from '@fv-components/button';
import PasswordField from '@components/PasswordField/PasswordField';
import useLogin from '@hooks/Identity/useLogin';
import ILogin from '@models/Identity/ILogin';

const css = require('./Login.module.css');

interface ILoginState {
  email?: string;
  password?: string;
}

interface ILoginProps {
  onLoginSuccess: (login: ILogin) => void;
  redirect?: string;
}

const Login: FC<ILoginProps> = ({
  onLoginSuccess,
  redirect,
}) => {
  const [loginSuccess, setLoginSuccess] = useState<boolean>();
  const [state, setState] = useState<ILoginState>({});
  const history = useHistory();

  const {
    login, result, loading, error, called,
  } = useLogin();

  useEffect(() => {
    if (result?.token && !loginSuccess) {
      setLoginSuccess(true);
      onLoginSuccess(result);
      if (redirect) {
        history.push(redirect);
      } else {
        history.push('/');
      }
    }
  }, [result, onLoginSuccess, loginSuccess, history, redirect]);

  const onEmailChange = (event: React.FormEvent<HTMLInputElement>) => {
    setState({
      ...state,
      email: event.currentTarget.value,
    });
  };

  const onPasswordChange = (password: string) => {
    setState({
      ...state,
      password,
    });
  };

  const onLoginClicked = () => {
    if (state.email && state.password) {
      login(state.email, state.password);
    }
  };

  const onEnterPress = () => {
    if (state.email && state.password) {
      onLoginClicked();
    }
  };

  const onForgotPasswordClick = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    event.preventDefault();
    history.push('/forgot');
  };

  return (
    <>
      <TextField
        label="Email"
        outlined
        className={css.textField}
      >
        <Input
          onChange={onEmailChange}
          value={state.email}
          disabled={loading}
          autoFocus
          data-cy="email"
        />
      </TextField>
      <PasswordField
        onPasswordChange={onPasswordChange}
        disabled={loading}
        onEnterPress={onEnterPress}
        allowAutoFill
        isRequired
      />
      <Button
        onClick={onLoginClicked}
        disabled={!state.email || !state.password}
        role="button"
        aria-label="Sign In To Timely"
        raised
        data-cy="signin"
      >
        Sign In
      </Button>

      <div className={css.forgotPasswordRegisterRow}>
        <a
          href="/forgot"
          role="button"
          aria-label="Go To Forgot Password Screen"
          onClick={onForgotPasswordClick}
          data-cy="forgot-password"
        >
          Forgot Password?
        </a>
      </div>

      { (error || (called && !loading && !result?.token)) && (
      <p className={css.error}>
        There was an error logging you in.
      </p>
      )}
    </>
  );
};

export default Login;

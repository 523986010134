import React, { FC, useEffect, useState } from 'react';
import {
  AltButton, DeadlineAutoComplete, ServiceMethodEditorDropDown, IServiceMethodValues,
} from '@components';
import { ITriggerDeadline, IDeadline } from '@models';
import MaterialIcon from '@fv-components/material-icon';
import IconButton from '@fv-components/icon-button';

interface IDeadlineListProps {
  triggerId?: string;
  jurisdictionId?: string;
  triggerDeadlines?: ITriggerDeadline[];
  onUpdate: (index: number, triggerDeadline: ITriggerDeadline) => void;
  onAdd: VoidFunction;
  onDelete: (index: number) => void;
  blockedDeadlineIds?: string[];
}

export interface IDeadlineSelection {
  id: string;
  deadline?: IDeadline;
}

const DeadlineList: FC<IDeadlineListProps> = ({
  triggerDeadlines,
  onUpdate,
  onAdd,
  onDelete,
  jurisdictionId,
  blockedDeadlineIds,
}: IDeadlineListProps) => {
  const [blocked, setBlocked] = useState<string[]>([]);

  const onDeadlineSelect = (index: number, deadline?: IDeadline) => {
    if (triggerDeadlines?.length && onUpdate && (
      deadline?.id
      || (triggerDeadlines[index]?.deadlineId && !deadline))) {
      onUpdate(index, {
        ...triggerDeadlines[index],
        deadline,
        deadlineId: deadline?.id,
        serviceMethodGroups: [],
        serviceMethods: [],
      });
    }
  };

  const onServiceMethodSelect = (index: number, values: IServiceMethodValues) => {
    if (triggerDeadlines?.length && onUpdate) {
      onUpdate(index, {
        ...triggerDeadlines[index],
        serviceMethodGroups: values.groups,
        serviceMethods: values.serviceMethods,
      });
    }
  };

  useEffect(() => {
    setBlocked([
      ...(blockedDeadlineIds || []),
      ...(triggerDeadlines?.map((td: ITriggerDeadline) => td.deadlineId || '') || []),
    ]);
  }, [blockedDeadlineIds, triggerDeadlines]);

  return (
    <>
      <div className="flex justify-between items-center">
        <b>Deadlines</b>
        <AltButton
          onClick={onAdd}
          icon={<MaterialIcon icon="add" />}
          aria-label="Add Trigger"
          data-cy="add-trigger"
        >
          Deadlines
        </AltButton>
      </div>
      <div className="flex flex-col gap-6 md:divide-none ">
        {triggerDeadlines?.map((triggerDeadline: ITriggerDeadline, index: number) => (
          <div
            key={triggerDeadline.id || `${triggerDeadline.deadlineId}-${triggerDeadline.triggerId || index}`}
            className="gap-3 flex flex-row justify-end items-center md:hover:bg-transparent hover:bg-gray-light p-4 md:p-0 "
          >
            <div className="flex-1 flex flex-col gap-3">
              <div className="flex-1">
                <DeadlineAutoComplete
                  value={triggerDeadline.deadline}
                  onSelect={(t?: IDeadline) => onDeadlineSelect(index, t)}
                  jurisdictionIds={jurisdictionId ? [jurisdictionId] : []}
                  blockIdsFromSelection={blocked}
                />
              </div>
              <div className="flex-1">
                <ServiceMethodEditorDropDown
                  values={{
                    serviceMethods: triggerDeadline.serviceMethods || [],
                    groups: triggerDeadline.serviceMethodGroups || [],
                  }}
                  placeholder="Response Service Methods"
                  onSelect={(values: IServiceMethodValues) => onServiceMethodSelect(index, values)}
                  jurisdictionId={jurisdictionId}
                />
              </div>
            </div>
            <div className="flex-0">
              <IconButton
                className="text-blue m-0"
                onClick={() => onDelete && onDelete(index)}
                role="button"
                aria-label="Remove Trigger"
                data-cy={`remove-trigger-${index}`}
              >
                <MaterialIcon icon="close" />
              </IconButton>
            </div>
          </div>
        ))}
      </div>
    </>
  );
};

export default DeadlineList;

import { ApolloError, FetchPolicy } from '@apollo/client';
import IServiceMethod from '@models/Deadlines/ServiceMethods/IServiceMethod';
import IServiceMethodQuery from '@models/Deadlines/ServiceMethods/IServiceMethodQuery';
import { IDeleteHookResult, useTimelyDelete } from '@hooks/useTimelyDelete';
import useTimelyQuery from '@hooks/useTimelyQuery';
import useServiceMethodSave, { IServiceMethodSaveHookResult } from './useServiceMethodSave';

type ServiceMethodQueryHook = (
  props: IServiceMethodQueryHookProps
) => IServiceMethodQueryHookResult;

export interface IServiceMethodQueryHookProps {
  queryObject: IServiceMethodQuery;
  fields: string[];
  lazy?: boolean;
  fetchPolicy?: FetchPolicy;
}

interface IServiceMethodQueryHookResult {
  loading: boolean;
  error?: ApolloError;
  result: { hasMore: boolean; items: IServiceMethod[] };
  save: IServiceMethodSaveHookResult;
  delete: IDeleteHookResult;
  called: boolean;
  load: VoidFunction;
}

const useServiceMethodQuery: ServiceMethodQueryHook = (
  {
    fields, queryObject, lazy, fetchPolicy,
  }: IServiceMethodQueryHookProps,
) => {
  const {
    loading, error, result, queryString, called, load,
  } = useTimelyQuery<IServiceMethod>({
    variables: { queryObject },
    lazy,
    collection: 'serviceMethods',
    fields,
    queryInputType: 'ServiceMethodQueryInputType',
    fetchPolicy,
  });

  return {
    called,
    load,
    loading,
    error,
    result,
    save: useServiceMethodSave({ queryString, queryObject }),
    delete: useTimelyDelete({ capitalizedType: 'ServiceMethod' }),
  };
};

export default useServiceMethodQuery;

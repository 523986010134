import {
  useMutation, ApolloError, FetchResult, DocumentNode, gql,
} from '@apollo/client';
import {
  IServiceMethodGroup, IServiceMethodGroupQuery, IJurisdiction, IServiceMethod,
} from '@models';
import { handleSaveResult } from '@libs/apollo';

type ServiceMethodGroupSaveHook = (
  props: IServiceMethodSaveHookProps
) => IServiceMethodGroupSaveHookResult;

interface IServiceMethodSaveHookProps {
  queryObject?: IServiceMethodGroupQuery;
  queryString?: DocumentNode;
}

export interface IServiceMethodGroupSaveHookResult {
  result: {
    error?: ApolloError;
    loading: boolean;
    called: boolean;
  };
  saveServiceMethodGroup: (
    description: string,
    jurisdiction: IJurisdiction,
    serviceMethods: IServiceMethod[],
    id?: string,
  ) => Promise<FetchResult<IServiceMethodGroup>>;
}

interface ISaveServiceMethodGroupResult {
  saveServiceMethodGroup: IServiceMethodGroup;
}

const useServiceMethodGroupSave: ServiceMethodGroupSaveHook = (
  { queryObject, queryString }: IServiceMethodSaveHookProps,
) => {
  const SAVE_GQL = gql`
    mutation ServiceMethodGroupMutation($serviceMethodGroup: ServiceMethodGroupInputType!) {
      saveServiceMethodGroup(item: $serviceMethodGroup) {
        id
      }
    }
  `;

  const [saveFunc, {
    error, loading, called, client,
  }] = useMutation<ISaveServiceMethodGroupResult>(SAVE_GQL);

  return {
    result: {
      error,
      loading,
      called,
    },
    saveServiceMethodGroup: (
      description: string,
      jurisdiction: IJurisdiction,
      serviceMethods: IServiceMethod[],
      id?: string,
    ) => {
      const serviceMethodGroup = {
        id,
        description,
        jurisdiction,
        jurisdictionId: jurisdiction.id,
        serviceMethods,
      };

      return saveFunc(
        {
          variables: {
            serviceMethodGroup: {
              ...serviceMethodGroup,
              jurisdictionId: jurisdiction.id,
              jurisdiction: undefined,
              serviceMethods: serviceMethodGroup.serviceMethods.map(
                (x: IServiceMethod) => ({
                  ...x,
                  __typename: undefined,
                  jurisdiction: undefined,
                }),
              ),
            },
          },
        },
      ).then((result: FetchResult<ISaveServiceMethodGroupResult>) => ({
        ...result,
        data: {
          ...handleSaveResult<IServiceMethodGroup>(
            result.data?.saveServiceMethodGroup || '',
            serviceMethodGroup,
            client,
            'serviceMethodGroups',
            queryObject,
            queryString,
          ),
        },
      }));
    },
  };
};

export default useServiceMethodGroupSave;

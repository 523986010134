import React, { FC } from 'react';
import Select, { Option } from '@fv-components/select';
import Interval from '@models/Deadlines/Interval';

interface IntervalDropDownProps {
  onChange: (interval?: Interval) => void;
  value?: Interval;
  label?: string;
  isOnlyDays?: boolean;
  isRequired?: boolean;
}

const IntervalDropDown: FC<IntervalDropDownProps> = (
  {
    onChange,
    value,
    label,
    isOnlyDays,
    isRequired,
  }: IntervalDropDownProps,
) => {
  const onSelect = (evt: React.FormEvent<HTMLSelectElement>) => {
    if (evt.currentTarget.value === '') {
      onChange(undefined);
    } else {
      onChange(evt.currentTarget.value as Interval);
    }
  };

  return (
    <Select
      outlined
      label={label || 'Interval'}
      onChange={onSelect}
      value={value || ''}
      required={isRequired}
    >
      <>
        <Option key="NONE" value="" />
        {Object.keys(Interval)
          .filter((i: string) => (isOnlyDays && i === 'DAY') || !isOnlyDays)
          .map((j) => (
            <Option key={j} value={j}>
              {j}
            </Option>
          ))}
      </>
    </Select>
  );
};

export default IntervalDropDown;

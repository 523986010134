import React, { FC } from 'react';
import { useHistory } from 'react-router-dom';
import MaterialIcon from '@fv-components/material-icon';
import {
  IDeadline, ICategory, IServiceMethod, IServiceMethodGroup,
} from '@models';
import { AltButton } from '@components';
import { getConfirmationDialogService } from '@hooks/useConfirmationDialog';
import ViewRow from '../ViewRow';
import ViewColumn from '../ViewColumn';

interface IDeadlineProps {
  deadline: IDeadline;
  warn?: boolean;
  serviceMethods?: IServiceMethod[];
  serviceMethodGroups?: IServiceMethodGroup[];
  isBlocked?: boolean;
}

const getServiceMethodDisplayText = (sms?: IServiceMethod[], smgs?: IServiceMethodGroup[]) => {
  const desc = [...(smgs || [])].sort(
    (a: IServiceMethodGroup, b: IServiceMethodGroup) => ((a.description || '') > (b.description || '') ? 1 : -1),
  )[0]?.description || [...(sms || [])].sort(
    (a: IServiceMethod, b: IServiceMethod) => ((a.description || '') > (b.description || '') ? 1 : -1),
  )[0]?.description;

  // when this query comes back it includes the service methods that are also in groups
  // for an accurate count we need to remove the service methods that are in groups
  const count = (sms?.filter(
    (sm: IServiceMethod) => !smgs?.find(
      (smg: IServiceMethodGroup) => smg.serviceMethods?.find(
        (smgsm: IServiceMethod) => smgsm.id === sm.id)
    )
  ).length || 0)
    + (smgs?.length || 0);

  if (desc && count > 1) {
    return `${desc} + ${count - 1}`;
  }
  return desc || '';
};

const Deadline: FC<IDeadlineProps> = (
  {
    deadline,
    warn,
    serviceMethods,
    serviceMethodGroups,
    isBlocked,
  }: IDeadlineProps,
) => {
  const history = useHistory();
  const confirmationDialog = getConfirmationDialogService();

  const onEditClicked = () => {
    if (warn) {
      confirmationDialog.showDialog(
        `You are about to edit a deadline that is used by ${deadline.triggerDeadlines?.length} triggers. This will also affect all other triggers that inherit this from these triggers.`,
        'Warning!',
        'Edit',
      ).then((confirmed: boolean) => {
        if (confirmed) {
          history.push(
            `/admin/deadlines/${deadline.id}/edit`,
            {
              trigger: deadline.triggerDeadlines,
              jurisdiction: deadline.jurisdiction,
              deadline,
            },
          );
        }
      });
    } else {
      history.push(
        `/admin/deadlines/${deadline.id}/edit`,
        {
          trigger: deadline.triggerDeadlines,
          jurisdiction: deadline.jurisdiction,
          deadline,
        },
      );
    }
  };

  return (
    <div className={`flex flex-1 flex-col border-solid border-l-8 border-t-0 border-r-0 border-b-0 ${isBlocked ? 'border-gray' : 'border-blue'} gap-3 hover:bg-gray-light p-4`}>
      <ViewRow>
        <ViewColumn>
          <b>Description:</b>
          <div>{deadline.description}</div>
        </ViewColumn>
      </ViewRow>
      <ViewRow>
        <ViewColumn>
          <b>Jurisdiction:</b>
          <div>
            {deadline.jurisdiction?.description}
          </div>
        </ViewColumn>
        <ViewColumn>
          <b>Short Description:</b>
          <div>{deadline.shortDescription}</div>
        </ViewColumn>
        {(!!serviceMethods?.length || !!serviceMethodGroups?.length)
          ? (
            <ViewColumn>
              <b>Service Methods:</b>
              <div>
                {getServiceMethodDisplayText(serviceMethods, serviceMethodGroups)}
              </div>
            </ViewColumn>
          ) : <ViewColumn><></></ViewColumn>}

      </ViewRow>
      <ViewRow>
        <ViewColumn>
          <b>Reference:</b>
          <div>
            {!!deadline.refLink && <a href={deadline.refLink}> link </a>}
            {deadline?.specReference}
          </div>
        </ViewColumn>
      </ViewRow>
      <ViewRow>
        <ViewColumn>
          <b>Calculation:</b>
          <div>
            {deadline.calculation}
          </div>
        </ViewColumn>
        {(deadline.extensionCalculation ? (
          <ViewColumn>
            <b>Extension Calculation:</b>
            <div>
              {deadline.extensionCalculation}
            </div>
          </ViewColumn>
        ) : <ViewColumn><></></ViewColumn>)}
        {(deadline.secondExtensionCalculation ? (
          <ViewColumn>
            <b>2nd Extension Calculation:</b>
            <div>
              {deadline.secondExtensionCalculation}
            </div>
          </ViewColumn>
        ) : <ViewColumn><></></ViewColumn>)}
      </ViewRow>
      {deadline.thirdExtensionCalculation ? (
        <ViewRow>
          <ViewColumn>
            <b>3rd Extension Calculation:</b>
            <div>
              {deadline.thirdExtensionCalculation}
            </div>
          </ViewColumn>

          <ViewColumn>
            <b>4th Extension Calculation:</b>
            <div>
              {deadline.fourthExtensionCalculation}
            </div>
          </ViewColumn>

          <ViewColumn>
            <b>5th Extension Calculation:</b>
            <div>
              {deadline.fifthExtensionCalculation}
            </div>
          </ViewColumn>
        </ViewRow>
      ) : <></>}
      <ViewRow>
        <ViewColumn>
          {!!deadline.categories?.length && (
            <>
              <b>
                {`Keywords (${deadline.categories?.length}):`}
              </b>
              <div>{deadline.categories?.map((sm: ICategory) => sm.description).join(', ')}</div>
            </>
          )}
        </ViewColumn>

        <div className="flex flex-row gap-3 items-end">
          <AltButton
            onClick={onEditClicked}
            aria-label="Edit Deadline"
            data-cy="edit-deadline"
            icon={<MaterialIcon icon="edit" />}
          >
            Edit
          </AltButton>
        </div>
      </ViewRow>

    </div>

  );
};

export default Deadline;

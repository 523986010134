import { ApolloError, FetchPolicy } from '@apollo/client';
import IRoleQuery from '@models/Identity/Role/IRoleQuery';
import IRole from '@models/Identity/Role/IRole';
import { IDeleteHookResult, useTimelyDelete } from '@hooks/useTimelyDelete';
import useTimelyQuery from '@hooks/useTimelyQuery';
import useRoleSave, { IRoleSaveHookResult } from './useRoleSave';

type RoleQueryHook = (
  props: IRoleQueryHookProps
) => IRoleQueryHookResult;

export interface IRoleQueryHookProps {
  queryObject: IRoleQuery;
  fields: string[];
  lazy?: boolean;
  fetchPolicy?: FetchPolicy;
}

interface IRoleQueryHookResult {
  loading: boolean;
  error?: ApolloError;
  result: { hasMore: boolean; items: IRole[] };
  save: IRoleSaveHookResult;
  delete: IDeleteHookResult;
  load: VoidFunction;
  called: boolean;
}

const useRoleQuery: RoleQueryHook = (
  {
    queryObject, fields, lazy, fetchPolicy,
  }: IRoleQueryHookProps,
) => {
  const {
    loading, error, result, load, queryString, called,
  } = useTimelyQuery<IRole>({
    variables: { queryObject },
    fields,
    queryInputType: 'RoleQueryInputType',
    collection: 'roles',
    lazy,
    fetchPolicy,
  });

  return {
    called,
    load,
    loading,
    error,
    result,
    save: useRoleSave({ queryString, queryObject }),
    delete: useTimelyDelete({ capitalizedType: 'Role' }),
  };
};

export default useRoleQuery;

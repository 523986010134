import React, {
  FC, useState, useEffect, useCallback,
} from 'react';
import {
  useParams,
  Switch,
  Route,
  Redirect,
} from 'react-router-dom';
import {
  ITrigger,
  IDeadline,
  ICalculation,
  IHistoryTrigger,
  IJurisdiction,
  IServiceMethod,
  ICategory,
  IHistoryTriggerDeadline,
  IHistoryTriggerDeadlineServiceMethod,
  IHistory,
  IServiceMethodCalculation,
  ITriggerDeadlineQuery,
  ITriggerDeadline,
  ICalculationQuery,
  IMatter,
  IDeadlineInheritanceBlock,
} from '@models';
import useHistoryQuery from '@hooks/History/useHistoryQuery';
import { filevineState } from '@libs/auth';
import useTriggerDeadlineQuery from '@hooks/Trigger/useTriggerDeadlineQuery';
import useCalculationQuery from '@hooks/Calculation/useCalculationQuery';
import useHandleFilevineProps from '@hooks/Filevine/useHandleFilevineProps';
import Snackbar from '@fv-components/snackbar';
import dayjs from 'dayjs';
import Intake from './Intake/Intake';
import DeadlinesHeader from './Deadlines/DeadlinesHeader';
import DeadlineList from './Deadlines/DeadlinesList/DeadlineList';

const css = require('./Calculator.module.scss');

interface IStringMap { [key: string]: string; }

export interface IHasServiceMethods {
  serviceMethods?: IServiceMethod[]
}

export interface IDeadlineCalculationState {
  historyTriggerDeadlineId?: string;
  // this trigger id may be different from the selected trigger
  // on the trigger calculation state if the deadline is coming from
  // a parent trigger
  triggerId: string;
  deadline: IDeadline;
  isSelected: boolean;
  isNewCalculation?: boolean;
  calculationId?: string;
  calculatedDate?: Date;
  serviceMethods?: IServiceMethodCalculationState[];
  filevineDeadlineId?: string;
  toBeRemoved?: boolean;
}

export interface IServiceMethodCalculationState {
  historyTriggerDeadlineServiceMethodId?: string;
  serviceMethod: IServiceMethod;
  isSelected: boolean;
  isNewCalculation?: boolean;
  calculationId?: string;
  calculatedDate?: Date;
  filevineDeadlineId?: string;
  toBeRemoved?: boolean;
}

export interface ITriggerCalculationState {
  historyTriggerId?: string;
  deadlines?: IDeadlineCalculationState[];
  triggerDate?: Date;
  serviceMethodId?: string;
  trigger?: ITrigger;
  id: number;
  isSavedToFilevine?: boolean;
  toBeRemoved?: boolean;
  triggerLabel?: string;
}

export interface ICalculationState {
  historyId?: string;
  applicableServiceMethodIds?: string[];
  triggers?: ITriggerCalculationState[];
  isSavedToFilevine?: boolean;
  isDirtySinceLastFilevineSync?: boolean;
  jurisdiction?: IJurisdiction;
}

// will modify a specific service method on a specific deadline
// to be selected ro un selected
const modifySelectionOfServiceMethodDeadline = (
  triggerDeadlines: IDeadlineCalculationState[],
  deadlineId: string,
  serviceMethodId: string,
  isSelected: boolean,
): IDeadlineCalculationState[] => triggerDeadlines.map(
  (ta: IDeadlineCalculationState) => (ta.deadline.id !== deadlineId ? ta : ({
    ...ta,
    serviceMethods: ta.serviceMethods?.map(
      (smcs: IServiceMethodCalculationState) => (smcs.serviceMethod.id !== serviceMethodId
        ? smcs
        : ({
          ...smcs,
          isSelected,
        }))),
  })),
);

const mergeTriggerDeadlinesAndCalculations = (
  triggerStates: ITriggerCalculationState[],
  calculations: ICalculation[],
): ITriggerCalculationState[] => {
  const dlines = [...calculations];
  return triggerStates.map(
    (triggerState: ITriggerCalculationState) => (
      {
        ...triggerState,
        deadlines: triggerState?.deadlines?.map(
          (dcs: IDeadlineCalculationState) : IDeadlineCalculationState => {
            const calculation = dlines.shift();
            return {
              ...dcs,
              calculatedDate: calculation?.date,
              calculationId: calculation?.id,
              serviceMethods: dcs.serviceMethods?.map(
                (smc: IServiceMethodCalculationState) => {
                  const calc = calculation?.serviceMethodCalculations?.find(
                    (x: IServiceMethodCalculation) => x.serviceMethod.id === smc.serviceMethod.id);
                  return {
                    ...smc,
                    calculationId: smc.calculationId || calc?.id,
                    calculatedDate: calc?.date,
                  };
                }),
            };
          },
        ).sort((
          a: IDeadlineCalculationState,
          b: IDeadlineCalculationState,
        ) => dayjs(a.calculatedDate).unix() - dayjs(b.calculatedDate).unix()),
      }),
  );
};

const mapTriggerDeadlinesToCalculationQuery = (
  jurisdictionId: string,
  triggerDate: Date,
  deadlineCalculationState: IDeadlineCalculationState,
  triggerServiceMethodId?: string,
  serviceMethods?: IServiceMethod[],
): ICalculationQuery => (
  {
    id: deadlineCalculationState.calculationId,
    jurisdictionId,
    triggerId: deadlineCalculationState.triggerId,
    triggerServiceMethodId,
    triggerDate,
    deadlineId: deadlineCalculationState.deadline.id,
    deadlineServiceMethods: serviceMethods
        && deadlineCalculationState.deadline.serviceMethods?.length
      ? serviceMethods.filter(
        (sm: IServiceMethod) => deadlineCalculationState.deadline.serviceMethods?.find(
          (asm: IServiceMethod) => sm.id === asm.id),
      ).map((x: IServiceMethod) => ({
        serviceMethodId: x.id as string,
        id: deadlineCalculationState.serviceMethods?.find(
          (smc: IServiceMethodCalculationState) => smc.serviceMethod.id === x.id)?.calculationId,
      })) : [],
  }
);

export const toCombinedDistinctServiceMethodIds = (
  hasServiceMethods: IHasServiceMethods[][],
) => Object.keys(hasServiceMethods.reduce(
  (map: IStringMap, outer: IHasServiceMethods[]) => {
    outer.forEach(
      (a: IHasServiceMethods) => a.serviceMethods?.forEach(
                (sm: IServiceMethod) => sm?.id && Object.assign(map, { [sm.id]: sm.id }),
              ),
    );
    return map;
  }, {},
));

const getAllTriggerIdsForDeadlines = (
  trigger: ITrigger,
): string[] => (trigger.id ? [
  ...([trigger.id] || []),
  ...(trigger.parentTriggers?.map((x: ITrigger) => x.id || '') || []),
] : []);

const areThereAnyFilevineIdsInHistoryTrigger = (
  ht: IHistoryTrigger,
) => !!ht.historyTriggerDeadlines.find(
  (htd: IHistoryTriggerDeadline) => !!htd.filevineDeadlineId
        || htd.historyTriggerDeadlineServiceMethods.find(
          (htdsm: IHistoryTriggerDeadlineServiceMethod) => !!htdsm.filevineDeadlineId,
        ),
);

const areThereAnyFilevineIdsInHistory = (
  history: IHistory,
) => !!history.historyTriggers.find(
  (ht: IHistoryTrigger) => areThereAnyFilevineIdsInHistoryTrigger(ht),
);

const markTriggerForRemoval = (
  trigger: ITriggerCalculationState,
): ITriggerCalculationState => ({
  ...trigger,
  toBeRemoved: true,
  deadlines: trigger.deadlines?.map(
      (dcs: IDeadlineCalculationState) => ({
        ...dcs,
        toBeRemoved: !!dcs.filevineDeadlineId,
        serviceMethods: dcs.serviceMethods?.map(
          (smc: IServiceMethodCalculationState) => ({
            ...smc,
            toBeRemoved: !!smc.filevineDeadlineId,
          })),
      })),
});

const toCalculationState = (
  history: IHistory,
): ICalculationState => ({
  historyId: history.id,
  jurisdiction: history.jurisdiction,
  applicableServiceMethodIds: toCombinedDistinctServiceMethodIds(history.historyTriggers.map(
    (ht: IHistoryTrigger) => ht.historyTriggerDeadlines.map(
      (htd: IHistoryTriggerDeadline) => htd.deadline || { serviceMethods: [] },
    ),
  )),
  isSavedToFilevine: areThereAnyFilevineIdsInHistory(history),
  triggers: history.historyTriggers.map(
    (ht: IHistoryTrigger, index: number): ITriggerCalculationState => ({
      id: index,
      historyTriggerId: ht.id,
      triggerDate: ht.triggerDate,
      trigger: ht.trigger,
      triggerLabel: ht.triggerLabel,
      serviceMethodId: ht.serviceMethodId,
      isSavedToFilevine: areThereAnyFilevineIdsInHistoryTrigger(ht),
      deadlines: ht.historyTriggerDeadlines.map(
        (htd: IHistoryTriggerDeadline): IDeadlineCalculationState => ({
          historyTriggerDeadlineId: htd.id,
          deadline: htd.deadline as IDeadline,
          triggerId: htd.triggerId as string,
          isSelected: htd.isSelected || false,
          isNewCalculation: htd.isNewCalculation || false,
          // yes that's right the calculation id is the same as the history id
          // when creating a new history object we use the calculation id
          calculationId: htd.id,
          calculatedDate: htd.calculatedDate as Date,
          filevineDeadlineId: htd.filevineDeadlineId,
          serviceMethods: htd.historyTriggerDeadlineServiceMethods.map(
            (htdsm: IHistoryTriggerDeadlineServiceMethod): IServiceMethodCalculationState => ({
              calculatedDate: htdsm.calculatedDate as Date,
              isSelected: htdsm.isSelected || false,
              isNewCalculation: htd.isNewCalculation || false,
              serviceMethod: htdsm.serviceMethod as IServiceMethod,
              historyTriggerDeadlineServiceMethodId: htdsm.id,
              calculationId: htdsm.id,
              filevineDeadlineId: htdsm.filevineDeadlineId,
            }),
          ).sort((
            a: IServiceMethodCalculationState,
            b: IServiceMethodCalculationState,
          ) => dayjs(a.calculatedDate).unix() - dayjs(b.calculatedDate).unix()),
        }
        ),
      ).sort((
        a: IDeadlineCalculationState,
        b: IDeadlineCalculationState,
      ) => dayjs(a.calculatedDate).unix() - dayjs(b.calculatedDate).unix()),
    }),
  ),
});

const toDeadlineCalculationStates = (
  triggerDeadlines: ITriggerDeadline[],
) => ([
  ...triggerDeadlines.map(
    (triggerDeadline: ITriggerDeadline) : IDeadlineCalculationState => (
      {
        deadline: triggerDeadline.deadline as IDeadline,
        triggerId: triggerDeadline.triggerId || '',
        isSelected: true,
      }),
  ),
]);

const getSelectedResponseServiceMethodsFromHistory = (history: IHistory): IServiceMethod[] => {
  const serviceMethodMap = history.historyTriggers.flatMap(
    (ht: IHistoryTrigger) => ht.historyTriggerDeadlines
      .flatMap((htd: IHistoryTriggerDeadline) => htd.historyTriggerDeadlineServiceMethods
        .map(
          (
            htdsm: IHistoryTriggerDeadlineServiceMethod,
          ) => htdsm.serviceMethod as IServiceMethod,
        )),
  ).reduce((map: IStringMap, sm: IServiceMethod) => {
    if (sm?.id) {
      Object.assign(map, { [sm.id]: sm });
    }
    return map;
  }, {});

  return Object.keys(serviceMethodMap).map(
    (key: string) => serviceMethodMap[key] as IServiceMethod,
  );
};

const getNextTriggerSelectionId = (calculationState?: ICalculationState) => (
  calculationState?.triggers?.length
    ? calculationState.triggers[calculationState?.triggers.length - 1].id + 1
    : 0
);

const toCalculationQueries = (
  jurisdictionId: string,
  calculationState?: ICalculationState,
  responseServiceMethods?: IServiceMethod[],
) => calculationState?.triggers?.flatMap(
  (tcs: ITriggerCalculationState) => tcs.deadlines?.map(
    (dcs: IDeadlineCalculationState) => mapTriggerDeadlinesToCalculationQuery(
      jurisdictionId,
      tcs.triggerDate || new Date(),
      dcs,
      tcs.serviceMethodId,
      responseServiceMethods,
    ),
  ) || []
  ) || [];

const modifyStateWithServiceSelectedServiceMethods = (
  responseServiceMethods?: IServiceMethod[],
  triggerCalculationStates?: ITriggerCalculationState[],
): ITriggerCalculationState[] | undefined => {
  const ids = responseServiceMethods?.map((sm: IServiceMethod) => sm.id || '');
  return triggerCalculationStates?.map(
    (tcs: ITriggerCalculationState) => ({
      ...tcs,
      deadlines: tcs.toBeRemoved ? tcs.deadlines : tcs.deadlines?.map(
        (dcs: IDeadlineCalculationState) => {
          const newServiceMethods = responseServiceMethods?.filter(
             // to find new service methods for a specific deadline we have to look
              // for service methods that are not already added to the calculation state
              // but are listed in the deadline object as a service method that would be
              // applicable to the deadline
            (sm: IServiceMethod) => !dcs.serviceMethods
              ?.find((smcs: IServiceMethodCalculationState) => smcs.serviceMethod.id === sm.id)
              && dcs.deadline.serviceMethods?.find((asm: IServiceMethod) => asm.id === sm.id))
            .map((sm: IServiceMethod): IServiceMethodCalculationState => ({
              isSelected: true,
              serviceMethod: sm,
            }));
          return {
            ...dcs,
            serviceMethods: [
              ...(dcs.serviceMethods?.filter(
                // if the filevine id is there not matter what we keep it in
                // the list it will be marked as to be removed
                // if not needed and removed from the list once filevine sync happens.
                // if it hasn't been saved to FV yet we will remove it from the list
                (smcs: IServiceMethodCalculationState) => smcs.filevineDeadlineId || ids?.includes(smcs.serviceMethod.id || '')
              ).map(
                (smcs: IServiceMethodCalculationState) => ({
                  ...smcs,
                  toBeRemoved: !!smcs.filevineDeadlineId && !ids?.includes(smcs.serviceMethod.id || ''),
                })) || []),
              ...(newServiceMethods || []),
            ],
          };
        }
    ),
    }));
};

const deadlineQueryFields = [
  'id',
  'triggerId',
  'deadline { id description shortDescription refLink specReference serviceMethods { id description } categories { id description }  }',
  'deadlineId',
  'serviceMethods { id description }',
];

const historyQueryFields = [
  'id',
  'matter { id description filevineProjectId filevineOrgId }',
  'date',
  'jurisdictionId',
  'userId',
  'jurisdiction { description id address path }',
  'historyTriggers { id triggerLabel trigger { description id isFilingRequired } triggerDate serviceMethodId historyTriggerDeadlines { id isSelected isNewCalculation triggerId calculatedDate filevineDeadlineId deadline { id description shortDescription refLink specReference serviceMethods { id description } categories { id description } } historyTriggerDeadlineServiceMethods { id filevineDeadlineId calculatedDate isSelected isNewCalculation serviceMethodId serviceMethod { id description } } } }',
];

const getDeadlineCalcStates = (
  map: { [key: number]: ITriggerDeadline[] },
  queryId?: number,
): IDeadlineCalculationState[] | undefined => {
  if (queryId !== undefined && map[queryId]) {
    return toDeadlineCalculationStates(map[queryId]);
  }
  return undefined;
};

const Calculator: FC = () => {
  const { projectId } = useHandleFilevineProps();
  const [projectIdFromParams, setProjectIdFromParams] = useState<number>();
  const [matter, setMatter] = useState<IMatter | undefined>();
  const [jurisdiction, setJurisdiction] = useState<IJurisdiction>();
  const [categories, setCategories] = useState<ICategory[]>();
  const [filter, setFilter] = useState<string>();
  const [responseServiceMethods, setResponseServiceMethods] = useState<IServiceMethod[]>();
  const [triggerHeadline, setTriggerHeadline] = useState<string>();
  const [
    calculationState,
    setCalculationState,
  ] = useState<ICalculationState>({ triggers: [{ id: 0 }], jurisdiction });
  const [
    deadlinesQueryObject,
    setDeadlinesQueryObject,
  ] = useState<{ id: number, query: ITriggerDeadlineQuery }[]>();
  const [calculationQueryObjects, setCalculationQueryObjects] = useState<ICalculationQuery[]>();
  const { historyId: historyIdFromParams } = useParams<{ historyId?: string }>();
  const { load: loadHistory, called, result } = useHistoryQuery({
    fields: historyQueryFields,
    queryObject: {
      id: historyIdFromParams,
      skip: 0,
      take: 1,
    },
    lazy: true,
    fetchPolicy: 'network-only',
  });

  // sets the project id to a piece of state that will not go away unless
  // the user refreshes the app;
  useEffect(() => {
    if (projectId) {
      setProjectIdFromParams(projectId);
    }
  }, [projectId]);

  const {
    called: deadlinesCalled,
    load: loadDeadlines,
    result: deadlineResult,
    loading: deadlineLoading,
  } = useTriggerDeadlineQuery({
    queryObjects: deadlinesQueryObject,
    fields: deadlineQueryFields,
  });

  const {
    calculations,
    guid: newHistoryId,
    error: calculationError,
    load: loadCalculations,
    called: calculationsCalled,
  } = useCalculationQuery({
    queryObjects: calculationQueryObjects,
    lazy: true,
  });

  useEffect(() => {
    if (historyIdFromParams && loadHistory && !called && historyIdFromParams !== 'deadlines' && historyIdFromParams !== 'new') {
      loadHistory();
    }
  }, [loadHistory, called, historyIdFromParams]);

  const onReset = useCallback(() => {
    const id = getNextTriggerSelectionId(calculationState);
    setMatter(undefined);
    setDeadlinesQueryObject(undefined);
    setCalculationQueryObjects(undefined);
    setCalculationState({
      triggers: [{ id }], jurisdiction: undefined,
    });
    setJurisdiction(undefined);
    setCategories(undefined);
    setFilter(undefined);
    setProjectIdFromParams(undefined);
  }, [calculationState]);

  useEffect(() => {
    if (calculations.length) {
      setCalculationState(
        (current?: ICalculationState) => ({
          ...current,
          jurisdiction,
          historyId: current?.historyId || newHistoryId,
          triggers: current?.triggers
            ? mergeTriggerDeadlinesAndCalculations(current?.triggers, calculations) : [],
        }),
      );
    }
    // adding newHistoryId to the list of deps literally caused a bug
    // that royally screws up the calculations
    // the calculations and newHistoryId are generated by the same
    // graphql query and should be set at the same time
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [calculations]);

  // used to load items from history into the calculator
  useEffect(() => {
    if (result.items.length && !jurisdiction && !matter && historyIdFromParams) {
      const historyItem = result.items[0];
      setJurisdiction(historyItem.jurisdiction);
      setMatter(historyItem.matter);
      filevineState.orgId = historyItem.matter?.filevineOrgId
        ? +historyItem.matter.filevineOrgId
        : undefined;
      setCalculationState(toCalculationState(historyItem));
      setResponseServiceMethods(getSelectedResponseServiceMethodsFromHistory(historyItem));
    }
  }, [historyIdFromParams, jurisdiction, matter, result.items]);

  // when the deadlines change we need to update the
  // deadlines and re -do the calculation query objects
  useEffect(() => {
    if (deadlineResult?.length) {
      const deadlineMap = deadlineResult.reduce(
        (
          map: { [key: number]: ITriggerDeadline[] },
          results: { queryId: number, items?: ITriggerDeadline[] },
        ) => (results.items?.length
          ? Object.assign(map, { [results.queryId]: results.items })
          : map),
        {},
      );

      // it should go without saying that doing this
      // is going to lose all the calculations
      const updatedCalculationState: ICalculationState = ({
        ...calculationState,
        applicableServiceMethodIds: toCombinedDistinctServiceMethodIds(deadlineResult.map(
          (x: { items?: ITriggerDeadline[] }) => x.items || [],
        )),
        triggers: calculationState?.triggers?.map(
          (tcs: ITriggerCalculationState) => (tcs.toBeRemoved ? tcs : ({
            ...tcs,
            deadlines: tcs.deadlines?.length
              ? tcs.deadlines
              : getDeadlineCalcStates(deadlineMap, tcs.id),
          }))) || [],
      });
      setCalculationState(updatedCalculationState);
      const selectedServiceMethods = !responseServiceMethods?.length
        ? undefined
        : responseServiceMethods.filter(
          (sm: IServiceMethod) => updatedCalculationState.applicableServiceMethodIds?.includes(sm.id || ''),
        );
      setResponseServiceMethods(selectedServiceMethods);
      if (jurisdiction?.id) {
        const calcQueries = toCalculationQueries(
          jurisdiction.id,
          updatedCalculationState,
          selectedServiceMethods,
        );
        setCalculationQueryObjects(calcQueries);
      }
    }

    // no i don't want to handle response service method, jurisdiction, or trigger changes
    // here changes here
    // i already have a event handler created for those
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deadlineResult]);

  useEffect(() => {
    if (calculationQueryObjects?.length && !calculationsCalled) {
      loadCalculations();
    }
  }, [calculationQueryObjects, calculationsCalled, loadCalculations]);

  const onMatterUpdate = (m?: IMatter) => {
    setMatter(m);
  };

  const persistToLocalStorage = useCallback(() => {
    localStorage.setItem('calculatorState', JSON.stringify({
      matter,
      jurisdiction,
      responseServiceMethods,
      calculationState,
    }));
  }, [calculationState, jurisdiction, matter, responseServiceMethods]);

  // this logic is for restoring the state of the calculator if the user
  // has pressed the connect to filevine button
  const stateString = localStorage.getItem('calculatorState');
  if (stateString) {
    const calculatorState = JSON.parse(stateString);
    setMatter(calculatorState.matter);
    setJurisdiction(calculatorState.jurisdiction);
    setResponseServiceMethods(calculatorState.responseServiceMethods);
    setCalculationState(calculatorState.calculationState);
    localStorage.removeItem('calculatorState');
  }

  // this mostly handles changes to the date and service method. These changes will trigger
  // re-calculation but should not trigger a fetch for fresh deadlines(deadlines ids
  // are sent to the calculation query API later)
  const onTriggerDateOrServiceMethodChange = useCallback(
    (updatedTriggerCalculationStates?: ITriggerCalculationState[]) => {
      if (updatedTriggerCalculationStates) {
        const updatedCalculationState : ICalculationState = calculationState && {
          ...calculationState,
          // if this project has been previously saved to filevine we have to make it dirty
          isDirtySinceLastFilevineSync: calculationState.isSavedToFilevine,
          triggers: updatedTriggerCalculationStates,
        };
        setCalculationState(updatedCalculationState);

        if (jurisdiction?.id) {
          const calcQueries = toCalculationQueries(
            jurisdiction.id,
            updatedCalculationState,
            responseServiceMethods,
          );
          setCalculationQueryObjects(calcQueries);
        }
      }
    }, [calculationState, jurisdiction, responseServiceMethods],
  );

  const onResponseServiceMethodsChanged = useCallback(
    (
      calcState: ICalculationState,
      serviceMethods?: IServiceMethod[],
    ): ICalculationState => {
      const updatedCalcState = {
        ...calcState,
        isDirtySinceLastFilevineSync: true,
        triggers: modifyStateWithServiceSelectedServiceMethods(
          serviceMethods,
          calcState.triggers,
        ),
      };

      setCalculationState(updatedCalcState);

      setResponseServiceMethods(serviceMethods);
      if (jurisdiction?.id) {
        setCalculationQueryObjects(
          toCalculationQueries(
            jurisdiction.id,
            calcState,
            serviceMethods,
          ),
        );
      }

      return updatedCalcState;
    }, [jurisdiction],
  );

  const onJurisdictionChange = (calcState: ICalculationState, j?: IJurisdiction) => {
    setJurisdiction(j);
    setResponseServiceMethods(undefined);
    const updatedCalcState = {
      ...calcState,
      applicableServiceMethodIds: undefined,
      triggers: [
        ...(calcState.triggers
          ?.filter((tcs: ITriggerCalculationState) => tcs.isSavedToFilevine)
          .map((tcs: ITriggerCalculationState) => markTriggerForRemoval(tcs)) || []),
        { id: getNextTriggerSelectionId(calcState) },
      ],
    };
    // calling this method will update the calculation state
    onResponseServiceMethodsChanged(updatedCalcState, undefined);
  };

  // updating the deadline query object should trigger fetch of fresh deadlines
  // and a recalculation
  const onTriggerChange = useCallback((index: number, trigger?: ITrigger) => {
    const updatedCalculationState: ICalculationState = calculationState
      && onResponseServiceMethodsChanged({
        ...calculationState,
        isDirtySinceLastFilevineSync: calculationState.isSavedToFilevine || undefined,
        triggers: calculationState?.triggers?.map(
          (tcs: ITriggerCalculationState, i: number) => (index !== i ? tcs : ({
            id: tcs.id,
            trigger,
            serviceMethodId: undefined,
            triggerDate: tcs.triggerDate,
          })),
        ),
      });

    if (jurisdiction?.id) {
      const calcQueries = toCalculationQueries(
        jurisdiction.id,
        updatedCalculationState,
        undefined,
      );
      setCalculationQueryObjects(calcQueries);
    }

    const updatedDeadlineQueryObjects = updatedCalculationState?.triggers?.map(
      (tcs: ITriggerCalculationState): { id: number, query: ITriggerDeadlineQuery } => ({
        id: tcs.id,
        query: {
          triggerIds: getAllTriggerIdsForDeadlines(tcs.trigger || {}),
          blockedDeadlineIds: [
            ...(jurisdiction?.deadlineInheritanceBlocks?.map(
              (b: IDeadlineInheritanceBlock) => b.deadlineId) || []),
            ...(tcs.trigger?.deadlineInheritanceBlocks || []),
          ],
          skip: 0,
          take: 100,
        },
      })) || [];
    setDeadlinesQueryObject(updatedDeadlineQueryObjects);
  }, [calculationState, jurisdiction, onResponseServiceMethodsChanged]);

  const onTriggerServiceMethodChange = useCallback((index: number, id?: string) => {
    onTriggerDateOrServiceMethodChange(calculationState?.triggers?.map(
      (tcs: ITriggerCalculationState, i: number) => (index !== i ? tcs : ({
        ...tcs,
        serviceMethodId: id,
      }))));
  }, [calculationState, onTriggerDateOrServiceMethodChange]);

  const onDateChange = useCallback((index: number, date?: Date) => {
    if (calculationState?.triggers) {
      onTriggerDateOrServiceMethodChange(calculationState?.triggers?.map(
        (tcs: ITriggerCalculationState, i: number): ITriggerCalculationState => (index !== i
          ? tcs
          : ({
            ...tcs,
            triggerDate: date,
          }))));
    }
  }, [calculationState, onTriggerDateOrServiceMethodChange]);

  const onAddTriggerClicked = useCallback(() => {
    setCalculationState(
      (current: ICalculationState) => ({
        ...current,
        triggers: [
          ...(current?.triggers || []),
          {
            id: getNextTriggerSelectionId(current),
          },
        ],
      }),
    );
  }, []);

  // marks every saved deadline as to be removed for a specified trigger
  const onDeleteTriggerSelection = useCallback((index: number) => {
    setCalculationState(
      (current: ICalculationState) => ({
        ...current,
        applicableServiceMethodIds: undefined,
        // if the trigger was previously saved to filevine then we will mark it as toBeRemoved
        // if the trigger has not been saved to filevine then we will just remove from array
        triggers: current?.triggers?.[index].isSavedToFilevine
          // if it has been previously saved to filevine we need to mark all service
          // method deadlines as to be removed
          ? current.triggers.map(
            (
              tcs: ITriggerCalculationState,
              i: number,
            ) => (i !== index ? tcs : markTriggerForRemoval(tcs)),
          )
          : current?.triggers?.filter((_, i: number) => index !== i) || [],
        isDirtySinceLastFilevineSync: current?.isSavedToFilevine || undefined,
      }),
    );
    setDeadlinesQueryObject(
      (
        current?: { id: number, query: ITriggerDeadlineQuery }[],
      ) => current?.filter((_, i: number) => i !== index),
    );
    setCalculationQueryObjects(
      (current?: ICalculationQuery[]) => current?.filter((_, i: number) => i !== index),
    );
    setResponseServiceMethods(undefined);
  }, []);

  const onServiceMethodDeadlineClick = useCallback((
    deadlineId: string,
    serviceMethodId: string,
    isSelected: boolean,
    triggerIndex: number,
  ) => {
    setCalculationState(
      (current: ICalculationState) => ({
        ...current,
        isDirtySinceLastFilevineSync: current.isSavedToFilevine,
        triggers: current?.triggers?.map(
          (
            tcs: ITriggerCalculationState,
            index: number,
          ) => (triggerIndex !== index ? tcs : ({
            ...tcs,
            deadlines: modifySelectionOfServiceMethodDeadline(
              tcs.deadlines || [],
              deadlineId,
              serviceMethodId,
              isSelected,
            ),
          }))

        ) || [],
      }),
    );
  }, []);

  // when a deadline is clicked on the calculations screen to
  // enable or disable a deadline is being exported
  const onDeadlineClick = useCallback((
    deadlineId: string,
    isSelected: boolean,
    triggerIndex: number,
  ) => {
    setCalculationState(
      (current: ICalculationState) => ({
        ...current,
        isDirtySinceLastFilevineSync: current.isSavedToFilevine,
        triggers: current?.triggers?.map(
          (
            tcs: ITriggerCalculationState,
            index: number,
          ) => (triggerIndex !== index ? tcs : ({
            ...tcs,
            deadlines: tcs.deadlines?.map(
                (dcs: IDeadlineCalculationState) => (dcs.deadline.id !== deadlineId ? dcs : ({
                  ...dcs,
                  isSelected,
                  toBeRemoved: !!dcs.filevineDeadlineId && !isSelected,
                  serviceMethods: dcs.serviceMethods?.map(
                  (smcs: IServiceMethodCalculationState) => ({
                    ...smcs,
                    toBeRemoved: !!smcs.filevineDeadlineId && !isSelected,
                  })),
                }))
              ),
          }))
        ) || [],
      }),
    );
  }, []);

  const onHistorySave = (history?: IHistory, isFvSyncComplete?: boolean) => {
    if (history?.id) {
      setMatter((current?: IMatter) => ({
        ...current,
        id: history.matter?.id,
      }));
      setCalculationState((current: ICalculationState) => ({
        ...current,
        historyId: history.id,
        isDirtySinceLastFilevineSync:
          isFvSyncComplete ? false : current.isDirtySinceLastFilevineSync,
        isSavedToFilevine: isFvSyncComplete ? true : current.isSavedToFilevine,
        triggers: current?.triggers
          ?.filter((tcs: ITriggerCalculationState) => (isFvSyncComplete ? !tcs.toBeRemoved : true))
          ?.map(
          (tcs: ITriggerCalculationState) => {
            const firstDeadlineCalculationId = tcs.deadlines?.length
              ? tcs.deadlines[0].calculationId
              : undefined;
            // since the history triggers and their deadlines don't come back in the same order we
            // have in our state we have to identify the right piece of trigger state based on the
            // calculation id of a deadline matching the id of the history trigger deadline object
            const historyTrigger = history.historyTriggers.find(
              (ht: IHistoryTrigger) => ht.historyTriggerDeadlines.find(
                (htd: IHistoryTriggerDeadline) => firstDeadlineCalculationId === htd.id,
              ),
            );
            return ({
              ...tcs,
              historyTriggerId: historyTrigger?.id,
              isSavedToFilevine: isFvSyncComplete ? true : tcs.isSavedToFilevine,
              deadlines: tcs.deadlines
                // the only reason this should happen is if i got a 403 error while
                // trying to save to filevine in which case there will be a diff between
                // this history record and the calc state
                ?.filter((dcs: IDeadlineCalculationState) => !!historyTrigger
                  ?.historyTriggerDeadlines.find(
                    (htd: IHistoryTriggerDeadline) => dcs.calculationId === htd.id))
                ?.map(
                (dcs: IDeadlineCalculationState) => {
                  const deadline = historyTrigger?.historyTriggerDeadlines.find(
                    (htd: IHistoryTriggerDeadline) => dcs.calculationId === htd.id);

                  return ({
                    ...dcs,
                    historyTriggerDeadlineId: deadline?.id,
                    isNewCalculation: deadline?.isNewCalculation,
                    filevineDeadlineId:
                      !isFvSyncComplete ? dcs.filevineDeadlineId : deadline?.filevineDeadlineId,
                    serviceMethods: dcs.serviceMethods
                      ?.filter((
                        smcs: IServiceMethodCalculationState,
                      ) => (isFvSyncComplete ? !smcs.toBeRemoved : true)
                      // the only reason this should happen is if i got a 403 error while
                      // trying to save to filevine in which case there will be a diff between
                      // this history record and the calc state
                    && deadline?.historyTriggerDeadlineServiceMethods.find(
                          (htdsm: IHistoryTriggerDeadlineServiceMethod) => (
                            htdsm.id === smcs.calculationId))
                    )
                      ?.map(
                      (smcs: IServiceMethodCalculationState) => {
                        const serviceMethod = deadline?.historyTriggerDeadlineServiceMethods.find(
                          (htdsm: IHistoryTriggerDeadlineServiceMethod) => (
                            htdsm.id === smcs.calculationId));
                        return ({
                          ...smcs,
                          isNewCalculation: serviceMethod?.isNewCalculation,
                          filevineDeadlineId: !isFvSyncComplete
                            ? dcs.filevineDeadlineId : serviceMethod?.filevineDeadlineId,
                          historyTriggerDeadlineServiceMethodId: serviceMethod?.id,
                        });
                      }

                    ),
                  });
                }
                ),
            });
          }
        ),
      }));
    }
  };

  const onLoadDeadlinesClick = useCallback(() => {
    setTriggerHeadline(undefined);
    if (!deadlinesCalled) {
      loadDeadlines();
    }
  }, [deadlinesCalled, loadDeadlines]);

  const onTriggerLabelChange = (triggerIndex: number, triggerLabel?: string) => {
    if (!triggerLabel || triggerLabel.length <= 80) {
      setCalculationState(
        (current: ICalculationState) => ({
          ...current,
          isDirtySinceLastFilevineSync: current.isSavedToFilevine,
          triggers: current?.triggers?.map(
          (
            tcs: ITriggerCalculationState,
            index: number,
          ) => (triggerIndex !== index ? tcs : ({
            ...tcs,
            triggerLabel,
          })
          )) || [],
        }),
      );
    }
  };

  return (
    <div className="flex flex-row justify-center">
      <div className="flex-1">
        <Switch>
          <Route
            exact
            path={['/calculator/deadlines', '/calculator/deadlines/:historyId']}
            render={() => {
              if (jurisdiction || (historyIdFromParams && historyIdFromParams !== 'deadlines')) {
                return (
                  <>
                    <DeadlinesHeader
                      matter={matter}
                      jurisdictionId={jurisdiction?.id || ''}
                      categories={categories}
                      onFilterChanged={setFilter}
                      onServiceMethodChange={(
                        sm?: IServiceMethod[],
                      ) => onResponseServiceMethodsChanged(calculationState, sm)}
                      triggerHeadline={triggerHeadline}
                      onCategoriesChanged={setCategories}
                      selectedServiceMethods={responseServiceMethods}
                      onMatterUpdate={onMatterUpdate}
                      calculationState={calculationState}
                      persistToLocalStorage={persistToLocalStorage}
                      onHistorySaved={onHistorySave}
                      filevineProjectIdFromUrl={`${projectIdFromParams || ''}`}
                    />
                    <div className={`${css.content} ${css.deadlineList}`}>
                      {!!calculationState?.triggers
                        ?.reduce((
                          current: number,
                          x: ITriggerCalculationState,
                        ) => current + (x.deadlines?.length || 0), 0)
                        && (
                        <DeadlineList
                          triggerCalculationStates={calculationState.triggers}
                          disabled={deadlineLoading}
                          filter={filter}
                          categories={categories}
                          onHeadlineChange={setTriggerHeadline}
                          onServiceMethodDeadlineClick={onServiceMethodDeadlineClick}
                          onDeadlineClick={onDeadlineClick}
                        />
                        )}
                    </div>
                    {!!calculationError && (
                      <Snackbar
                        message="There was an error calculating the deadlines"
                      />
                    )}
                  </>
                );
              }
              return <Redirect to="/calculator" />;
            }}
          />
          <Route
            exact
            path={['/calculator/:historyId', '/calculator']}
          >
            <Intake
              triggerCalculationStates={calculationState?.triggers}
              onTriggerChange={onTriggerChange}
              onServiceMethodChange={onTriggerServiceMethodChange}
              onDateChange={onDateChange}
              onAddClicked={onAddTriggerClicked}
              onJurisdictionChange={(
                j?: IJurisdiction,
              ) => onJurisdictionChange(calculationState, j)}
              loadDeadlines={onLoadDeadlinesClick}
              jurisdiction={jurisdiction}
              onDeleteTriggerSelection={onDeleteTriggerSelection}
              onReset={onReset}
              onTriggerLabelChange={onTriggerLabelChange}
            />
          </Route>
        </Switch>
      </div>
    </div>
  );
};

export default Calculator;

import React, {
  FC, useState,
} from 'react';
import { IOrganization } from '@models';
import useOrganizationQuery from '@hooks/Identity/Organization/useOrganizationQuery';
import AutoComplete from './AutoComplete';

interface IProps {
  onSelect: (org?: IOrganization) => void,
  value?: IOrganization;
  isDisabled?: boolean;
  isAutoSelect?: boolean;
  label?: string;
  isRequired?: boolean;
  isSticky?: boolean;
}

const fields = [
  'id',
  'description',
];

const pageSize = 25;

const OrganizationAutoComplete: FC<IProps> = (
  {
    onSelect,
    value,
    isDisabled,
    isAutoSelect,
    label,
    isRequired,
    isSticky,
  }: IProps,
) => {
  const [filter, setFilter] = useState<string>();
  const [take, setTake] = useState(pageSize);

  const {
    result: { items, hasMore }, loading, called, load,
  } = useOrganizationQuery({
    queryObject: {
      skip: 0,
      take,
      description: filter,
    },
    fields,
    lazy: true,
    fetchPolicy: 'network-only',
  });

  const onLoadMore = () => {
    setTake((current: number) => current + pageSize);
  };

  return (
    <AutoComplete<IOrganization>
      label={label || 'Select Organization'}
      value={value}
      isDisabled={isDisabled}
      isAutoSelect={isAutoSelect}
      captionFields={['path']}
      descriptionField="description"
      cypressLabel="organization"
      autoComplete="organization"
      called={called}
      load={load}
      hasMore={hasMore}
      items={items}
      onSelect={onSelect}
      onLoadMore={onLoadMore}
      loading={loading}
      onFilterChange={(f?: string) => setFilter(f)}
      isRequired={isRequired}
      isSticky={isSticky}
    />
  );
};

export default OrganizationAutoComplete;

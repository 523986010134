import React, { FC } from 'react';
import Table, { ITableState } from '@components/Table/Table';
import IRole from '@models/Identity/Role/IRole';
import { IRowMenuOption } from '@components/Table/Menu/RowMenu';
import { ITableMenuOption } from '@components/Table/Menu/TableMenu';

interface IRoleTableProps {
  roles?: IRole[];
  onTableStateChanged: (state: ITableState) => void;
  hasMore?: boolean;
  onRoleEdit?: (role: IRole) => void;
  onFabClick?: VoidFunction;
  onDelete?: (toBeDeleted: IRole[]) => void;
  loading: boolean;
  onRoleSelected?: (selectedItem: IRole, isSelected: boolean) => void;
  selected?: IRole[];
}

const RoleTable: FC<IRoleTableProps> = (
  {
    roles,
    onTableStateChanged,
    hasMore,
    onRoleEdit,
    onFabClick,
    onDelete,
    loading,
    onRoleSelected,
    selected,
  },
) => {
  const rowOptions: IRowMenuOption[] = [
    {
      key: 'edit',
      text: 'Edit',
      onClick: (role: IRole) => {
        if (onRoleEdit) {
          onRoleEdit(role);
        }
      },
    },
  ];

  const tableMenuOptions: ITableMenuOption[] = [
    {
      key: 'deleteSelected',
      text: 'Delete Selected',
      onClick: (selectedItems?: IRole[]) => {
        if (selectedItems && onDelete) {
          onDelete(selectedItems);
        }
      },
    },
  ];

  return (
    <Table
      dataSource={roles}
      rowMenuOptions={onRoleEdit ? rowOptions : []}
      minHeight={400}
      maxHeight={700}
      onStateChange={onTableStateChanged}
      hasMore={hasMore}
      takeOptions={[100, 500]}
      isPagerVisible
      isFabVisible={!!onFabClick}
      isMultiSelect
      onFabClick={onFabClick}
      fabIcon="add"
      fabAriaLabel="Add New Role"
      tableMenuOptions={onDelete ? tableMenuOptions : undefined}
      loading={loading}
      onRowSelect={onRoleSelected}
      selected={selected}
      title={onFabClick && 'Roles'}
      fabText="Add Role"
    >
      {[
        {
          key: 'description',
          header: 'Description',
          field: 'description',
          isFilterable: true,
          isSortable: true,
        },
      ]}
    </Table>
  );
};

export default RoleTable;

import React, { FC, useState, useEffect } from 'react';
import Snackbar from '@fv-components/snackbar';
import useJurisdictionQuery from '@hooks/Jurisdiction/useJurisdictionQuery';
import { IServiceMethod, IServiceMethodGroup } from '@models';
import { MultiSelectDropDown } from '.';
import { IItemGroup } from './MultiSelectDropDown/MultiSelectDropDown';

export interface IServiceMethodValues {
  serviceMethods: IServiceMethod[];
  groups: IServiceMethodGroup[];
}

type CombinedServiceType = IServiceMethod | IServiceMethodGroup;

interface IServiceMethodEditorDropDownProps {
  onSelect: (selected: IServiceMethodValues) => void;
  jurisdictionId?: string;
  disabled?: boolean;
  autoFocus?: boolean;
  values?: IServiceMethodValues;
  lazy?: boolean;
  placeholder?: string;
  restrictToIds?: string[];
  onClose?: (selected: IServiceMethodValues) => void;
}

const getServiceMethodsNotInGroups = (
  serviceMethods?: IServiceMethod[],
  groups?: IServiceMethodGroup[],
) => serviceMethods?.filter(
    (x: IServiceMethod) => !groups?.find(
      (g: IServiceMethodGroup) => g.serviceMethods?.find(
        (sm: IServiceMethod) => sm.id === x.id))) || [];

const ServiceMethodEditorDropDown: FC<IServiceMethodEditorDropDownProps> = (
  {
    onSelect,
    jurisdictionId,
    autoFocus,
    values,
    lazy,
    placeholder,
    restrictToIds,
    onClose,
  }: IServiceMethodEditorDropDownProps,
) => {
  const {
    result,
    error,
    load,
    called,
  } = useJurisdictionQuery({
    queryObject: {
      ids: jurisdictionId ? [jurisdictionId] : undefined,
      skip: 0,
      take: 1,
    },
    fields: [
      'serviceMethods { id description }',
      'serviceMethodGroups { id description serviceMethods { id description } }',
    ],
    lazy,
    fetchPolicy: 'network-only',
  });
  const [dropDownOptions, setDropDownOptions] = useState<IItemGroup[]>([]);

  useEffect(() => {
    const serviceMethods = getServiceMethodsNotInGroups(
      result.items[0]?.serviceMethods,
      values?.groups,
    );

    const groupOptions = restrictToIds ? result.items[0]?.serviceMethodGroups?.filter(
      (g: IServiceMethodGroup) => restrictToIds.includes(g.id || ''),
    ) : result.items[0]?.serviceMethodGroups;

    const individualOptions = restrictToIds ? serviceMethods.filter(
      (g: IServiceMethodGroup) => restrictToIds.includes(g.id || ''),
    ) : serviceMethods;

    const options = [
      ...(groupOptions?.length ? [{
        header: 'Service Method Groups',
        items: groupOptions,
      }] : []),
      ...(individualOptions.length ? [({
        header: 'Service Methods',
        // the point of this crazy looking filter is to not display service methods
        // that are part of a selected service method group
        items: individualOptions,
      })] : []),
    ];
    setDropDownOptions(options);
  }, [result.items, values, restrictToIds]);

  const onFocus = () => {
    if (!called) {
      load();
    }
  };

  const onChange = (selected: CombinedServiceType[]) => {
    onSelect({
      serviceMethods: selected.filter(
        (x: CombinedServiceType) => (x as IServiceMethodGroup).serviceMethods === undefined,
      ),
      groups: selected.filter(
        (x: CombinedServiceType) => (x as IServiceMethodGroup).serviceMethods !== undefined,
      ),
    });
  };

  const onMultiSelectClose = (selected: CombinedServiceType[]) => {
    if (onClose) {
      onClose({
        serviceMethods: selected.filter(
          (x: CombinedServiceType) => (x as IServiceMethodGroup).serviceMethods === undefined,
        ),
        groups: selected.filter(
          (x: CombinedServiceType) => (x as IServiceMethodGroup).serviceMethods !== undefined,
        ),
      });
    }
  };

  return (
    <>
      <MultiSelectDropDown
        display="description"
        label={placeholder || 'Service Method'}
        onChange={onChange}
        items={dropDownOptions}
        autoFocus={autoFocus}
        values={[
          ...(values?.groups || []),
          ...(getServiceMethodsNotInGroups(values?.serviceMethods, values?.groups)),
        ]}
        onFocus={onFocus}
        onClose={onMultiSelectClose}
      />
      {!!error && (
      <Snackbar
        message="There was an error fetching the service methods for this jurisdiction"
      />
      )}
    </>
  );
};

export default ServiceMethodEditorDropDown;

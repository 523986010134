import React, { CSSProperties, FC } from 'react';

interface IFilevineLogoProps {
  width?: number;
  className?: string;
  style?: CSSProperties;
}
// i left these styles in here on purose so it would all be in 1 file.
// there are plenty of stles in the svg string anyway
const FilevineLogo: FC<IFilevineLogoProps> = (
  {
    width,
    className,
    style,
  }: IFilevineLogoProps,
) => (
  <div
    style={style || {
      width,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      paddingLeft: width ? width / 2 : 0,
      paddingRight: width ? width / 2 : 0,
    }}
    data-cy="logo"
    // this is perfectly safe as we completely control the string
    // eslint-disable-next-line react/no-danger
    dangerouslySetInnerHTML={{ __html: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 44.8 31.88"><defs></defs><g id="Layer_2" data-name="Layer 2"><g id="Layer_1-2" data-name="Layer 1"><path class="${className || 'fill-current'}" d="M22.4,0,0,22.4l3.94,4L22.4,7.89,40.86,26.35l3.94-4ZM5.57,28l3.87,3.88,13-13,13,13L39.24,28,22.4,11.14Z"/></g></g></svg>` }}
  />
);

export default FilevineLogo;

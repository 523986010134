import React, {
  FC,
} from 'react';

interface IViewColumnProps {
  children: JSX.Element | (JSX.Element | boolean)[] | boolean;
}

const ViewColumn: FC<IViewColumnProps> = ({
  children,
}) => (
  <div className="flex md:flex-1 md:flex-col flex-row gap-1 flex-wrap truncate">
    {children}
  </div>
);

export default ViewColumn;

import {
  useMutation, ApolloError, FetchResult, gql, MutationFunctionOptions, ApolloClient,
} from '@apollo/client';
import curry from 'lodash.curry';
import { handleSaveResult } from '@libs/apollo';
import {
  IJurisdiction,
  IDeadline,
  CalendarType,
  Interval,
  RoundingDirection,
  ICategory,
  IServiceMethod,
  IServiceMethodGroup,
  ITriggerDeadline,
} from '@models';

type UseDeadlineSaveHook = () => IUseDeadlineSaveHookResult;

export interface IUseDeadlineSaveHookResult {
  result: {
    error?: ApolloError;
    loading: boolean;
    called: boolean;
  };
  saveDeadline: (
    description: string,
    shortDescription: string,
    calendar: CalendarType,
    interval: Interval,
    duration: number,
    specReference: string,
    jurisdiction: IJurisdiction,
    roundingDirection?: RoundingDirection,
    refLink?: string,
    triggerDeadlines?: ITriggerDeadline[],
    id?: string,
    extensionCalendar?: CalendarType,
    extensionInterval?: Interval,
    extensionDuration?: number,
    categories?: ICategory[],
    extensionRoundingDirection?: RoundingDirection,
    secondExtensionCalendar?: CalendarType,
    secondExtensionInterval?: Interval,
    secondExtensionDuration?: number,
    secondExtensionRoundingDirection?: RoundingDirection,
    thirdExtensionCalendar?: CalendarType,
    thirdExtensionInterval?: Interval,
    thirdExtensionDuration?: number,
    thirdExtensionRoundingDirection?: RoundingDirection,
    fourthExtensionCalendar?: CalendarType,
    fourthExtensionInterval?: Interval,
    fourthExtensionDuration?: number,
    fourthExtensionRoundingDirection?: RoundingDirection,
    fifthExtensionCalendar?: CalendarType,
    fifthExtensionInterval?: Interval,
    fifthExtensionDuration?: number,
    fifthExtensionRoundingDirection?: RoundingDirection,
  ) => Promise<FetchResult<IDeadline>>;
}

interface ISaveDeadlineResult {
  saveDeadline: IDeadline
}

const SAVE_DEADLINE = gql`
    mutation DeadlineMutation($deadline: DeadlineInputType!) {
      saveDeadline(item: $deadline) {
         id
      }
    }
  `;

const save = (
  saveFunc: (
    options?: MutationFunctionOptions<IDeadline>,
  ) => Promise<FetchResult<ISaveDeadlineResult>>,
  client: ApolloClient<object>,
  description: string,
  shortDescription: string,
  calendar: CalendarType,
  interval: Interval,
  duration: number,
  specReference: string,
  jurisdiction: IJurisdiction,
  roundingDirection?: RoundingDirection,
  refLink?: string,
  triggerDeadlines?: ITriggerDeadline[],
  id?: string,
  extensionCalendar?: CalendarType,
  extensionInterval?: Interval,
  extensionDuration?: number,
  categories?: ICategory[],
  extensionRoundingDirection?: RoundingDirection,
  secondExtensionCalendar?: CalendarType,
  secondExtensionInterval?: Interval,
  secondExtensionDuration?: number,
  secondExtensionRoundingDirection?: RoundingDirection,
  thirdExtensionCalendar?: CalendarType,
  thirdExtensionInterval?: Interval,
  thirdExtensionDuration?: number,
  thirdExtensionRoundingDirection?: RoundingDirection,
  fourthExtensionCalendar?: CalendarType,
  fourthExtensionInterval?: Interval,
  fourthExtensionDuration?: number,
  fourthExtensionRoundingDirection?: RoundingDirection,
  fifthExtensionCalendar?: CalendarType,
  fifthExtensionInterval?: Interval,
  fifthExtensionDuration?: number,
  fifthExtensionRoundingDirection?: RoundingDirection,
) : Promise<FetchResult<IDeadline>> => {
  const deadline: IDeadline = {
    description,
    shortDescription,
    calendar,
    interval,
    duration,
    refLink,
    specReference,
    triggerDeadlines,
    roundingDirection,
    id,
    extensionCalendar,
    extensionInterval,
    extensionDuration,
    categories,
    extensionRoundingDirection,
    jurisdictionId: jurisdiction.id,
    secondExtensionCalendar,
    secondExtensionInterval,
    secondExtensionDuration,
    secondExtensionRoundingDirection,
    thirdExtensionCalendar,
    thirdExtensionInterval,
    thirdExtensionDuration,
    thirdExtensionRoundingDirection,
    fourthExtensionCalendar,
    fourthExtensionInterval,
    fourthExtensionDuration,
    fourthExtensionRoundingDirection,
    fifthExtensionCalendar,
    fifthExtensionInterval,
    fifthExtensionDuration,
    fifthExtensionRoundingDirection,
  };

  return saveFunc({
    variables: {
      deadline: {
        ...deadline,
        jurisdiction: undefined,
        categories: categories?.map((c: ICategory) => c.id),
        triggerDeadlines: triggerDeadlines?.map((td: ITriggerDeadline) => ({
          id: td.id,
          triggerId: td.triggerId,
          deadlineId: td.deadlineId,
          serviceMethods: td.serviceMethods?.map((x: IServiceMethod) => x.id),
          serviceMethodGroups: td.serviceMethodGroups?.map((x: IServiceMethodGroup) => x.id),
        })),
      },
    },
  }).then((result: FetchResult<ISaveDeadlineResult>) => ({
    ...result,
    data: {
      ...handleSaveResult<IDeadline>(
        result.data?.saveDeadline || '',
        deadline,
        client,
        'deadlines',
      ),
    },
  }));
};

const useDeadlineSave: UseDeadlineSaveHook = () => {
  const [saveFunc, {
    error, loading, called, client,
  }] = useMutation<ISaveDeadlineResult>(SAVE_DEADLINE);

  return {
    result: {
      error, loading, called,
    },
    saveDeadline: curry(save)(saveFunc)(client),
  };
};

export default useDeadlineSave;

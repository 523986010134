import { ApolloError, FetchPolicy } from '@apollo/client';
import IHoliday from '@models/Jurisdiction/Holidays/IHoliday';
import IHolidayQuery from '@models/Jurisdiction/Holidays/IHolidayQuery';
import { IDeleteHookResult, useTimelyDelete } from '@hooks/useTimelyDelete';
import useTimelyQuery from '@hooks/useTimelyQuery';
import useHolidaySave, { IHolidaySaveHookResult } from './useHolidaySave';

type HolidayQueryHook = (
  props: IHolidayQueryHookProps
) => IHolidayQueryHookResult;

export interface IHolidayQueryHookProps {
  queryObject: IHolidayQuery;
  fields: string[];
  lazy?: boolean;
  fetchPolicy?: FetchPolicy;
}

interface IHolidayQueryHookResult {
  load: VoidFunction;
  loading: boolean;
  error?: ApolloError;
  result: { hasMore: boolean; items: IHoliday[] };
  save: IHolidaySaveHookResult;
  delete: IDeleteHookResult;
}

const useHolidayQuery: HolidayQueryHook = (
  {
    queryObject, fields, lazy, fetchPolicy,
  }: IHolidayQueryHookProps,
) => {
  const {
    loading, error, result, queryString, called, load,
  } = useTimelyQuery<IHoliday>({
    fields,
    collection: 'holidays',
    variables: { queryObject },
    queryInputType: 'HolidayQueryInputType',
    lazy,
    fetchPolicy,
  });

  return {
    load,
    called,
    loading,
    error,
    result,
    save: useHolidaySave({ queryString, queryObject }),
    delete: useTimelyDelete({ capitalizedType: 'Holiday' }),
  };
};

export default useHolidayQuery;

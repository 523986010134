import React, {
  FC, useState, useRef, useEffect,
} from 'react';
import TextField, { Input } from '@fv-components/text-field';
import MaterialIcon from '@fv-components/material-icon';
import dayjs, { Dayjs } from 'dayjs';
import MenuSurface, { Corner } from '@fv-components/menu-surface';
import TimeSelector from './TimeSelector/TimeSelector';

const css = require('./TimePicker.module.scss');

// I'm hard coding formats here because as far as I could tell
// the material UI version of this component didn't support
// changing formats
const timeFormat = 'hh:mm A';
const dateFormat = 'YYYY-MM-DD ';
const timeRegEx = RegExp('^ *(1[0-2]|0[1-9]):[0-5][0-9] *(A|P)(M) *$');

interface ITimePickerProps {
  onTimeChange: (date: Date) => void;
  value?: Date;
  disabled?: boolean;
}

const TimePicker: FC<ITimePickerProps> = (
  {
    onTimeChange,
    value,
    disabled,
  }: ITimePickerProps,
) => {
  const [date, setDate] = useState(value ? dayjs(value) : value);
  const [text, setText] = useState(date ? date.format(timeFormat) : '');
  const pickerAnchor = useRef() as React.MutableRefObject<HTMLInputElement>;
  const [isVisible, setIsVisible] = useState(false);
  const textFieldRef = useRef<any>(null);

  const onPickerIconClicked = () => {
    setIsVisible(!isVisible);
  };

  const onTextChanged = (newText: string) => {
    setText(newText);
    if (timeRegEx.test(newText)) {
      const dateString = `${dayjs().format(dateFormat)} ${newText}`;
      const newDate = dayjs(dateString, { format: `${dateFormat} ${timeFormat}` });
      if (newDate.isValid()) {
        setDate(newDate);
        onTimeChange(newDate.toDate());
      }
    }
  };

  useEffect(() => {
    if (value) {
      const newDate = dayjs(value);
      setDate(newDate);
      setText(newDate.format(timeFormat));
    }
  }, [value]);

  const closePicker = () => {
    setIsVisible(false);
  };

  const onSelectedTime = (d: Dayjs) => {
    setDate(d);
    closePicker();
    setText(d.format(timeFormat));
    onTimeChange(d.toDate());
    textFieldRef.current.textFieldElement.current.getElementsByTagName('i')[0].focus();
  };

  return (
    <div ref={pickerAnchor}>
      <TextField
        label="Time"
        className={css.textField}
        outlined
        onTrailingIconSelect={onPickerIconClicked}
        disabled={disabled}
        ref={textFieldRef}
        trailingIcon={(
          <MaterialIcon
            className={css.clearIconOutline}
            role="button"
            icon="access_time"
            disabled={disabled}
          />
        )}
      >
        <Input
          className={css.input}
          onChange={(e: React.FormEvent<HTMLInputElement>) => onTextChanged(e.currentTarget.value)}
          value={text}
          disabled={disabled}
          autoComplete="false"
        />
      </TextField>
      <MenuSurface
        open={isVisible && !disabled}
        anchorCorner={Corner.TOP_LEFT}
        onClose={() => closePicker()}
        anchorElement={pickerAnchor.current}
      >
        {isVisible && !disabled
          && (
            <TimeSelector
              onSelectedTime={onSelectedTime}
              date={date || dayjs()}
            />
          )}
      </MenuSurface>
    </div>
  );
};

export default TimePicker;

import React, { FC, useState, useEffect } from 'react';
import Table, { ITableState } from '@components/Table/Table';
import useServiceMethodQuery, { IServiceMethodQueryHookProps } from '@hooks/ServiceMethod/useServiceMethodQuery';
import { IJurisdiction, IServiceMethod } from '@models';

interface IProps {
  jurisdiction?: IJurisdiction;
  onRowSelect?: (serviceMethod: IServiceMethod, isSelected: boolean) => void;
  onSelectAll: (isSelected: boolean) => void;
}

const InheritedServiceMethodTable: FC<IProps> = ({
  jurisdiction,
  onRowSelect,
  onSelectAll,
}) => {
  const serviceMethodGraphQlQueryFields = [
    'id',
    'description',
    'jurisdiction { address hasChildren description id }',
    'jurisdictionId',
  ];

  const [
    requestObject,
    setRequestObject] = useState<IServiceMethodQueryHookProps>({
      queryObject: { skip: 0, take: 100, includeInherited: true },
      fields: serviceMethodGraphQlQueryFields,
      fetchPolicy: 'network-only',
    });

  useEffect(() => {
    setRequestObject((ro: IServiceMethodQueryHookProps) => ({
      ...ro,
      queryObject: {
        ...ro.queryObject,
        jurisdictionId: jurisdiction?.parent?.id,
      },
    }));
  }, [jurisdiction]);

  const apolloWrapper = useServiceMethodQuery(requestObject);

  const tableStateChanged = (state: ITableState) => {
    setRequestObject((current: IServiceMethodQueryHookProps) => ({
      ...current,
      queryObject: {
        ...state,
        ...state.filter,
        jurisdictionId: jurisdiction?.parent?.id,
        filter: undefined,
      },
    }));
  };

  const selected = apolloWrapper.result.items
    .filter((sm: IServiceMethod) => !jurisdiction?.serviceMethodInheritanceBlocks?.find(
      (block: string) => sm.id === block)) || [];

  return (
    <Table
      dataSource={jurisdiction?.parent?.id ? apolloWrapper.result.items : []}
      minHeight={350}
      maxHeight={350}
      onStateChange={tableStateChanged}
      hasMore={apolloWrapper.result.hasMore}
      takeOptions={[100, 500]}
      isPagerVisible
      isMultiSelect
      onRowSelect={onRowSelect}
      loading={jurisdiction && apolloWrapper.loading}
      selected={selected}
      onSelectAll={onSelectAll}
    >
      {[
        {
          key: 'description',
          header: 'Description',
          field: 'description',
          isFilterable: true,
          isSortable: true,
        },
        {
          key: 'jurisdiction.description',
          header: 'Jurisdiction',
          field: 'jurisdiction.description',
        },
      ]}
    </Table>
  );
};

export default InheritedServiceMethodTable;

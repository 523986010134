import { useEffect, useState } from 'react';
import {
  ApolloError, gql, useLazyQuery, FetchPolicy,
} from '@apollo/client';
import { ITriggerDeadlineQuery, ITriggerDeadline } from '@models';

type TriggerDeadlineQueryHook = (
  props: ITriggerDeadlineQueryHookProps
) => IDeadlineQueryHookResult;

export interface ITriggerDeadlineQueryHookProps {
  queryObjects?: { id: number, query: ITriggerDeadlineQuery }[];
  fields: string[];
  lazy?: boolean;
  fetchPolicy?: FetchPolicy;
}

interface IDeadlineQueryResponse {
  [key: string]: { hasMore: boolean; items: ITriggerDeadline[] };
}

interface IResult {
  hasMore?: boolean;
  items?: ITriggerDeadline[];
  queryId: number;
}

interface IDeadlineQueryHookResult {
  loading: boolean;
  error?: ApolloError;
  result?: IResult[];
  refetch: VoidFunction | undefined;
  called: boolean;
  load: VoidFunction;
}

const defaultQueryString = 'query($q: TriggerDeadlineQueryInputType!) { triggerDeadlines(queryObject: $q) { hasMore } }';

const useTriggerDeadlineQuery: TriggerDeadlineQueryHook = (
  {
    queryObjects,
    fields,
    lazy,
    fetchPolicy,
  }: ITriggerDeadlineQueryHookProps,
) => {
  const [variables, setVariables] = useState<{ [key: string]: ITriggerDeadlineQuery }>();
  const [result, setResult] = useState<IResult[]>();
  const [query, setQuery] = useState(gql(defaultQueryString));

  useEffect(() => {
    if (queryObjects?.length) {
      const vars = queryObjects
        .reduce((previous: any, current: { id: number, query: ITriggerDeadlineQuery }) => {
          // i absolutely need this
          // eslint-disable-next-line no-param-reassign
          previous[`q${current.id}`] = {
            ...current.query,
          };
          return previous;
        }, {});
      setVariables(vars);
      setQuery(gql`query(${Object.keys(vars).map(
        (key:string) => `$${key}: TriggerDeadlineQueryInputType!,`,
      )}) {
     ${Object.keys(vars).map(
    (key: string) => (
      `triggerDeadlines${key.substring(1)}: triggerDeadlines( queryObject: $${key}) { hasMore items { ${fields.join(',')} } }`),
  )}
  }`);
    } else {
      setQuery(gql(defaultQueryString));
      setVariables(undefined);
    }
    setResult(undefined);
  }, [fields, queryObjects]);

  const [load, {
    loading, error, data, called, refetch,
  }] = useLazyQuery<IDeadlineQueryResponse>(query, {
    variables,
    fetchPolicy,
  });

  useEffect(() => {
    if (!lazy && load && !called && variables) {
      load();
    }
  }, [called, lazy, load, variables]);

  useEffect(() => {
    if (data && !loading && !error && variables) {
      const res: IResult[] = Object.keys(data)
        .map((key: string) => ({
          items: data[key].items,
          hasMore: data[key].hasMore,
          // we are removing the `triggerDeadlines` from the result set key
          queryId: +key.substring(16),
        }));
      setResult(res);
    } else {
      setResult(undefined);
    }
  }, [data, loading, error, variables]);

  return {
    loading,
    error,
    result,
    refetch,
    called,
    load,
  };
};

export default useTriggerDeadlineQuery;

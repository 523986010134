import {
  useMutation, ApolloError, FetchResult, DocumentNode, gql,
} from '@apollo/client';
import IHolidayQuery from '@models/Jurisdiction/Holidays/IHolidayQuery';
import IHoliday from '@models/Jurisdiction/Holidays/IHoliday';
import { handleSaveResult } from '@libs/apollo';
import IJurisdiction from '@models/Jurisdiction/IJurisdiction';
import dayjs from 'dayjs';

type HolidaySaveHook = (
  props: IHolidaySaveHookProps
) => IHolidaySaveHookResult;

interface IHolidaySaveHookProps {
  queryObject?: IHolidayQuery;
  queryString?: DocumentNode;
}

export interface IHolidaySaveHookResult {
  result: {
    error?: ApolloError;
    loading: boolean;
    called: boolean;
  };
  saveHoliday: (
    jurisdiction: IJurisdiction,
    description: string,
    date: Date,
    id?: string
  ) => Promise<FetchResult<IHoliday>>;
}

interface ISaveHolidayResult {
  saveHoliday: IHoliday;
}

const useHolidaySave: HolidaySaveHook = (
  { queryString, queryObject }: IHolidaySaveHookProps,
) => {
  const SAVE_GQL = gql`
    mutation HolidayMutation($holiday: HolidayInputType!) {
      saveHoliday(item: $holiday) {
         id
      }
    }
  `;

  const [saveFunc, {
    error, loading, called, client,
  }] = useMutation<ISaveHolidayResult>(SAVE_GQL);

  return {
    result: {
      error,
      loading,
      called,
    },
    saveHoliday: (
      jurisdiction: IJurisdiction,
      description: string,
      date: Date,
      id?: string,
    ) => {
      const holiday = {
        id,
        jurisdiction,
        description,
        date: dayjs(date).format('YYYY-MM-DD'),
      };

      return saveFunc({
        variables: {
          holiday: {
            ...holiday,
            jurisdictionId: holiday.jurisdiction.id,
            jurisdiction: undefined,
          },
        },
      }).then((result: FetchResult<ISaveHolidayResult>) => ({
        ...result,
        data: {
          ...handleSaveResult<IHoliday>(
            result.data?.saveHoliday || '',
            {
              ...holiday,
              jurisdictionId: holiday.jurisdiction.id,
              date: new Date(holiday.date),
            },
            client,
            'holidays',
            queryObject,
            queryString,
          ),
        },
      }));
    },
  };
};

export default useHolidaySave;

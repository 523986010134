import { ApolloError, FetchPolicy } from '@apollo/client';
import useTimelyQuery from '@hooks/useTimelyQuery';
import { IProjectQuery, IProject } from '@models';

type ProjectQueryHook = (
  props: IProjectQueryHookProps
) => IProjectQueryHookResult;

export interface IProjectQueryHookProps {
  queryObject: IProjectQuery;
  lazy?: boolean;
  fetchPolicy?: FetchPolicy;
}

interface IProjectQueryHookResult {
  loading: boolean;
  error?: ApolloError;
  result: { hasMore: boolean; items: IProject[] };
  load: VoidFunction,
  called?: boolean,
  refetch?: VoidFunction,
}

const fields = [
  'matterDescription',
  'id',
  'historyId',
  'triggerDescription',
  'jurisdictionDescription',
  'serviceMethodDescription',
  'triggerDate',
  'filevineSyncTimestamp',
  'exportedBy',
  'filevineProjectId',
  'filevineOrgId',
];

const useProjectQuery: ProjectQueryHook = (
  {
    queryObject, lazy, fetchPolicy,
  }: IProjectQueryHookProps,
) => {
  const {
    loading, error, result, load, called, refetch,
  } = useTimelyQuery<IProject>({
    queryInputType: 'ProjectQueryInputType',
    variables: { queryObject },
    collection: 'projects',
    lazy,
    fields,
    fetchPolicy,
  });

  return {
    refetch,
    load,
    called,
    loading,
    error,
    result,
  };
};

export default useProjectQuery;

import { useEffect, useState } from 'react';
import { useProxy } from 'valtio';
import { filevineState, IUserOrg } from '@libs/auth';
import { getConfirmationDialogService } from '@hooks/useConfirmationDialog';
import { getSnackBarService } from '@hooks/useSnackBar';
import { useLocation, useHistory } from 'react-router-dom';
import { Auth } from 'aws-amplify';

interface IParamsState {
  orgId?: string | null;
  projectId?: string | null;
}

interface IHandleFilevinePropsResult {
  projectId?: number,
}

const useHandleFilevineProps = () : IHandleFilevinePropsResult => {
  const { userId, userOrgs } = useProxy(filevineState);
  const confirmationDialog = getConfirmationDialogService();
  const snackBar = getSnackBarService();
  const history = useHistory();

  const [{ orgId, projectId }, setParamsState] = useState<IParamsState>({});
  const location = useLocation();

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    setParamsState({
      orgId: searchParams.get('orgId'),
      projectId: searchParams.get('projectId'),
    });
  }, [location]);

  // The confirmation dialog won't be available later till the page is rendered
  // if the query params come in and the user isn't connected to filevine yet the user
  // is state is going to get flagged and will pick up the flag when logged in;
  useEffect(() => {
    if (confirmationDialog.isInitialized && snackBar.isInitialized) {
      if (orgId && !Number.isNaN(+orgId)) {
        if (!userId) {
          localStorage.setItem('filevineUrlParams', JSON.stringify({ orgId, projectId }));
          getConfirmationDialogService().showDialog(
            'We need to connect Timely to Filevine. Do you mind if I redirect you to Filevine to authenticate?',
            'Connect Timely to Filevine',
            'Connect',
          ).then((confirmed: boolean) => {
            if (confirmed) {
              Auth.federatedSignIn();
            } else {
              snackBar.showSnackBar('We were not able to find your Filevine project.');
              history.push('/');
            }
          });
        } else {
          const isOrgInList = !!userOrgs.find((x: IUserOrg) => x.orgId === +orgId);
          if (isOrgInList) {
            filevineState.orgId = +orgId;
          } else {
            snackBar.showSnackBar('We were not able to find your Filevine project.');
          }
        }
      }
    }
  }, [
    confirmationDialog,
    userId,
    userOrgs,
    history,
    orgId,
    projectId,
    snackBar,
  ]);

  return {
    projectId: userId
      && projectId
      && !Number.isNaN(+projectId) ? +projectId : undefined,
  };
};

export default useHandleFilevineProps;

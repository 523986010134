import { ApolloError, FetchPolicy } from '@apollo/client';
import IOrganizationQuery from '@models/Identity/Organization/IOrganizationQuery';
import IOrganization from '@models/Identity/Organization/IOrganization';
import { IDeleteHookResult, useTimelyDelete } from '@hooks/useTimelyDelete';
import useTimelyQuery from '@hooks/useTimelyQuery';
import useOrganizationSave, { IOrganizationSaveHookResult } from './useOrganizationSave';

type OrganizationQueryHook = (
  props: IOrganizationQueryHookProps
) => IOrganizationQueryHookResult;

export interface IOrganizationQueryHookProps {
  queryObject: IOrganizationQuery;
  fields: string[];
  lazy?: boolean;
  fetchPolicy?: FetchPolicy;
}

interface IOrganizationQueryHookResult {
  loading: boolean;
  error?: ApolloError;
  result: { hasMore: boolean; items: IOrganization[] };
  save: IOrganizationSaveHookResult;
  delete: IDeleteHookResult;
  load: VoidFunction;
  called?: boolean;
}

const useOrganizationQuery: OrganizationQueryHook = (
  {
    queryObject, fields, lazy, fetchPolicy,
  }: IOrganizationQueryHookProps,
) => {
  const {
    loading, error, result, queryString, load, called,
  } = useTimelyQuery<IOrganization>({
    variables: { queryObject },
    fields,
    collection: 'organizations',
    queryInputType: 'OrganizationQueryInputType',
    lazy,
    fetchPolicy,
  });

  return {
    called,
    load,
    loading,
    error,
    result,
    save: useOrganizationSave({ queryString, queryObject }),
    delete: useTimelyDelete({ capitalizedType: 'Organization' }),
  };
};

export default useOrganizationQuery;

import { useEffect, useState } from 'react';
import {
  ApolloError, gql, useLazyQuery, FetchPolicy,
} from '@apollo/client';
import IDeadlineQuery from '@models/Deadlines/IDeadlineQuery';
import IDeadline from '@models/Deadlines/IDeadline';
import { IDeleteHookResult } from '@libs/apollo';
import { useTimelyDelete } from '@hooks/useTimelyDelete';
import useDeadlineSave, { IUseDeadlineSaveHookResult } from './useDeadlineSave';

type DeadlineQueryHook = (
  props: IDeadlineQueryHookProps
) => IDeadlineQueryHookResult;

export interface IDeadlineQueryHookProps {
  queryObjects: IDeadlineQuery[];
  fields: string[];
  lazy?: boolean;
  fetchPolicy?: FetchPolicy;
}

interface IDeadlineQueryResponse {
  [key: string]: { hasMore: boolean; items: IDeadline[] };
}

interface IDeadlineQueryHookResult {
  loading: boolean;
  error?: ApolloError;
  result: { hasMore: boolean[]; items: IDeadline[][] };
  save: IUseDeadlineSaveHookResult;
  delete: IDeleteHookResult;
  refetch: VoidFunction | undefined;
  load: VoidFunction;
  called: boolean;
}

const useDeadlineQuery: DeadlineQueryHook = ({
  queryObjects,
  fields,
  lazy,
  fetchPolicy,
}: IDeadlineQueryHookProps) => {
  const [items, setItems] = useState<IDeadline[][]>([]);
  const [hasMore, setHasMore] = useState<boolean[]>([]);
  const GET_DEADLINES = gql`query(${queryObjects.map(
    (query: IDeadlineQuery, index: number) => `$q${index}: DeadlineQueryInputType!,`,
  )}) {
     ${queryObjects.map(
    (query: IDeadlineQuery, index: number) => (
      `deadlines${index}: deadlines( queryObject: $q${index}) { hasMore items { ${fields.join(',')} } }`),
  )}
  }`;

  const variables = (queryObjects as IDeadlineQuery[])
    .reduce((previous: any, current: any, index: number) => {
      // i absolutely need this
      // eslint-disable-next-line no-param-reassign
      previous[`q${index}`] = current;
      return previous;
    }, {});

  const [load, {
    loading, error, data, called, refetch,
  }] = useLazyQuery<IDeadlineQueryResponse>(GET_DEADLINES, {
    variables,
    fetchPolicy,
  });

  useEffect(() => {
    if (!lazy && load && !called) {
      load();
    }
  }, [called, lazy, load]);

  useEffect(() => {
    if (data && !loading && !error) {
      const hm = Object.keys(data || {})
        .map((key: string) => (data ? data[key].hasMore : false));

      const i = Object.keys(data || {})
        .map((key: string) => (data ? data[key].items : []));
      setHasMore(hm);
      setItems(i);
    }
  }, [data, loading, error]);

  return {
    loading,
    error,
    result: {
      hasMore,
      items,
    },
    save: useDeadlineSave(),
    delete: useTimelyDelete({ capitalizedType: 'Deadline' }),
    refetch,
    load,
    called,
  };
};

export default useDeadlineQuery;

import React, { FC } from 'react';
import Table, { ITableState } from '@components/Table/Table';
import { IRowMenuOption } from '@components/Table/Menu/RowMenu';
import { ITableMenuOption } from '@components/Table/Menu/TableMenu';
import IOrganization from '@models/Identity/Organization/IOrganization';

interface IOrganizationTableProps {
  organizations?: IOrganization[];
  onTableStateChanged: (state: ITableState) => void;
  hasMore?: boolean;
  onOrganizationEdit: (organization: IOrganization) => void;
  onFabClick: VoidFunction;
  onDelete: (toBeDeleted: IOrganization[]) => void;
  loading: boolean;
}

const OrganizationTable: FC<IOrganizationTableProps> = (
  {
    organizations,
    onTableStateChanged,
    hasMore,
    onOrganizationEdit,
    onFabClick,
    onDelete,
    loading,
  },
) => {
  const rowOptions: IRowMenuOption[] = [
    {
      key: 'edit',
      text: 'Edit',
      onClick: (organization: IOrganization) => {
        onOrganizationEdit(organization);
      },
    },
  ];

  const tableMenuOptions: ITableMenuOption[] = [
    {
      key: 'deleteSelected',
      text: 'Delete Selected',
      onClick: (selectedItems?: IOrganization[]) => {
        if (selectedItems) {
          onDelete(selectedItems);
        }
      },
    },
  ];

  return (
    <Table
      dataSource={organizations}
      rowMenuOptions={rowOptions}
      minHeight={400}
      maxHeight={700}
      onStateChange={onTableStateChanged}
      hasMore={hasMore}
      takeOptions={[100, 500]}
      isPagerVisible
      isFabVisible
      isMultiSelect
      onFabClick={onFabClick}
      fabIcon="add"
      fabAriaLabel="Add New Organization"
      tableMenuOptions={tableMenuOptions}
      loading={loading}
      fabText="Add Organization"
      title="Organizations"
    >
      {[
        {
          key: 'description',
          header: 'Description',
          field: 'description',
          isFilterable: true,
          isSortable: true,
        },
      ]}
    </Table>
  );
};

export default OrganizationTable;

import React, { FC } from 'react';
import { ICalendarCellProps } from './CalendarCell/CalendarCell';

const dsCss = require('../DateSelector.module.css');

interface ICalendarRowProps {
  row?: number;
  children: React.ReactElement<ICalendarCellProps>;
}

const CalendarRow: FC<ICalendarRowProps> = ({ row, children }: ICalendarRowProps) => (
  <div key={row} className={[dsCss.row, dsCss.grow].join(' ')}>
    {children}
  </div>
);

export default CalendarRow;

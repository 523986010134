import { ApolloError, FetchPolicy } from '@apollo/client';
import ITrigger from '@models/Deadlines/Triggers/ITrigger';
import ITriggerQuery from '@models/Deadlines/Triggers/ITriggerQuery';
import { IDeleteHookResult, useTimelyDelete } from '@hooks/useTimelyDelete';
import useTimelyQuery from '@hooks/useTimelyQuery';
import useTriggerSave, { ITriggerSaveHookResult } from './useTriggerSave';

type TriggerQueryHook = (
  props: ITriggerQueryHookProps
) => ITriggerQueryHookResult;

export interface ITriggerQueryHookProps {
  queryObject: ITriggerQuery;
  fields: string[];
  lazy?: boolean;
  fetchPolicy?: FetchPolicy,
}

interface ITriggerQueryHookResult {
  loading: boolean;
  called: boolean;
  error?: ApolloError;
  result: { hasMore: boolean; items: ITrigger[] };
  save: ITriggerSaveHookResult;
  delete: IDeleteHookResult;
  load: VoidFunction;
  refetch: VoidFunction | undefined;
}

const useTriggerQuery: TriggerQueryHook = (
  {
    queryObject, fields, lazy, fetchPolicy,
  }: ITriggerQueryHookProps,
) => {
  const {
    called, loading, load, error, result, refetch,
  } = useTimelyQuery<ITrigger>({
    lazy,
    fields,
    variables: { queryObject },
    collection: 'triggers',
    queryInputType: 'TriggerQueryInputType',
    fetchPolicy,
  });

  return {
    called,
    loading,
    error,
    result,
    save: useTriggerSave(),
    delete: useTimelyDelete({ capitalizedType: 'Trigger' }),
    load,
    refetch,
  };
};

export default useTriggerQuery;

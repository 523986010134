import React, { FC, useState } from 'react';
import TextField, { Input, HelperText } from '@fv-components/text-field';
import MaterialIcon from '@fv-components/material-icon';

const css = require('./PasswordField.module.scss');

interface ISetPasswordState {
  password?: string;
  isValid: boolean;
  passwordDirty: boolean;
  showPassword: boolean;
}

interface IPasswordFieldProps {
  label?: string;
  validate?: boolean;
  isValid?: boolean;
  validationMessage?: string;
  onPasswordChange: (password: string, isValid: boolean) => void;
  disabled?: boolean;
  onEnterPress?: VoidFunction;
  allowAutoFill: boolean;
  autoFocus?: boolean;
  isRequired: boolean;
}

const PasswordField: FC<IPasswordFieldProps> = (
  {
    onPasswordChange,
    label,
    isValid,
    validationMessage,
    validate,
    disabled,
    onEnterPress,
    allowAutoFill,
    autoFocus,
    isRequired,
  }: IPasswordFieldProps,
) => {
  const [state, setState] = useState<ISetPasswordState>({
    isValid: false,
    passwordDirty: false,
    showPassword: false,
  });

  const validatePassword = (password: string): boolean => {
    if (password.match(/^(?=.{8})(?=.*[^a-zA-Z])/g)) {
      return true;
    }
    if (!isRequired && !password?.length) {
      return true;
    }
    return false;
  };

  const onPasswordInputChange = (event: React.FormEvent<HTMLInputElement>) => {
    const password = event.currentTarget.value;
    const isPasswordValid = validate ? validatePassword(password) : true;
    if (isPasswordValid) {
      if (isValid !== undefined) {
        onPasswordChange(password, isValid);
      } else {
        onPasswordChange(password, true);
      }
    } else {
      onPasswordChange(password, false);
    }
    setState({
      ...state,
      password,
      passwordDirty: true,
      isValid: isPasswordValid,
    });
  };

  const onShowPasswordButtonClicked = () => {
    setState({
      ...state,
      showPassword: !state.showPassword,
    });
  };

  const onKeyUp = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.keyCode === 13 && onEnterPress) {
      onEnterPress();
    }
  };

  return (
    <TextField
      label={label || 'Password'}
      className={css.textField}
      outlined
      onTrailingIconSelect={onShowPasswordButtonClicked}
      trailingIcon={(
        <MaterialIcon
          className={css.icon}
          role="button"
          icon={state.showPassword ? 'visibility_off' : 'visibility'}
        />
          )}
      helperText={(
        <HelperText validation>
          { validationMessage || 'Password must be at least 8 characters and have at least one non-letter'}
        </HelperText>
          )}
      data-cy="password-container"
    >
      <Input
        className={css.input}
        disabled={disabled}
        value={state.password}
        onChange={onPasswordInputChange}
        isValid={!state.passwordDirty || (isValid !== undefined
          ? isValid : state.isValid)}
        type={state.showPassword ? 'text' : 'password'}
        onKeyUp={onKeyUp}
        autoComplete={allowAutoFill ? 'on' : 'off'}
        autoFocus={autoFocus}
        data-cy="password"
      />
    </TextField>

  );
};

export default PasswordField;

import React, { FC, useState, memo } from 'react';
import Checkbox from '@fv-components/checkbox';
import IColumnProps from '../Column';
import TableMenu, { ITableMenuOption } from '../Menu/TableMenu';
import HeaderCell, { SortDirection } from './HeaderCell/HeaderCell';

interface IHeaderProps {
  columnProps: IColumnProps[];
  tableMenuOptions?: ITableMenuOption[];
  isCheckboxVisible?: boolean;
  onSelect?: (checked: boolean) => void;
  selectedItems?: any[];
  onHeaderStateChanged: (state: IHeaderState) => void;
  disabled?: boolean;
  isSelectAllChecked?: boolean;
  headerState?: IHeaderState;
}

export interface IHeaderState {
  filter?: any;
  sortBy?: string;
  sortDirection?: SortDirection;
}

const Header: FC<IHeaderProps> = memo(({
  columnProps,
  tableMenuOptions,
  isCheckboxVisible,
  onSelect,
  selectedItems,
  onHeaderStateChanged,
  disabled,
  isSelectAllChecked,
  headerState: hs,
}: IHeaderProps) => {
  const [headerColumnProps, setHeaderColumnProps] = useState<IColumnProps[]>(
    !hs ? columnProps : columnProps.map((cp: IColumnProps) => ({
      ...cp,
      sortDirection: (cp.sortFilterField || cp.field) !== hs.sortBy ? undefined : hs.sortDirection,
      filter: (hs?.filter && hs?.filter[cp.sortFilterField || cp.field]),
    })),
  );
  const [headerState, setHeaderState] = useState<IHeaderState | undefined>(hs);

  const onSort = (sortBy?: string, sortDirection?: SortDirection) => {
    const updatedCols = headerColumnProps.map((x) => (
      {
        ...x,
        sortDirection: (x.sortFilterField || x.field) !== sortBy ? undefined : sortDirection,
      }
    ));
    const newHeaderState = {
      sortDirection,
      sortBy,
      filter: headerState?.filter,
    };
    setHeaderState(newHeaderState);
    onHeaderStateChanged(newHeaderState);
    setHeaderColumnProps(updatedCols);
  };

  const onFilter = (field: string, value: any, columnPropIndex: number) => {
    const newHeaderState = { ...headerState, filter: { ...headerState?.filter, [field]: value } };
    setHeaderState(newHeaderState);
    onHeaderStateChanged(newHeaderState);

    const updatedCols = headerColumnProps.map((columnProp: IColumnProps, index: number) => {
      if (index !== columnPropIndex) {
        return columnProp;
      }
      return {
        ...columnProp,
        filter: value,
      };
    });
    setHeaderColumnProps(updatedCols);
  };

  const onClearSortFilter = () => {
    const updatedCols = headerColumnProps.map((columnProp: IColumnProps) => (
      {
        ...columnProp,
        sortDirection: undefined,
        sortBy: undefined,
        filter: undefined,
      }
    ));
    const newHeaderState = {
      sortDirection: undefined,
      sortBy: undefined,
      filter: undefined,
    };
    setHeaderState(newHeaderState);
    onHeaderStateChanged(newHeaderState);
    setHeaderColumnProps(updatedCols);
  };

  const resetTable: ITableMenuOption = {
    key: 'a-unique-key-for-the-default-clear-menu-item-🦄', // just needed to be somehting unique
    text: 'Clear Filters / Sort',
    icon: 'clear',
    onClick: onClearSortFilter,
  };

  const headerOptions = [
    ...(tableMenuOptions || []),
    ...(headerColumnProps.find((cp: IColumnProps) => cp.isFilterable || cp.isSortable)
      ? [resetTable]
      : []),
  ];

  return (
    <div
      className="bg-white z-2 flex flex-row items-center top-0 sticky border-solid border-0 border-b border-gray-mild"
    >
      {isCheckboxVisible
        && (
          <Checkbox
            disabled={disabled}
            checked={isSelectAllChecked}
            onChange={(evt) => {
              if (onSelect) {
                onSelect(evt.target.checked);
              }
            }}
          />
        )}
      <div className={`flex-1 grid gap-1 grid-cols-${headerColumnProps.length}`}>
        {headerColumnProps.map((col: IColumnProps, columnIndex: number) => (
          <HeaderCell
            key={col.key}
            onFilter={(field: string, value: any) => onFilter(field, value, columnIndex)}
            onSort={onSort}
            header={col.header}
            field={col.sortFilterField || col.field}
            sortDirection={col.sortDirection}
            isFilterable={col.isFilterable}
            isSortable={col.isSortable}
            disabled={disabled}
            filter={col.filter}
          />
        ))}
      </div>
      <TableMenu
        options={headerOptions}
        selectedItems={selectedItems}
        disabled={disabled}
      />
    </div>
  );
});

export default Header;

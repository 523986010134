import React, { FC } from 'react';
import Button from '@fv-components/button';
import { Dayjs } from 'dayjs';

const css = require('./CalendarCell.module.css');

export interface ICalendarCellProps {
  row?: number;
  col?: number;
  today: Dayjs;
  onClick: (date: Dayjs) => void;
  minDate?: Dayjs;
  maxDate?: Dayjs;
  gridValues?: (Dayjs | undefined)[][];
}

const CalendarCell: FC<ICalendarCellProps> = (
  {
    row,
    col,
    today,
    onClick,
    minDate,
    maxDate,
    gridValues,
  }: ICalendarCellProps,
) => {
  const date = row !== undefined && col !== undefined ? gridValues?.[row]?.[col] : undefined;
  const isToday = !!date && today.isSame(date, 'date');
  const isBeforeMinDate = date && minDate ? (date < minDate) : false;
  const isAfterMaxDate = date && maxDate ? (date > maxDate) : false;

  const onCellClick = () => {
    if (date) {
      onClick(date);
    }
  };

  return (
    <Button
      disabled={!date || isBeforeMinDate || isAfterMaxDate}
      onClick={onCellClick}
      outlined={isToday}
      className={css.button}
      role="button"
      aria-label={`Select ${isToday ? 'Today ' : ''}${date?.format('MMMM')} ${date?.date} ${date?.format('YYYY')}`}
    >
      <div>{date?.date() || ''}</div>
    </Button>
  );
};

export default CalendarCell;

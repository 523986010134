import {
  ApolloClient, ApolloQueryResult, gql, NormalizedCacheObject,
} from '@apollo/client';
import dayjs from 'dayjs';

const getGuid = (client: ApolloClient<NormalizedCacheObject>): Promise<string> => client
  .query<{ guid: string }>({ query: gql('query { guid }'), fetchPolicy: 'network-only' })
  .then((x: ApolloQueryResult<{ guid: string; }>) => x.data.guid);

const getIsExpired = (expirationDate?: Date) => !(dayjs(expirationDate).format('YYYY-MM-DD') < dayjs().format('YYYY-MM-DD'));

// what is the point of exporting a method in a library
// where the importer can change the name of the method
// eslint-disable-next-line import/prefer-default-export
export { getGuid, getIsExpired };

import {
  useMutation, ApolloError, FetchResult, gql, DocumentNode,
} from '@apollo/client';
import IInvite from '@models/Identity/Invite/IInvite';
import IRole from '@models/Identity/Role/IRole';
import IOrganization from '@models/Identity/Organization/IOrganization';
import { handleSaveResult } from '@libs/apollo';
import IInviteQuery from '@models/Identity/Invite/IInviteQuery';
import dayjs from 'dayjs';

type InviteSaveHook = (props: IInviteSendHookProps) => IInviteSaveHookResult;

interface IInviteSaveResponse {
  saveInvite: IInvite;
}

interface IInviteSendHookProps {
  queryObject?: IInviteQuery;
  queryString?: DocumentNode;
}

export interface IInviteSaveHookResult {
  result: {
    error?: ApolloError;
    loading: boolean;
    called: boolean;
  };
  saveInvite: (
    id: string,
    emailAddress: string,
    roles: IRole[],
    organization?: IOrganization,
    expirationDate?: Date,
  ) => Promise<FetchResult<IInvite>>;
}

const useInviteSave: InviteSaveHook = ({ queryObject, queryString }) => {
  const SAVE_GQL = gql`
    mutation InviteMutation($invite: InviteInputType!) {
      saveInvite(item: $invite) {
         id
      }
    }
  `;

  const [saveFunc, {
    error, loading, called, client,
  }] = useMutation<IInviteSaveResponse>(SAVE_GQL);

  return {
    result: {
      error,
      loading,
      called,
    },
    saveInvite: (
      id: string,
      emailAddress: string,
      roles: IRole[],
      organization?: IOrganization,
      expirationDate?: Date,
    ) => {
      const invite = {
        id,
        emailAddress,
        organization: {
          id: organization?.id,
          description: organization?.description,
        },
        expirationDate,
        // i have to do this silly mapping to remove the __typename property
        roles: roles?.map((j: IRole) => (
          { id: j.id, description: j.description })),
      };
      return saveFunc({
        variables: {
          invite: {
            ...invite,
            expirationDate: dayjs(invite.expirationDate).format('YYYY-MM-DD'),
          },
        },
      }).then((result: FetchResult<IInviteSaveResponse>) => ({
        ...result,
        data: {
          ...handleSaveResult<IInvite>(
            result.data?.saveInvite || '',
            invite,
            client,
            'invites',
            queryObject,
            queryString,
          ),
        },
      }));
    },
  };
};

export default useInviteSave;

import React, { FC, useState } from 'react';
import Snackbar from '@fv-components/snackbar';
import { ITableState } from '@components/Table/Table';
import ConfirmationDialog from '@components/ConfirmationDialog/ConfirmationDialog';
import IPermission from '@models/Identity/Role/Permission/IPermission';
import usePermissionQuery, { IPermissionQueryHookProps } from '@hooks/Identity/Permissions/usePermissionQuery';
import PermissionAdminForm from './PermissionAdminForm/PermissionAdminForm';
import PermissionsTable from './PermissionsTable/PermissionsTable';

interface IPermissionAdminPageState {
  selected?: IPermission;
  requestObject: IPermissionQueryHookProps;
  isEditingMode: boolean;
  isDeleteConfirmationDialogVisible: boolean;
  toBeDeleted?: IPermission[];
}

const PermissionAdminPage: FC = () => {
  const graphQlQueryFields = [
    'id',
    'permissionObject',
    'permissionAction',
  ];

  const [state, setState] = useState<IPermissionAdminPageState>({
    requestObject: {
      queryObject: { skip: 0, take: 100 },
      fields: graphQlQueryFields,
      fetchPolicy: 'network-only',
    },
    isEditingMode: false,
    isDeleteConfirmationDialogVisible: false,
  });

  const apolloWrapper = usePermissionQuery(state.requestObject);

  const onDeleteConfirmationClose = (deleteConfirmed: boolean) => {
    if (deleteConfirmed) {
      if (state.toBeDeleted && state.toBeDeleted.length > 0) {
        apolloWrapper.delete.delete(
          ...state.toBeDeleted.filter(
            (x: IPermission) => x.id,
          ).map((x: IPermission) => x.id as string),
        );
      }
    }
    setState({
      ...state,
      isDeleteConfirmationDialogVisible: false,
      toBeDeleted: undefined,
    });
  };

  const onFabClick = () => {
    setState({
      ...state,
      selected: undefined,
      isEditingMode: true,
    });
  };

  const onFormClose = () => {
    setState({
      ...state,
      selected: undefined,
      isEditingMode: false,
    });
  };

  const onEdit = (permission: IPermission) => {
    setState({
      ...state,
      selected: {
        ...permission,
      },
      isEditingMode: true,
    });
  };

  const onDelete = (toBeDeleted: IPermission[]) => {
    setState({
      ...state,
      isDeleteConfirmationDialogVisible: true,
      toBeDeleted,
    });
  };

  const onTableStateChanged = (tableState: ITableState) => {
    const queryObject = {
      ...tableState,
      ...tableState.filter,
      filter: undefined,
    };
    setState({
      ...state,
      requestObject: {
        queryObject,
        fields: graphQlQueryFields,
      },
    });
  };

  const saveSuccessful = apolloWrapper.save.result.called
    && !apolloWrapper.save.result.loading
    && !apolloWrapper.save.result.error;

  const deleteSuccessful = apolloWrapper.delete.result.called
    && !apolloWrapper.delete.result.loading
    && !apolloWrapper.delete.result.error;

  const deleteError = apolloWrapper.delete.result.called
    && !apolloWrapper.delete.result.loading
    && apolloWrapper.delete.result.error;

  const saveError = apolloWrapper.save.result.called
    && !apolloWrapper.save.result.loading
    && apolloWrapper.save.result.error;

  return (
    <div className="flex flex-col gap-3">
      <div>
        <div className={state.isEditingMode ? 'hidden' : 'w-full'}>
          <PermissionsTable
            onTableStateChanged={onTableStateChanged}
            onEdit={onEdit}
            hasMore={apolloWrapper.result.hasMore}
            permissions={apolloWrapper.result.items}
            onFabClick={onFabClick}
            onDelete={onDelete}
            loading={apolloWrapper.loading || apolloWrapper.delete.result.loading}
          />
        </div>
        {state.isEditingMode
            && (
            <PermissionAdminForm
              onFormClose={onFormClose}
              permission={state.selected}
              save={apolloWrapper.save}
            />
            )}
      </div>
      <Snackbar open={saveSuccessful} message="Save successful" />
      <Snackbar open={deleteSuccessful} message="Delete successful" />
      <Snackbar open={!!deleteError} message="There was an error deleting the actions" />
      <Snackbar open={!!saveError} message="Error! Unable to save permission." />
      <ConfirmationDialog
        isOpen={state.isDeleteConfirmationDialogVisible}
        text="Do you really want to delete these permissions?"
        onClose={onDeleteConfirmationClose}
      />
    </div>
  );
};

export default PermissionAdminPage;

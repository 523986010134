import { ApolloError, FetchPolicy } from '@apollo/client';
import IFilingMethod from '@models/Jurisdiction/FilingMethods/IFilingMethod';
import IFilingMethodQuery from '@models/Jurisdiction/FilingMethods/IFilingMethodQuery';
import { IDeleteHookResult, useTimelyDelete } from '@hooks/useTimelyDelete';
import useTimelyQuery from '@hooks/useTimelyQuery';
import useFilingMethodSave, { IFilingMethodSaveHookResult } from './useFilingMethodSave';

type FilingMethodQueryHook = (
  props: IFilingMethodQueryHookProps
) => IFilingMethodQueryHookResult;

export interface IFilingMethodQueryHookProps {
  queryObject: IFilingMethodQuery;
  fields: string[];
  lazy?: boolean;
  fetchPolicy?: FetchPolicy;
}

interface IFilingMethodQueryHookResult {
  loading: boolean;
  error?: ApolloError;
  result: { hasMore: boolean; items: IFilingMethod[] };
  save: IFilingMethodSaveHookResult;
  delete: IDeleteHookResult;
  load: VoidFunction;
  called: boolean;
}

const useFilingMethodQuery: FilingMethodQueryHook = (
  {
    queryObject, fields, lazy, fetchPolicy,
  }: IFilingMethodQueryHookProps,
) => {
  const {
    loading, error, result, queryString, load, called,
  } = useTimelyQuery<IFilingMethod>({
    variables: { queryObject },
    collection: 'filingMethods',
    fields,
    queryInputType: 'FilingMethodQueryInputType',
    lazy,
    fetchPolicy,
  });

  return {
    called,
    load,
    loading,
    error,
    result,
    save: useFilingMethodSave({ queryString, queryObject }),
    delete: useTimelyDelete({ capitalizedType: 'FilingMethod' }),
  };
};

export default useFilingMethodQuery;

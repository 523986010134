import LinearProgress from '@material/react-linear-progress';
import './LinearProgress.scss';

/**
 * LinearProgress MCWR Component
 *
 * @version ./package.json
 */
export default LinearProgress;


import React, {
  FC, useCallback, useState, useEffect,
} from 'react';
import { ITriggerDeadline } from '@models';
import Table from '@components/Table/Table';

interface IProps {
  triggerDeadlines?: ITriggerDeadline[];
  jurisdictionDeadlineInheritanceBlocks?: string[];
  blockedIds?: string[]
  onChange: (blocked: string[]) => void;
}

const DeadlineInheritanceTable: FC<IProps> = ({
  triggerDeadlines,
  blockedIds,
  jurisdictionDeadlineInheritanceBlocks,
  onChange,
}) => {
  const [inheritedDeadlines, setInheritedDeadlines] = useState<ITriggerDeadline[]>();

  const onRowSelect = useCallback(
    (d: ITriggerDeadline, isSelected: boolean) => {
      if (!isSelected) {
        onChange([
          ...blockedIds || [],
          d.deadlineId || '',
        ]);
      } else {
        onChange((blockedIds || []).filter((id: string) => id !== d.deadlineId));
      }
    }, [blockedIds, onChange],
  );

  useEffect(() => {
    setInheritedDeadlines(triggerDeadlines?.filter(
      (td: ITriggerDeadline) => !jurisdictionDeadlineInheritanceBlocks?.includes(td.deadlineId || '')));
  }, [jurisdictionDeadlineInheritanceBlocks, triggerDeadlines]);

  return (
    <Table
      dataSource={inheritedDeadlines}
      isMultiSelect
      maxHeight={500}
      takeOptions={[100, 500]}
      onRowSelect={onRowSelect}
      title={<b>Inherited Deadlines</b>}
      selected={inheritedDeadlines?.filter((t: ITriggerDeadline) => !blockedIds?.includes(t.deadlineId || '')) || []}
      isPagerVisible
    >
      {[
        {
          key: 'deadline.description',
          header: 'Description',
          field: 'deadline.description',
        },
        {
          key: 'deadline.specReference',
          header: 'Reference',
          field: 'deadline.specReference',
        },
        {
          key: 'trigger.description',
          header: 'Trigger',
          field: 'trigger.description',
        },
        {
          key: 'trigger.jurisdiction.description',
          header: 'Jurisdiction',
          field: 'trigger.jurisdiction.description',
        },
      ]}
    </Table>
  );
};

export default DeadlineInheritanceTable;

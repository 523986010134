import Dialog, {
  DialogTitle,
  DialogContent,
  DialogFooter,
  DialogButton,
} from '@fv-components/dialog';
import React, { FC } from 'react';

export interface IConfirmationDialogProps {
  title?: string,
  text: string,
  okButtonText?: string;
  cancelButtonText?: string;
  onClose: (isConfirmed: boolean, context?: any) => void;
  isOpen?: boolean;
  context?: any;
  isCancelButtonHidden?: boolean
}

enum ConfirmationDialogAction {
  OK = 'OK',
  CANCEL = 'CANCEL'
}

const ConfirmationDialog: FC<IConfirmationDialogProps> = ({
  onClose,
  title,
  text,
  okButtonText,
  cancelButtonText,
  isOpen,
  context,
  isCancelButtonHidden,
}: IConfirmationDialogProps) => (
  <Dialog
    onClose={(action) => { onClose(action === ConfirmationDialogAction.OK, context); }}
    open={isOpen}
  >
    {title ? <DialogTitle>{title}</DialogTitle> : <></>}

    <DialogContent>
      <p>{text}</p>
    </DialogContent>
    <DialogFooter>
      <DialogButton action={ConfirmationDialogAction.OK} isDefault>{okButtonText || 'Ok'}</DialogButton>
      {!isCancelButtonHidden && <DialogButton action={ConfirmationDialogAction.CANCEL}>{cancelButtonText || 'Cancel'}</DialogButton>}
    </DialogFooter>
  </Dialog>
);

export default ConfirmationDialog;

import React, {
  FC, useRef, useEffect, useCallback,
} from 'react';

interface IProps {
  anchor: React.ReactElement
  children: React.ReactElement;
  isOpen?: boolean;
  onMenuClosed?: VoidFunction;
  menuContainerId?: string;
  isWidthRestricted?: boolean;
}

const BetterMenuSurface: FC<IProps> = ({
  children,
  isOpen,
  onMenuClosed,
  anchor,
  menuContainerId,
  isWidthRestricted,
}) => {
  const containerRef = useRef<HTMLDivElement>(null);

  const onDocumentClick = useCallback((event: MouseEvent) => {
    if (!containerRef.current?.contains(event.target as Node) && onMenuClosed && isOpen) {
      onMenuClosed();
    }
  }, [isOpen, onMenuClosed]);

  useEffect(() => {
    document.addEventListener('click', onDocumentClick, true);
    return () => {
      document.removeEventListener('click', onDocumentClick, true);
    };
  }, [onDocumentClick]);

  return (
    <div className="relative inline-block w-full" ref={containerRef}>
      {anchor}
      <div
        style={{ zIndex: 3 }}
        className={`${isOpen ? `absolute block ${isWidthRestricted ? 'overflow-x-hidden w-full' : ''}` : 'hidden'}`}
      >
        <div
          className="max-h-96 overflow-y-auto overflow-hidden shadow-md bg-white whitespace-nowrap"
          id={menuContainerId}
        >
          {children}
        </div>
        <div className="h-3" />
      </div>
    </div>
  );
};

export default BetterMenuSurface;

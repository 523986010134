import React, { FC } from 'react';
import {
  TriggerAutoComplete, ServiceMethodEditorDropDown, IServiceMethodValues,
} from '@components';
import { ITriggerDeadline, ITrigger } from '@models';

interface ITriggerDeadlineProps {
  jurisdictionId: string;
  value: ITriggerDeadline;
  onUpdate: (td: ITriggerDeadline) => void;
  blockedTriggerIds?: string[];
}

export interface ITriggerSelection {
  id: string;
  trigger?: ITrigger;
}

const TriggerDeadline: FC<ITriggerDeadlineProps> = ({
  value,
  onUpdate,
  jurisdictionId,
  blockedTriggerIds,
}: ITriggerDeadlineProps) => {
  const triggerSelected = (trigger?: ITrigger) => {
    if (trigger || (value.trigger?.id && !trigger)) {
      onUpdate({
        ...value,
        trigger,
        triggerId: trigger?.id,
      });
    }
  };

  const serviceMethodSelected = (smv: IServiceMethodValues) => {
    onUpdate({
      ...value,
      serviceMethodGroups: smv.groups,
      serviceMethods: smv.serviceMethods,
    });
  };

  return (
    <div className="flex-1 flex flex-row gap-3">
      <div className="flex-1">
        <TriggerAutoComplete
          value={value.trigger}
          onSelect={triggerSelected}
          jurisdictionIds={[jurisdictionId]}
          blockIdsFromSelection={blockedTriggerIds}
        />
      </div>
      <div className="flex-1">
        <ServiceMethodEditorDropDown
          values={{
            serviceMethods: value.serviceMethods || [],
            groups: value.serviceMethodGroups || [],
          }}
          placeholder="Response Service Methods"
          onSelect={serviceMethodSelected}
          jurisdictionId={jurisdictionId}
        />
      </div>
    </div>
  );
};

export default TriggerDeadline;

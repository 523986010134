import React, { FC } from 'react';
import Table, { ITableState } from '@components/Table/Table';
import { IRole } from '@models';

interface IRoleTableProps {
  roles?: IRole[];
  onTableStateChanged: (state: ITableState) => void;
  hasMore?: boolean;
  onRoleSelect: (role: IRole, isSelected: boolean) => void;
  selectedRoles?: IRole[];
  loading: boolean;
}

const RoleTable: FC<IRoleTableProps> = (
  {
    roles,
    onTableStateChanged,
    hasMore,
    onRoleSelect,
    selectedRoles,
    loading,
  },
) => (
  <div className="w-96">
    <Table
      dataSource={roles}
      isMultiSelect
      minHeight={400}
      maxHeight={600}
      onStateChange={onTableStateChanged}
      hasMore={hasMore}
      takeOptions={[100, 500]}
      isPagerVisible
      selected={selectedRoles}
      onRowSelect={onRoleSelect}
      loading={loading}
    >
      {[
        {
          key: 'description',
          header: 'Role',
          field: 'description',
          isFilterable: true,
          isSortable: true,
        },
      ]}
    </Table>
  </div>
);

export default RoleTable;

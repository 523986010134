import React, { FC } from 'react';

const svgString = '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 332.53 96.69"><defs><style>.cls-white{fill:white;}</style></defs><g id="Layer_2" data-cy="logo" data-name="Layer 2"><g id="Layer_1-2" data-name="Layer 1"><path class="cls-white" d="M64.57,16.06H41.27V75.73H23.19V16.06H0V0H64.57Z"/><path class="cls-white" d="M67.12,20.85H83.71V75.73H67.12ZM67.55.53H83.4v14H67.55Z"/><path class="cls-white" d="M176.47,40.1V75.73H159.88V43c0-5.95-2.34-9.25-8.2-9.25-5,0-9.46,3.3-9.46,11.7V75.73h-16.6V43c0-5.95-2.34-9.25-8.19-9.25-5,0-9.57,3.3-9.57,11.7V75.73H91.27V20.85h16.17v7.76c3.51-5.21,8.4-9,15.63-9,8,0,14,3.51,17,9.89,3.72-5.53,9.57-9.89,17.13-9.89C169,19.57,176.47,26.91,176.47,40.1Z"/><path class="cls-white" d="M238.27,53.18H197.64c1.17,7.88,6.06,11.6,12.55,11.6,5.53,0,9.15-2.24,11.06-6.17H237C234,70.2,223.38,77,210.19,77c-17,0-28.51-11.27-28.51-28.72S192.85,19.57,210,19.57s28.29,11.81,28.29,30.32ZM197.74,42.65H222c-1.17-6.8-5.54-10.85-12.24-10.85C203.7,31.8,199,35,197.74,42.65Z"/><path class="cls-white" d="M243.91,0H260.5V75.73H243.91Z"/><path class="cls-white" d="M321.13,20.85l-19.89,57c-4.78,13.83-11.06,18.83-27.33,18.83-3.2,0-4.79-.11-6.49-.21V83.71h5.32c6.17,0,8.29-3.51,8.29-7.66,0-3.08-1.27-7-2.55-10.63L262.74,20.85h17.87l2.44,7.76c3.2,10.75,6.39,22,9.36,34.57,3-12.65,6.07-23.82,9.15-34.46L304,20.85Z"/><path class="cls-white" d="M332.53,24.78a4.43,4.43,0,1,1-4.42-4.31A4.31,4.31,0,0,1,332.53,24.78Zm-1,0a3.39,3.39,0,1,0-3.38,3.3A3.29,3.29,0,0,0,331.49,24.78ZM330,26.36a.8.8,0,0,0,.18.54h-1.21a1.23,1.23,0,0,1-.14-.54l0-.45c0-.42-.15-.54-.52-.54h-.73V26.9h-1.15V22.57h2c1,0,1.61.47,1.61,1.28a1.07,1.07,0,0,1-.61,1,.91.91,0,0,1,.52.94Zm-1.67-1.89a.46.46,0,0,0,.52-.47c0-.32-.19-.47-.54-.47h-.76v.94Z"/></g></g></svg>';
const svgStringBlack = '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 332.53 96.69"><defs><style>.cls-black{fill:black;}</style></defs><g id="Layer_2" data-cy="logo" data-name="Layer 2"><g id="Layer_1-2" data-name="Layer 1"><path class="cls-black" d="M64.57,16.06H41.27V75.73H23.19V16.06H0V0H64.57Z"/><path class="cls-black" d="M67.12,20.85H83.71V75.73H67.12ZM67.55.53H83.4v14H67.55Z"/><path class="cls-black" d="M176.47,40.1V75.73H159.88V43c0-5.95-2.34-9.25-8.2-9.25-5,0-9.46,3.3-9.46,11.7V75.73h-16.6V43c0-5.95-2.34-9.25-8.19-9.25-5,0-9.57,3.3-9.57,11.7V75.73H91.27V20.85h16.17v7.76c3.51-5.21,8.4-9,15.63-9,8,0,14,3.51,17,9.89,3.72-5.53,9.57-9.89,17.13-9.89C169,19.57,176.47,26.91,176.47,40.1Z"/><path class="cls-black" d="M238.27,53.18H197.64c1.17,7.88,6.06,11.6,12.55,11.6,5.53,0,9.15-2.24,11.06-6.17H237C234,70.2,223.38,77,210.19,77c-17,0-28.51-11.27-28.51-28.72S192.85,19.57,210,19.57s28.29,11.81,28.29,30.32ZM197.74,42.65H222c-1.17-6.8-5.54-10.85-12.24-10.85C203.7,31.8,199,35,197.74,42.65Z"/><path class="cls-black" d="M243.91,0H260.5V75.73H243.91Z"/><path class="cls-black" d="M321.13,20.85l-19.89,57c-4.78,13.83-11.06,18.83-27.33,18.83-3.2,0-4.79-.11-6.49-.21V83.71h5.32c6.17,0,8.29-3.51,8.29-7.66,0-3.08-1.27-7-2.55-10.63L262.74,20.85h17.87l2.44,7.76c3.2,10.75,6.39,22,9.36,34.57,3-12.65,6.07-23.82,9.15-34.46L304,20.85Z"/><path class="cls-black" d="M332.53,24.78a4.43,4.43,0,1,1-4.42-4.31A4.31,4.31,0,0,1,332.53,24.78Zm-1,0a3.39,3.39,0,1,0-3.38,3.3A3.29,3.29,0,0,0,331.49,24.78ZM330,26.36a.8.8,0,0,0,.18.54h-1.21a1.23,1.23,0,0,1-.14-.54l0-.45c0-.42-.15-.54-.52-.54h-.73V26.9h-1.15V22.57h2c1,0,1.61.47,1.61,1.28a1.07,1.07,0,0,1-.61,1,.91.91,0,0,1,.52.94Zm-1.67-1.89a.46.46,0,0,0,.52-.47c0-.32-.19-.47-.54-.47h-.76v.94Z"/></g></g></svg>';

interface ITimelyLogoProps {
  isBlack?: boolean;
  width: number;
}
// i left these styles in here on purose so it would all be in 1 file.
// there are plenty of stles in the svg string anyway
const TimelyLogo: FC<ITimelyLogoProps> = (
  {
    isBlack,
    width,
  }: ITimelyLogoProps,
) => (
  <div
    style={{
      width,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
    }}
    // this is perfectly safe as we completely control the string
    // eslint-disable-next-line react/no-danger
    dangerouslySetInnerHTML={{ __html: isBlack ? svgStringBlack : svgString }}
  />
);

export default TimelyLogo;

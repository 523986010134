import React, { FC, useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import Button from '@fv-components/button';
import MaterialIcon from '@fv-components/material-icon';
import TextField, { HelperText, Input } from '@fv-components/text-field';
import Snackbar from '@fv-components/snackbar';
import SetPassword from '@components/SetPassword/SetPassword';
import useRegister from '@hooks/Identity/useRegister';

const notLoggedInCss = require('../../NotLoggedIn.module.scss');
const appCss = require('../../../App.module.scss');
const css = require('./RegisterForm.module.scss');

interface IRegisterFormProps {
  onRegister: VoidFunction;
  inviteId: string;
}

interface IRegisterFormState {
  firstName?: string;
  lastName?: string;
  password?: string;
  isPasswordValid?: boolean;
  isFirstNameValid?: boolean;
  isLastNameValid?: boolean;
}

const RegisterForm: FC<IRegisterFormProps> = ({
  onRegister,
  inviteId,
}: IRegisterFormProps) => {
  const [state, setState] = useState<IRegisterFormState>({});
  const [showError, setShowError] = useState(false);
  const { register, result: { data, error } } = useRegister();
  const history = useHistory();

  useEffect(() => {
    if (data?.register) {
      onRegister();
    } else if (error) {
      setShowError(true);
    }
  }, [data, onRegister, error]);

  const onPasswordChange = (password?: string, isPasswordValid?: boolean) => {
    setState({
      ...state,
      password,
      isPasswordValid,
    });
  };

  const onFirstNameChange = (event: React.FormEvent<HTMLInputElement>) => {
    setState({
      ...state,
      firstName: event.currentTarget.value,
      isFirstNameValid: event.currentTarget.validity.valid,
    });
  };

  const onLastNameChange = (event: React.FormEvent<HTMLInputElement>) => {
    setState({
      ...state,
      lastName: event.currentTarget.value,
      isLastNameValid: event.currentTarget.validity.valid,
    });
  };

  const onRegisterButtonClicked = () => {
    if (state.firstName
      && state.lastName
      && state.password
      && state.isFirstNameValid
      && state.isLastNameValid
      && state.isPasswordValid
    ) {
      register(state.firstName, state.lastName, inviteId, state.password);
    }
  };

  const onBackClicked = () => {
    history.push('/sign-in');
  };

  const onShowTermsClick = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    event.preventDefault();
    history.push('/terms');
  };

  return (
    <>
      <h2 data-cy="registration-header">
        Registration
      </h2>
      <div className={css.column}>
        <TextField
          label="First Name"
          outlined
          className={css.textField}
          helperText={(
            <HelperText className="cypress-first-name-validation-message" validation>
              You must enter a first name
            </HelperText>
          )}
          data-cy="first-name-validation"
        >
          <Input
            value={state.firstName}
            onChange={onFirstNameChange}
            pattern="^([ \u00c0-\u01ffa-zA-Z'-`ḑȯ])+$"
            required
            autoFocus
            data-cy="first-name-input"
          />
        </TextField>
        <TextField
          label="Last Name"
          outlined
          className={css.textField}
          helperText={(
            <HelperText className="cypress-last-name-validation-message" validation>
              You must enter a last name
            </HelperText>
          )}
        >
          <Input
            value={state.lastName}
            onChange={onLastNameChange}
            pattern="^([ \u00c0-\u01ffa-zA-Z'-`ḑȯ])+$"
            required
            data-cy="last-name-input"
          />
        </TextField>
        <SetPassword onPasswordChange={onPasswordChange} isRequired />
      </div>

      <div className={notLoggedInCss.buttonRow}>
        <Button
          onClick={onBackClicked}
          icon={<MaterialIcon icon="arrow_back" />}
          role="button"
          aria-label="Back To Sign In Screen"
          outlined
          className={`${appCss.secondaryButton} ${notLoggedInCss.backButton}`}
          data-cy="back"
        />
        <Button
          onClick={onRegisterButtonClicked}
          disabled={!state.isLastNameValid || !state.isPasswordValid || !state.isFirstNameValid}
          role="button"
          aria-label="Register"
          raised
          className={notLoggedInCss.submitButton}
          data-cy="register"
        >
          Register
        </Button>
      </div>
      <span className={css.tosPhrase}>
        By using Timely, you agree to the
        <a
          className={css.tosLink}
          href="/terms"
          onClick={onShowTermsClick}
          data-cy="terms"
        >
          terms of service.
        </a>
      </span>
      <Snackbar
        message="Unable to create a new account using this invite."
        onClose={() => setShowError(false)}
        data-cy="error-msg"
        open={showError}
      />
    </>
  );
};

export default RegisterForm;

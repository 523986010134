import React, { FC } from 'react';
import {
  DurationInput, IntervalDropDown, CalendarTypeDropDown,
} from '@components';
import {
  CalendarType, RoundingDirection, Interval,
} from '@models';
import RoundingDirectionDropDown from './RoundingDirectionDropDown';

interface IProps {
  duration?: number;
  onDurationChange: (duration?: number) => void;
  interval?: Interval;
  onIntervalChange: (interval?: Interval) => void;
  calendarType?: CalendarType;
  onCalendarChange: (type?: CalendarType) => void;
  roundingDirection?: RoundingDirection;
  onRoundingChange: (direction?: RoundingDirection) => void;
}

const DeadlineProps: FC<IProps> = ({
  duration,
  onDurationChange,
  onIntervalChange,
  onCalendarChange,
  onRoundingChange,
  interval,
  calendarType,
  roundingDirection,
}) => (
  <div className="flex flex-col md:flex-row gap-3">
    <div className="flex flex-col gap-3 md:flex-1">
      <b>Duration</b>
      <DurationInput
        onChange={onDurationChange}
        value={duration}
        isNegativeAllowed
        isRequired
      />
    </div>
    <div className="flex flex-col gap-3 md:flex-1">
      <b>Interval</b>
      <IntervalDropDown
        onChange={onIntervalChange}
        value={interval}
        isOnlyDays={calendarType === CalendarType.JUDICIAL}
        isRequired
      />
    </div>
    <div className="flex flex-col gap-3 md:flex-1">
      <b>Calendar</b>
      <CalendarTypeDropDown
        onChange={onCalendarChange}
        value={calendarType}
        isRequired
      />
    </div>
    <div className="flex flex-col gap-3 md:flex-1">
      <b>Rounding</b>
      <RoundingDirectionDropDown
        onChange={onRoundingChange}
        label="Rounding Direction"
        value={roundingDirection}
        disabled={calendarType === CalendarType.JUDICIAL}
        isRequired={calendarType === CalendarType.CALENDAR}
      />
    </div>
  </div>
);

export default DeadlineProps;

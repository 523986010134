import {
  useMutation, ApolloError, gql, DocumentNode,
} from '@apollo/client';
import IUserQuery from '@models/Identity/User/IUserQuery';

type SetIsActiveHook = (
  props: ISetIsActiveHookProps
) => ISetIsActiveHookResult;

interface ISetIsActiveHookProps {
  queryObject?: IUserQuery;
  queryString?: DocumentNode;
}

export interface ISetIsActiveHookResult {
  result: {
    error?: ApolloError;
    loading: boolean;
    called: boolean;
  };
  setIsActive: (
    id: string,
    isActive: boolean,
  ) => Promise<any>;
}

interface ISetIsActiveResult {
  setUserIsActive: string;
}

const useUserSetIsActive: SetIsActiveHook = () => {
  const SET_IS_ACTIVE = gql`
    mutation UserMutation($item: ActiveInputType!) {
      setUserIsActive(item: $item)
    }
  `;

  const [saveFunc, {
    error, loading, called, client,
  }] = useMutation<ISetIsActiveResult>(SET_IS_ACTIVE);

  return {
    result: {
      error, loading, called,
    },
    setIsActive: (
      userId: string,
      isActive: boolean,
    ) => saveFunc({
      variables: {
        item: {
          userId,
          isActive,
        },
      },
    }).then(() => client.cache.modify({ id: `UserType:${userId}`, fields: { isActive: () => isActive } })),
  };
};

export default useUserSetIsActive;

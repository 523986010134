import React, { FC } from 'react';

interface IDeadlineTextProps {
  description?: string;
  secondaryText?: string;
}

const DeadlineText: FC<IDeadlineTextProps> = (
  {
    description,
    secondaryText,
  }: IDeadlineTextProps,
) => (
  <div className="flex flex-col justify-center flex-1">
    <div data-cy="deadline-list-item-primary-text">
      {description}
    </div>
    {!!secondaryText && (
      <div
        className="text-gray text-opacity-75 text-sm"
        data-cy="deadline-list-item-secondary-text"
      >
        {secondaryText}
      </div>
    )}
  </div>
);

export default DeadlineText;

import {
  useMutation, ApolloError, FetchResult, gql, ApolloClient,
} from '@apollo/client';
import curry from 'lodash.curry';
import { handleSaveResult } from '@libs/apollo';
import {
  IServiceMethodGroup, ITrigger, IServiceMethod, ICategory, ITriggerDeadline,
} from '@models';

type TriggerSaveHook = () => ITriggerSaveHookResult;
export interface ITriggerSaveHookResult {
  result: {
    error?: ApolloError;
    loading: boolean;
    called: boolean;
  };
  saveTrigger: (
    jurisdictionId: string,
    description: string,
    isFilingRequired: boolean,
    id?: string,
    parentTriggers?: ITrigger[],
    serviceMethods?: IServiceMethod[],
    childTriggers?: ITrigger[],
    categories?: ICategory[],
    serviceMethodGroups?: IServiceMethodGroup[],
    triggerDeadlines?: ITriggerDeadline[],
    deadlineInheritanceBlocks?: string[],
  ) => Promise<FetchResult<ITrigger>>;
}

interface ISaveTriggerResult {
  saveTrigger: ITrigger;
}

const save = (
  saveFunc: (options?: any | undefined) => Promise<FetchResult<ISaveTriggerResult>>,
  client: ApolloClient<object>,
  jurisdictionId: string,
  description: string,
  isFilingRequired: boolean,
  id?: string,
  parentTriggers?: ITrigger[],
  serviceMethods?: IServiceMethod[],
  childTriggers?: ITrigger[],
  categories?: ICategory[],
  serviceMethodGroups?: IServiceMethodGroup[],
  triggerDeadlines?: ITriggerDeadline[],
  deadlineInheritanceBlocks?: string[],
): Promise<FetchResult<ITrigger>> => {
  const trigger = {
    id,
    jurisdictionId,
    description,
    parentTriggers,
    isFilingRequired,
    serviceMethods,
    childTriggers,
    categories,
    serviceMethodGroups,
    triggerDeadlines,
    deadlineInheritanceBlocks,
  };
  return saveFunc({
    variables: {
      trigger: {
        ...trigger,
        parentTriggers: parentTriggers?.map((t: ITrigger) => t.id),
        serviceMethods: serviceMethods?.map((sm: IServiceMethod) => sm.id),
        childTriggers: childTriggers?.map((t: ITrigger) => t.id),
        categories: categories?.map((t: ICategory) => t.id),
        serviceMethodGroups: serviceMethodGroups?.map((sm: IServiceMethodGroup) => sm.id),
        triggerDeadlines: triggerDeadlines?.map((td: ITriggerDeadline) => ({
          id: td.id,
          triggerId: td.triggerId,
          deadlineId: td.deadlineId,
          serviceMethods: td.serviceMethods?.map((x: IServiceMethod) => x.id),
          serviceMethodGroups: td.serviceMethodGroups?.map((x: IServiceMethodGroup) => x.id),
        })),
      },
    },
  }).then((result: FetchResult<ISaveTriggerResult>) => ({
    ...result,
    data: {
      ...handleSaveResult<ITrigger>(
        result.data?.saveTrigger || '',
        trigger,
        client,
        'triggers',
      ),
    },
  }));
};

const SAVE_GQL = gql`
    mutation TriggerMutation($trigger: TriggerInputType!) {
      saveTrigger(item: $trigger) {
        id
      }
    }
  `;

const useTriggerSave: TriggerSaveHook = () => {
  const [saveFunc, {
    error, loading, called, client,
  }] = useMutation<ISaveTriggerResult>(SAVE_GQL);

  return {
    result: {
      error,
      loading,
      called,
    },
    saveTrigger: curry(save)(saveFunc)(client),
  };
};

export default useTriggerSave;

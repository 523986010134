import {
  useCallback, useEffect, useRef,
} from 'react';
import useDebounce from './useDebounce';

const findEndPositionOfSearch = (input: string, startPos: number, beginSearchChar?: string) => {
  if (beginSearchChar) {
    if (input.indexOf(' ', startPos) > startPos) {
      return input.indexOf(' ', startPos);
    }
    if (input.indexOf(beginSearchChar, startPos) > startPos) {
      return input.indexOf(beginSearchChar, startPos);
    }
  }
  return input.length;
};

interface ILookupInputHookProps {
  inputRefCurrent: any;
  beginSearchChar?: string;
  debounce: number;
  callback?: (searchState?: ISearchState, inputValue?: string) => void;
  setInputValue: (text: string) => void;
}

interface ILookupInputHookResult {
  inputOnChangeHandler: (event: React.ChangeEvent<HTMLInputElement>) => void;
  clear: VoidFunction;
}

export interface ISearchState {
  isStartingNewSearch: boolean;
  startPos?: number;
  endPos?: number;
  searchTerm?: string;
}

const useLookupInput: (props: ILookupInputHookProps) => ILookupInputHookResult = (
  {
    inputRefCurrent,
    beginSearchChar,
    debounce,
    callback,
    setInputValue,
  },
) => {
  const searchState = useRef<ISearchState>();

  //  this method should not be called if you are not using a begin search character
  const newSearchInitiated = useCallback((text: string) => {
    if (beginSearchChar && searchState.current?.startPos !== undefined
      && text.indexOf(beginSearchChar,
        searchState.current?.startPos - 1) === searchState.current?.startPos - 1) {
      const endPos = findEndPositionOfSearch(
        text,
        searchState.current?.startPos,
        beginSearchChar,
      );
      const newSearchTerm = text.substring(searchState.current?.startPos, endPos)
        .trim().toLocaleLowerCase();

      const newSearchState = {
        ...searchState.current,
        endPos,
        isStartingNewSearch: false,
        searchTerm: newSearchTerm,
      };

      searchState.current = newSearchState;

      if (callback) {
        callback(newSearchState, text);
      }
    }
  }, [beginSearchChar, callback]);

  // this method is for handling searches where there is no begin search char
  const handleSearch = useCallback((text: string) => {
    const newSearchState = {
      startPos: 0,
      endPos: text.length,
      isStartingNewSearch: false,
      searchTerm: text,
    };
    searchState.current = newSearchState;

    if (callback) {
      callback(newSearchState, text);
    }
  }, [callback]);

  const handleExistingSearch = useCallback((text: string) => {
    if (text.includes(beginSearchChar as string)) {
      if (searchState.current?.startPos !== undefined) {
        const endPos = findEndPositionOfSearch(
          text,
        searchState.current?.startPos,
        beginSearchChar,
        );

        const newSearchTerm = text.substring(searchState.current?.startPos, endPos)
          .trim().toLocaleLowerCase();
        if (searchState.current.searchTerm !== newSearchTerm) {
          const newSearchState = {
            ...searchState.current,
            endPos,
            searchTerm: newSearchTerm,
          };

          searchState.current = newSearchState;
          if (callback) {
            callback(newSearchState, text);
          }
        }
      }
    } else {
      searchState.current = undefined;
      if (callback) {
        callback(undefined, text);
      }
    }
  }, [beginSearchChar, callback]);

  const immediateCallback = useCallback((m: string) => setInputValue(m), [setInputValue]);

  const debouncedCallback = useCallback((x: string) => {
    if (beginSearchChar) {
      if (searchState.current?.isStartingNewSearch) {
        newSearchInitiated(x);
      } else {
        handleExistingSearch(x);
      }
    } else {
      handleSearch(x);
    }
  }, [beginSearchChar, handleExistingSearch, handleSearch, newSearchInitiated]);

  const { handler: inputOnChangeHandler } = useDebounce({
    immediateCallback,
    debouncedCallback,
    timeout: debounce,
  });

  // we only need to handle keyboard events in the case
  // where we have a new search character
  const handleInputKeyboardEvents = useCallback((e: Event) => {
    const keyboardEvent = e as KeyboardEvent;

    if (keyboardEvent.key === beginSearchChar
      && !searchState.current?.isStartingNewSearch
    ) {
      const newStartPos = ((inputRefCurrent?.inputElement?.selectionEnd) || 0)
        + 1;

      searchState.current = {
        isStartingNewSearch: true,
        startPos: newStartPos,
        endPos: newStartPos,
      };
    }
  }, [beginSearchChar, inputRefCurrent, searchState]);

  useEffect(() => {
    if (inputRefCurrent?.inputElement && beginSearchChar) {
      const ref = inputRefCurrent.inputElement;
      ref.addEventListener('keydown', handleInputKeyboardEvents);
      return () => {
        ref.removeEventListener('keydown', handleInputKeyboardEvents);
      };
    }
    return undefined;
  }, [beginSearchChar, handleInputKeyboardEvents, inputRefCurrent]);

  const clear = () => {
    searchState.current = undefined;
    setInputValue('');
    if (callback) {
      callback();
    }
  };

  return {
    inputOnChangeHandler,
    clear,
  };
};

export default useLookupInput;

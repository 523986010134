export interface IConfig {
  filevineApiBaseUrl: string;
  filevineBaseDomain: string;
  filevineBaseUrl: string;
  filevineTenantUrl: string;
  hostUrl: string;
  googleApiUrl: string;
  googleApiKey: string;
  googleApiClientId: string;
}

interface IIdentityProviders {
  name?: string;
}

interface IAppClient {
  appClientID: string;
  clientType: string;
  clientTypeValue: number;
  identityProviders: IIdentityProviders[];
}

interface IFVAuthTenantConfig {
  appClients: IAppClient[];
  authorizationEndpoint: string;
  awsRegion: string;
  cognitoUserPoolID: string;
  identityProviders: IIdentityProviders[];
  tenantID: string;
}

export interface ITenantConfig {
  appClientID?: string;
  authorizationEndpoint?: string;
  awsRegion?: string;
  cognitoUserPoolID?: string;
  tenantID?: string;
  identityProviders?: IIdentityProviders[];
  error?: string;
}

interface IShardGroup { key: string, name: string }

export const shardGroups: { [key: string]: IShardGroup[] } = {
  prod: [{ key: 'app', name: 'app.filevine.com (United States)' }],
  preprod: [{ key: 'fru', name: 'Free Range Unicorns' }, { key: 'jacksonville', name: 'Jacksonville' }],
};

export const envShards: IShardGroup[] | undefined = shardGroups[process.env.REACT_APP_GROUP || ''];

export const setUserTenant = (userTenant: string): void => {
  localStorage.setItem('userTenant', userTenant);
};

export const getUserTenant = (): string => (
  localStorage.getItem('userTenant') || ''
);

export const getConfig = (): IConfig => {
  const tenant = getUserTenant();
  const group = process.env.REACT_APP_GROUP;
  let filevineBaseDomain = group === 'prod' ? '.filevineapp.com' : '.filevinedev.com';
  let filevineTenantUrl = `${tenant}${filevineBaseDomain}`;

  if (tenant === 'app') {
    filevineBaseDomain = '.filevine.com';
    filevineTenantUrl = `app${filevineBaseDomain}`;
  }

  if (tenant === 'app-ca') {
    filevineBaseDomain = '.filevine.ca';
    filevineTenantUrl = `app${filevineBaseDomain}`;
  }

  return {
    filevineApiBaseUrl: `https://${filevineTenantUrl}/v2`,
    filevineBaseDomain,
    filevineBaseUrl: `https://${filevineTenantUrl}`,
    filevineTenantUrl,
    hostUrl: process.env.REACT_APP_HOST || '',
    googleApiUrl: process.env.REACT_APP_GOOGLE_URL || '',
    googleApiKey: process.env.REACT_APP_GOOGLE_API_KEY || '',
    googleApiClientId: process.env.REACT_APP_GOOGLE_CLIENT_ID || '',
  };
};

const formatFVTenantConfig = (config:IFVAuthTenantConfig):ITenantConfig|undefined => {
  const {
    appClients, authorizationEndpoint, awsRegion, cognitoUserPoolID, tenantID,
  } = config;

  // If there are app clients find the filevine one and flatten
  if (appClients?.length && cognitoUserPoolID) {
    const FVAppClient = appClients.find((c:IAppClient) => c.clientType === 'Filevine');

    return {
      appClientID: FVAppClient?.appClientID,
      authorizationEndpoint,
      awsRegion,
      cognitoUserPoolID,
      identityProviders: FVAppClient?.identityProviders,
      tenantID,
    };
  }

  return undefined;
};

export const fetchTenantConfig = async (tenant:string):Promise<ITenantConfig|undefined> => {
  const prodAuthAPIUrl = 'auth-prod-public.filevine.com';
  const nonprodAuthAPIUrl = 'auth-nonprod-public.filevinedev.com';
  const authAPIUrl = (process.env.REACT_APP_GROUP === 'prod') ? prodAuthAPIUrl : nonprodAuthAPIUrl;

  if (tenant) {
    try {
      const response = await fetch(`https://${authAPIUrl}/api/Tenants/authconfig/${tenant}`, {});
      // if api returns 404 (tenant not found) return custom error text
      if (response.status === 404) {
        return {
          error: `A match for ${tenant} was not found.`,
        };
      }

      const data:IFVAuthTenantConfig = await response.json();
      return formatFVTenantConfig(data);
    } catch {
      return {
        error: 'There was an error fetching your tenant details from Filevine.',
      };
    }
  }

  return undefined;
};

export const setTenantConfig = (userTenantConfig: string): void => {
  localStorage.setItem('userTenantConfig', userTenantConfig);
};

export const getTenantConfig = async (): Promise<ITenantConfig|undefined> => {
  const lsConfig = localStorage.getItem('userTenantConfig');
  if (lsConfig) {
    return JSON.parse(lsConfig);
  }

  const fConfig = await fetchTenantConfig(getUserTenant());
  if (fConfig?.cognitoUserPoolID) {
    setTenantConfig(JSON.stringify(fConfig));
    return fConfig;
  }

  return fConfig;
};

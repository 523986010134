import { useLazyQuery, ApolloError, gql } from '@apollo/client';
import { useCallback } from 'react';
import ILogin from '@models/Identity/ILogin';

type LoginHook = () => ILoginHookResult;

interface ILoginResponse {
  login: ILogin;
}

interface ILoginHookResult {
  loading: boolean;
  error?: ApolloError;
  result?: ILogin;
  called: boolean;
  login: (email: string, password: string) => void;
}

const useLogin: LoginHook = () => {
  const GET_LOGIN = gql`
    query($loginRequest: LoginRequestInputType!) {
      login(
        loginRequest: $loginRequest
      ) {
          name
          email
          token
          permissions
          userId
          organizationId
          organizationName
      }
    }
  `;

  const [executeQuery, {
    loading, error, data, called,
  }] = useLazyQuery<ILoginResponse>(GET_LOGIN, { fetchPolicy: 'network-only' });

  const login = useCallback(
    (email: string, password: string) => {
      executeQuery({
        variables: { loginRequest: { email, password } },
      });
    },
    [executeQuery],
  );

  return {
    loading,
    error,
    result: {
      ...data?.login,
      permissionMap: JSON.parse(data?.login?.permissions || '{}'),
    },
    called,
    login,
  };
};

export default useLogin;

import React, {
  useEffect, useCallback, MutableRefObject, useState, useRef,
} from 'react';
import { MenuList, MenuListItem } from '@fv-components/menu';
import { Input } from '@fv-components/text-field';
import Button from '@fv-components/button';
import BetterMenuSurface from './BetterMenuSurface';

interface ILookupMenuProps<T> {
  isOpen: boolean;
  onMenuItemSelected: (item: T) => void;
  descriptionField: string;
  keyField: string;
  descriptionPrefix?: string;
  inputRef?: MutableRefObject<Input | HTMLElement | null>;
  onMenuClosed?: VoidFunction;
  captionFields?: string[];
  items?: T[];
  onLoadMore?: VoidFunction;
  hasMore?: boolean;
  isSelectionBlocked?: boolean;
  children: React.ReactElement
}

const createMarkup = (str: string) => ({ __html: str });

const escapeRegExp = (text: string) => text.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');

const LookupMenu: <T>(
  props: ILookupMenuProps<T>,
) => React.ReactElement = <T extends { [key: string]: any }>(
  {
    isOpen,
    onMenuItemSelected,
    keyField,
    descriptionField,
    descriptionPrefix,
    inputRef,
    onMenuClosed,
    items,
    captionFields,
    onLoadMore,
    hasMore,
    isSelectionBlocked,
    children,
  }: ILookupMenuProps<T>,
) => {
  const [highlightedIndex, setHighlightedIndex] = useState<number>(0);
  const menuRef = useRef<MenuList>(null);
  // this value is here for me to set when i'm loading more items and i want to temporarily
  // block the control from scrolling to the top
  const [isDontScroll, setIsDontScroll] = useState(false);
  const stopFurtherAction = (keyboardEvent: KeyboardEvent) => {
    keyboardEvent.stopPropagation();
    keyboardEvent.preventDefault();
  };

  const scroll = useCallback((index: number, scrollBehavior?: 'auto' | 'smooth') => {
    const li = menuRef.current?.listElements[index];
    if (li) {
      li.scrollIntoView({
        behavior: scrollBehavior || 'smooth',
        block: 'center',
      });
    }
  }, []);

  useEffect(() => {
    if (items) {
      setHighlightedIndex(0);
      if (!isDontScroll) {
        scroll(1);
      } else {
        setIsDontScroll(false);
      }
    }
    // it is very intentional that i do not want to triller this useEffect on isDontScroll change
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [items, scroll]);

  const handleInputKeyboardEevents = useCallback((e: Event) => {
    const keyboardEvent = e as KeyboardEvent;
    if (isOpen && items) {
      if (keyboardEvent.key === 'ArrowUp') {
        stopFurtherAction(keyboardEvent);
        if (highlightedIndex !== 0) {
          setHighlightedIndex(highlightedIndex - 1);
          scroll(highlightedIndex - 1);
        }
      } else if (keyboardEvent.key === 'ArrowDown') {
        stopFurtherAction(keyboardEvent);
        if (highlightedIndex < items.length - 1) {
          setHighlightedIndex(highlightedIndex + 1);
          scroll(highlightedIndex + 1);
        }
      } else if (keyboardEvent.key === 'Enter' && !isSelectionBlocked) {
        stopFurtherAction(keyboardEvent);
        if (items[highlightedIndex]) {
          onMenuItemSelected(items[highlightedIndex]);
        }
      } else if ((keyboardEvent.key === 'Escape' || keyboardEvent.key === 'Tab') && isOpen) {
        if (onMenuClosed) {
          onMenuClosed();
        }
      }
    }
  }, [
    highlightedIndex,
    isOpen,
    isSelectionBlocked,
    items,
    onMenuClosed,
    onMenuItemSelected,
    scroll,
  ]);

  useEffect(() => {
    if (inputRef?.current) {
      if (inputRef.current instanceof Input && inputRef.current.inputElement) {
        const ref = inputRef.current.inputElement;
        ref.addEventListener('keydown', handleInputKeyboardEevents);
        return () => {
          ref.removeEventListener('keydown', handleInputKeyboardEevents);
        };
      }
      if (inputRef.current instanceof HTMLElement) {
        const ref = inputRef.current;
        ref.addEventListener('keydown', handleInputKeyboardEevents);
        return () => {
          ref.removeEventListener('keydown', handleInputKeyboardEevents);
        };
      }
    }

    return undefined;
  }, [handleInputKeyboardEevents, inputRef]);

  const highlightTerm = inputRef?.current instanceof Input && inputRef?.current?.getValue();
  const regEx = highlightTerm ? new RegExp(escapeRegExp(highlightTerm), 'gi') : new RegExp('', 'gi');

  return (
    <BetterMenuSurface
      anchor={children}
      isOpen={isOpen}
      onMenuClosed={onMenuClosed}
      isWidthRestricted
    >
      <MenuList ref={menuRef}>
        {items?.map(
          (item: T, index: number) => (
            <MenuListItem
              key={item[keyField]}
              tabIndex={-1}
              className={`${index === highlightedIndex ? 'bg-gray-light' : ''} flex flex-col py-1 gap-1 items-start justify-center`}
              onClick={() => onMenuItemSelected(item)}
            >
              <div
                className="whitespace-nowrap overflow-ellipsis overflow-hidden w-full"
                // eslint-disable-next-line react/no-danger
                dangerouslySetInnerHTML={createMarkup(`${descriptionPrefix || ''}${!highlightTerm ? item[descriptionField] : item[descriptionField].replace(regEx, (str: string) => `<b>${str}</b>`)}`)}
              />
              {!!captionFields?.length && (
                <div className="text-gray text-xs">
                  {captionFields.map((captionField: string) => captionField.split('.').reduce((
                    currentObj: any,
                    nextProp: string,
                  ) => (currentObj
                    && currentObj[nextProp] !== undefined ? currentObj[nextProp] : ''
                  ),
                  item)).join(',  ')}
                </div>
              )}
            </MenuListItem>
          ),
        )}
        {hasMore && onLoadMore && (<Button onClick={() => { setIsDontScroll(true); onLoadMore(); }} className="w-full">Load More...</Button>)}
      </MenuList>
    </BetterMenuSurface>
  );
};

export default LookupMenu;

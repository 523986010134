import React, {
  FC, useState, useRef,
} from 'react';
import TextField, { HelperText, Input } from '@fv-components/text-field';
import MaterialIcon from '@fv-components/material-icon';
import dayjs, { Dayjs } from 'dayjs';
import BetterMenuSurface from '@components/BetterMenuSurface';
import DateSelector from './DateSelector/DateSelector';

const css = require('./DatePicker.module.scss');

interface IDatePickerProps {
  onDateChange: (date?: Date) => void;
  dateFormat: string;
  value?: Date;
  disabled?: boolean;
  minDate?: Date;
  maxDate?: Date;
  label?: string;
  isRequired?: boolean;
}

// this will only return true if the string matches the MM/DD/YYYY format
export const isCompletedValidDate = (value: string): boolean => !!value
  .match(/(0[1-9]|1[012])\/(0[1-9]|[12][0-9]|3[01])\/\d\d\d\d/);

// This will match a bunch of different formats that i deemed to be possible paths to a valid date
// 0, 01, 01/, 01/1, 01/1/1 etc etc Its intention is not to ensure you have a valid date but that
// you entered the / character in the right places and didn't enter any non numeric characters
export const isValidInProgressDate = (value: string): boolean => !!value
  .match(/(^([0-9]{0,2}(\/)?)$)|(^([0-9]{0,2}(\/)[0-9]{0,2}(\/)?)$)|(^([0-9]{0,2}(\/)[0-9]{0,2}(\/)[0-9]{0,4})$)/);

const checkIsWithinMinMaxRange = (
  d?: Dayjs,
  minDate?: Dayjs,
  maxDate?: Dayjs,
) => {
  const isAboveMinDate = (!minDate || !d) || (d >= minDate);
  const isBelowMaxDate = (!maxDate || !d) || (d <= maxDate);
  return isAboveMinDate && isBelowMaxDate;
};

const formatText = (dateString: string): string => {
  const today = dayjs();

  let [month, day, year] = dateString.split('/');

  if (day?.length !== 2) {
    if (day?.length === 1) {
      day = `0${day}`;
    } else if (!day?.length) {
      day = today.format('DD');
    }
  }
  if (month?.length !== 2) {
    if (month?.length === 1) {
      month = `0${month}`;
    } else if (!month?.length) {
      month = today.format('MM');
    }
  }
  if (year?.length !== 4) {
    if (year?.length === 2) {
      year = `${today.year().toString().slice(0, 2)}${year}`;
    } else if (!year?.length) {
      year = today.format('YYYY');
    }
  }

  return [month, day, year].join('/');
};

const getValidationMessage = (minDate?: Dayjs, maxDate?: Dayjs): string => {
  if (minDate && !maxDate) {
    return `Date must be greater than ${minDate?.format('MM/DD/YYYY')} and in proper format`;
  } if (!minDate && maxDate) {
    return `Date must be less than ${maxDate?.format('MM/DD/YYYY')} and in proper format`;
  } if (minDate && maxDate) {
    return `Date must be between ${minDate?.format('MM/DD/YYYY')} and ${maxDate?.format('MM/DD/YYYY')} and in proper format`;
  }
  return 'Date must be in proper format';
};

const DatePicker: FC<IDatePickerProps> = (
  {
    dateFormat,
    onDateChange,
    value,
    disabled,
    minDate: mind,
    maxDate: maxd,
    label,
    isRequired,
  }: IDatePickerProps,
) => {
  const minDate = mind ? dayjs(mind) : dayjs('1000/01/01');
  const maxDate = maxd ? dayjs(maxd) : dayjs('9999/12/31');
  const [isDirty, setIsDirty] = useState(false);
  const [isValid, setIsValid] = useState(true);
  const [date, setDate] = useState(value ? dayjs(value) : value);
  const [text, setText] = useState(date ? date.format(dateFormat) : '');
  const pickerAnchor = useRef<HTMLDivElement>(null);
  const [isVisible, setIsVisible] = useState(false);

  const onPickerIconClicked = () => {
    setIsVisible(!isVisible);
    if (pickerAnchor.current) {
      pickerAnchor.current.getElementsByTagName('input')[0].focus();
    }
  };

  const onTextChanged = (newText: string) => {
    setIsDirty(true);
    if (newText.length === 10 && isCompletedValidDate(newText)) {
      setText(newText);
      const newDate = dayjs(newText, { format: dateFormat });
      if (newDate.isValid() && checkIsWithinMinMaxRange(newDate, minDate, maxDate)) {
        setDate(newDate);
        onDateChange(newDate.toDate());
        setIsValid(true);
      } else {
        setIsValid(false);
      }
    } else if (!newText) {
      setIsValid(false);
      setText('');
      setDate(undefined);
      onDateChange();
    } else if (isValidInProgressDate(newText)) {
      setIsValid(false);
      setText(newText);
      setDate(undefined);
      onDateChange();
    }
  };

  const closePicker = () => {
    setIsVisible(false);
  };

  const onSelectedDate = (d: Dayjs) => {
    setDate(d);
    setText(d.format(dateFormat));
    onDateChange(d.toDate());
    setIsValid(true);
    setIsDirty(true);
    closePicker();
    if (pickerAnchor.current) {
      pickerAnchor.current.getElementsByTagName('input')[0].focus();
    }
  };

  const onBlur = () => {
    if (text) {
      const formattedText = formatText(text);
      onTextChanged(formattedText);
    }
  };

  const onPartialUpdate = (d: Dayjs) => {
    setDate(d);
    setText(d.format(dateFormat));
    onDateChange(d.toDate());
  };

  return (
    <div className={css.textField} ref={pickerAnchor}>
      <BetterMenuSurface
        isOpen={isVisible}
        onMenuClosed={closePicker}
        anchor={
          (
            <TextField
              label={`${label || 'Date'} ${dateFormat}`}
              className={css.textField}
              outlined
              onTrailingIconSelect={onPickerIconClicked}
              disabled={disabled}
              trailingIcon={(
                <MaterialIcon
                  className={css.icon}
                  role="button"
                  icon="calendar_today"
                />
              )}
              helperText={!isValid ? (
                <HelperText validation>
                  {getValidationMessage(minDate, maxDate)}
                </HelperText>
              ) : undefined}
            >
              <Input
                className={css.input}
                onChange={
                  (e: React.FormEvent<HTMLInputElement>) => onTextChanged(e.currentTarget.value)
                }
                value={text}
                disabled={disabled}
                autoComplete="date"
                onBlur={onBlur}
                isValid={!isDirty || isValid}
                data-cy="date-input"
                required={isRequired}
              />
            </TextField>
          )
        }
      >
        {isVisible
          ? (
            <DateSelector
              onSelectedDate={onSelectedDate}
              date={date}
              minDate={minDate}
              maxDate={maxDate}
              onPartialUpdate={onPartialUpdate}
            />
          ) : <></>}
      </BetterMenuSurface>

    </div>
  );
};

export default DatePicker;

import {
  useMutation, gql,
} from '@apollo/client';
import {
  IHistoryTriggerInput,
  IFilevineDeadline,
  IHistoryTriggerDeadlineInput,
  IHistoryTriggerDeadlineServiceMethodInput,
  IMatter,
  IHistory,
} from '@models';
import dayjs from 'dayjs';

type HistorySaveHook = (props: IProps) => ISaveHistoryResult;

export interface ISaveHistoryResult {
  saveHistory: (
    matter: IMatter,
    filevineDeadlines?: IFilevineDeadline[],
    alreadyDeletedDeadlines?: IFilevineDeadline[],
  ) => Promise<IHistory | undefined>;
}

interface IProps {
  historyId?: string;
  jurisdictionId?: string;
  historyTriggers: IHistoryTriggerInput[];
}

const useHistorySave: HistorySaveHook = ({
  historyId,
  jurisdictionId,
  historyTriggers,
}: IProps) : ISaveHistoryResult => {
  const SAVE_GQL = gql`
    mutation HistoryMutation($item: HistoryInputType!) {
      saveHistory(item: $item) {
        id
        matter { id }
        historyTriggers {
          id
          historyId
          triggerId
          triggerDate
          triggerLabel
          historyTriggerDeadlines {
            id
            deadlineId
            filevineDeadlineId
            historyTriggerDeadlineServiceMethods {
              id
              serviceMethodId
              filevineDeadlineId
            }
          }
        }
      }
    }
  `;
  const [saveFunc] = useMutation<{ saveHistory: IHistory }>(SAVE_GQL);

  return {
    saveHistory: async (
      matter: IMatter,
      filevineDeadlines?: IFilevineDeadline[],
      alreadyDeletedDeadlines?: IFilevineDeadline[],
    ) => {
      const deadlineIdMap = filevineDeadlines?.reduce((
        map: { [key: string]: string },
        next: IFilevineDeadline,
      ) => {
        if (next.deadlineId?.partner && next.deadlineId.native && !next.isAlreadyDeleted) {
          // we aren't re-assigning anything. all these should be unique
          // new keys we are adding.
          // eslint-disable-next-line no-param-reassign
          map[next.deadlineId?.partner] = `${next.deadlineId?.native}`;
        } else if (next.deadlineId?.partner && next.isAlreadyDeleted) {
          // eslint-disable-next-line no-param-reassign
          map[next.deadlineId?.partner] = 'isAlreadyDeleted';
        }
        return map;
      }, {}) || {};

      // this is how .foreach works
      // eslint-disable-next-line no-unused-expressions
      alreadyDeletedDeadlines?.forEach((fvd: IFilevineDeadline) => {
        if (fvd.deadlineId?.partner) {
          deadlineIdMap[fvd.deadlineId?.partner] = 'isAlreadyDeleted';
        }
      });

      const result = await saveFunc({
        variables: {
          item: {
            id: historyId,
            matter: {
              ...matter,
              __typename: undefined,
            },
            jurisdictionId,
            // this whole mess of code is to integrate deadline ids into what we
            // save in timely
            historyTriggers: historyTriggers.map((ht: IHistoryTriggerInput) => ({
              ...ht,
              triggerDate: dayjs(ht.triggerDate).format('YYYY-MM-DD'),
              historyTriggerDeadlines: ht.historyTriggerDeadlines
                .filter((htd: IHistoryTriggerDeadlineInput) => deadlineIdMap?.[htd.id] !== 'isAlreadyDeleted')
                .map(
                  (htd: IHistoryTriggerDeadlineInput) => ({
                    ...htd,
                    filevineDeadlineId: deadlineIdMap
                      ? deadlineIdMap[htd.id]
                      : undefined,
                    historyTriggerDeadlineServiceMethods: htd
                      .historyTriggerDeadlineServiceMethods
                      .filter((htdsm: IHistoryTriggerDeadlineServiceMethodInput) => deadlineIdMap?.[htdsm.id] !== 'isAlreadyDeleted')
                      .map(
                        (htdsm: IHistoryTriggerDeadlineServiceMethodInput) => ({
                          ...htdsm,
                          filevineDeadlineId: deadlineIdMap
                            ? deadlineIdMap[htdsm.id]
                            : undefined,
                        }),
                      ),
                  }),
                ),
            })),
          },
        },
      });
      return result.data?.saveHistory;
    },
  };
};

export default useHistorySave;

import React, {
  FC, useCallback, useEffect,
} from 'react';
import { useHistory } from 'react-router-dom';
import { getSnackBarService } from '@hooks/useSnackBar';
import { getTenantConfig } from '@src/config';
import { handleAmplifyConfigure } from '@libs/auth';
import useFilevineClient from '@hooks/Filevine/useFilevineClient';

const CognitoSuccess: FC = () => {
  const history = useHistory();
  const { onFVUserConnectSuccess } = useFilevineClient();
  const getTheSessionAsync = useCallback(async () => {
    // Set config and Init Amplify if config returned
    const tc = await getTenantConfig();
    if (tc?.error) {
      getSnackBarService().showSnackBar(tc.error);
      history.push('/');
    }
    if (tc?.appClientID && tc?.cognitoUserPoolID) {
      await handleAmplifyConfigure();
      await onFVUserConnectSuccess();
    }
  }, [history, onFVUserConnectSuccess]);

  useEffect(() => {
    getTheSessionAsync();
  }, [getTheSessionAsync]);

  return (
    <div className="container flex flex-col items-center">
      <div className="flex flex-col max-w-screen-lg p-4">
        <div>
          <h2>Success</h2>
          <p>
            Congratulations on connecting to Filevine. Hold on while we get your connection details.
          </p>
        </div>
      </div>
    </div>
  );
};

export default CognitoSuccess;

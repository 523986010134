import React, {
  FC, useRef, useEffect, useState,
} from 'react';
import Button from '@fv-components/button';
import { TimeSelectorDisplayMode, MeridianMode } from '../TimeSelector';

const css = require('./TimeSelectorHeader.module.css');
const tsCss = require('../TimeSelector.module.css');

interface ITimeSelectorHeaderProps {
  onHoursClicked: VoidFunction;
  onMinutesClicked: VoidFunction;
  onAmClicked: VoidFunction;
  onPmClicked: VoidFunction;
  hour: number;
  minute: number;
  meridianMode: MeridianMode;
  mode: TimeSelectorDisplayMode;
  onHourChanged: (hour: number) => void;
  onMinuteChanged: (minute: number) => void;
}

const TimeSelectorHeader: FC<ITimeSelectorHeaderProps> = (
  {
    onHoursClicked,
    onMinutesClicked,
    onAmClicked,
    onPmClicked,
    hour,
    minute,
    meridianMode,
    mode,
    onHourChanged,
    onMinuteChanged,
  }: ITimeSelectorHeaderProps,
) => {
  const hourButtonContainerRef = useRef<HTMLDivElement>(null);
  const [hourTextInput, setHourTextInput] = useState('');
  const [minuteTextInput, setMinuteTextInput] = useState('');

  // this is the kind of annoying code that happens when i listen to a linter
  // hourText = hour === 0 ? '12' : hour < 10 ? `0${hour} : hour
  // would have been a lot cleaner to me
  let hourText = '';
  if (hour === 0) {
    hourText = '12';
  } else if (hour < 10) {
    hourText = `0${hour}`;
  } else {
    hourText = `${hour}`;
  }
  useEffect(() => {
    if (hourButtonContainerRef) {
      // yes this set timeout was necessary
      // it wouldn't find the button otherwise
      setTimeout(() => {
      // unfortunately i don't have a good way to get a
      // ref of the button control so i had to put a div inside
      // this button and go through its parents to get a ref to the button
      // so i can focus on it.
        const buttonRef = hourButtonContainerRef.current?.firstElementChild as HTMLButtonElement;
        if (buttonRef) {
          buttonRef.focus();
        }
      }, 0);
    }
  }, [hourButtonContainerRef]);

  const onHourKeyUp = (event: React.KeyboardEvent) => {
    switch (event.key) {
      case 'ArrowUp':
        onHourChanged(hour < 12 ? hour + 1 : 1);
        break;
      case 'ArrowDown':
        onHourChanged(hour > 1 ? hour - 1 : 12);
        break;
      case '1':
      case '2':
      case '3':
      case '4':
      case '5':
      case '6':
      case '7':
      case '8':
      case '9':
      case '0':
        if (hourTextInput.length === 0 && event.key !== '0') {
          onHourChanged(+event.key);
          setHourTextInput(event.key);
        } else {
          const combinedString = `${hourTextInput[hourTextInput.length - 1]}${event.key}`;
          const newHour = +combinedString;
          if (newHour < 13) {
            onHourChanged(newHour);
            setHourTextInput(combinedString);
          } else {
            onHourChanged(+event.key);
            setHourTextInput(combinedString);
          }
        }
        break;
      default:
        break;
    }
  };

  const onMinuteKeyUp = (event: React.KeyboardEvent) => {
    switch (event.key) {
      case 'ArrowUp':
        onMinuteChanged(minute < 59 ? minute + 1 : 0);
        break;
      case 'ArrowDown':
        onMinuteChanged(minute > 1 ? minute - 1 : 0);
        break;
      case '1':
      case '2':
      case '3':
      case '4':
      case '5':
      case '6':
      case '7':
      case '8':
      case '9':
      case '0':
        if (minuteTextInput.length === 0) {
          onMinuteChanged(+event.key);
          setMinuteTextInput(event.key);
        } else {
          const combinedString = `${minuteTextInput[minuteTextInput.length - 1]}${event.key}`;
          const newMinute = +combinedString;
          if (newMinute < 60) {
            onMinuteChanged(newMinute);
            setMinuteTextInput(combinedString);
          } else {
            onMinuteChanged(+event.key);
            setMinuteTextInput(combinedString);
          }
        }
        break;
      default:
        break;
    }
  };

  const onAmKeyUp = (event: React.KeyboardEvent) => {
    switch (event.key) {
      case 'Enter':
        onAmClicked();
        break;
      default:
        break;
    }
  };

  const onPmKeyUp = (event: React.KeyboardEvent) => {
    switch (event.key) {
      case 'Enter':
        onPmClicked();
        break;
      default:
        break;
    }
  };

  return (
    <div className={css.container}>
      <div className={tsCss.column}>
        <div className={tsCss.grow} />
      </div>

      <div className={tsCss.grow} ref={hourButtonContainerRef}>
        <Button
          className={[css.timeDisplayText, css.timeButton, mode === TimeSelectorDisplayMode.HOURS ? css.selected : ''].join(' ')}
          onClick={onHoursClicked}
          role="button"
          aria-label="Change Time Selector Mode To Hours"
          onKeyUp={onHourKeyUp}
        >
          {hourText}
        </Button>

      </div>
      <div className={css.timeDisplayText}>:</div>
      <div className={tsCss.grow}>
        <Button
          className={[css.timeDisplayText, css.timeButton, mode === TimeSelectorDisplayMode.MINUTES ? css.selected : ''].join(' ')}
          onFocus={onMinutesClicked}
          role="button"
          aria-label="Change Time Selector Mode To Minutes"
          onKeyUp={onMinuteKeyUp}
        >
          {minute < 10 ? `0${minute}` : minute}
        </Button>
      </div>
      <div className={tsCss.column}>
        <div className={tsCss.row}>
          <Button
            onClick={onAmClicked}
            role="button"
            aria-label="Change To AM"
            outlined={meridianMode === MeridianMode.AM}
            onKeyUp={onAmKeyUp}
          >
            AM
          </Button>
        </div>
        <div className={tsCss.row}>
          <Button
            onClick={onPmClicked}
            role="button"
            aria-label="Change To PM"
            outlined={meridianMode === MeridianMode.PM}
            onKeyUp={onPmKeyUp}
          >
            PM
          </Button>
        </div>
      </div>
    </div>
  );
};

export default TimeSelectorHeader;

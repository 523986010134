// I need these here until I'm ready to tackle accessibility
// In this component
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { FC, useState, useEffect } from 'react';
import { TimeSelectorDisplayMode } from '../TimeSelector';

const css = require('./TimeSelectorClock.module.scss');

interface ITimeSelectorClockProps {
  hour: number;
  minute: number;
  mode: TimeSelectorDisplayMode;
  onHourChanged: (hour: number) => void;
  onMinuteChanged: (minute: number) => void;
  onHourDragEnd: VoidFunction;
}

interface IThumbPosition {
  x: number;
  y: number;
}

const calculateThumbPosition = (handRotation: number): IThumbPosition => {
  const radius = 114;
  let result = { x: 0, y: 0 };
  if (handRotation >= 180 && handRotation <= 270) {
    // top right quadrant of clock
    const rad = ((handRotation - 180) * Math.PI) / 180;
    result = {
      x: Math.sin(rad) * radius,
      y: -1 * Math.cos(rad) * radius,
    };
  } else if (handRotation > 270 && handRotation <= 360) {
    // lower right quadrant of clock
    const rad = ((handRotation - 270) * Math.PI) / 180;
    result = {
      y: Math.sin(rad) * radius,
      x: Math.cos(rad) * radius,
    };
  } else if (handRotation >= 0 && handRotation <= 90) {
    // lower left quadrant of clock
    const rad = (handRotation * Math.PI) / 180;
    result = {
      x: -1 * (Math.sin(rad) * radius),
      y: Math.cos(rad) * radius,
    };
  } else {
    // top left quadrant
    const rad = ((handRotation - 90) * Math.PI) / 180;
    result = {
      y: -1 * Math.sin(rad) * radius,
      x: -1 * Math.cos(rad) * radius,
    };
  }
  return result;
};

// calculates the angle beginning at 12:00 as 0 deg
// given the coordinates from the top left hand corner of the clock div
const calculateAngle = (x: number, y: number): number => {
  // the clock div is 260 x 260 so the center of the clock is at x: 130, y: 130
  // this is calculating the length of the opposite side of the right triangle
  const opposite = y - 130;
  // this is calculating the length of the adjacent side of the right triangle
  const adjacent = x - 130;
  const radians = Math.atan(Math.abs(opposite) / Math.abs(adjacent));
  const degrees = (radians * 180) / Math.PI;

  // we need to take the degrees of the right triangle
  // and adjust it to the quadrant we are working in
  if (opposite <= 0 && adjacent <= 0) {
    // top left quadrant
    return 270 + degrees;
  }
  if (opposite <= 0 && adjacent >= 0) {
    // top right quadrant
    return (90 - degrees);
  }
  if (opposite >= 0 && adjacent >= 0) {
    // bottom right quadrant
    return 90 + degrees;
  }
  // bottom left quadrant
  return 180 + (90 - degrees);
};

// this will calculate the rotation of the clock hand
// value is either hours or minutes
const calculateRotation = (value: number, isHours: boolean): number => {
  let degrees = isHours ? (value * 30) + 180 : (value * 6) + 180;
  degrees = degrees > 360 ? degrees - 360 : degrees;
  return degrees;
};

const TimeSelectorClock: FC<ITimeSelectorClockProps> = (
  {
    hour, minute, mode, onHourChanged, onMinuteChanged, onHourDragEnd,
  }: ITimeSelectorClockProps,
) => {
  const [isHours, setIsHours] = useState(mode === TimeSelectorDisplayMode.HOURS);
  const [pointerRotation, setPointerRotation] = useState(
    calculateRotation(isHours ? hour : minute, isHours),
  );
  const [thumbPosition, setThumbPosition] = useState(calculateThumbPosition(pointerRotation));
  const [isDragging, setIsDragging] = useState(false);

  useEffect(() => {
    const isHourMode = mode === TimeSelectorDisplayMode.HOURS;
    setIsHours(isHourMode);
    const rotation = calculateRotation(isHourMode ? hour : minute, isHourMode);
    setPointerRotation(rotation);
    setThumbPosition(calculateThumbPosition(rotation));
  }, [mode, hour, minute]);

  const updateHands = (offsetX: number, offsetY: number) => {
    const deg = calculateAngle(offsetX, offsetY);
    if (isHours) {
      const h = Math.round(deg / 30);
      if (h <= 12) {
        onHourChanged(h !== 0 ? h : 12);
      }
    } else {
      const m = Math.round(deg / 6);
      if (m <= 60) {
        onMinuteChanged(m !== 60 ? m : 0);
      }
    }
  };

  const onMouseDown = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    setIsDragging(true);
    updateHands(event.nativeEvent.offsetX, event.nativeEvent.offsetY);
  };

  const onMouseUp = () => {
    setIsDragging(false);
    if (isHours) {
      onHourDragEnd();
    }
  };

  const onMouseMove = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if (isDragging) {
      updateHands(event.nativeEvent.offsetX, event.nativeEvent.offsetY);
    }
  };

  const onMouseLeave = () => {
    if (isDragging) {
      onMouseUp();
    }
  };

  return (
    <div className={css.container}>
      <div
        className={[css.squareMask, isDragging ? css.dragging : ''].join(' ')}
        onMouseDown={onMouseDown}
        onMouseUp={onMouseUp}
        onMouseLeave={onMouseLeave}
        onMouseMove={onMouseMove}
      />
      <div className={css.pin} />
      <div
        className={[css.thumb, pointerRotation % 30 === 0 ? css.thumbOnNumber : ''].join(' ')}
        style={{ transform: `translate(${thumbPosition.x}px, ${thumbPosition.y}px)` }}
      />
      <div className={css.pointer} style={{ transform: `rotateZ(${pointerRotation}deg)` }} />
      <div
        className={[css.clockNumber, pointerRotation === 180 ? css.selected : ''].join(' ')}
        style={{ transform: 'translate(0px, -114px)' }}
      >
        {isHours ? 12 : '00'}
      </div>
      <div
        className={[css.clockNumber, pointerRotation === 210 ? css.selected : ''].join(' ')}
        style={{ transform: 'translate(57px, -98.7px)' }}
      >
        {isHours ? 1 : '05'}
      </div>
      <div
        className={[css.clockNumber, pointerRotation === 240 ? css.selected : ''].join(' ')}
        style={{ transform: 'translate(98.7px, -57px)' }}
      >
        {isHours ? 2 : 10}
      </div>
      <div
        className={[css.clockNumber, pointerRotation === 270 ? css.selected : ''].join(' ')}
        style={{ transform: 'translate(114px, 0px)' }}
      >
        {isHours ? 3 : 15}
      </div>
      <div
        className={[css.clockNumber, pointerRotation === 300 ? css.selected : ''].join(' ')}
        style={{ transform: 'translate(98.7px, 57px)' }}
      >
        {isHours ? 4 : 20}
      </div>
      <div
        className={[css.clockNumber, pointerRotation === 330 ? css.selected : ''].join(' ')}
        style={{ transform: 'translate(57px, 98.7px)' }}
      >
        {isHours ? 5 : 25}
      </div>
      <div
        className={[css.clockNumber, pointerRotation === 360 ? css.selected : ''].join(' ')}
        style={{ transform: 'translate(0px, 114px)' }}
      >
        {isHours ? 6 : 30}
      </div>
      <div
        className={[css.clockNumber, pointerRotation === 30 ? css.selected : ''].join(' ')}
        style={{ transform: 'translate(-57px, 98.7px)' }}
      >
        {isHours ? 7 : 35}
      </div>
      <div
        className={[css.clockNumber, pointerRotation === 60 ? css.selected : ''].join(' ')}
        style={{ transform: 'translate(-98.7px, 57px)' }}
      >
        {isHours ? 8 : 40}
      </div>
      <div
        className={[css.clockNumber, pointerRotation === 90 ? css.selected : ''].join(' ')}
        style={{ transform: 'translate(-114px, 0px)' }}
      >
        {isHours ? 9 : 45}
      </div>
      <div
        className={[css.clockNumber, pointerRotation === 120 ? css.selected : ''].join(' ')}
        style={{ transform: 'translate(-98.7px, -57px)' }}
      >
        {isHours ? 10 : 50}
      </div>
      <div
        className={[css.clockNumber, pointerRotation === 150 ? css.selected : ''].join(' ')}
        style={{ transform: 'translate(-57px, -98.7px)' }}
      >
        {isHours ? 11 : 55}
      </div>
    </div>
  );
};

export default TimeSelectorClock;

import React, { FC, useState } from 'react';
import { Dayjs } from 'dayjs';
import Header from '../Header/Header';
import Grid from '../Grid/Grid';
import YearCell from './YearCell';
import YearRow from './YearRow';
import { DisplayMode } from '../DateSelector';

interface IYearsProps {
  date: Dayjs;
  onYearSelect: (date: Dayjs) => void;
  onClose: VoidFunction;
  isVisible: boolean;
  minDate?: Dayjs;
  maxDate?: Dayjs;
}

const Years: FC<IYearsProps> = ({
  date,
  onYearSelect,
  onClose,
  isVisible,
  minDate,
  maxDate,
}: IYearsProps) => {
  const [baseYear, setBaseYear] = useState(date.year() - 3);
  const [buttonText, setButtonText] = useState(`${baseYear}-${baseYear + 23}`);
  const onNavButtonClicked = (year: number) => {
    setBaseYear(year);
    setButtonText(`${year}-${year + 23}`);
  };

  return (
    <div className={isVisible ? 'flex flex-col' : 'hidden'}>
      <Header
        buttonText={buttonText}
        isExpanded
        onNextButtonClick={() => onNavButtonClicked(baseYear + 24)}
        onPrevButtonClick={() => onNavButtonClicked(baseYear - 24)}
        onButtonClick={onClose}
        displayMode={DisplayMode.YEARS}
      />
      <Grid numberOfRows={6} cellsPerRow={4}>
        <YearRow>
          <YearCell
            baseYear={baseYear}
            onYearSelect={onYearSelect}
            date={date}
            minDate={minDate}
            maxDate={maxDate}
          />
        </YearRow>
      </Grid>
    </div>
  );
};

export default Years;

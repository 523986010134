import React, {
  FC, useCallback,
} from 'react';
import { useHistory, useParams } from 'react-router-dom';
import MaterialIcon from '@fv-components/material-icon';
import {
  ICategory, IServiceMethod, IHistory, IMatter,
} from '@models';
import { JurisdictionServiceMethodDropDown, Keywords, AltButton } from '@components';
import CalculatorHeader from '../CalculatorHeader/CalculatorHeader';
import DeadlineSearch from './DeadlineSearch';
import ExportControls from './ExportControls/ExportControls';
import { ICalculationState } from '../Calculator';

const css = require('./Deadlines.module.scss');

interface IDeadlineHeaderProps {
  matter?: IMatter;
  triggerHeadline?: string;
  selectedServiceMethods?: IServiceMethod[];
  jurisdictionId: string;
  onFilterChanged: (filter?: string) => void;
  onServiceMethodChange: (serviceMethods: IServiceMethod[]) => void;
  onCategoriesChanged: (categories?: ICategory[]) => void;
  categories?: ICategory[];
  onMatterUpdate: (matter?: IMatter) => void;
  persistToLocalStorage: VoidFunction;
  calculationState?: ICalculationState;
  onHistorySaved: (history?: IHistory, isFvSyncComplete?: boolean) => void;
  filevineProjectIdFromUrl?: string;
}

const DeadlinesHeader: FC<IDeadlineHeaderProps> = (
  {
    matter,
    jurisdictionId,
    triggerHeadline,
    selectedServiceMethods,
    onFilterChanged,
    onServiceMethodChange,
    onCategoriesChanged,
    categories,
    onMatterUpdate,
    persistToLocalStorage,
    calculationState,
    onHistorySaved,
    filevineProjectIdFromUrl,
  }: IDeadlineHeaderProps,
) => {
  const history = useHistory();
  const { historyId } = useParams<{ historyId?: string }>();

  const onCategoryPicked = useCallback((category?: ICategory) => {
    if (category) {
      const updatedCategories = [...(categories || []), category];
      onCategoriesChanged(updatedCategories);
    }
  }, [categories, onCategoriesChanged]);

  const onBackClick = useCallback(() => {
    onFilterChanged();
    onCategoriesChanged();
    if (historyId) {
      history.push(`/calculator/${historyId}`);
    } else {
      history.push('/calculator');
    }
  }, [history, historyId, onCategoriesChanged, onFilterChanged]);

  const onRemoveCategory = useCallback((category: ICategory) => {
    const updatedCategories = categories?.filter(
      (cat: ICategory) => cat.id !== category.id);
    onCategoriesChanged(updatedCategories);
  }, [categories, onCategoriesChanged]);

  const onResetClick = () => {
    history.push('/calculator/new');
  };

  return (
    <CalculatorHeader
      leftControl={(
        <AltButton
          onClick={onBackClick}
          icon={<MaterialIcon icon="navigate_before" />}
          aria-label="Navigate Back to Matter Details Screen"
          data-cy="navigate-back-to-matter-details"
        >
          Matter Details
        </AltButton>
       )}
      rightControl={(
        <div className="flex flex-row gap-3">
          <ExportControls
            jurisdictionId={jurisdictionId}
            matter={matter}
            calculationState={calculationState}
            persistToLocalStorage={persistToLocalStorage}
            onMatterUpdate={onMatterUpdate}
            onHistorySave={onHistorySaved}
            filevineProjectIdFromUrl={filevineProjectIdFromUrl}
          />
          <AltButton
            onClick={onResetClick}
            icon={<MaterialIcon icon="event" />}
            aria-label="Start New Calculation"
            data-cy="start-new-calculation"
          >
            New
          </AltButton>
        </div>

      )}
      title="Deadlines"
      matterDescription={matter?.description}
      subHeaderElement={(
        <div className={css.container}>
          <div className="flex md:flex-row flex-col justify-end gap-3">
            <JurisdictionServiceMethodDropDown
              jurisdictionId={jurisdictionId}
              onSelect={onServiceMethodChange}
              autoFocus
              filterServiceMethodIds={calculationState?.applicableServiceMethodIds}
              values={selectedServiceMethods}
              disabled={!calculationState?.applicableServiceMethodIds?.length}
            />
            <DeadlineSearch
              onCategoryPicked={onCategoryPicked}
              onFilterChange={onFilterChanged}
            />
          </div>
          <div
            className="py-2 flex flex-row justify-between print:-pt-14 print:pb-0"
            data-cy="deadlinesMatterHeader"
          >
            <div className="flex flex-row gap-1 items-center font-semibold">
              <span>{triggerHeadline || ''}</span>
            </div>
            <Keywords
              categories={categories}
              onCategoryRemove={onRemoveCategory}
            />
          </div>
        </div>
        )}
    />
  );
};

export default DeadlinesHeader;

import {
  useEffect,
} from 'react';
import dayjs from 'dayjs';
import useGoogleQueryCalendars, { IGoogleCalendar } from '@hooks/Google/useGoogleQueryCalendars';
import useGoogleInsertEvent, { IGoogleEvent } from '@hooks/Google/useGoogleInsertEvent';
import { ITriggerCalculationState, IDeadlineCalculationState, IServiceMethodCalculationState } from '@src/App/Calculator/Calculator';
import { getSnackBarService } from '@hooks/useSnackBar';
import { generateDescription } from '@libs/export';
import useGoogleClient from '@hooks/Google/useGoogleClient';
import { IJurisdiction } from '@models';

const formatDate = (date?: Date): string => dayjs(date).format('YYYY-MM-DD');

const getDate = (date: Date, googleCalendar: IGoogleCalendar) => (
  { date: formatDate(date), timeZone: googleCalendar.timeZone }
);

const googleEventBase: Partial<IGoogleEvent> = {
  reminders: {
    useDefault: false,
    overrides: [{ method: 'popup', minutes: 1440 }],
  },
};

const mapServiceMethodDeadlineToGoogleEvents = (
  smd: IServiceMethodCalculationState,
  triggerCalculationState: ITriggerCalculationState,
  deadlineCalculationState: IDeadlineCalculationState,
  googleCalendar: IGoogleCalendar,
  matter: string,
  jurisdiction: IJurisdiction,
): IGoogleEvent => ({
  summary: deadlineCalculationState.deadline.shortDescription || '',
  description: generateDescription(
    triggerCalculationState,
    deadlineCalculationState,
    jurisdiction,
    matter,
    smd,
  ),
  start: getDate(smd.calculatedDate || new Date(), googleCalendar),
  end: getDate(smd.calculatedDate || new Date(), googleCalendar),
  ...googleEventBase,
});

const mapTriggerDeadlineToGoogleEvents = (
  triggerCalculationState: ITriggerCalculationState,
  deadlineCalculationState: IDeadlineCalculationState,
  googleCalendar: IGoogleCalendar,
  matter: string,
  jurisdiction: IJurisdiction,
): IGoogleEvent[] => (deadlineCalculationState.serviceMethods?.map((
  smc: IServiceMethodCalculationState,
) => mapServiceMethodDeadlineToGoogleEvents(
  smc,
  triggerCalculationState,
  deadlineCalculationState,
  googleCalendar,
  matter,
  jurisdiction,
))
 || (deadlineCalculationState.calculatedDate && [{
   summary: `${deadlineCalculationState.deadline.shortDescription}`,
   description: generateDescription(
     triggerCalculationState, deadlineCalculationState, jurisdiction, matter,
   ),
   start: getDate(deadlineCalculationState.calculatedDate, googleCalendar),
   end: getDate(deadlineCalculationState.calculatedDate, googleCalendar),
   ...googleEventBase,
 }]) || []);

export const toGoogleCalendarEvents = (
  triggerCalculationStates: ITriggerCalculationState[],
  calendar: IGoogleCalendar,
  matter: string,
  jurisdiction: IJurisdiction,
) => triggerCalculationStates?.reduce(
        (events: IGoogleEvent[], tcs: ITriggerCalculationState) => events.concat(
          tcs.deadlines?.reduce(
            (d: IGoogleEvent[], ta: IDeadlineCalculationState) => d.concat(
              mapTriggerDeadlineToGoogleEvents(tcs, ta, calendar, matter, jurisdiction),
            ), [],
          ) || [],
        ), [],
  );

interface IGoogleHookResult {
  calendars: IGoogleCalendar[];
  isCalendarsLoading: boolean;
  isSignedIn?: boolean;
  insertEvents: (calendarId: string, events: IGoogleEvent[]) => Promise<Response> | null;
  signIn?: VoidFunction;
  isSaving?: boolean;
}

const useGoogle = () : IGoogleHookResult => {
  const snackBar = getSnackBarService();
  const {
    client,
    signIn,
    accessToken,
  } = useGoogleClient({});
  const {
    getCalendars,
    items: calendars,
    loading,
    called,
    error: isErrorGettingGoogleCalendarList,
  } = useGoogleQueryCalendars({ client });
  const { insertEvents, loading: isSaving } = useGoogleInsertEvent({ accessToken });

  useEffect(() => {
    if (isErrorGettingGoogleCalendarList) {
      snackBar.showSnackBar('There was an error getting your list of Google calendars.');
    }
  }, [isErrorGettingGoogleCalendarList, snackBar]);

  useEffect(() => {
    if (!called && client && getCalendars) {
      getCalendars();
    }
  }, [called, client, getCalendars]);

  return {
    calendars,
    isCalendarsLoading: !!loading,
    isSignedIn: !!client,
    insertEvents,
    signIn,
    isSaving,
  };
};

export default useGoogle;
export type { IGoogleEvent, IGoogleCalendar };

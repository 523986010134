export default interface IPermission {
  id?: string;
  permissionObject?: PermissionObject;
  permissionAction?: PermissionAction;
  // eslint-disable-next-line semi
}

export enum PermissionAction {
  Read = 'READ',
  ReadLimited = 'READ_LIMITED',
  Write = 'WRITE',
  WriteLimited = 'WRITE_LIMITED',
  Delete = 'DELETE',
  DeleteLimited = 'DELETE_LIMITED',
}

export enum PermissionObject {
  Deadline = 'DEADLINE',
  Calculation = 'CALCULATION',
  Category = 'CATEGORY',
  History = 'HISTORY',
  ServiceMethod = 'SERVICE_METHOD',
  Trigger = 'TRIGGER',
  PasswordReset = 'PASSWORD_RESET',
  Role = 'ROLE',
  Permission = 'PERMISSION',
  User = 'USER',
  Invite = 'INVITE',
  Jurisdiction = 'JURISDICTION',
  FilingMethod = 'FILING_METHOD',
  Holiday = 'HOLIDAY',
// eslint-disable-next-line semi
}

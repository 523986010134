import React, { FC } from 'react';
import Select, { Option } from '@fv-components/select';
import IconButton from '@fv-components/icon-button';
import MaterialIcon from '@fv-components/material-icon';

export interface IPagerProps {
  skip: number;
  take: number;
  hasMore: boolean;
  takeOptions?: number[];
  onPagerStateChange?: (pagerState: IPagerState) => void;
  disabled?: boolean;
  hidePagerIfNotNeeded?: boolean,
  numberItemsCurrentlyDisplayed: number;
}

export interface IPagerState {
  skip: number;
  take: number;
}

const Pager: FC<IPagerProps> = ({
  skip,
  take,
  hasMore,
  takeOptions: to,
  onPagerStateChange,
  hidePagerIfNotNeeded,
  disabled,
  numberItemsCurrentlyDisplayed,
}: IPagerProps) => {
  const takeOptions = to || [10, 25, 50, 100];

  const onButtonClick = (newSkip: number) => {
    if (onPagerStateChange) {
      onPagerStateChange({
        skip: newSkip,
        take,
      });
    }
  };

  const onChange = (event: React.FormEvent<HTMLSelectElement>) => {
    if (!Number.isNaN(+event.currentTarget.value)) {
      if (onPagerStateChange) {
        onPagerStateChange({
          skip,
          take: +event.currentTarget.value,
        });
      }
    }
  };

  return (
    <div className="flex flex-row items-center content-between mt-1 mb-1">
      {(!hidePagerIfNotNeeded || hasMore)
        && (
        <>
          <Select
            className="w-28 min-w-max"
            outlined
            label="Page Size"
            onChange={onChange}
            value={take.toString()}
            disabled={disabled}
            data-cy="page-size"
          >
            {takeOptions.map((x) => (
              <Option data-cy-selected={take === x} key={x} value={x}>
                {x}
              </Option>
            ))}
          </Select>
          <div className="ml-3 whitespace-nowrap" data-cy="pager-count">
            {`${numberItemsCurrentlyDisplayed > 0 ? skip + 1 : 0}-${skip + (hasMore ? take : numberItemsCurrentlyDisplayed)} `}
          </div>
          <IconButton
            disabled={skip === 0 || disabled}
            onClick={() => onButtonClick(0)}
            role="button"
            aria-label="First Page"
            data-cy="first-page"
          >
            <MaterialIcon icon="first_page" />
          </IconButton>
          <IconButton
            disabled={skip === 0 || disabled}
            onClick={() => onButtonClick(skip - take < 0 ? 0 : skip - take)}
            role="button"
            aria-label="Previous Page"
            data-cy="previous-page"
          >
            <MaterialIcon icon="chevron_left" />
          </IconButton>
          <IconButton
            disabled={!(hasMore || disabled)}
            onClick={() => onButtonClick(skip + take)}
            role="button"
            aria-label="Next Page"
            data-cy="next-page"
          >
            <MaterialIcon icon="chevron_right" />
          </IconButton>
        </>
        )}
    </div>
  );
};

export default Pager;

import React, { FC } from 'react';
import Button from '@fv-components/button';
import dayjs, { Dayjs } from 'dayjs';
import { isAnyDateValid } from '../DateSelector';

enum Months {
  JAN = 0,
  FEB = 1,
  MAR = 2,
  APR = 3,
  MAY = 4,
  JUN = 5,
  JULY = 6,
  AUG = 7,
  SEP = 8,
  OCT = 9,
  NOV = 10,
  DEC = 11
}

export interface IMonthCellProps {
  row?: number;
  col?: number;
  date: Dayjs;
  onMonthSelect: (date: Dayjs) => void;
  minDate?: Dayjs;
  maxDate?: Dayjs;
}

const MonthCell: FC<IMonthCellProps> = ({
  row,
  col,
  date: d,
  onMonthSelect,
  minDate,
  maxDate,
}: IMonthCellProps) => {
  const monthValue = (row || 0) * 4 + (col || 0);
  const date = dayjs(d).month(monthValue);

  return (
    <Button
      className="flex-1"
      role="button"
      disabled={!isAnyDateValid(date.set('date', 1), date.set('date', 1).add(1, 'month').subtract(1, 'day'), minDate, maxDate)}
      aria-label={`Select ${date.format('MMMM')}`}
      onClick={() => onMonthSelect(date)}
    >
      {Months[monthValue]}
    </Button>
  );
};

export default MonthCell;

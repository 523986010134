import React, { FC, useState } from 'react';
import Dialog, {
  DialogTitle,
  DialogContent,
} from '@fv-components/dialog';
import LinearProgress from '@fv-components/linear-progress';
import TextField, { Input } from '@fv-components/text-field';
import Button from '@fv-components/button';
import { IFilingMethod, IJurisdiction } from '@models';
import { JurisdictionAutoComplete, ModalBottomButtonRow, AltButton } from '@components';
import { IFilingMethodSaveHookResult } from '@hooks/FilingMethod/useFilingMethodSave';
import MaterialIcon from '@fv-components/material-icon';
import { discardChangesDialogService } from '@hooks/useConfirmationDialog';

interface IEditFilingMethodModalProps {
  isOpen: boolean;
  filingMethod?: IFilingMethod;
  onClose: VoidFunction;
  save: IFilingMethodSaveHookResult;
}

export enum FilingMethodEditResult {
  SAVE = 'SAVE',
  CANCEL = 'CANCEL'
}

const EditFilingMethodModal: FC<IEditFilingMethodModalProps> = (
  {
    filingMethod: fm,
    isOpen,
    onClose,
    save,
  }: IEditFilingMethodModalProps,
) => {
  const [isDirty, setIsDirty] = useState(false);
  const [filingMethod, setFilingMethod] = useState<IFilingMethod | undefined>(fm);

  const closeDialog = () => {
    setFilingMethod(undefined);
    onClose();
    setIsDirty(false);
  };

  const saveFilingMethod = () => {
    if (filingMethod?.jurisdiction
      && filingMethod?.description
      && filingMethod?.warningMessage) {
      save.saveFilingMethod(
        filingMethod?.jurisdiction,
        filingMethod?.description,
        filingMethod?.warningMessage,
        filingMethod?.id,
      ).then(() => {
        closeDialog();
      });
    }
  };

  const onCancel = () => {
    if (isDirty) {
      discardChangesDialogService.showDialog(closeDialog);
    } else {
      closeDialog();
    }
  };

  const onJurisdictionChange = (jurisdiction?: IJurisdiction) => {
    setIsDirty(true);
    setFilingMethod({
      ...filingMethod,
      jurisdiction,
    });
  };

  const onDescriptionChange = (description?: string) => {
    setIsDirty(true);
    setFilingMethod({
      ...filingMethod,
      description,
    });
  };

  const onWarningMessageChange = (warningMessage?: string) => {
    setIsDirty(true);
    setFilingMethod({
      ...filingMethod,
      warningMessage,
    });
  };

  return (
    <Dialog
      onClose={closeDialog}
      open={isOpen}
    >
      <DialogTitle>
        {filingMethod?.id ? 'Edit ' : 'Add New '}
        Filing Method
      </DialogTitle>
      <DialogContent style={{ overflow: 'visible' }}>
        <div className="flex flex-col mt-2 gap-3">
          <TextField label="Method" outlined>
            <Input
              required
              value={filingMethod?.description || ''}
              onChange={(event: React.FormEvent<HTMLInputElement>) => {
                onDescriptionChange(event.currentTarget.value);
              }}
            />
          </TextField>
          <JurisdictionAutoComplete
            onSelect={onJurisdictionChange}
            value={filingMethod?.jurisdiction}
            isRequired
          />
          <TextField label="Warning Message" outlined>
            <Input
              required
              value={filingMethod?.warningMessage || ''}
              onChange={(event: React.FormEvent<HTMLInputElement>) => {
                onWarningMessageChange(event.currentTarget.value);
              }}
            />
          </TextField>
        </div>
        <ModalBottomButtonRow>
          <Button
            onClick={saveFilingMethod}
            role="button"
            aria-label="Save Filing Method"
            raised
            disabled={!(filingMethod?.jurisdiction
      && filingMethod?.description
              && filingMethod?.warningMessage)}
            icon={<MaterialIcon icon="save" />}
          >
            Save
          </Button>
          <AltButton
            onClick={onCancel}
            aria-label="Cancel Filing Method Edit"
            icon={<MaterialIcon icon="cancel" />}
          >
            Cancel
          </AltButton>
        </ModalBottomButtonRow>
      </DialogContent>
      <>
        {save.result.loading && <LinearProgress indeterminate />}
      </>
    </Dialog>
  );
};

export default EditFilingMethodModal;

/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { FC, useState, useEffect } from 'react';
import SortIndicator from './SortIndicator';
import Filter from './Filter';

interface IHeaderCellProps {
  onSort?: (field?: string, sortDirection?: SortDirection) => void;
  onFilter?: (field: string, value: any) => void;
  sortDirection?: SortDirection;
  field: string;
  header?: string;
  isSortable?: boolean;
  isFilterable?: boolean;
  disabled?: boolean;
  filter?: any;
}

export enum SortDirection {
  ASC = 'ASC',
  DESC = 'DESC'
}

const HeaderCell: FC<IHeaderCellProps> = ({
  onSort,
  onFilter,
  sortDirection,
  field,
  header,
  isSortable,
  isFilterable,
  disabled,
  filter: defaultFilter,
}) => {
  const [filter, setFilter] = useState<any>(defaultFilter);

  useEffect(() => {
    setFilter(defaultFilter);
  }, [defaultFilter]);

  const onClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if (onSort
      && !disabled
      && isSortable
      // checking to make sure the click event hasn't bubbled up from
      // clicking on the filter icon
      && (event.target as HTMLElement).innerText !== 'filter_list'
    ) {
      switch (sortDirection) {
        case SortDirection.ASC:
          onSort(field, SortDirection.DESC);
          break;
        case SortDirection.DESC:
          onSort(undefined, undefined);
          break;
        default:
          onSort(field, SortDirection.ASC);
          break;
      }
    }
  };

  const onFiltered = (value: any) => {
    setFilter(value);
    if (onFilter) {
      onFilter(field, value);
    }
  };

  const getAriaLabel = () => {
    switch (sortDirection) {
      case SortDirection.ASC:
        return `Click to sort column ${header} descending`;
      case SortDirection.DESC:
        return `Click to remove sort from column ${header}`;
      default:
        return `Click to sort column ${header} ascending`;
    }
  };

  return (
    // eslint-disable-next-line jsx-a11y/interactive-supports-focus
    <div
      className={`flex-1 flex flex-row items-center  ${isSortable ? 'group cursor-pointer' : ''}`}
      onClick={onClick}
      role="button"
      aria-label={getAriaLabel()}
      data-cy={`table-header-${header}`}
    >
      <div
        className="overflow-hidden overflow-ellipsis whitespace-nowrap font-semibold"
      >
        {header}
      </div>

      {isFilterable
        && (
        <Filter
          onFilter={onFiltered}
          filter={filter}
          disabled={disabled}
        />
        )}
      <SortIndicator
        sort={sortDirection}
        isSortable={isSortable}
      />
    </div>
  );
};

export default HeaderCell;

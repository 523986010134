import { ApolloError, FetchPolicy } from '@apollo/client';
import { IDeleteHookResult, useTimelyDelete } from '@hooks/useTimelyDelete';
import IInviteQuery from '@models/Identity/Invite/IInviteQuery';
import IInvite from '@models/Identity/Invite/IInvite';
import useTimelyQuery from '@hooks/useTimelyQuery';
import useInviteSave, { IInviteSaveHookResult } from './useInviteSave';
import useInviteSend, { IInviteSendHookResult } from './useInviteSend';
import useInviteResend, { IInviteResendHookResult } from './useInviteResend';

type InviteQueryHook = (
  props: IInviteQueryHookProps
) => IInviteQueryHookResult;

export interface IInviteQueryHookProps {
  queryObject: IInviteQuery;
  fields: string[];
  lazy?: boolean;
  fetchPolicy?: FetchPolicy;
}

interface IInviteQueryHookResult {
  loading: boolean;
  error?: ApolloError;
  result: { hasMore: boolean; items: IInvite[] };
  save: IInviteSaveHookResult;
  delete: IDeleteHookResult;
  send: IInviteSendHookResult;
  resend: IInviteResendHookResult;
  load: VoidFunction;
  called: boolean;
}

const useInviteQuery: InviteQueryHook = (
  {
    queryObject, fields, lazy, fetchPolicy,
  }: IInviteQueryHookProps,
) => {
  const {
    loading, error, result, queryString, load, called,
  } = useTimelyQuery<IInvite>({
    variables: { queryObject },
    lazy,
    collection: 'invites',
    queryInputType: 'InviteQueryInputType',
    fields,
    fetchPolicy,
  });

  return {
    called,
    load,
    loading,
    error,
    result,
    save: useInviteSave({ queryString, queryObject }),
    send: useInviteSend({ queryString, queryObject }),
    delete: useTimelyDelete({ capitalizedType: 'Invite' }),
    resend: useInviteResend(),
  };
};

export default useInviteQuery;

import { ApolloError, FetchPolicy } from '@apollo/client';
import IServiceMethodGroup from '@models/Deadlines/ServiceMethodGroups/IServiceMethodGroup';
import IServiceMethodGroupQuery from '@models/Deadlines/ServiceMethodGroups/IServiceMethodGroupQuery';
import useServiceMethodGroupSave, { IServiceMethodGroupSaveHookResult } from './useServiceMethodGroupSave';
import { IDeleteHookResult, useTimelyDelete } from '../useTimelyDelete';
import useTimelyQuery from '../useTimelyQuery';

export interface IServiceMethodGroupQueryHookProps {
  queryObject: IServiceMethodGroupQuery;
  fields: string[];
  lazy?: boolean;
  fetchPolicy?: FetchPolicy;
}

type ServiceMethodGroupQueryHook = (
  props: IServiceMethodGroupQueryHookProps
) => IServiceMethodGroupQueryHookResult;

interface IServiceMethodGroupQueryHookResult {
  loading: boolean;
  error?: ApolloError;
  result: { hasMore: boolean; items: IServiceMethodGroup[] };
  save: IServiceMethodGroupSaveHookResult;
  delete: IDeleteHookResult;
  called: boolean;
  load: VoidFunction;
}

const useServiceMethodGroupQuery: ServiceMethodGroupQueryHook = (
  {
    fields, queryObject, lazy, fetchPolicy,
  }: IServiceMethodGroupQueryHookProps,
) => {
  const {
    loading, error, result, queryString, called, load,
  } = useTimelyQuery<IServiceMethodGroup>({
    variables: { queryObject },
    lazy,
    collection: 'serviceMethodGroups',
    fields,
    queryInputType: 'ServiceMethodGroupQueryInputType',
    fetchPolicy,
  });

  return {
    called,
    load,
    loading,
    error,
    result,
    save: useServiceMethodGroupSave({ queryString, queryObject }),
    delete: useTimelyDelete({ capitalizedType: 'ServiceMethodGroup' }),
  };
};

export default useServiceMethodGroupQuery;

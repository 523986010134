import React, { FC } from 'react';
import { IMonthCellProps } from './MonthCell';

interface IMonthRowProps {
  row?: number;
  children: React.ReactElement<IMonthCellProps>;
}

const MonthRow: FC<IMonthRowProps> = ({ children }: IMonthRowProps) => (
  <div className="flex flex-row">{children}</div>);

export default MonthRow;

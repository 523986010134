import {
  useMutation, gql, FetchResult,
} from '@apollo/client';
import {
  ITriggerInheritanceBlock,
} from '@models';
import { getSnackBarService } from '@hooks/useSnackBar';

type TriggerInheritanceBlockMutateHook = () => IResult;

interface IAddResult {
  addTriggerInheritanceBlock: ITriggerInheritanceBlock;
}

interface IRemoveResult {
  removeTriggerInheritanceBlock: ITriggerInheritanceBlock;
}

interface IResult {
  addBlock: (
    block: ITriggerInheritanceBlock,
  ) => Promise<ITriggerInheritanceBlock | undefined>;
  removeBlock: (
    block: ITriggerInheritanceBlock,
  ) => Promise<ITriggerInheritanceBlock | undefined>;
}

const ADD_GQL = gql`
    mutation TriggerInheritanceBlockMutation($item: TriggerInheritanceBlockInputType!) {
      addTriggerInheritanceBlock(item: $item) {
        triggerId
        jurisdictionId
      }
    }
  `;

const REMOVE_GQL = gql`
    mutation TriggerInheritanceBlockMutation($item: TriggerInheritanceBlockInputType!) {
      removeTriggerInheritanceBlock(item: $item) {
        triggerId
        jurisdictionId
      }
    }
  `;

const useTriggerInheritanceBlockMutate: TriggerInheritanceBlockMutateHook = () => {
  const [add] = useMutation<IAddResult>(ADD_GQL);
  const [remove] = useMutation<IRemoveResult>(REMOVE_GQL);

  const { showSnackBar } = getSnackBarService();

  return {
    addBlock: (item: ITriggerInheritanceBlock) => add({
      variables: {
        item,
      },
    }).then((result: FetchResult<IAddResult>) => {
      showSnackBar('Blocked');
      return result.data?.addTriggerInheritanceBlock;
    })
      .catch(() => {
        showSnackBar('There was an error adding the trigger inheritance block');
        return undefined;
      }),
    removeBlock: (item: ITriggerInheritanceBlock) => remove({
      variables: {
        item,
      },
    }).then((result: FetchResult<IRemoveResult>) => {
      showSnackBar('Block Removed');
      return result.data?.removeTriggerInheritanceBlock;
    })
      .catch(() => {
        showSnackBar('There was an error removing the trigger inheritance block');
        return undefined;
      }),
  };
};

export default useTriggerInheritanceBlockMutate;

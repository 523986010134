import React, { FC, useState } from 'react';
import Grid, { Row, Cell } from '@fv-components/layout-grid';
import Card from '@fv-components/card';
import InviteAdminPage from '@src/App/Admin/InviteAdminPage/InviteAdminPage';
import type { IOrganization } from '@models';
import UserAdminPage from '../Admin/UserAdminPage/UserAdminPage';

interface IUserManagerProps {
  organization?: IOrganization;
}

const UserManager: FC<IUserManagerProps> = ({
  organization,
}) => {
  const [isEditingInvite, setIsEditingInvite] = useState(false);
  const [isEditingUser, setIsEditingUser] = useState(false);

  return (
    <Grid>
      <Row>
        <Cell desktopColumns={1} />
        <Cell desktopColumns={10} phoneColumns={4} tabletColumns={8}>
          <Card className="p-6">
            <h1>{`Organization: ${organization?.description || ''}`}</h1>
            <div className={isEditingUser ? 'hidden' : ''}>
              <InviteAdminPage
                organization={organization}
                onIsEditingChange={setIsEditingInvite}
              />
            </div>
            <div className={isEditingInvite ? 'hidden' : ''}>
              <UserAdminPage
                isLimited
                onIsEditingChange={setIsEditingUser}
              />
            </div>
          </Card>
        </Cell>
        <Cell desktopColumns={1} />
      </Row>
    </Grid>
  );
};

export default UserManager;

import React, {
  FC,
} from 'react';

interface IViewRowProps {
  children: JSX.Element | (JSX.Element | boolean)[] | boolean;
  rightToLeft?: boolean;
  spaceBetween?: boolean;
}

const ViewRow: FC<IViewRowProps> = ({ children, rightToLeft, spaceBetween }) => (
  <div className={`flex flex-col md:flex-row gap-3${rightToLeft ? ' md:justify-end' : ''}${spaceBetween ? ' justify-between' : ''}`}>
    {children}
  </div>
);

export default ViewRow;

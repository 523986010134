import dayjs from 'dayjs';
import { createEvents, EventAttributes, DateArray } from 'ics-dayjs';
import { IDeadlineCalculationState, ITriggerCalculationState, IServiceMethodCalculationState } from '@src/App/Calculator/Calculator';
import { generateDescription } from '@libs/export';
import { IJurisdiction } from '@models';

const baseEventAttributes: EventAttributes = {
  start: [0, 0, 0],
  duration: { hours: 0, minutes: 0 },
  alarms: [{
    action: 'display',
    trigger: { hours: 24, before: true },
  }],
  productId: 'timely',
};

const formatDate = (date: Date): DateArray => [
  dayjs(date).year(),
  dayjs(date).month() + 1,
  dayjs(date).date(),
];

const mapServiceMethodDeadlineToEventAttributes = (
  smcs: IServiceMethodCalculationState,
  deadlineCalculationState: IDeadlineCalculationState,
  triggerCalculationState: ITriggerCalculationState,
  matter: string,
  jurisdiction: IJurisdiction,
): EventAttributes => ({
  ...baseEventAttributes,
  title: `${smcs.serviceMethod.description} ${deadlineCalculationState.deadline.shortDescription}`,
  description: generateDescription(
    triggerCalculationState, deadlineCalculationState, jurisdiction, matter, smcs,
  ),
  start: formatDate(smcs.calculatedDate || new Date()),
}
);

const mapDeadlineToEventAttribute = (
  deadlineCalculationState: IDeadlineCalculationState,
  triggerCalculationState: ITriggerCalculationState,
  matter: string,
  jurisdiction: IJurisdiction,
): EventAttributes => ({
  ...baseEventAttributes,
  title: `${deadlineCalculationState.deadline.shortDescription}`,
  description: generateDescription(
    triggerCalculationState, deadlineCalculationState, jurisdiction, matter,
  ),
  // new Date() will never be needed here because we part a guard ahead of the method call
  // this was necessary for typing though
  start: formatDate(deadlineCalculationState.calculatedDate || new Date()),
});

const mapTriggerDeadlineToEventAttributes = (
  deadlineCalculationState: IDeadlineCalculationState,
  triggerCalculationState: ITriggerCalculationState,
  matter: string,
  jurisdiction: IJurisdiction,
): EventAttributes[] => (
    deadlineCalculationState.serviceMethods?.map(
      (smcs: IServiceMethodCalculationState) => mapServiceMethodDeadlineToEventAttributes(
        smcs,
        deadlineCalculationState,
        triggerCalculationState,
        matter,
        jurisdiction,
      ))
    || (deadlineCalculationState.calculatedDate
      && [mapDeadlineToEventAttribute(
        deadlineCalculationState, triggerCalculationState, matter, jurisdiction,
      )])
    || []);

const getDownloadUrl = (icsFileContent: string): string => {
  const data = new Blob([icsFileContent], { type: 'text/plain' });
  return window.URL.createObjectURL(data);
};

const exportFile = (icsFileContents: string, matter: string, triggerDescription: string) => {
  const link = document.createElement('a');
  link.setAttribute('download', `${matter}-${triggerDescription}.ics`);
  link.href = getDownloadUrl(icsFileContents);
  document.body.appendChild(link);

  // wait for the link to be added to the document
  window.requestAnimationFrame(() => {
    const event = new MouseEvent('click');
    link.dispatchEvent(event);
    document.body.removeChild(link);
  });
};

interface IExportIcsHook {
  exportIcsFile: (
    deadlineCalculationState: IDeadlineCalculationState[],
    triggerCalculationState: ITriggerCalculationState,
    matter: string,
    jurisdiction: IJurisdiction,
  ) => void;
}

const useExportIcs = () : IExportIcsHook => {
  const exportIcsFile = (
    deadlineCalculationStates: IDeadlineCalculationState[],
    triggerCalculationState: ITriggerCalculationState,
    matter: string,
    jurisdiction: IJurisdiction,
  ) => {
    const eventAttributes = deadlineCalculationStates.reduce(
      (d: (EventAttributes)[], dcs: IDeadlineCalculationState) => d.concat(
        mapTriggerDeadlineToEventAttributes(
          dcs, triggerCalculationState, matter, jurisdiction,
        ),
      ), [],
    );

    const callback = (error?: Error, value?: string) => {
      if (!error && value && triggerCalculationState.trigger?.description) {
        exportFile(value, matter, triggerCalculationState.trigger.description);
      }
    };
    createEvents(eventAttributes, callback);
  };
  return {
    exportIcsFile,
  };
};

export default useExportIcs;

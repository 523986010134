import {
  useMutation, ApolloError, FetchResult, DocumentNode, gql,
} from '@apollo/client';
import { handleSaveResultWithInputType } from '@libs/apollo';
import {
  IServiceMethod, IServiceMethodGroup, IJurisdiction, IJurisdictionQuery, IJurisdictionInput,
} from '@models';

type JurisdictionSaveHook = (
  props: IJurisdictionSaveHookProps
) => IJurisdictionSaveHookResult;

interface IJurisdictionSaveHookProps {
  queryObject?: IJurisdictionQuery;
  queryString?: DocumentNode;
}

interface IJurisdictionSaveResult {
  saveJurisdiction: IJurisdiction;
}

export interface IJurisdictionSaveHookResult {
  result: {
    error?: ApolloError;
    loading: boolean;
    called: boolean;
  };
  saveJurisdiction: (
    description: string,
    id?: string,
    parent?: IJurisdiction,
    isRoundBeforeCalculation?: boolean,
    isRoundedBetweenServiceAndDeadlineCalculation?: boolean,
    isServiceCalculatedBeforeDeadline?: boolean,
    notes?: string,
    serviceMethods?: IServiceMethod[],
    serviceMethodGroups?: IServiceMethodGroup[],
    serviceMethodInheritanceBlocks?: string[],
    serviceMethodGroupInheritanceBlocks?: string[],
    triggerInheritanceBlocks?: string[],
    deadlineInheritanceBlocks?: string[],
  ) => Promise<FetchResult<IJurisdiction>>;
}

const useJurisdictionSave: JurisdictionSaveHook = (
  { queryString, queryObject }: IJurisdictionSaveHookProps,
) => {
  const SAVE_JURISDICTION = gql`
    mutation JurisdictionMutation($jurisdiction: JurisdictionInputType!) {
      saveJurisdiction(item: $jurisdiction) {
        id
        address
      }
    }
  `;

  const [saveFunc, {
    error, loading, called, client,
  }] = useMutation<IJurisdictionSaveResult>(SAVE_JURISDICTION);

  return {
    result: {
      error,
      loading,
      called,
    },
    saveJurisdiction: (
      description: string,
      id?: string,
      parent?: IJurisdiction,
      isRoundBeforeCalculation?: boolean,
      isRoundedBetweenServiceAndDeadlineCalculation?: boolean,
      isServiceCalculatedBeforeDeadline?: boolean,
      notes?: string,
      serviceMethods?: IServiceMethod[],
      serviceMethodGroups?: IServiceMethodGroup[],
      serviceMethodInheritanceBlocks?: string[],
      serviceMethodGroupInheritanceBlocks?: string[],
      triggerInheritanceBlocks?: string[],
      deadlineInheritanceBlocks?: string[],
    ) => {
      const jurisdiction = {
        id,
        parent: (parent || null) as IJurisdiction | undefined,
        description,
        parentId: (parent?.id || null) as string,
        isRoundBeforeCalculation,
        isRoundedBetweenServiceAndDeadlineCalculation,
        isServiceCalculatedBeforeDeadline,
        notes,
        serviceMethods: serviceMethods?.map(
                (x: IServiceMethod) => ({
                  ...x,
                  __typename: undefined,
                  jurisdiction: undefined,
                  jurisdictionId: id || undefined,
                }),
              ),
        serviceMethodGroups: serviceMethodGroups?.map(
                (x: IServiceMethodGroup) => ({
                  ...x,
                  __typename: undefined,
                  jurisdiction: undefined,
                  jurisdictionId: id || undefined,
                  serviceMethods: x.serviceMethods?.map(
                (y: IServiceMethod) => ({
                  ...y,
                  __typename: undefined,
                  jurisdiction: undefined,
                  // sometimes the jurisdiction of the service method is different from
                  // the service method group and the jurisdiction being saved
                  jurisdictionId: y.jurisdictionId || id || undefined,
                }),
              ),
                }),
        ),
        serviceMethodInheritanceBlocks,
        serviceMethodGroupInheritanceBlocks,
        triggerInheritanceBlocks,
        deadlineInheritanceBlocks,
      };
      return saveFunc({
        variables: {
          jurisdiction: {
            ...jurisdiction,
            parent: undefined,
          },
        },
      }).then((result: FetchResult<IJurisdictionSaveResult>) => ({
        ...result,
        data: {
          ...handleSaveResultWithInputType<IJurisdictionInput, IJurisdiction>(
            result.data?.saveJurisdiction || '',
            jurisdiction,
            client,
            'jurisdictions',
            queryObject,
            queryString,
          ),
        },
      }));
    },
  };
};

export default useJurisdictionSave;

import MenuSurface, {
  Corner,
} from '@material/react-menu-surface';
import './MenuSurface.scss';

export default MenuSurface;

export {
  Corner,
};

import Button from '@fv-components/button';
import React, { FC } from 'react';

export interface IAltButtonProps {
  onClick?: (event: React.MouseEvent<any, MouseEvent>) => void;
  children?: JSX.Element | string;
  icon?: React.ReactElement<React.HTMLProps<HTMLOrSVGElement>>;
  trailingIcon?: React.ReactElement<React.HTMLProps<HTMLOrSVGElement>>;
  dense?: boolean;
  disabled?: boolean;
  className?: string;
}

const AltButton: FC<IAltButtonProps> = ({
  onClick,
  icon,
  trailingIcon,
  children,
  dense,
  disabled,
  className,
  ...rest
}: IAltButtonProps) => (
  <Button
    onClick={onClick}
    icon={icon}
    trailingIcon={trailingIcon}
    role="button"
    outlined
    dense={dense}
    className={`border-none shadow-md disabled:shadow-none bg-white text-blue disabled:bg-gray-light disabled:text-gray ${className || ''}`}
    disabled={disabled}
    // I want prop spreading to forward on data and aira arttibutes
    // eslint-disable-next-line react/jsx-props-no-spreading
    {...rest}
  >
    {children}
  </Button>
);

export default AltButton;

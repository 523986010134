import React, {
  FC, useEffect, useState, useCallback,
} from 'react';
import { proxy, snapshot } from 'valtio';
import ConfirmationDialog, { IConfirmationDialogProps } from '@components/ConfirmationDialog/ConfirmationDialog';

interface IConfirmationHookResult {
  ConfirmationDialog: FC;
}

interface IConfirmationService {
  isInitialized: boolean;
  showDialog: (
    text: string,
    title?: string,
    okButtonText?: string,
    cancelButtonText?: string,
    isCancelButtonHidden?: boolean,
  ) => Promise<boolean>;
}

const confirmationDialogService = proxy<IConfirmationService>(
  {
    isInitialized: false,
    showDialog: () => { throw new Error('service has not been initialized'); },
  },
);

const getConfirmationDialogService = () => snapshot(confirmationDialogService);

const useConfirmationDialog: () => IConfirmationHookResult = () => {
  const [isVisible, setIsVisible] = useState<boolean>(false);
  const [
    confirmationDialogProps,
    setConfirmationDialogProps,
  ] = useState<IConfirmationDialogProps>({ onClose: () => {}, text: '' });

  const confirmationDialog = useCallback(() => (
    <ConfirmationDialog
      isOpen={isVisible}
      text={confirmationDialogProps?.text || ''}
      title={confirmationDialogProps?.title}
      cancelButtonText={confirmationDialogProps?.cancelButtonText}
      okButtonText={confirmationDialogProps?.okButtonText}
      isCancelButtonHidden={confirmationDialogProps?.isCancelButtonHidden}
      onClose={confirmationDialogProps?.onClose}
    />
  ), [confirmationDialogProps, isVisible]);

  const showDialog = useCallback((
    text: string,
    title?: string,
    okButtonText?: string,
    cancelButtonText?: string,
    isCancelButtonHidden?: boolean,
  ) => new Promise<boolean>((resolve) => {
    setIsVisible(true);
    setConfirmationDialogProps({
      text,
      title,
      okButtonText,
      cancelButtonText,
      onClose: (isConfirmed: boolean) => {
        setIsVisible(false);
        resolve(isConfirmed);
      },
      isCancelButtonHidden,
    });
  }), []);

  // I only want this to run once
  useEffect(() => {
    confirmationDialogService.showDialog = showDialog;
    confirmationDialogService.isInitialized = true;
  }, [showDialog]);

  return {
    ConfirmationDialog: confirmationDialog,
  };
};

const discardChangesDialogService = {
  isInitialized: confirmationDialogService.isInitialized,
  showDialog: (ifConfirmed: VoidFunction) => confirmationDialogService
    .showDialog('Are you sure you wish to discard your changes?', 'Discard Changes?', 'Yes', 'No')
    .then((isConfirmed: boolean) => {
      if (isConfirmed) {
        ifConfirmed();
      }
    }),
};

export { getConfirmationDialogService, confirmationDialogService, discardChangesDialogService };
export default useConfirmationDialog;

import React, {
  FC, useState, useEffect, useRef, useCallback,
} from 'react';
import TextField, { Input } from '@fv-components/text-field';
import MaterialIcon from '@fv-components/material-icon';
import { IDeadline } from '@models';
import useDeadlineQuery from '@hooks/Deadline/useDeadlineQuery';
import useLookupInput, { ISearchState } from '@hooks/useLookupInput';
import { InputLinearProgress, NewLookupMenu } from '@components';

interface IDeadlineAutoCompleteProps {
  onSelect: (deadline?: IDeadline) => void;
  jurisdictionIds?: string[];
  value?: IDeadline;
  blockIdsFromSelection?: string[];
}

const fields = [
  'id',
  'description',
  'shortDescription',
  'triggerDeadlines { id triggerId deadlineId trigger { id description } serviceMethods { id description } serviceMethodGroups { id description serviceMethods { id description } } }',
  'specReference',
  'refLink',
  'interval',
  'duration',
  'calendar',
  'extensionDuration',
  'extensionInterval',
  'extensionCalendar',
  'roundingDirection',
  'extensionRoundingDirection',
  'categories { id description }',
  'jurisdiction { id description address }',
  'calculation',
  'extensionCalculation',
];

const DeadlineAutoComplete: FC<IDeadlineAutoCompleteProps> = (
  {
    jurisdictionIds,
    onSelect,
    value,
    blockIdsFromSelection,
  }: IDeadlineAutoCompleteProps,
) => {
  const [isDeadlineSelected, setIsDeadlineSelected] = useState(!!value?.id);
  const [showMenu, setShowMenu] = useState(false);
  const inputRef = useRef<any | null>(null);
  const [items, setItems] = useState<IDeadline[]>();
  const [filteredItems, setFilteredItems] = useState<IDeadline[]>();
  const [inputValue, setInputValue] = useState<string | undefined>(value?.description);

  const {
    result, loading, load, called,
  } = useDeadlineQuery({
    queryObjects: [{
      jurisdictionIds,
      skip: 0,
      take: jurisdictionIds ? 500 : 0,
    }],
    fields,
    lazy: true,
  });

  const onSearchUpdate = (searchState?: ISearchState) => {
    if (!called) {
      load();
    }
    setShowMenu(true);
    setIsDeadlineSelected(false);

    const filtered = items?.filter(
      (t: IDeadline) => (t.description
        && t.description?.toLowerCase()
          .indexOf(searchState?.searchTerm?.toLowerCase() || '') >= 0)
        || (t.specReference
          && t.specReference?.toLowerCase()
            .indexOf(searchState?.searchTerm?.toLowerCase() || '') >= 0)
        || (t.calculation
          && t.calculation?.toLowerCase()
            .indexOf(searchState?.searchTerm?.toLowerCase() || '') >= 0)
      ,
    );
    setFilteredItems(filtered);
    onSelect(undefined);
  };

  const {
    inputOnChangeHandler,
  } = useLookupInput({
    debounce: 500,
    inputRefCurrent: inputRef?.current,
    callback: onSearchUpdate,
    setInputValue,
  });

  useEffect(() => {
    if (!loading && result.items[0]?.length) {
      if (blockIdsFromSelection) {
        const deadlines = result.items[0].filter(
          (d: IDeadline) => d.id && !blockIdsFromSelection.includes(d?.id),
        );
        setItems(deadlines);
        setFilteredItems(deadlines);
      } else {
        setItems(result.items[0]);
        setFilteredItems(result.items[0]);
      }
    }
  }, [loading, result.items, blockIdsFromSelection]);

  // this is here to see if someone clears out a value that was
  // supplied and whipe out the input field
  useEffect(() => {
    if (!value?.id) {
      setInputValue('');
      setIsDeadlineSelected(false);
    }
  }, [value]);

  const onLookupSelect = useCallback((deadline: IDeadline) => {
    setInputValue(deadline.description);
    onSelect(deadline);
    setShowMenu(false);
    setIsDeadlineSelected(true);
  }, [onSelect]);

  const onMenuClosed = useCallback(() => {
    setShowMenu(false);
  }, []);

  const onFocus = () => {
    if (!isDeadlineSelected) {
      if (!called) {
        load();
      }
      setShowMenu(true);
    }
  };

  // this is required because clicking into the input field
  // would randomly close the lookup menu for some reason i can't determine
  const onClick = (event: React.MouseEvent<HTMLInputElement, MouseEvent>) => {
    event.stopPropagation();
  };

  return (
    <div className="flex flex-col flex-1">
      <NewLookupMenu<IDeadline>
        isOpen={showMenu && !!filteredItems?.length}
        onMenuItemSelected={onLookupSelect}
        descriptionField="description"
        keyField="id"
        onMenuClosed={onMenuClosed}
        inputRef={inputRef}
        items={filteredItems}
        captionFields={['specReference', 'calculation', 'extensionCalculation']}
      >
        <TextField
          label="Select Deadline"
          className="w-full"
          outlined
          trailingIcon={isDeadlineSelected ? <MaterialIcon icon="done" /> : undefined}
        >
          <Input
            value={inputValue}
            onChange={inputOnChangeHandler}
            ref={inputRef}
            disabled={!jurisdictionIds}
            onFocus={onFocus}
            onClick={onClick}
            data-cy="deadline"
          />
        </TextField>
      </NewLookupMenu>
      {loading && <InputLinearProgress />}
    </div>
  );
};

export default DeadlineAutoComplete;

import React, {
  FC, useState,
} from 'react';
import { IJurisdiction } from '@models';
import useJurisdictionQuery from '@hooks/Jurisdiction/useJurisdictionQuery';
import AutoComplete from './AutoComplete';

interface IJurisdictionAutoCompleteProps {
  onSelect: (jurisdiction?: IJurisdiction) => void,
  value?: IJurisdiction;
  mustBeBelowJurisdiction?: IJurisdiction;
  mustBeAboveJurisdiction?: IJurisdiction;
  isDisabled?: boolean;
  isAutoSelect?: boolean;
  label?: string;
  isRequired?: boolean;
  isSticky?: boolean;
}

const fields = [
  'id',
  'description',
  'address',
  'path',
  'parent { id description }',
  'deadlineInheritanceBlocks { jurisdictionId deadlineId }',
];

const jurisdictionPageSize = 50;

const JurisdictionAutoComplete: FC<IJurisdictionAutoCompleteProps> = (
  {
    onSelect,
    value,
    mustBeBelowJurisdiction,
    mustBeAboveJurisdiction,
    isDisabled,
    isAutoSelect,
    label,
    isRequired,
    isSticky,
  }: IJurisdictionAutoCompleteProps,
) => {
  const [filter, setFilter] = useState<string>();
  const [take, setTake] = useState(jurisdictionPageSize);

  const {
    result, loading, load, called,
  } = useJurisdictionQuery({
    queryObject: {
      address: mustBeBelowJurisdiction?.address,
      description: filter,
      skip: 0,
      take,
      ids: mustBeAboveJurisdiction?.address ? mustBeAboveJurisdiction?.address.split('.') : undefined,
    },
    fields,
    lazy: !filter,
  });

  const onLoadMore = () => {
    setTake((current: number) => current + jurisdictionPageSize);
  };

  return (
    <div className="flex flex-col gap-3">
      <AutoComplete<IJurisdiction>
        label={label || 'Select Jurisdiction'}
        value={value}
        isDisabled={isDisabled}
        isAutoSelect={isAutoSelect}
        captionFields={['path']}
        descriptionField="description"
        cypressLabel="jurisdiction"
        called={called}
        load={load}
        hasMore={result.hasMore}
        items={result.items}
        onSelect={onSelect}
        onLoadMore={onLoadMore}
        loading={loading}
        onFilterChange={(f?: string) => setFilter(f)}
        isRequired={isRequired}
        isSticky={isSticky}
      />
      <div className="text-gray pl-12 mt-1 text-sm h-5">{value?.path}</div>
    </div>
  );
};

export default JurisdictionAutoComplete;

import MaterialIcon from '@fv-components/material-icon';
import { useHistory } from 'react-router-dom';
import TextField, { Input } from '@fv-components/text-field';
import { AltButton } from '@components';
import React, {
  FC, useState, useEffect,
} from 'react';
import {
  IJurisdiction, IDeadlineQuery, IDeadline, ICategory,
} from '@models';
import useDeadlineQuery from '@hooks/Deadline/useDeadlineQuery';
import Deadline from './DeadlineList/Deadline';

interface IDeadlineSearchResultsViewProps {
  queryObject: Partial<IDeadlineQuery>;
  jurisdiction: IJurisdiction;
}

const fields = [
  'id',
  'description',
  'shortDescription',
  'specReference',
  'refLink',
  'calculation',
  'extensionCalculation',
  'categories { id description }',
  'jurisdiction { id description address }',
  'secondExtensionCalculation',
  'thirdExtensionCalculation',
  'fourthExtensionCalculation',
  'fifthExtensionCalculation',
];

const DeadlineSearchResultsView: FC<IDeadlineSearchResultsViewProps> = ({
  queryObject,
  jurisdiction,
}) => {
  const history = useHistory();
  const [filter, setFilter] = useState('');
  const [filteredDeadlines, setFilteredDeadlines] = useState<IDeadline[]>();
  const apolloWrapper = useDeadlineQuery({
    fields,
    queryObjects: [{
      ...queryObject,
      skip: 0,
      take: 500,
    }],
    fetchPolicy: 'network-only',
  });

  const onFilterChange = (event: React.FormEvent<HTMLInputElement>) => {
    setFilter(event.currentTarget.value);
  };

  useEffect(() => {
    const lowerCaseFilter = filter?.toLowerCase();
    const filtered = lowerCaseFilter ? apolloWrapper.result.items[0].filter(
      (x: IDeadline) => !!x.refLink?.toLowerCase().includes(lowerCaseFilter)
        || !!x.shortDescription?.toLowerCase().includes(lowerCaseFilter)
        || !!x.specReference?.toLowerCase().includes(lowerCaseFilter)
        || !!x.description?.toLowerCase().includes(lowerCaseFilter)
        || x.categories?.find(
          (cat: ICategory) => cat.description?.toLowerCase().includes(lowerCaseFilter)),
    ) : apolloWrapper.result.items[0];
    setFilteredDeadlines(filtered);
  }, [apolloWrapper.result.items, filter]);

  return (
    <div className="flex-1 flex flex-col gap-3">
      <div className="flex md:flex-row flex-col gap-3 md:items-center justify-between">
        <b className="text-2xl">Deadlines</b>
        <div className="flex flex-row gap-3 justify-end">
          <AltButton
            onClick={() => history.push('/admin/deadlines', { jurisdiction, activeTab: 1 })}
            aria-label="Back To Search Trigger"
            data-cy="search-trigger"
            icon={<MaterialIcon icon="search" />}
          >
            Search
          </AltButton>
          <AltButton
            onClick={() => history.push('/admin/deadlines/new', { jurisdiction, activeTab: 1 })}
            aria-label="New Deadline"
            data-cy="new-deadline"
            icon={<MaterialIcon icon="add" />}
          >
            Add Deadline
          </AltButton>
        </div>
      </div>
      <TextField
        label="Filter"
        className="w-full rounded-t flex-1"
        onTrailingIconSelect={() => {
          setFilter('');
        }}
        leadingIcon={<MaterialIcon className="overflow-hidden outline-none" icon="search" />}
        trailingIcon={filter ? <MaterialIcon className="overflow-hidden outline-none" role="button" icon="clear" /> : <></>}
      >
        <Input
          value={filter}
          onChange={onFilterChange}
          className="border-b-0"
        />
      </TextField>
      {!apolloWrapper.loading && !apolloWrapper.result.items.length && <div className="justify-center flex">No Deadlines Found</div>}
      {filteredDeadlines?.length === 500
        && apolloWrapper.result.hasMore[0]
        && (
          <div className="justify-center flex">
            Your search returned more than 500 results. Please narrow your search.
          </div>
        )}
      <div className="flex flex-col gap-5">
        {filteredDeadlines?.map(
          (deadline: IDeadline) => (
            <Deadline
              key={deadline.id}
              deadline={deadline}
            />
          ),
        )}
      </div>
    </div>
  );
};

export default DeadlineSearchResultsView;

import React, {
  FC, useState, useRef,
} from 'react';
import LookupMenu from '@components/NewLookupMenu';
import { snapshot } from 'valtio';
import { IUserOrg, filevineState } from '@libs/auth';
import Button from '@fv-components/button';

interface IInlineOrgPickerProps {
  onOrgSelected?: VoidFunction;
}

const InlineOrgPicker: FC<IInlineOrgPickerProps> = (
  {
    onOrgSelected,
  }: IInlineOrgPickerProps,
) => {
  const [orgPickerIsVisible, setOrgPickerIsVisible] = useState(false);
  const { userOrgs, orgId } = snapshot(filevineState);
  const [selectedOrgName, setSelectedOrgName] = useState<string>(
    userOrgs.find((x: IUserOrg) => orgId && +x.orgId === +orgId)?.name
    || 'Click To Select An Org',
  );
  const ref = useRef<HTMLDivElement>(null);

  const onSelect = (item: IUserOrg) => {
    filevineState.orgId = item.orgId;
    setSelectedOrgName(item.name);
    if (onOrgSelected) {
      onOrgSelected();
    }
    setOrgPickerIsVisible(false);
  };

  const toggleMenu = (isOpen: boolean | null = null) => {
    setOrgPickerIsVisible((previous: boolean) => (isOpen !== null ? isOpen : !previous));
  };

  return (
    <>
      {userOrgs.length > 1 && (
        <div className="flex flex-row text-sm text-black gap-1 items-center">
          <div>Filevine Org:</div>

          <LookupMenu<IUserOrg>
            inputRef={ref}
            descriptionField="name"
            keyField="orgId"
            onMenuItemSelected={onSelect}
            // needed to spread the array because the array was in frozen mode
            // because it is a read only copy
            items={[...userOrgs].sort(
              (a: IUserOrg, b: IUserOrg) => (a.name).localeCompare(b.name),
            )}
            isOpen={orgPickerIsVisible}
            onMenuClosed={() => toggleMenu(false)}
          >
            <div ref={ref}>
              <Button
                onClick={() => toggleMenu()}
                role="button"
                aria-label="Selected Org"
                className="flex flex-row"
                data-cy="selected-org"
              >
                {/* must be a bug in valtio  for some reason one of
          these wasn't correctly being evaluated as a number */}
                {selectedOrgName}
              </Button>
            </div>
          </LookupMenu>
        </div>
      )}

    </>
  );
};

export default InlineOrgPicker;

import React, { FC } from 'react';
import dayjs from 'dayjs';
import { ListItem } from '@fv-components/list';
import Checkbox from '@fv-components/checkbox';
import MaterialIcon from '@fv-components/material-icon';
import { IServiceMethodCalculation } from '@models';
import { PillBox, Pill, Tooltip } from '@components';
import DeadlineText from './DeadlineText';
import { IDeadlineCalculationState, IServiceMethodCalculationState } from '../../../Calculator';

const css = require('./DeadlineListItem.module.scss');

interface IDeadlineListItemProps {
  deadlineCalculationState: IDeadlineCalculationState;
  onItemClick: (deadlineId: string, isSelected: boolean) => void;
  onServiceMethodDeadlineClick: (
    deadlineId: string,
    serviceMethodId: string,
    isSelected: boolean
  ) => void;
  disabled?: boolean;
}

const DeadlineListItem: FC<IDeadlineListItemProps> = (
  {
    deadlineCalculationState,
    onItemClick,
    onServiceMethodDeadlineClick,
    disabled,
  }: IDeadlineListItemProps,
) => {
  const onPillClick = (smc: IServiceMethodCalculation) => {
    if (deadlineCalculationState.deadline.id && smc.serviceMethod.id) {
      onServiceMethodDeadlineClick(deadlineCalculationState.deadline.id,
        smc.serviceMethod.id,
        !smc.isSelected);
    }
  };

  const onListItemClicked = () => {
    if (!disabled && deadlineCalculationState.deadline?.id) {
      onItemClick(deadlineCalculationState.deadline.id,
        !deadlineCalculationState.isSelected);
    }
  };

  const onKeyDown = (event: React.KeyboardEvent) => {
    switch (event.key) {
      case ' ':
      case 'Enter':
        onListItemClicked();
        break;
      default:
        break;
    }
  };

  return (
    <div className="flex flex-row relative">
      {
        deadlineCalculationState.isNewCalculation && (
          <div className="absolute -left-10 top-2.5 cursor-default">
            <Tooltip tooltipValue="Newly added deadline.  Sync with Filevine to save!" minWidth="250px">
              <MaterialIcon
                icon="new_releases"

              />
            </Tooltip>
          </div>
        )
      }
      <div className="flex-1">
        <ListItem
          disabled={disabled}
          className={[
            css.listItem,
            (deadlineCalculationState.isSelected ? css.listItemSelected : css.listItemUnselected),
          ].join(' ')}
          onClick={onListItemClicked}
          checkboxList
          tabIndex={-1}
          onKeyDown={onKeyDown}
          data-cy="deadline-list-item"
        >

          <MaterialIcon icon={deadlineCalculationState.isSelected ? 'check_box' : 'check_box_outline_blank'} />
          <Checkbox checked={deadlineCalculationState.isSelected} className={css.checkbox} data-cy="deadline-item-checkbox" />
          <div role="button" tabIndex={0} className={css.deadline} data-cy="deadline-list-item-deadline">
            <div data-cy="list-item-deadline-month-day">
              {deadlineCalculationState.calculatedDate ? dayjs(deadlineCalculationState.calculatedDate).format('MMM DD') : ''}
            </div>
            <div data-cy="list-item-deadline-year">
              {deadlineCalculationState.calculatedDate ? dayjs(deadlineCalculationState.calculatedDate).format('YYYY') : '...?'}
            </div>
          </div>
          <div className={css.innerContainer}>
            <DeadlineText
              description={deadlineCalculationState.deadline.description}
              secondaryText={deadlineCalculationState.deadline.specReference}
            />
            {deadlineCalculationState.deadline.serviceMethods
              && deadlineCalculationState.deadline
                .serviceMethods.length > 0
              && (
                <PillBox>
                  {
                    deadlineCalculationState.serviceMethods
                      ?.filter((smcs: IServiceMethodCalculationState) => !smcs.toBeRemoved)
                      .map((smcs: IServiceMethodCalculationState) => (
                        <Pill<IServiceMethodCalculation>
                          text={`${smcs.serviceMethod.description} by ${dayjs(smcs.calculatedDate).format('MMM DD')}`}
                          item={smcs}
                          onClick={onPillClick}
                          selected={deadlineCalculationState.isSelected && smcs.isSelected}
                          notSelectable={false}
                          key={smcs.serviceMethod.id}
                        />
                      ))
                  }
                </PillBox>
              )}
          </div>
        </ListItem>
      </div>
    </div>
  );
};
export default DeadlineListItem;

import React, { FC } from 'react';
import LinearProgress from '@fv-components/linear-progress';

const css = require('./InputLinearProgress.module.css');

interface InputLinearProgressProps {
  isNotRounded?: boolean;
}

const InputLinearProgress: FC<InputLinearProgressProps> = ({
  isNotRounded,
}) => (
  <LinearProgress
    indeterminate
    className={!isNotRounded ? css.progress : css.notRoundedProgress}
  />
);

export default InputLinearProgress;

import React, { FC } from 'react';
import AltButton from '@components/AltButton/AltButton';
import MaterialIcon from '@fv-components/material-icon';
import ITrigger from '@models/Deadlines/Triggers/ITrigger';
import IJurisdiction from '@models/Jurisdiction/IJurisdiction';
import IconButton from '@fv-components/icon-button';
import TriggerRelationship from './TriggerRelationship';

interface IRelationshipList {
  description: string;
  relationships?: ITriggerSelection[];
  onUpdate: (index: number, trigger: ITriggerSelection) => void;
  parentTriggerJurisdiction?: IJurisdiction;
  childTriggerJurisdiction?: IJurisdiction;
  onAdd: VoidFunction;
  onDelete: (index: number) => void;
  blockedTriggerIds?: string[];
  onBlockAdd?: (triggerId: string) => void;
  onBlockRemove?: (triggerId: string) => void;
}

export interface ITriggerSelection {
  id?: string;
  trigger?: ITrigger;
}

const RelationshipList: FC<IRelationshipList> = ({
  description,
  relationships,
  onUpdate,
  parentTriggerJurisdiction,
  childTriggerJurisdiction,
  onAdd,
  onDelete,
  blockedTriggerIds,
  onBlockAdd,
  onBlockRemove,
}: IRelationshipList) => (
  <>
    <div className="flex justify-between items-center">
      <b>
        {`${description} Trigger`}
      </b>
      <AltButton
        onClick={onAdd}
        icon={<MaterialIcon icon="add" />}
        aria-label={`Add ${description} Trigger`}
        data-cy={`add-${description.toLowerCase()}-trigger`}
      >
        {`${description} Trigger`}
      </AltButton>
    </div>
    <div className="flex flex-col md:gap-3 gap-3 md:divide-none ">
      {relationships?.map((selection: ITriggerSelection, index: number) => (
        <div
          key={selection.id || index}
          className="gap-3 flex flex-row justify-end items-start md:hover:bg-transparent hover:bg-gray-light p-4 md:p-0 "
        >
          <div className="flex-1">
            <TriggerRelationship
              trigger={selection.trigger}
              onUpdate={(t: ITrigger) => onUpdate(index, { ...selection, id: t.id || '', trigger: t })}
              parentTriggerJurisdiction={parentTriggerJurisdiction}
              childTriggerJurisdiction={childTriggerJurisdiction}
              blockedTriggerIds={blockedTriggerIds}
              onBlockAdd={onBlockAdd}
              onBlockRemove={onBlockRemove}
            />
          </div>
          <div className="flex-0 mt-1">
            <IconButton
              className="text-blue m-0"
              onClick={() => onDelete(index)}
              role="button"
              aria-label={`Remove ${description} Trigger`}
              data-cy={`remove-${description.toLowerCase()}-trigger-${index}`}
            >
              <MaterialIcon icon="close" />
            </IconButton>
          </div>
        </div>
      ))}
    </div>
  </>
);

export default RelationshipList;

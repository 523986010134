import React, { FC, useCallback, useState, useEffect } from 'react';
import Table from '@components/Table/Table';
import { IDeadlineQueryHookProps } from '@hooks/Deadline/useDeadlineQuery';
import { useDeadlineQuery } from '@hooks';
import { IDeadline } from '@models';
import { ITableState } from '@components/Table/Table';

interface IProps {
  parentJurisdictionId?: string;
  blockedIds?: string[];
  onChange: (blocked: string[]) => void;
}

const DeadlineInheritanceTable: FC<IProps> = ({
  parentJurisdictionId: jurisdictionId,
  blockedIds,
  onChange,
}) => {
  const [deadlineRequest, setDeadlineRequest] = useState<IDeadlineQueryHookProps>({
    queryObjects: [{ skip: 0, take: 100 }],
    fields: ['id', 'description', 'specReference'],
    lazy: true,
  });

  const {
    result: { items, hasMore },
    loading,
    load,
    called,
  } = useDeadlineQuery(deadlineRequest);

  const onRowSelect = useCallback(
    (d: IDeadline, isSelected: boolean) => {
      if (!isSelected) {
        onChange([
          ...blockedIds || [],
          d.id || '',
        ]);
      } else {
        onChange((blockedIds || []).filter((id: string) => id !== d.id));
      }
    }, [blockedIds, onChange],
  );

  const tableStateChanged = (state: ITableState) => {
    setDeadlineRequest((current: IDeadlineQueryHookProps) => ({
      queryObjects: [{
        ...state,
        ...state.filter,
        filter: undefined,
        jurisdictionIds: [jurisdictionId || ''],
      }],
      fields: current.fields,
    }));
  };

  useEffect(() => {
    if (jurisdictionId) {
      setDeadlineRequest((current: IDeadlineQueryHookProps) => ({
        queryObjects: [{
          ...current.queryObjects[0],
          jurisdictionIds: [jurisdictionId],
        }],
        fields: current.fields,
      }));
      if (!called) {
        load();
      }
    }
  }, [jurisdictionId, called, load]);

  return jurisdictionId ? (
    <Table
      dataSource={items[0] || []}
      isMultiSelect
      maxHeight={500}
      takeOptions={[100, 500]}
      onRowSelect={onRowSelect}
      title={<b>Inherited Deadlines</b>}
      selected={items[0]?.filter((t: IDeadline) => !blockedIds?.includes(t.id || '')) || []}
      loading={loading}
      onStateChange={tableStateChanged}
      hasMore={hasMore[0]}
      isPagerVisible
    >
      {[
        {
          key: 'description',
          header: 'Description',
          field: 'description',
          isFilterable: true,
          isSortable: true,
        },
        {
          key: 'specReference',
          header: 'Reference',
          field: 'specReference',
          isFilterable: true,
          isSortable: true,
        },
      ]}
    </Table>
  ) : (
    <div className="flex flex-row justify-center">
      <div>No inherited deadlines</div>
    </div>
  );
};

export default DeadlineInheritanceTable;

import React, { FC, useState } from 'react';
import { IJurisdiction } from '@models';
import TabBar from '@fv-components/tab-bar';
import Tab from '@fv-components/tab';
import IconButton from '@fv-components/icon-button';
import MaterialIcon from '@fv-components/material-icon';
import { useHistory } from 'react-router-dom';
import TriggerSearch from './TriggerSearch';
import DeadlineSearch from './DeadlineSearch';

interface ISearchProps {
  jurisdiction: IJurisdiction;
  isVisible?: boolean;
  onClearSearch?: VoidFunction;
}

const SearchBar: FC<ISearchProps> = ({
  jurisdiction,
  isVisible,
  onClearSearch,
}) => {
  const history = useHistory<{ jurisdiction: IJurisdiction, activeTab?: number }>();
  const [activeTab, setActiveTab] = useState<number>(history.location.state?.activeTab || 0);

  const handleTabChange = (index: number) => {
    setActiveTab(index);
    history.push('/admin/deadlines', {
      jurisdiction,
      activeTab: index,
    });
  };

  const onSearchUpdate = () => {
    if (onClearSearch) {
      onClearSearch();
    }
  };

  return (
    <>
      {isVisible && (
      <div className="flex flex-row gap-3 flex-1 items-end">
        <div className="flex-1 flex flex-col">
          <TabBar
            activeIndex={activeTab}
            handleActiveIndexUpdate={handleTabChange}
          >
            <Tab>
              <span className="mdc-tab__text-label">Trigger</span>
            </Tab>
            <Tab>
              <span className="mdc-tab__text-label">Search</span>
            </Tab>
          </TabBar>
          <div className="mt-3">
            {activeTab === 0 ? (
              <TriggerSearch jurisdiction={jurisdiction} onSearchUpdate={onSearchUpdate} />
            ) : (
              <DeadlineSearch onSearchUpdate={onSearchUpdate} jurisdiction={jurisdiction} />
            )}
          </div>
        </div>
        {!!onClearSearch && (
        <div className="flex-0 mb-1">
          <IconButton
            className="text-blue m-0"
            onClick={onClearSearch}
            role="button"
            aria-label="Clear Search Bar"
            data-cy="remove-trigger-search"
          >
            <MaterialIcon icon="close" />
          </IconButton>
        </div>
        )}
      </div>
      )}
    </>
  );
};

export default SearchBar;

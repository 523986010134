import React, {
  FC, useEffect, useState,
} from 'react';
import {
  AltButton,
} from '@components';
import { ITriggerDeadline, ITrigger } from '@models';
import MaterialIcon from '@fv-components/material-icon';
import IconButton from '@fv-components/icon-button';
import Trigger from './TriggerDeadline';

interface ITriggerListProps {
  jurisdictionId: string;
  triggerDeadlines?: ITriggerDeadline[];
  onUpdate: (index: number, triggerDeadline: ITriggerDeadline) => void;
  onAdd: VoidFunction;
  onDelete: (index: number) => void;
}

export interface ITriggerSelection {
  id: string;
  trigger?: ITrigger;
}

const TriggerList: FC<ITriggerListProps> = ({
  triggerDeadlines,
  onUpdate,
  onAdd,
  onDelete,
  jurisdictionId,
}: ITriggerListProps) => {
  const [blockedTriggerIds, setBlockedTriggerIds] = useState<string[]>();

  useEffect(() => {
    setBlockedTriggerIds(triggerDeadlines?.flatMap((td: ITriggerDeadline) => [
      td.triggerId || '',
      ...(td.trigger?.childTriggers?.map((t: ITrigger) => t.id || '') || []),
      ...(td.trigger?.parentTriggers?.map((t: ITrigger) => t.id || '') || []),
    ]));
  }, [triggerDeadlines]);

  return (
    <>
      <div className="flex justify-between items-center">
        <b>Triggers</b>
        <AltButton
          onClick={onAdd}
          icon={<MaterialIcon icon="add" />}
          aria-label="Add Trigger"
          data-cy="add-trigger"
        >
          Trigger
        </AltButton>
      </div>
      <div className="flex flex-col md:gap-3 gap-3 md:divide-none ">
        {triggerDeadlines?.map((triggerDeadline: ITriggerDeadline, index: number) => (
          <div
            key={triggerDeadline.id || `${triggerDeadline.deadlineId}-${triggerDeadline.triggerId || index}`}
            className="gap-3 flex flex-row justify-end items-center md:hover:bg-transparent hover:bg-gray-light p-4 md:p-0 "
          >
            <Trigger
              onUpdate={(td: ITriggerDeadline) => onUpdate(index, td)}
              value={triggerDeadline}
              jurisdictionId={jurisdictionId}
              blockedTriggerIds={blockedTriggerIds}
            />
            <div className="flex-0">
              <IconButton
                className="text-blue m-0"
                onClick={() => onDelete && onDelete(index)}
                role="button"
                aria-label="Remove Trigger"
                data-cy={`remove-trigger-${index}`}
              >
                <MaterialIcon icon="close" />
              </IconButton>
            </div>
          </div>
        ))}
      </div>
    </>
  );
};

export default TriggerList;

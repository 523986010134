import React, { FC } from 'react';
import BaseMenu from './BaseMenu';

export interface IRowMenuOption {
  key: string | number;
  text: string;
  icon?: string;
  onClick: (item: any) => void;
  getIsVisible?: (item: any) => boolean;
}

interface IRowMenuProps {
  options?: IRowMenuOption[];
  item: any;
  disabled?: boolean;
}

const RowMenu: FC<IRowMenuProps> = ({
  options,
  item,
  disabled,
}: IRowMenuProps) => {
  const visibleOptions = options?.filter(
    (o: IRowMenuOption) => (!o.getIsVisible ? true : o.getIsVisible(item)));
  const onClick = (optionIndex: number) => {
    if (visibleOptions && visibleOptions[optionIndex]) {
      visibleOptions[optionIndex].onClick(item);
    }
  };

  return (
    <BaseMenu
      onOptionClick={onClick}
      options={visibleOptions}
      disabled={disabled}
      ariaLabel="Table Row Menu"
      icon="expand_more"
    />
  );
};

export default RowMenu;

import React, {
  FC,
} from 'react';
import dayjs from 'dayjs';
import { DatePicker } from '@components';

interface IProps {
  onDateChange: (expirationDate?: Date) => void;
  value?: Date;
}

const InvitesDatePicker: FC<IProps> = (
  {
    onDateChange,
    value,
  }: IProps,
) => (
  <DatePicker
    onDateChange={onDateChange}
    dateFormat="MM/DD/YYYY"
    value={value}
    minDate={dayjs(dayjs().add(1, 'd').format('YYYY-MM-DD')).toDate()}
    maxDate={dayjs().add(14, 'd').toDate()}
    label="Expires"
    isRequired
  />
);

export default InvitesDatePicker;

import React, { FC } from 'react';
import Pager, { IPagerState } from '../Pager/Pager';

export interface IFabProps {
  fabIcon: string;
  fabClickHandler: VoidFunction;
}

interface IFooterProps {
  isPagerVisible?: boolean;
  skip?: number;
  take?: number;
  hasMore?: boolean;
  takeOptions?: number[];
  onPagerStateChange?: (pagerState: IPagerState) => void;
  disabled?: boolean;
  numberItemsCurrentlyDisplayed: number;
  sortBy?: string;
  sortDirection?: string;
}

const Footer: FC<IFooterProps> = ({
  isPagerVisible,
  skip,
  take,
  hasMore,
  takeOptions,
  onPagerStateChange,
  disabled,
  numberItemsCurrentlyDisplayed,
  sortBy,
  sortDirection,
}) => (
  <div className="grid grid-cols-3 items-center">
    <div
      className="text-gray text-sm font-light overflow-hidden overflow-ellipsis whitespace-nowrap"
      data-cy="current-sort-order"
    >
      {sortBy && sortDirection && `Sort: ${sortBy} ${sortDirection}`}
    </div>
    <div>
      {isPagerVisible
          && skip !== undefined
          && take !== undefined
          && hasMore !== undefined
        ? (
          <Pager
            skip={skip}
            take={take}
            onPagerStateChange={onPagerStateChange}
            hasMore={hasMore}
            takeOptions={takeOptions}
            disabled={disabled}
            numberItemsCurrentlyDisplayed={numberItemsCurrentlyDisplayed}
          />
        ) : null}
    </div>
  </div>
);

export default Footer;

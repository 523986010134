import { ApolloError, FetchPolicy } from '@apollo/client';
import useTimelyQuery from '@hooks/useTimelyQuery';
import { IHistoryQuery, IHistory } from '@models';
import { IDeleteHookResult, useTimelyDelete } from '@hooks/useTimelyDelete';

type HistoryQueryHook = (
  props: IHistoryQueryHookProps
) => IHistoryQueryHookResult;

export interface IHistoryQueryHookProps {
  queryObject: IHistoryQuery;
  fields: string[];
  lazy?: boolean;
  fetchPolicy?: FetchPolicy;
}

interface IHistoryQueryHookResult {
  loading: boolean;
  error?: ApolloError;
  result: { hasMore: boolean; items: IHistory[] };
  delete: IDeleteHookResult;
  load: VoidFunction,
  called?: boolean,
}

const useHistoryQuery: HistoryQueryHook = (
  {
    fields, queryObject, lazy, fetchPolicy,
  }: IHistoryQueryHookProps,
) => {
  const {
    loading, error, result, load, called,
  } = useTimelyQuery<IHistory>({
    queryInputType: 'HistoryQueryInputType',
    variables: { queryObject },
    collection: 'history',
    lazy,
    fields,
    fetchPolicy,
  });

  return {
    load,
    called,
    loading,
    error,
    result,
    delete: useTimelyDelete({ capitalizedType: 'History' }),
  };
};

export default useHistoryQuery;

import React, {
  FC, useState,
} from 'react';
import TextField, { HelperText, Input } from '@fv-components/text-field';
import Button from '@fv-components/button';
import MaterialIcon from '@fv-components/material-icon';
import dayjs from 'dayjs';
import {
  AltButton, OrganizationAutoComplete, InvitesDatePicker, RoleTable,
} from '@components';
import {
  IInviteInput, IOrganization, IInvite, IRole,
} from '@models';
import { getIsExpired } from '@libs/utils';
import useRoleQuery, { IRoleQueryHookProps } from '@hooks/Identity/Role/useRoleQuery';
import { ITableState } from '@components/Table/Table';
import { discardChangesDialogService } from '@hooks/useConfirmationDialog';

interface IProps {
  onSave: (invite: IInviteInput) => void;
  onCancel: VoidFunction;
  organization?: IOrganization;
}

const roleGraphQLQueryFields = [
  'id',
  'description',
];

const CreateInvitesForm: FC<IProps> = (
  {
    onSave,
    onCancel,
    organization,
  }: IProps,
) => {
  const [isDirty, setIsDirty] = useState(false);
  const [invite, setInvite] = useState<IInviteInput>({
    emailAddresses: [''],
    roles: [],
    expirationDate: dayjs().add(7, 'd').toDate(),
    organization,
  });
  const [isEmailValid, setIsEmailValid] = useState<boolean[]>([false]);
  const isDateValid = invite.expirationDate && getIsExpired(invite.expirationDate);
  const [roleRequestObject, setRoleRequestObject] = useState<IRoleQueryHookProps>({
    queryObject: { skip: 0, take: 100 },
    fields: roleGraphQLQueryFields,
    fetchPolicy: 'network-only',
  });

  const roleApolloWrapper = useRoleQuery(roleRequestObject);

  const onDateSelect = (expirationDate?: Date) => {
    setIsDirty(true);
    const updatedInvite: IInviteInput = {
      ...invite,
      expirationDate,
    };
    setInvite(updatedInvite);
  };

  const onEmailChange = (index: number, event: React.FormEvent<HTMLInputElement>) => {
    setIsDirty(true);
    const isValid = event.currentTarget.validity.valid;
    const isValidArray = isEmailValid.map(
      (is: boolean, i: number) => (i !== index ? is : isValid),
    );
    setIsEmailValid(isValidArray);

    const updatedInvite: IInviteInput = {
      ...invite,
      emailAddresses: invite.emailAddresses?.map(
        (e: string, i: number) => (i !== index ? e : event.currentTarget.value),
      ),
    };
    setInvite(updatedInvite);
  };

  const onAddNewEmail = () => {
    setIsDirty(true);
    setIsEmailValid([...isEmailValid, false]);
    const updatedInvite: IInviteInput = {
      ...invite,
      emailAddresses: [...invite.emailAddresses || [], ''],
    };
    setInvite(updatedInvite);
  };

  const removeEmail = (index: number) => {
    setIsDirty(true);
    const isValidArray = [...isEmailValid.filter((_, i: number) => i !== index)];
    setIsEmailValid(isValidArray);
    setInvite((current: IInviteInput) => ({
      ...current,
      emailAddresses: [...invite.emailAddresses?.filter((_, i: number) => i !== index) || []],
    }));
  };

  const onOrganizationSelect = (org?: IOrganization) => {
    setIsDirty(true);
    setInvite((current: IInviteInput) => ({
      ...current,
      organization: org,
    }));
  };

  const roleTableStateChanged = (tableState: ITableState) => {
    setIsDirty(true);
    setRoleRequestObject({
      queryObject: {
        ...tableState,
        ...tableState.filter,
        filter: undefined,
      },
      fields: roleGraphQLQueryFields,
    });
  };

  const onRoleSelected = (role: IRole, isSelected: boolean) => {
    setIsDirty(true);
    if (isSelected) {
      setInvite((current: IInvite) => ({
        ...invite,
        roles: [...(current.roles || []), role],
      }));
    } else {
      setInvite((current: IInvite) => ({
        ...current,
        roles: [...(invite.roles?.filter((r: IRole) => r.id !== role.id) || [])],
      }));
    }
  };

  const onCancelClick = () => {
    if (isDirty) {
      discardChangesDialogService.showDialog(onCancel);
    } else {
      onCancel();
    }
  };

  return (
    <div className="flex flex-col gap-3 flex-1">
      <b className="text-2xl">
        New Invite
      </b>
      <div className="flex md:flex-row flex-col gap-3">
        <div className="flex flex-col gap-3">
          {/* We only allow selecting an organization if it is not provided  */}
          {!!organization?.id
            || (
            <OrganizationAutoComplete
              onSelect={onOrganizationSelect}
              isRequired
            />
            )}
          <InvitesDatePicker value={invite.expirationDate} onDateChange={onDateSelect} />
          { invite?.emailAddresses?.map((email: string, index: number) => (
            <div key={index} className="flex flex-row">
              <div className="flex-1">
                <TextField
                  label="Email"
                  outlined
                  className="w-full"
                  helperText={(
                    <HelperText validation>
                      Email is not in correct format
                    </HelperText>
          )}
                >
                  <Input
                    value={email}
                    onChange={
                      (event: React.FormEvent<HTMLInputElement>) => onEmailChange(index, event)
                    }
                    type="email"
                    required
                    data-cy={`email-input-${index}`}
                    autoComplete={`email-input-${index}`}
                  />
                </TextField>
              </div>

              <Button
                onClick={() => removeEmail(index)}
                role="button"
                aria-label="Clear Email"
                data-cy="clear-email"
                icon={<MaterialIcon icon="clear" />}
              />
            </div>
          ))}
          <AltButton
            onClick={onAddNewEmail}
            aria-label="Add Email"
            data-cy="add-email"
            icon={<MaterialIcon icon="add" />}
          >
            Add Email
          </AltButton>
        </div>
        <RoleTable
          roles={roleApolloWrapper.result.items}
          hasMore={roleApolloWrapper.result.hasMore}
          onTableStateChanged={roleTableStateChanged}
          onRoleSelect={onRoleSelected}
          selectedRoles={invite.roles}
          loading={roleApolloWrapper.loading}
        />
      </div>
      <div className="flex flex-row justify-end gap-3">
        <Button
          onClick={() => onSave(invite)}
          role="button"
          aria-label="Send Invites"
          data-cy="send-invite"
          icon={<MaterialIcon icon="send" />}
          raised
          disabled={!(
            isEmailValid.every((b: boolean) => b)
            && isDateValid
            && invite.organization?.id
            && invite.roles?.length)}
        >
          Send Invites
        </Button>
        <AltButton
          onClick={onCancelClick}
          aria-label="Cancel Send Invites"
          data-cy="cancel-invite"
          icon={<MaterialIcon icon="cancel" />}
        >
          Cancel
        </AltButton>
      </div>
    </div>
  );
};

export default CreateInvitesForm;

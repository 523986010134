import { ApolloError, FetchPolicy } from '@apollo/client';
import useTimelyQuery from '@hooks/useTimelyQuery';
import IJurisdiction from '@models/Jurisdiction/IJurisdiction';
import IJurisdictionQuery from '@models/Jurisdiction/IJurisdictionQuery';
import { IDeleteHookResult, useTimelyDelete } from '@hooks/useTimelyDelete';
import useJurisdictionSave, { IJurisdictionSaveHookResult } from './useJurisdictionSave';

type JurisdictionQueryHook = (
  props: IJurisdictionQueryHookProps
) => IJurisdictionQueryHookResult;

export interface IJurisdictionQueryHookProps {
  queryObject: IJurisdictionQuery;
  fields: string[];
  lazy?: boolean;
  fetchPolicy?: FetchPolicy;
}

interface IJurisdictionQueryHookResult {
  loading: boolean;
  error?: ApolloError;
  result: { hasMore: boolean; items: IJurisdiction[] };
  save: IJurisdictionSaveHookResult;
  delete: IDeleteHookResult;
  load: VoidFunction,
  called?: boolean,
}

const useJurisdictionQuery: JurisdictionQueryHook = (
  {
    fields, queryObject, lazy, fetchPolicy,
  }: IJurisdictionQueryHookProps,
) => {
  const {
    loading, error, result, load, called, queryString,
  } = useTimelyQuery<IJurisdiction>({
    queryInputType: 'JurisdictionQueryInputType',
    variables: { queryObject },
    collection: 'jurisdictions',
    lazy,
    fields,
    fetchPolicy,
  });

  return {
    load,
    called,
    loading,
    error,
    result,
    save: useJurisdictionSave({ queryString, queryObject }),
    delete: useTimelyDelete({ capitalizedType: 'Jurisdiction' }),
  };
};

export default useJurisdictionQuery;

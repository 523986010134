import React, { FC } from 'react';
import Select, { Option } from '@fv-components/select';
import { PermissionAction } from '@models/Identity/Role/Permission/IPermission';

interface IPermissionActionDropDownProps {
  onChange: (action?: PermissionAction) => void;
  value?: PermissionAction;
  label?: string;
}

const PermissionActionDropDown: FC<IPermissionActionDropDownProps> = (
  { onChange, value, label }: IPermissionActionDropDownProps,
) => {
  const onSelect = (evt: React.FormEvent<HTMLSelectElement>) => {
    if (evt.currentTarget.value === '') {
      onChange(undefined);
    } else {
      onChange(evt.currentTarget.value as PermissionAction);
    }
  };

  return (
    <Select
      outlined
      label={label || 'Action'}
      onChange={onSelect}
      value={value?.toString() || ''}
    >
      <>
        <Option key="NONE" value="" />
        {Object.values(PermissionAction).map((j) => (
          <Option key={j} value={j}>
            { j }
          </Option>
        ))}
      </>
    </Select>
  );
};

export default PermissionActionDropDown;

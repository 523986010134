import React, {
  FC, useState, useCallback,
} from 'react';
import { ITableMenuOption } from '@components/Table/Menu/TableMenu';
import Table from '@components/Table/Table';
import { IRowMenuOption } from '@components/Table/Menu/RowMenu';
import { IServiceMethod, IServiceMethodGroup, IJurisdiction } from '@models';
import ServiceMethodGroupForm from './ServiceMethodGroupForm';

interface IServiceMethodGroupTableProps {
  jurisdiction?: IJurisdiction;
  serviceMethodGroups?: IServiceMethodGroup[];
  serviceMethods?: IServiceMethod[];
  onDoneEditing: (serviceMethodGroup?: IServiceMethodGroup) => void;
  onEditingStart: () => void;
  onGroupsDeleted: (groups?: IServiceMethodGroup[]) => void;
}

const ServiceMethodGroup: FC<IServiceMethodGroupTableProps> = ({
  jurisdiction,
  serviceMethodGroups,
  onDoneEditing,
  onEditingStart,
  onGroupsDeleted,
}) => {
  const [isFormVisible, setIsFormVisible] = useState(false);
  const [serviceMethodGroup, setServiceMethodGroup] = useState<IServiceMethodGroup>();
  const [selectedGroups, setSelectedGroups] = useState<IServiceMethodGroup[]>();

  const options: ITableMenuOption[] = [
    {
      key: 'deleteSelected',
      text: 'Delete Selected',
      onClick: () => {
        onGroupsDeleted(selectedGroups);
        setSelectedGroups(undefined);
      },
    },
  ];

  const rowOptions: IRowMenuOption[] = [
    {
      key: 'edit',
      text: 'Edit',
      onClick: (item: IServiceMethodGroup) => {
        onEditingStart();
        setServiceMethodGroup(item);
        setIsFormVisible(true);
      },
    },
  ];

  const onRowSelect = useCallback(
    (smg: IServiceMethodGroup, isSelected: boolean) => {
      setSelectedGroups(
        (current?: IServiceMethodGroup[]) => (isSelected ? [...(current || []), smg]
          : (current?.filter((sm: IServiceMethod) => sm.id !== smg.id) || [])),
      );
    }, [],
  );

  const onAddMethodClick = () => {
    onEditingStart();
    setIsFormVisible(true);
    setServiceMethodGroup({ jurisdictionId: jurisdiction?.id });
  };

  const onDone = (item?: IServiceMethodGroup) => {
    setIsFormVisible(false);
    onDoneEditing(item);
  };

  return (
    <div className="flex-1 flex flex-col gap-3">
      {isFormVisible && serviceMethodGroup ? (
        <ServiceMethodGroupForm
          serviceMethodGroup={serviceMethodGroup}
          jurisdictionId={jurisdiction?.id || ''}
          jurisdictionAddress={jurisdiction?.address}
          onDone={onDone}
          serviceMethods={jurisdiction?.serviceMethods}
        />
      ) : (
        <Table
          dataSource={serviceMethodGroups}
          isMultiSelect
          tableMenuOptions={options}
          rowMenuOptions={rowOptions}
          maxHeight={350}
          onRowSelect={onRowSelect}
          onFabClick={onAddMethodClick}
          fabText="Add Group"
          title={<b>Service Method Groups</b>}
          fabIcon="add"
          isFabVisible
        >
          {[
            {
              key: 'description',
              header: 'Description',
              field: 'description',
            },
          ]}
        </Table>
      )}
    </div>

  );
};

export default ServiceMethodGroup;

import React, { FC, useState, useRef } from 'react';
import MaterialIcon from '@fv-components/material-icon';
import Menu, {
  MenuList,
  MenuListItem,
  MenuListItemText,
} from '@fv-components/menu';
import { Corner } from '@fv-components/menu-surface';
import { IconButtonBase } from '@fv-components/icon-button';

export interface IBaseMenuOption {
  key: string | number;
  text: string;
  icon?: string;
}

export interface IBaseMenuProps {
  options?: IBaseMenuOption[];
  onOptionClick?: (optionIndex: number) => void;
  disabled?: boolean;
  ariaLabel?: string;
  className?: string;
  icon: string;
  onRowMenuOpen?: VoidFunction;
}

const BaseMenu: FC<IBaseMenuProps> = ({
  options,
  onOptionClick,
  disabled,
  ariaLabel,
  className,
  icon,
}: IBaseMenuProps) => {
  const [isMenuVisible, setIsMenuVisible] = useState(false);
  const anchor = useRef() as React.MutableRefObject<HTMLInputElement>;

  const onButtonClick = () => {
    setIsMenuVisible(!isMenuVisible);
  };

  return (
    <div ref={anchor}>
      <IconButtonBase
        onClick={onButtonClick}
        disabled={disabled}
        role="button"
        aria-label={ariaLabel}
        aria-haspopup="true"
        aria-expanded={isMenuVisible ? 'true' : 'false'}
        aria-controls="table-menu"
        className={`${!options?.length ? 'invisible' : ''} ${className}`}
        data-cy="table-menu"
      >
        <MaterialIcon
          icon={icon}
        />
      </IconButtonBase>
      <Menu
        open={isMenuVisible}
        anchorCorner={Corner.BOTTOM_LEFT}
        anchorElement={anchor.current}
        onSelected={(index) => {
          if (onOptionClick) {
            onOptionClick(index);
          }
          setIsMenuVisible(false);
        }}
        disabled={disabled}
        id="table-menu"
        onClose={() => setIsMenuVisible(false)}
      >
        <MenuList>
          {options?.map((option : IBaseMenuOption) => (
            <MenuListItem
              key={option.key}
              disabled={disabled}
              onClick={(event) => event.stopPropagation()}
            >
              <MenuListItemText primaryText={option.text} />
            </MenuListItem>
          ))}
        </MenuList>
      </Menu>
    </div>
  );
};

export default BaseMenu;

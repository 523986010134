import React, { FC, useState } from 'react';
import TextField, { Input, HelperText } from '@fv-components/text-field';
import IUser from '@models/Identity/User/IUser';

interface IUserFormProps {
  user?: IUser;
  onUserChange: (user?: IUser, isValid?: boolean) => void;
  autoFocus?: boolean;
}

interface IUserFormState {
  isEmailValid?: boolean;
  isFirstNameValid?: boolean;
  isLastNameValid?: boolean;
}

const UserForm: FC<IUserFormProps> = ({ onUserChange, user, autoFocus }: IUserFormProps) => {
  const [state, setState] = useState<IUserFormState>({
    isEmailValid: true,
    isFirstNameValid: true,
    isLastNameValid: true,
  });

  const onEmailChange = (event: React.FormEvent<HTMLInputElement>) => {
    const isEmailValid = event.currentTarget.validity.valid;
    setState({
      ...state,
      isEmailValid,
    });

    onUserChange({
      ...user,
      email: event.currentTarget.value,
    }, state.isFirstNameValid && state.isLastNameValid && isEmailValid);
  };

  const onFirstNameChange = (event: React.FormEvent<HTMLInputElement>) => {
    const isFirstNameValid = event.currentTarget.validity.valid;
    setState({
      ...state,
      isFirstNameValid,
    });

    onUserChange({
      ...user,
      firstName: event.currentTarget.value,
    }, isFirstNameValid && state.isLastNameValid && state.isEmailValid);
  };

  const onLastNameChange = (event: React.FormEvent<HTMLInputElement>) => {
    const isLastNameValid = event.currentTarget.validity.valid;
    setState({
      ...state,
      isLastNameValid,
    });

    onUserChange({
      ...user,
      lastName: event.currentTarget.value,
    }, isLastNameValid && state.isFirstNameValid && state.isEmailValid);
  };

  return (
    <div className="flex flex-col">
      <TextField
        label="First Name"
        outlined
        helperText={(
          <HelperText className="cypress-first-name-validation-message" validation>
            You must enter a first name
          </HelperText>
          )}
      >
        <Input
          value={user?.firstName}
          onChange={onFirstNameChange}
          pattern="^([ \u00c0-\u01ffa-zA-Z'-`ḑȯ])+$"
          required
          autoFocus={autoFocus}
          data-cy="first-name-input"
        />
      </TextField>
      <TextField
        label="Last Name"
        outlined
        helperText={(
          <HelperText className="cypress-last-name-validation-message" validation>
            You must enter a last name
          </HelperText>
          )}
      >
        <Input
          value={user?.lastName}
          onChange={onLastNameChange}
          pattern="^([ \u00c0-\u01ffa-zA-Z'-`ḑȯ])+$"
          required
          data-cy="last-name-input"
        />
      </TextField>
      <TextField
        label="Email"
        outlined
        helperText={(
          <HelperText className="cypress-email-validation-message" validation>
            Email is not in correct format
          </HelperText>
          )}
      >
        <Input
          value={user?.email}
          onChange={onEmailChange}
          type="email"
          required
          data-cy="email-input"
        />
      </TextField>
    </div>
  );
};

export default UserForm;

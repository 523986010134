import {
  useMutation, ApolloError, FetchResult, DocumentNode, gql,
} from '@apollo/client';
import ICategory from '@models/Deadlines/Categories/ICategory';
import ICategoryQuery from '@models/Deadlines/Categories/ICategoryQuery';
import { handleSaveResult } from '@libs/apollo';

type CategorySaveHook = (
  props: ICategorySaveHookProps
) => ICategorySaveHookResult;

interface ICategorySaveHookProps {
  queryObject?: ICategoryQuery;
  queryString?: DocumentNode;
}

interface ICategorySaveResult {
  saveCategory: ICategory;
}

export interface ICategorySaveHookResult {
  result: {
    error?: ApolloError;
    loading: boolean;
    called: boolean;
  };
  saveCategory: (
    description: string,
    id?: string,
    parentId?: string,
  ) => Promise<FetchResult<ICategory>>;
}

const useCategorySave: CategorySaveHook = (
  { queryString, queryObject }: ICategorySaveHookProps,
) => {
  const SAVE_CATEGORY = gql`
    mutation CategoryMutation($category: CategoryInputType!) {
      saveCategory(item: $category) {
        id
      }
    }
  `;

  const [saveFunc, {
    error, loading, called, client,
  }] = useMutation<ICategorySaveResult>(SAVE_CATEGORY);

  return {
    result: {
      error,
      loading,
      called,
    },
    saveCategory: (
      description: string,
      id?: string,
    ): Promise<FetchResult<ICategory>> => {
      const category = {
        id,
        description,
      };

      return saveFunc({
        variables: {
          category,
        },
      }).then((result: FetchResult<ICategorySaveResult>) => ({
        ...result,
        data: {
          ...handleSaveResult<ICategory>(
            result.data?.saveCategory || '',
            category,
            client,
            'categories',
            queryObject,
            queryString,
          ),
        },
      }));
    },
  };
};

export default useCategorySave;

import { RestLink } from 'apollo-link-rest';
import {
  NormalizedCacheObject, ApolloClient, InMemoryCache, ApolloLink,
} from '@apollo/client';
import { useProxy } from 'valtio';
import { useCallback, useEffect } from 'react';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import {
  getAccessToken,
  showLoginPage,
  filevineState,
  updateFVUserFromSession,
} from '@libs/auth';
import { getConfig, setTenantConfig, setUserTenant } from '@src/config';
import { useHistory } from 'react-router-dom';
import { getSnackBarService } from '@hooks/useSnackBar';
import useIpAddress from './useIpAddress';

const PLEASE_AUTHENTICATE_ERROR_MESSAGE = 'please authenticate';

export type FilevineApolloClient = ApolloClient<NormalizedCacheObject>;

interface IFilevineClientHook {
  client: FilevineApolloClient;
  logout: VoidFunction;
  onFVUserConnectSuccess: VoidFunction;
  changeOrg: (orgId: number) => void;
}

const getErrorLink = () => onError(({ graphQLErrors, networkError, operation }) => {
  if (graphQLErrors) {
    graphQLErrors.forEach(({ message, locations, path }) => {
      // eslint-disable-next-line no-console
      console.error(`[Query error] Message: ${message}, Location: ${JSON.stringify(locations)}, Path: ${path}, Operation: ${operation.operationName}`);
    });
  }

  if (networkError) {
    // eslint-disable-next-line no-console
    console.error(`[Network error] Operation: ${operation.operationName} Network Error: ${networkError}`);
    if (networkError?.message?.toLocaleLowerCase()?.includes(PLEASE_AUTHENTICATE_ERROR_MESSAGE)) {
      showLoginPage();
    }
  }
});

const getRestLink = () : RestLink => {
  const { filevineApiBaseUrl } = getConfig();
  return new RestLink({ uri: filevineApiBaseUrl });
};

const useFilevineClient = (): IFilevineClientHook => {
  const { clientIpAddress } = useIpAddress();
  const { userId, orgId, client } = useProxy(filevineState);

  const getAuthLink = useCallback(
    (oId: number, uId: number) => setContext(async (_, { headers, ...rest }) => (
      getAccessToken().then((accessToken: string) => ({
        ...rest,
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'x-fv-application': 'timely',
          'x-fv-clientIp': clientIpAddress,
          'x-fv-orgId': oId,
          'x-fv-userId': uId,
        },
      })).catch((reason: any) => {
        // eslint-disable-next-line no-console
        console.error(reason);
        throw new Error('Unable to create auth link');
      })
    )), [clientIpAddress],
  );

  const getClient = useCallback(
    (oId: number, uId: number) => (oId && uId ? new ApolloClient({
      cache: new InMemoryCache(),
      link: ApolloLink.from([getErrorLink(), getAuthLink(oId, uId), getRestLink()]),
    }) : undefined), [getAuthLink],
  );

  useEffect(() => {
    if (orgId && userId && clientIpAddress) {
      const cli = getClient(orgId, userId);
      filevineState.client = cli;
    }
  }, [clientIpAddress, getClient, orgId, userId]);

  const changeOrg = useCallback((id: number) => {
    filevineState.orgId = id;
  }, []);

  const logout = useCallback((): Promise<void> | undefined => {
    filevineState.client = undefined;
    filevineState.orgId = undefined;
    filevineState.userId = undefined;
    filevineState.userOrgs = [];
    filevineState.SAMLAuthProvider = '';
    setUserTenant('');
    setTenantConfig('');

    return new Promise<void>((resolve) => {
      try {
        return client?.cache.reset().finally(() => resolve());
      } catch {
        return resolve();
      }
    });
  }, [client]);

  const history = useHistory();
  const onFVUserConnectSuccess = async () => {
    try {
      // Once a user is logged in to cognito we use the token to call FV and get user and org info
      await updateFVUserFromSession();

      // now determine where to redirect the user
      const paramsString = localStorage.getItem('filevineUrlParams');
      const historyId = localStorage.getItem('historyIdParam');
      if (paramsString) {
        const params: { orgId: number, projectId: number } = JSON.parse(paramsString);
        localStorage.removeItem('filevineUrlParams');
        filevineState.orgId = params.orgId;
        history.push(`/?projectId=${params.projectId}&orgId=${params.orgId}`);
      } else if (historyId) {
        history.push(`/calculator/deadlines/${historyId}`);
        localStorage.removeItem('historyIdParam');
      } else {
        history.push('/calculator/deadlines');
      }
      getSnackBarService().showSnackBar('You successfully connected your account with Filevine.');
    } catch {
      getSnackBarService().showSnackBar('There was an error connecting your account with Filevine.');
      history.push('/calculator');
    }
  };

  return {
    client: client as FilevineApolloClient,
    logout,
    onFVUserConnectSuccess,
    changeOrg,
  };
};

export default useFilevineClient;

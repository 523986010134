import { ApolloError, FetchPolicy } from '@apollo/client';
import IPermissionQuery from '@models/Identity/Role/Permission/IPermissionQuery';
import IPermission from '@models/Identity/Role/Permission/IPermission';
import { IDeleteHookResult, useTimelyDelete } from '@hooks/useTimelyDelete';
import useTimelyQuery from '@hooks/useTimelyQuery';
import usePermissionSave, { IPermissionSaveHookResult } from './usePermissionSave';

type PermissionQueryHook = (
  props: IPermissionQueryHookProps
) => IPermissionQueryHookResult;

export interface IPermissionQueryHookProps {
  queryObject: IPermissionQuery;
  fields: string[];
  lazy?: boolean;
  fetchPolicy?: FetchPolicy;
}

interface IPermissionQueryHookResult {
  loading: boolean;
  error?: ApolloError;
  result: { hasMore: boolean; items: IPermission[] };
  save: IPermissionSaveHookResult;
  delete: IDeleteHookResult;
  load: VoidFunction;
  called: boolean;
}

const usePermissionQuery: PermissionQueryHook = (
  {
    queryObject, fields, lazy, fetchPolicy,
  }: IPermissionQueryHookProps,
) => {
  const {
    loading, error, queryString, result, load, called,
  } = useTimelyQuery<IPermission>({
    variables: { queryObject },
    collection: 'permissions',
    fields,
    lazy,
    queryInputType: 'PermissionQueryInputType',
    fetchPolicy,
  });

  return {
    called,
    load,
    loading,
    error,
    result,
    save: usePermissionSave({ queryString, queryObject }),
    delete: useTimelyDelete({ capitalizedType: 'Permission' }),
  };
};

export default usePermissionQuery;

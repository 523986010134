import React, { FC, useState } from 'react';
import Snackbar from '@fv-components/snackbar';
import Button from '@fv-components/button';
import TextField, { Input } from '@fv-components/text-field';
import LinearProgress from '@fv-components/linear-progress';
import { ITableState } from '@components/Table/Table';
import { IRole, IJurisdiction, IPermission } from '@models';
import useRoleQuery, { IRoleQueryHookProps } from '@hooks/Identity/Role/useRoleQuery';
import useJurisdictionQuery, { IJurisdictionQueryHookProps } from '@hooks/Jurisdiction/useJurisdictionQuery';
import usePermissionQuery, { IPermissionQueryHookProps } from '@hooks/Identity/Permissions/usePermissionQuery';
import { AltButton, ConfirmationDialog } from '@components';
import TabBar, { Tab } from '@fv-components/tab-bar';
import MaterialIcon from '@fv-components/material-icon';
import { discardChangesDialogService } from '@hooks/useConfirmationDialog';
import RoleTable from './RoleTable/RoleTable';
import JurisdictionTable from './JurisdictionTable/JurisdictionTable';
import PermissionsTable from './PermissionsTable/PermissionsTable';

interface IRoleAdminPageState {
  selectedRole?: IRole;
  jurisdictionRequestObject: IJurisdictionQueryHookProps;
  permissionRequestObject: IPermissionQueryHookProps;
  roleRequestObject: IRoleQueryHookProps;
  roleRoleRequestObject: IRoleQueryHookProps;
  isEditingMode: boolean;
  isDeleteConfirmationDialogVisible: boolean;
  isValid?: boolean;
  isDirty?: boolean;
  toBeDeleted?: IRole[];
}

const RoleAdminPage: FC = () => {
  const roleGraphQLQueryFields = [
    'id',
    'description',
    'jurisdictions { id description parent { id description }  }',
    'permissions { id permissionObject permissionAction  }',
    'roles { id description }',
  ];

  const jurisdictionGraphQLQueryFields = [
    'id',
    'description',
    'parent { description id }',
  ];

  const permissionGraphQLQueryFields = [
    'id',
    'permissionObject',
    'permissionAction',
  ];

  const roleRoleGraphQLQueryFields = [
    'id',
    'description',
  ];

  const [state, setState] = useState<IRoleAdminPageState>({
    jurisdictionRequestObject: {
      queryObject: { skip: 0, take: 100 },
      fields: jurisdictionGraphQLQueryFields,
      lazy: true,
      fetchPolicy: 'network-only',
    },
    permissionRequestObject: {
      queryObject: { skip: 0, take: 100 },
      fields: permissionGraphQLQueryFields,
      lazy: true,
      fetchPolicy: 'network-only',
    },
    isEditingMode: false,
    roleRequestObject: {
      queryObject: { skip: 0, take: 100 },
      fields: roleGraphQLQueryFields,
      fetchPolicy: 'network-only',
    },
    roleRoleRequestObject: {
      queryObject: { skip: 0, take: 100 },
      fields: roleRoleGraphQLQueryFields,
      fetchPolicy: 'network-only',
    },
    isDeleteConfirmationDialogVisible: false,
    isDirty: false,
    isValid: true,
  });

  const [activeTab, setActiveTab] = useState(0);

  const jurisdictionApolloWrapper = useJurisdictionQuery(state.jurisdictionRequestObject);
  const permissionApolloWrapper = usePermissionQuery(state.permissionRequestObject);
  const roleApolloWrapper = useRoleQuery(state.roleRequestObject);
  const roleRoleApolloWrapper = useRoleQuery(state.roleRoleRequestObject);

  const onDeleteConfirmationClose = (deleteConfirmed: boolean) => {
    if (deleteConfirmed) {
      if (state.toBeDeleted && state.toBeDeleted.length > 0) {
        roleApolloWrapper.delete.delete(
          ...state.toBeDeleted.filter((x: IRole) => x.id).map((x: IRole) => x.id as string),
        );
      }
    }
    setState({
      ...state,
      isDeleteConfirmationDialogVisible: false,
      toBeDeleted: undefined,
    });
  };

  const onFabClick = () => {
    jurisdictionApolloWrapper.load();
    permissionApolloWrapper.load();
    setState({
      ...state,
      selectedRole: {},
      isEditingMode: true,
    });
  };

  const onJurisdictionSelected = (selectedItem: IJurisdiction, isSelected: boolean) => {
    const selectedRole: IRole = {
      ...state.selectedRole,
      jurisdictions: isSelected ? [...state.selectedRole?.jurisdictions || [], selectedItem]
        : state.selectedRole?.jurisdictions?.filter((x: IJurisdiction) => x.id !== selectedItem.id),
    };
    setState({
      ...state,
      selectedRole,
      isDirty: true,
    });
  };

  const onRoleRoleSelected = (selectedItem: IRole, isSelected: boolean) => {
    const selectedRole: IRole = {
      ...state.selectedRole,
      roles: isSelected ? [...state.selectedRole?.roles || [], selectedItem]
        : state.selectedRole?.roles?.filter((x: IRole) => x.id !== selectedItem.id),
    };
    setState({
      ...state,
      selectedRole,
      isDirty: true,
    });
  };

  const onPermissionSelected = (selectedItem: IPermission, isSelected: boolean) => {
    const selectedRole: IRole = {
      ...state.selectedRole,
      permissions: isSelected ? [...state.selectedRole?.permissions || [], selectedItem]
        : state.selectedRole?.permissions?.filter((x: IPermission) => x.id !== selectedItem.id),
    };
    setState({
      ...state,
      selectedRole,
      isDirty: true,
    });
  };

  const jurisdictionTableStateChanged = (tableState: ITableState) => {
    const queryObject = {
      ...tableState,
      ...tableState.filter,
      filter: undefined,
    };
    setState({
      ...state,
      jurisdictionRequestObject: {
        queryObject,
        fields: jurisdictionGraphQLQueryFields,
      },
    });
  };

  const permissionTableStateChanged = (tableState: ITableState) => {
    const queryObject = {
      ...tableState,
      ...tableState.filter,
      filter: undefined,
    };
    setState({
      ...state,
      permissionRequestObject: {
        queryObject,
        fields: permissionGraphQLQueryFields,
      },
    });
  };

  const roleTableStateChanged = (tableState: ITableState) => {
    const queryObject = {
      ...tableState,
      ...tableState.filter,
      filter: undefined,
    };
    setState({
      ...state,
      roleRequestObject: {
        queryObject,
        fields: roleGraphQLQueryFields,
      },
    });
  };

  const roleRoleTableStateChanged = (tableState: ITableState) => {
    const queryObject = {
      ...tableState,
      ...tableState.filter,
      filter: undefined,
    };
    setState({
      ...state,
      roleRoleRequestObject: {
        queryObject,
        fields: roleRoleGraphQLQueryFields,
      },
    });
  };

  const onCancelClick = () => {
    if (state.isDirty) {
      discardChangesDialogService.showDialog(() => {
        setState({
          ...state,
          selectedRole: undefined,
          isEditingMode: false,
        });
      });
    } else {
      setState({
        ...state,
        isEditingMode: false,
        jurisdictionRequestObject: {
          ...state.jurisdictionRequestObject,
          queryObject: { skip: 0, take: 100 },
        },
        permissionRequestObject: {
          ...state.permissionRequestObject,
          queryObject: { skip: 0, take: 100 },
        },
        roleRoleRequestObject: {
          ...state.roleRoleRequestObject,
          queryObject: { skip: 0, take: 100 },
        },
      });
    }
  };

  const onSaveClicked = () => {
    if (state.selectedRole && state.selectedRole.description) {
      roleApolloWrapper.save.saveRole(
        state.selectedRole.description,
        state.selectedRole.id,
        state.selectedRole.jurisdictions,
        state.selectedRole.permissions,
        state.selectedRole.roles,
      )
        .then(() => setState((previous: IRoleAdminPageState) => ({
          ...previous,
          isEditingMode: false,
          isValid: true,
          isDirty: false,
          selectedRole: undefined,
          jurisdictionRequestObject: {
            ...previous.jurisdictionRequestObject,
            queryObject: { skip: 0, take: 100 },
          },
          permissionRequestObject: {
            ...previous.permissionRequestObject,
            queryObject: { skip: 0, take: 100 },
          },
          roleRoleRequestObject: {
            ...previous.roleRoleRequestObject,
            queryObject: { skip: 0, take: 100 },
          },
        })));
    }
  };

  const onRoleEdit = (role: IRole) => {
    jurisdictionApolloWrapper.load();
    permissionApolloWrapper.load();
    setActiveTab(0);
    setState({
      ...state,
      selectedRole: {
        // doing this so that changes made to the user
        // wont affect the properties of the user in the grid unless saved
        ...role,
      },
      isEditingMode: true,
      isValid: true,
      isDirty: false,
    });
  };

  const onDescriptionChange = (description: string) => {
    setState({
      ...state,
      selectedRole: {
        ...state.selectedRole,
        description,
      },
      isDirty: true,
    });
  };

  const onDelete = (toBeDeleted: IRole[]) => {
    setState({
      ...state,
      isDeleteConfirmationDialogVisible: true,
      toBeDeleted,
    });
  };

  const saveSuccessful = roleApolloWrapper.save.result.called
    && !roleApolloWrapper.save.result.loading
    && !roleApolloWrapper.save.result.error;

  const saveError = roleApolloWrapper.save.result.called
    && !roleApolloWrapper.save.result.loading
    && roleApolloWrapper.save.result.error;

  const deleteSuccessful = roleApolloWrapper.delete.result.called
    && !roleApolloWrapper.delete.result.loading
    && !roleApolloWrapper.delete.result.error;

  const deleteError = roleApolloWrapper.delete.result.called
    && !roleApolloWrapper.delete.result.loading
    && roleApolloWrapper.delete.result.error;

  return (
    <div className="flex flex-col gap-3">
      <div className="flex flex-row">
        <div className={state.isEditingMode ? 'hidden' : 'w-full'}>
          <RoleTable
            onTableStateChanged={roleTableStateChanged}
            onRoleEdit={onRoleEdit}
            hasMore={roleApolloWrapper.result.hasMore}
            roles={roleApolloWrapper.result.items}
            onFabClick={onFabClick}
            onDelete={onDelete}
            loading={roleApolloWrapper.loading || roleApolloWrapper.delete.result.loading}
          />
        </div>
        {state.isEditingMode
            && (
            <div className="flex flex-col flex-1">
              <div className="flex flex-col gap-3">
                <b className="text-2xl flex-1">{state.selectedRole?.id ? 'Edit Role' : 'New Role' }</b>
                <TextField label="Name" outlined>
                  <Input
                    value={state.selectedRole?.description}
                    onChange={(event: React.FormEvent<HTMLInputElement>) => {
                      onDescriptionChange(event.currentTarget.value);
                    }}
                  />
                </TextField>
                <TabBar
                  activeIndex={activeTab}
                  handleActiveIndexUpdate={setActiveTab}
                >
                  <Tab>
                    <span className="mdc-tab__text-label">Jurisdictions</span>
                  </Tab>
                  <Tab>
                    <span className="mdc-tab__text-label">Roles</span>
                  </Tab>
                  <Tab>
                    <span className="mdc-tab__text-label">Permissions</span>
                  </Tab>
                </TabBar>
                <div className="overflow-x-auto overflow-y-hidden">
                  <div className={activeTab === 0 ? '' : 'hidden'}>
                    <JurisdictionTable
                      onTableStateChanged={jurisdictionTableStateChanged}
                      hasMore={jurisdictionApolloWrapper.result.hasMore}
                      jurisdictions={jurisdictionApolloWrapper.result.items}
                      onJurisdictionSelect={onJurisdictionSelected}
                      selectedJurisdictions={state.selectedRole?.jurisdictions || []}
                      loading={jurisdictionApolloWrapper.loading}
                    />
                  </div>
                  <div className={activeTab === 1 ? '' : 'hidden'}>
                    <RoleTable
                      onRoleSelected={onRoleRoleSelected}
                      onTableStateChanged={roleRoleTableStateChanged}
                      loading={roleRoleApolloWrapper.loading}
                      hasMore={roleRoleApolloWrapper.result.hasMore}
                      roles={roleRoleApolloWrapper.result.items.filter(
                        (r: IRole) => r.id !== state.selectedRole?.id,
                      )}
                      selected={state.selectedRole?.roles}
                    />
                  </div>
                  <div className={activeTab === 2 ? '' : 'hidden'}>
                    <PermissionsTable
                      onTableStateChanged={permissionTableStateChanged}
                      hasMore={permissionApolloWrapper.result.hasMore}
                      permissions={permissionApolloWrapper.result.items}
                      onPermissionSelect={onPermissionSelected}
                      selectedPermissions={state.selectedRole?.permissions || []}
                      loading={permissionApolloWrapper.loading}
                    />
                  </div>
                </div>
              </div>
              {roleApolloWrapper.save.result.loading && <LinearProgress indeterminate />}
              <div className="flex flex-row justify-end gap-3">
                <Button
                  onClick={onSaveClicked}
                  disabled={!state.isDirty || !state.isValid}
                  role="button"
                  aria-label="Save Role"
                  raised
                  icon={<MaterialIcon icon="save" />}
                >
                  Save
                </Button>
                <AltButton
                  onClick={onCancelClick}
                  aria-label="Cancel Role Edit"
                  icon={<MaterialIcon icon="cancel" />}
                >
                  Cancel
                </AltButton>
              </div>
            </div>
            )}
      </div>
      <ConfirmationDialog
        isOpen={state.isDeleteConfirmationDialogVisible}
        text="Do you really want to delete these roles?"
        onClose={onDeleteConfirmationClose}
      />
      <Snackbar open={saveSuccessful} message="Save successful." />
      <Snackbar open={!!saveError} message="Error! Unable to save role." />
      <Snackbar open={deleteSuccessful} message="Delete successful." />
      <Snackbar open={!!deleteError} message="There was an error deleting the role." />
    </div>
  );
};

export default RoleAdminPage;

import {
  useMutation, ApolloError, gql,
} from '@apollo/client';

type InviteResendHook = () => IInviteResendHookResult;

export interface IInviteResendHookResult {
  result: {
    error?: ApolloError;
    loading: boolean;
    called: boolean;
  };
  resendInvite: (id: string) => void;
}

const useInviteResend: InviteResendHook = () => {
  const RESEND_GQL = gql`
    mutation InviteMutation($id: Guid!) {
      resendInvite(id: $id)
    }
  `;

  const [resendFunc, { error, loading, called }] = useMutation<{
    id: string;
  }>(RESEND_GQL);

  return {
    result: { error, loading, called },
    resendInvite: (id: string) => resendFunc({ variables: { id } }),
  };
};

export default useInviteResend;

import {
  useMutation, ApolloError, gql, DocumentNode,
} from '@apollo/client';
import IRole from '@models/Identity/Role/IRole';
import IUserQuery from '@models/Identity/User/IUserQuery';

type SetRolesHook = (
  props: ISetRolesHookProps
) => ISetRolesHookResult;

interface ISetRolesHookProps {
  queryObject?: IUserQuery;
  queryString?: DocumentNode;
}

export interface ISetRolesHookResult {
  result: {
    error?: ApolloError;
    loading: boolean;
    called: boolean;
  };
  setRoles: (
    id: string,
    roles: IRole[],
  ) => Promise<any>;
}

interface ISetRolesResult {
  setUserRoles: string;
}

const useUserSetRoles: SetRolesHook = () => {
  const SET_IS_ACTIVE = gql`
    mutation UserMutation($item: UserUpdateRolesInputType!) {
      setUserRoles(item: $item)
    }
  `;

  const [saveFunc, {
    error, loading, called, client,
  }] = useMutation<ISetRolesResult>(SET_IS_ACTIVE);

  return {
    result: {
      error, loading, called,
    },
    setRoles: (
      userId: string,
      roles: IRole[],
    ) => saveFunc({
      variables: {
        item: {
          userId,
          // need to do this to remove the unwanted _typename filed
          roles: roles.map((r: IRole) => ({ id: r.id, description: r.description })),
        },
      },
    }).then(() => client.cache.modify({ id: `UserType:${userId}`, fields: { roles: () => roles } })),
  };
};

export default useUserSetRoles;

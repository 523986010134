import React, { FC, useEffect, useState } from 'react';
import { I18n } from 'aws-amplify';
import {
  Authenticator, Greetings, SignIn, ConfirmSignIn,
} from 'aws-amplify-react';

import Button from '@fv-components/button';
import LinearProgress from '@fv-components/linear-progress';
import MaterialIcon from '@fv-components/material-icon';

import {
  getConfig,
  getTenantConfig,
  setTenantConfig,
  envShards,
  setUserTenant,
  getUserTenant,
  ITenantConfig,
} from '@src/config';
import FilevineLogoFullText from '@src/App/FilevineLogoFullText';
import { getSnackBarService } from '@hooks/useSnackBar';
import { filevineState, handleAmplifyConfigure } from '@libs/auth';
import useFilevineClient from '@hooks/Filevine/useFilevineClient';

const css = require('./CognitoLogin.module.scss');

const authTheme = {
  sectionHeader: { display: 'none' },
  inputLabel: { display: 'none' },
  input: {
    border: '1px solid #ccc',
    borderRadius: '6px',
    marginBottom: '20px',
    padding: '10px 16px',
    width: '100%',
    boxSizing: 'border-box',
    height: '46px',
    lineHeight: '1.33',
    fontSize: '15px',
    fontWeight: '300',
    color: '#555',
  },
  button: {
    padding: '10px 16px',
    fontSize: '18px',
    lineHeight: '1.33',
    borderRadius: '6px',
    width: '100%',
    color: '#fff',
    border: '1px solid #005776',
    outline: 'none !important',
    background: '#005776',
    marginBottom: '10px',
  },
  toast: {
    color: '#860A0E',
    textAlign: 'center',
    fontSize: '.85em',
    marginBottom: '10px',
  },
};

// Change input placeholders and error messages
export const authLabels = {
  en: {
    'Enter your username': 'Email',
    'Enter your password': 'Password',
    'Username cannot be empty': 'Email cannot be empty.',
    'Incorrect username or password': 'Invalid email or password.',
    'CUSTOM_AUTH is not enabled for the client.': 'Password cannot be empty.',
    Code: 'Enter Verification Code',
  },
};
I18n.setLanguage('en');
I18n.putVocabularies(authLabels);

const Login: FC = () => {
  // Auth state
  const [isSignedIn, setIsSignedIn] = useState(false);
  const { onFVUserConnectSuccess } = useFilevineClient();
  const handleAuthStateChange = async (state: string) => {
    if (state === 'signedIn') {
      setIsSignedIn(true);
      await onFVUserConnectSuccess();
    }
  };

  // Tenant Config
  interface IConfigState {
    tenantConfig?: ITenantConfig;
    tenantKey: string;
    loading: boolean;
  }

  const [loading, setLoading] = useState(false);
  const [state, setState] = useState<IConfigState>({
    tenantConfig: undefined,
    tenantKey: getUserTenant(),
    loading: false,
  });

  const appClientID = state.tenantConfig?.appClientID;

  const handleIDPClick = (idp: string) => {
    filevineState.SAMLAuthProvider = idp;
    const { hostUrl } = getConfig();
    const authorizationEndpoint = state?.tenantConfig?.authorizationEndpoint;
    const cognitoRedirectUrl = appClientID && authorizationEndpoint && hostUrl && `${authorizationEndpoint}?identity_provider=${idp}&redirect_uri=${hostUrl}/success&response_type=code&client_id=${appClientID}&scope=aws.cognito.signin.user.admin%20email%20openid%20profile`;

    if (cognitoRedirectUrl) {
      window.location.assign(cognitoRedirectUrl);
    }
  };

  // Attempt to get the tenant config when the users search for one
  useEffect(() => {
    const getConf = async () => {
      setLoading(true);

      const tc = await getTenantConfig();
      if (tc?.error) { getSnackBarService().showSnackBar(tc.error); }
      // Set config and Init Amplify if config returned
      if (tc?.appClientID && tc?.cognitoUserPoolID) {
        await handleAmplifyConfigure();
        setState((s: IConfigState) => ({
          ...s,
          tenantConfig: tc,
        }));
      } else {
        // if the tenant config is empty or invalid we need to clear it from LS
        setUserTenant('');
      }

      setLoading(false);
    };

    // User search by tenantKey but config has nto been set yet
    if (state.tenantKey && !appClientID) { getConf(); }
  }, [state.tenantKey, appClientID]);

  // Update tenant input field value
  const [tenantInputValue, setTenantInputValue] = useState('');
  const handleTenantInputValueChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTenantInputValue(e.target.value);
  };

  // Handle tenant picked either from shard button or input enter
  const handleTenantChange = (tenant?: string): void => {
    const tenantName = tenant?.toLowerCase() || tenantInputValue.toLowerCase();
    setUserTenant(tenantName);
    setState((s: IConfigState) => ({
      ...s,
      tenantKey: tenantName,
    }));
  };

  const handleTenantClear = () => {
    setState((s: IConfigState) => ({
      ...s,
      tenantConfig: undefined,
      tenantKey: '',
    }));
    filevineState.SAMLAuthProvider = '';
    setTenantConfig('');
    setTenantInputValue('');
    setUserTenant('');
  };

  const { filevineBaseDomain, filevineTenantUrl, filevineBaseUrl } = getConfig();

  return (
    <div className={css.container}>
      <div className={css.content}>
        <FilevineLogoFullText style={{ margin: '60px auto 40px' }} />
        {!state.tenantConfig
          ? (
            <div className={css.shardOptions}>
              <div>Select your Filevine Login</div>
              {envShards?.map(({ key, name }: { key: string; name: string; }) => (
                <Button
                  raised
                  key={key}
                  className={css.shardBtn}
                  onClick={() => handleTenantChange(key)}
                >
                  {name}
                  <MaterialIcon icon="chevron_right" />
                </Button>
              ))}
              <div className={css.customOptionTitle}>Use a team domain</div>
              <div className={css.customOption}>
                <div>
                  <input
                    id="customShard"
                    className={css.customInputTeam}
                    onChange={
                      (e: React.ChangeEvent<HTMLInputElement>) => handleTenantInputValueChange(e)
                    }
                    onKeyDown={(e: React.KeyboardEvent<HTMLSpanElement>) => {
                      if (e.key === 'Enter') {
                        handleTenantChange();
                      }
                    }}
                  />
                  <input
                    disabled
                    className={css.customInputDomain}
                    value={filevineBaseDomain}
                  />
                </div>
                <Button
                  raised
                  className={css.customBtn}
                  disabled={!tenantInputValue}
                  onClick={() => handleTenantChange(undefined)}
                >
                  <MaterialIcon icon="chevron_right" />
                </Button>
              </div>
              {loading && (
                <LinearProgress indeterminate className={css.connectLoading} />
              )}
            </div>
          )
          : (
            <>
              {!isSignedIn && (
              <Button
                className={css.clearShardBtn}
                onClick={handleTenantClear}
              >
                <MaterialIcon icon="chevron_left" className={css.clearShardBtnIcon} />
                {filevineTenantUrl}
              </Button>
              )}

              <Authenticator
                hideDefault
                theme={authTheme}
                onStateChange={handleAuthStateChange}
              >
                <SignIn />
                <Greetings />
                <ConfirmSignIn />
              </Authenticator>

              {!isSignedIn && (
                <>
                  <a
                    className={css.forgotLink}
                    href={`${filevineBaseUrl}/Account/ForgotPassword`}
                  >
                    Forgot password?
                  </a>

                  {state.tenantConfig.identityProviders?.map(({ name }) => (
                    <Button
                      outlined
                      className={css.IDPBtn}
                      onClick={() => handleIDPClick(name || '')}
                      key={name}
                    >
                      {` Sign in with ${name}`}
                    </Button>
                  ))}
                </>
              )}
            </>
          )}
      </div>
    </div>
  );
};

export default Login;

import React, { FC, memo } from 'react';
import MaterialIcon from '@fv-components/material-icon';
import { SortDirection } from './HeaderCell';

interface ISortIndicatorProps {
  sort?: SortDirection;
  isSortable?: boolean;
}
export const SortIndicator: FC<ISortIndicatorProps> = memo(
  (props: ISortIndicatorProps) => (
    <>
      {props.isSortable && props.sort === SortDirection.ASC && (
        <MaterialIcon icon="arrow_upward" className="text-blue" />
      )}
      {props.isSortable && props.sort === SortDirection.DESC && (
        <MaterialIcon icon="arrow_downward" className="text-blue" />
      )}
      {props.isSortable
        && props.sort === undefined
        && (
        <MaterialIcon icon="arrow_upward" className="hidden group-hover:block text-gray" />
        )}
    </>
  ),
);

export default SortIndicator;

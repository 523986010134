import React, { FC, useState } from 'react';
import PasswordField from '../PasswordField/PasswordField';

interface ISetPasswordState {
  password: string;
  confirmPassword: string;
  isConfirmed: boolean;
  isValid: boolean;
  passwordDirty: boolean;
  confirmPasswordDirty: boolean;
  showPassword: boolean;
  showConfirmPassword: boolean;
}

interface ISetPasswordProps {
  onPasswordChange: (password?: string, isFormValid?: boolean) => void;
  autoFocus?: boolean;
  isRequired: boolean;
}

const SetPassword: FC<ISetPasswordProps> = ({
  onPasswordChange,
  autoFocus,
  isRequired,
}: ISetPasswordProps) => {
  const [state, setState] = useState<ISetPasswordState>({
    password: '',
    confirmPassword: '',
    isConfirmed: false,
    isValid: false,
    passwordDirty: false,
    confirmPasswordDirty: false,
    showPassword: false,
    showConfirmPassword: false,
  });

  const onPasswordInputChange = (password: string, isValid: boolean) => {
    if (state.confirmPassword === password
      && isValid) {
      onPasswordChange(password, true);
    } else {
      onPasswordChange(password, false);
    }
    setState({
      ...state,
      password,
      passwordDirty: true,
      isConfirmed: isValid && state.confirmPasswordDirty && password === state.confirmPassword,
      isValid,
    });
  };

  const onConfirmPasswordInputChange = (confirmPassword: string) => {
    if (state.password === confirmPassword && state.isValid && state.passwordDirty) {
      onPasswordChange(state.password, true);
    } else {
      onPasswordChange(state.password, false);
    }
    setState({
      ...state,
      confirmPassword,
      confirmPasswordDirty: true,
      isConfirmed: state.isValid && state.passwordDirty && state.password === confirmPassword,
    });
  };

  return (
    <div className="flex flex-col gap-2">
      <PasswordField
        validate
        onPasswordChange={onPasswordInputChange}
        allowAutoFill={false}
        autoFocus={autoFocus}
        isRequired={isRequired}
      />
      <PasswordField
        onPasswordChange={onConfirmPasswordInputChange}
        label="Confirm Password"
        isValid={state.confirmPassword === state.password}
        validationMessage="Passwords must match"
        data-cy="validation-message"
        allowAutoFill={false}
        isRequired={isRequired && !!state.password}
      />
    </div>
  );
};

export default SetPassword;

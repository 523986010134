import React, { FC } from 'react';
import Select, { Option } from '@fv-components/select';
import { RoundingDirection } from '@models';

interface IRoundingDirectionDropDownProps {
  onChange: (type?: RoundingDirection) => void;
  value?: RoundingDirection;
  label?: string;
  disabled?: boolean;
  isRequired?: boolean;
}

const RoundingDirectionDropDown: FC<IRoundingDirectionDropDownProps> = (
  {
    onChange,
    value,
    label,
    disabled,
    isRequired,
  }: IRoundingDirectionDropDownProps,
) => {
  const onSelect = (evt: React.FormEvent<HTMLSelectElement>) => {
    if (evt.currentTarget.value === '') {
      onChange(undefined);
    } else {
      onChange(evt.currentTarget.value as RoundingDirection);
    }
  };

  return (
    <Select
      outlined
      label={label || 'Rounding Direction'}
      onChange={onSelect}
      value={value || ''}
      disabled={disabled}
      required={isRequired}
    >
      <Option key="NONE" value="" />
      <>
        {Object.keys(RoundingDirection).map((j) => (
          <Option key={j} value={j}>
            {j}
          </Option>
        ))}
      </>
    </Select>
  );
};
export default RoundingDirectionDropDown;

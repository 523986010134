import React, { FC } from 'react';
import {
  Switch, Route, Redirect,
} from 'react-router-dom';
import Grid, { Row, Cell } from '@fv-components/layout-grid';
import Card from '@fv-components/card';
import IPermissionMap from '@models/Identity/Role/Permission/IPermissionMap';
import JurisdictionAdminPage from './JurisdictionAdminPage/JurisdictionAdminPage';
import DeadlineAdminPage from './DeadlineAdminPage/DeadlineAdminPage';
import FilingMethodAdminPage from './FilingMethodAdminPage/FilingMethodAdminPage';
import RoleAdminPage from './RoleAdminPage/RoleAdminPage';
import UserAdminPage from './UserAdminPage/UserAdminPage';
import KeywordAdminPage from './KeywordAdminPage/KeywordAdminPage';
import HolidayAdminPage from './HolidayAdminPage/HolidayAdminPage';
import PermissionAdminPage from './PermissionAdminPage/PermissionAdminPage';
import OrganizationAdminPage from './OrganizationAdminPage/OrganizationAdminPage';
import InviteAdminPage from './InviteAdminPage/InviteAdminPage';

interface IAdminProps {
  permissions: IPermissionMap;
}

const Admin: FC<IAdminProps> = ({ permissions }) => (
  <Grid>
    <Row>
      <Cell desktopColumns={1} />
      <Cell desktopColumns={10} phoneColumns={4} tabletColumns={8}>
        <Card className="p-6">
          <Switch>
            {(permissions.Jurisdiction?.Write
              || permissions.Jurisdiction?.Delete)
            && (
            <Route path="/admin/jurisdiction">
              <JurisdictionAdminPage />
            </Route>
            )}
            {(permissions.Deadline?.Write
              || permissions.Deadline?.Delete)
              && (
              <Route path="/admin/deadlines">
                <DeadlineAdminPage />
              </Route>
              )}
            {(permissions.FilingMethod?.Write
              || permissions.FilingMethod?.Delete)
              && (
              <Route path="/admin/filing-method">
                <FilingMethodAdminPage />
              </Route>
              )}
            {(permissions.Role?.Write
              || permissions.Role?.Delete)
              && (
              <Route path="/admin/role">
                <RoleAdminPage />
              </Route>
              )}
            {(permissions.User?.Write
              || permissions.User?.Delete)
              && (
              <Route path="/admin/user">
                <UserAdminPage />
              </Route>
              )}
            {(permissions.Category?.Write
              || permissions.Category?.Delete)
              && (
              <Route path="/admin/keywords">
                <KeywordAdminPage />
              </Route>
              )}
            {(permissions.Holiday?.Write
              || permissions.Holiday?.Delete)
              && (
              <Route path="/admin/holidays">
                <HolidayAdminPage />
              </Route>
              )}
            {(permissions.Permission?.Write
              || permissions.Permission?.Delete)
              && (
              <Route path="/admin/permissions">
                <PermissionAdminPage />
              </Route>
              )}
            {(permissions.Organization?.Write
              || permissions.Organization?.Delete)
              && (
              <Route path="/admin/organization">
                <OrganizationAdminPage />
              </Route>
              )}
            {(permissions.Invite?.Write
              || permissions.Invite?.Delete)
              && (
              <Route path="/admin/invites">
                <InviteAdminPage />
              </Route>
              )}
            <Route>
              <Redirect to="/" />
            </Route>
          </Switch>
        </Card>
      </Cell>
      <Cell desktopColumns={1} />
    </Row>
  </Grid>
);

export default Admin;

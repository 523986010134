import React, { FC } from 'react';
import { IJurisdiction } from '@models';
import { JurisdictionAutoComplete } from '@components';
import { useHistory } from 'react-router-dom';
import SearchBar from './SearchBar/SearchBar';

interface ITriggerSearchPageProps {
  jurisdiction?: IJurisdiction
}

const TriggerSearchPage: FC<ITriggerSearchPageProps> = ({ jurisdiction }) => {
  const history = useHistory<{ jurisdiction?: IJurisdiction, activeTab?: number }>();
  const onJurisdictionSelect = (j?: IJurisdiction) => history.push('/admin/deadlines', {
    jurisdiction: j,
    activeTab: history.location.state?.activeTab,
  });

  return (
    <>
      <JurisdictionAutoComplete value={jurisdiction} onSelect={onJurisdictionSelect} />
      <b className="text-2xl">Deadlines</b>
      {jurisdiction && (
      <SearchBar
        jurisdiction={jurisdiction}
        isVisible
      />
      )}

    </>
  );
};

export default TriggerSearchPage;

import {
  ApolloError, FetchPolicy,
} from '@apollo/client';
import { ITriggerServiceMethodQuery, IServiceMethod } from '@models';
import { useTimelyQuery } from '..';

type Hook = (
  props: ITriggerServiceMethodQueryHookProps
) => IResult;

export interface ITriggerServiceMethodQueryHookProps {
  queryObject?: ITriggerServiceMethodQuery;
  fields: string[];
  lazy?: boolean;
  fetchPolicy?: FetchPolicy;
}

interface IResult {
  loading: boolean;
  error?: ApolloError;
  result?: { hasMore: boolean, items: IServiceMethod[] };
  refetch: VoidFunction | undefined;
  called: boolean;
  load: VoidFunction;
}

const useTriggerServiceMethodQuery: Hook = (
  {
    queryObject, fields, lazy, fetchPolicy,
  }: ITriggerServiceMethodQueryHookProps,
) => {
  const {
    called, loading, load, error, result, refetch,
  } = useTimelyQuery<IServiceMethod>({
    lazy,
    fields,
    variables: { queryObject },
    collection: 'triggerServiceMethods',
    queryInputType: 'TriggerServiceMethodQueryInputType',
    fetchPolicy,
  });

  return {
    called,
    loading,
    error,
    result,
    load,
    refetch,
  };
};

export default useTriggerServiceMethodQuery;

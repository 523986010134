import React, { FC, useEffect } from 'react';
import { useTriggerQuery } from '@hooks';
import { MultiSelectDropDown } from '@components';
import { ITrigger } from '@models';

interface IProps {
  parentJurisdictionAddress?: string;
  blockedIds?: string[];
  onChange: (blocked: string[]) => void;
}

const TriggerInheritanceDropDown: FC<IProps> = ({
  parentJurisdictionAddress,
  blockedIds,
  onChange,
}) => {
  const { result: { items }, loading, load } = useTriggerQuery({
    queryObject: { skip: 0, take: 500, jurisdictionIds: parentJurisdictionAddress?.split('.') || [] },
    fields: ['id', 'description', 'jurisdiction { description }'],
    lazy: true,
  });

  const onMultiSelectChange = (selected: ITrigger[]) => onChange(items.filter(
    (i: ITrigger) => !selected.find((s: ITrigger) => s.id === i.id),
  ).map((b: ITrigger) => b.id || ''));

  useEffect(() => {
    if (parentJurisdictionAddress) {
      load();
    }
  }, [parentJurisdictionAddress, load]);

  return parentJurisdictionAddress ? (
    <MultiSelectDropDown
      items={items}
      values={items.filter((t: ITrigger) => !blockedIds?.includes(t.id || ''))}
      label="Inherited Triggers"
      loading={loading}
      onChange={onMultiSelectChange}
      display="description"
      caption={(item: ITrigger) => item.jurisdiction?.description || ''}
    />
  ) : <></>;
};

export default TriggerInheritanceDropDown;

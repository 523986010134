import React, { useRef } from 'react';
import MaterialIcon from '@fv-components/material-icon';
import Button from '@fv-components/button';

const css = require('./Pill.module.scss');

export interface IPillProps<T> {
  text: string;
  onClick?: (item: T) => void;
  item: any;
  selected?: boolean;
  notSelectable?: boolean;
  removable?: boolean;
  onRemove?: (item: T) => void;
  disabled?: boolean;
}

const Pill: <T>(
  props: IPillProps<T>,
) => React.ReactElement = <T extends { [key: string]: any }>({
  text,
  onClick,
  item,
  selected,
  notSelectable,
  removable,
  onRemove,
  disabled,
}: IPillProps<T>) => {
  const closeIconRef = useRef<MaterialIcon>(null);

  const onKeyDown = (event: React.KeyboardEvent) => {
    if (removable && onRemove) {
      switch (event.key) {
        case ' ':
        case 'Enter':
          onRemove(item);
          break;
        default:
          break;
      }
    } else if (!notSelectable) {
      switch (event.key) {
        case ' ':
        case 'Enter':
          event.stopPropagation();
          break;
        default:
          break;
      }
    }
  };

  return (
    <Button
      className={[css.serviceButton, disabled ? css.disabled : ''].join(' ')}
      trailingIcon={removable ? (
        <MaterialIcon
          icon="close"
          role="button"
          aria-label={`Remove ${text} Pill`}
          ref={closeIconRef}
          onClick={(event: React.MouseEvent<HTMLElement, MouseEvent>) => {
            event.stopPropagation();
            event.preventDefault();
            if (onRemove && !disabled) {
              onRemove(item);
            }
          }}
        />
      ) : undefined}
      onClick={(event: React.MouseEvent<HTMLElement, MouseEvent>) => {
        event.stopPropagation();
        event.preventDefault();
        if (onClick && !disabled) {
          onClick(item);
        }
      }}
      icon={selected && !notSelectable ? <MaterialIcon icon="check" /> : undefined}
      role="checkbox"
      aria-label={`Toggle ${text} Pill Selection`}
      aria-checked={selected && !notSelectable ? 'true' : 'false'}
      onKeyDown={onKeyDown}
    >
      {text}
    </Button>
  );
};

export default Pill;

import { gql } from '@apollo/client';
import IFilevineProjectProjectQueryResult from '@models/Filevine/Projects/IFilevineProjectQueryResult';
import IFilevineProject from '@models/Filevine/Projects/IFilevineProject';
import useTimelyQuery from '@hooks/useTimelyQuery';
import { useState } from 'react';
import { filevineState } from '@libs/auth';
import { snapshot } from 'valtio';
import { subscribeKey } from 'valtio/utils';
import { FilevineApolloClient } from './useFilevineClient';

interface IProjectQuery {
  projectId?: string;
  searchTerm?: string;
  skip: number;
  take: number;
}

export interface IProjectQueryHookProps {
  queryObject: IProjectQuery;
  fields: string[];
  lazy?: boolean;
}

const getGql = (fields: string[]) => gql`
    query projectsQuery {
      projects(offset: $offset, limit: $limit, projectId: $projectId, searchTerm: $searchTerm, excludeArchived: $excludeArchived)
        @rest(type: "Projects", path: "/projects?{args}") {
          ${fields.join(', ')}
      }
    }
  `;

const useFilevineQueryProjects = ({
  queryObject: {
    projectId,
    searchTerm,
    skip: offset,
    take: limit,
  },
  fields,
  lazy,
}: IProjectQueryHookProps): IFilevineProjectProjectQueryResult => {
  const [client, setClient] = useState<FilevineApolloClient>(
    snapshot(filevineState).client as FilevineApolloClient,
  );

  subscribeKey(filevineState, 'client', (value?: any) => {
    setClient(value);
  });

  const {
    result: { items, hasMore }, error, loading, called, load,
  } = useTimelyQuery<IFilevineProject>({
    queryString: getGql(fields),
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'cache-first',
    client,
    variables: {
      searchTerm: projectId ? undefined : searchTerm,
      offset,
      limit,
      projectId,
      excludeArchived: true,
    },
    collection: 'projects',
    lazy,
  });

  return {
    error: !!error,
    loading,
    items,
    hasMore,
    called,
    load: () => !!client && load(),
  };
};

export default useFilevineQueryProjects;

import { useMutation, ApolloError, gql } from '@apollo/client';

type UseRegisterHook = () => IUseRegisterHookResult;

export interface IUseRegisterHookResult {
  result: {
    data?: { register: boolean } | null;
    error?: ApolloError;
    loading: boolean;
    called: boolean;
  };
  register: (
     firstName: string,
      lastName: string,
      inviteId: string,
      password: string,
  ) => void;
}

const useRegister: UseRegisterHook = () => {
  const REGISTER = gql`
    mutation ($registration: RegistrationInputType!) {
      register(item: $registration)
    }
  `;

  const [saveFunc, {
    data, error, loading, called,
  }] = useMutation<{register: boolean}>(
    REGISTER,
  );

  return {
    result: {
      data, error, loading, called,
    },
    register: (
      firstName: string,
      lastName: string,
      inviteId: string,
      password: string,
    ) => saveFunc({
      variables: {
        registration: {
          firstName,
          lastName,
          inviteId,
          password,
        },
      },
    }),
  };
};

export default useRegister;

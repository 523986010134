/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { FC, useState } from 'react';
import Button from '@fv-components/button';
import LinearProgress from '@fv-components/linear-progress';
import MaterialIcon from '@fv-components/material-icon';
import IPermission, { PermissionAction, PermissionObject } from '@models/Identity/Role/Permission/IPermission';
import { IPermissionSaveHookResult } from '@hooks/Identity/Permissions/usePermissionSave';
import { AltButton } from '@components';
import { discardChangesDialogService } from '@hooks/useConfirmationDialog';
import PermissionActionDropDown from './PermissionActionDropDown/PermissionActionDropDown';
import PermissionObjectDropDown from './PermissionObjectDropDown/PermissionObjectDropDown';

interface IPermissionAdminFormProps {
  permission?: IPermission;
  onFormClose: VoidFunction;
  save: IPermissionSaveHookResult;
}

interface IPermissionAdminFormState {
  isDirty: boolean;
  permission?: IPermission;
}

const PermissionAdminForm: FC<IPermissionAdminFormProps> = (
  { permission, save, onFormClose }: IPermissionAdminFormProps,
) => {
  const [state, setState] = useState<IPermissionAdminFormState>({
    isDirty: false,
    permission,
  });

  const isValid = !!state?.permission?.permissionAction
    && !!state.permission.permissionObject;

  const onPermissionObjectChange = (permissionObject?: PermissionObject) => {
    setState({
      ...state,
      isDirty: true,
      permission: {
        ...state.permission,
        permissionObject,
      },
    });
  };

  const onPermissionActionChange = (permissionAction?: PermissionAction) => {
    setState({
      ...state,
      isDirty: true,
      permission: {
        ...state.permission,
        permissionAction,
      },
    });
  };

  const onSaveClick = () => {
    if (state.permission && isValid) {
      if (state.permission
        && state.permission.permissionObject
        && state.permission.permissionAction
      ) {
        save.savePermission(
          state.permission.permissionObject,
          state.permission.permissionAction,
          state.permission.id,
        ).then(() => {
          setState({
            ...state,
            permission: undefined,
          });
          onFormClose();
        });
      }
    } else {
      // TODO do something here to highlight that some fields are required
    }
  };

  const onCancelClick = () => {
    if (state.isDirty) {
      discardChangesDialogService.showDialog(() => {
        setState({
          ...state,
          permission: undefined,
        });
        onFormClose();
      });
    } else {
      onFormClose();
    }
  };

  return (
    <div className="flex flex-col gap-3">
      <b className="text-2xl flex-1">{state.permission?.id ? 'Edit Permission' : 'New Permission' }</b>
      <div className="flex flex-row">
        <PermissionObjectDropDown
          onChange={onPermissionObjectChange}
          value={state.permission?.permissionObject}
        />
      </div>
      <div className="flex flex-row">
        <PermissionActionDropDown
          onChange={onPermissionActionChange}
          value={state.permission?.permissionAction}
        />
      </div>
      { save.result.loading && <LinearProgress indeterminate />}
      <div className="flex flex-row justify-end gap-3">
        <Button
          onClick={onSaveClick}
          disabled={!(state.isDirty && isValid)}
          role="button"
          aria-label="Save Permission"
          icon={<MaterialIcon icon="save" />}
          raised
        >
          Save
        </Button>
        <AltButton
          onClick={onCancelClick}
          aria-label="Cancel Permission Edit"
          icon={<MaterialIcon icon="cancel" />}
        >
          Cancel
        </AltButton>
      </div>
    </div>
  );
};

export default PermissionAdminForm;

import React, { FC, useState, useEffect } from 'react';
import { useTriggerServiceMethodQuery } from '@hooks';
import { AutoComplete } from '@components';
import { IServiceMethod } from '@models';

interface ITriggerServiceMethodDropDownProps {
  onSelect: (serviceMethodId: string) => void;
  triggerId: string;
  jurisdictionId: string;
  value?: string;
  isDisabled?: boolean;
  onItemsChanged?: (hasServiceMethods: boolean) => void;
}

const TriggerServiceMethodDropDown: FC<ITriggerServiceMethodDropDownProps> = (
  {
    onSelect,
    triggerId,
    jurisdictionId,
    value,
    isDisabled,
    onItemsChanged,
  }: ITriggerServiceMethodDropDownProps,
) => {
  const [take, setTake] = useState(50);
  const [filter, setFilter] = useState<string>();
  const { loading, result } = useTriggerServiceMethodQuery({
    queryObject: {
      triggerId,
      jurisdictionId,
      skip: 0,
      take,
    },
    fields: ['id', 'description'],
  });

  useEffect(() => {
    if (onItemsChanged) {
      onItemsChanged(!!result?.items.length);
    }
  }, [result, onItemsChanged]);

  const filteredItems = filter
    ? result?.items?.filter(
      (sm: IServiceMethod) => sm.description?.toLowerCase().includes(filter.toLowerCase()))
    : result?.items;

  return (result?.items?.length
    ? (
      <AutoComplete<IServiceMethod>
        label="Service Method"
        isDisabled={isDisabled}
        descriptionField="description"
        onSelect={(sm?: IServiceMethod) => onSelect(sm?.id || '')}
        hasMore={result?.hasMore || false}
        loading={loading}
        cypressLabel="trigger-service-method"
        onFilterChange={setFilter}
        value={result?.items?.find((sm: IServiceMethod) => sm.id === value)}
        onLoadMore={() => setTake(take + 50)}
        items={filteredItems}
      />
    ) : <> </>
  );
};

export default TriggerServiceMethodDropDown;

import React, { FC, useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { ITableMenuOption } from '@components/Table/Menu/TableMenu';
import { ConfirmationDialog, Table } from '@components';
import useJurisdictionQuery, { IJurisdictionQueryHookProps } from '@hooks/Jurisdiction/useJurisdictionQuery';
import { ITableState } from '@components/Table/Table';
import { IRowMenuOption } from '@components/Table/Menu/RowMenu';
import { IJurisdiction, IJurisdictionQuery } from '@models';
import { useRouterState } from '@hooks';
import { getSnackBarService } from '@hooks/useSnackBar';

interface IProps {
  parentId?: string;
}

const graphQLQueryFields = [
  'id',
  'description',
  'parentId',
  'parent { address description id hasChildren }',
  'address',
  'notes',
  'isRoundBeforeCalculation',
  'isRoundedBetweenServiceAndDeadlineCalculation',
  'isServiceCalculatedBeforeDeadline',
];

const defaultQueryObject: IJurisdictionQueryHookProps = {
  queryObject: { skip: 0, take: 100 },
  fields: graphQLQueryFields,
  fetchPolicy: 'network-only',
};

const JurisdictionTable: FC<IProps> = ({ parentId }) => {
  const history = useHistory();
  const snackBar = getSnackBarService();
  const { queryObject: qo, tableState: ts } = useRouterState<{
    queryObject: IJurisdictionQuery, tableState: ITableState
  }>();
  const [tableState, setTableState] = useState<ITableState | undefined>(ts);
  const [isConfirmationDialogVisible, setIsConfirmationDialogVisible] = useState(false);
  const [selectedJurisdictions, setSelectedJurisdictions] = useState<IJurisdiction[]>([]);
  const [
    requestObject,
    setRequestObject,
  ] = useState<IJurisdictionQueryHookProps>({
    ...defaultQueryObject,
    queryObject: qo || defaultQueryObject.queryObject,
  });

  const {
    loading,
    result: { items, hasMore },
    delete: { delete: deleteJurisdiction, result: { loading: isDeleting } },
  } = useJurisdictionQuery(requestObject);
  const options: ITableMenuOption[] = [
    {
      key: 'deleteSelected',
      text: 'Delete Selected',
      onClick: (selectedItems?: IJurisdiction[]) => {
        if (selectedItems) {
          setSelectedJurisdictions(selectedItems);
          setIsConfirmationDialogVisible(true);
        }
      },
    },
  ];

  useEffect(() => {
    setRequestObject((current) => ({
      ...current,
      queryObject: {
        ...current.queryObject,
        parentId,
      },
    }));
  }, [parentId]);

  const rowOptions: IRowMenuOption[] = [
    {
      key: 'edit',
      text: 'Edit',
      onClick: (item: IJurisdiction) => {
        history.push(`/admin/jurisdiction/${item.id}/edit`, { queryObject: requestObject.queryObject, tableState });
      },
    },
  ];

  const onDeleteConfirmationClose = async (deleteConfirmed: boolean) => {
    if (deleteConfirmed) {
      if (selectedJurisdictions && selectedJurisdictions.length > 0) {
        try {
          await deleteJurisdiction(
            ...selectedJurisdictions.filter((x: IJurisdiction) => x.id)
              .map((x: IJurisdiction) => x.id as string),
          );
          snackBar.showSnackBar('Delete successful.');
        } catch {
          snackBar.showSnackBar('There was an error deleting the jurisdictions.');
        }
      }
      setSelectedJurisdictions([]);
    }
    setIsConfirmationDialogVisible(false);
  };

  const onFabClick = () => {
    history.push('/admin/jurisdiction/new', { queryObject: requestObject.queryObject, tableState });
  };

  const tableStateChanged = (state: ITableState) => {
    setTableState(state);
    const queryObject = {
      ...state,
      ...state.filter,
      filter: undefined,
      parentId,
    };
    setRequestObject({
      queryObject,
      fields: graphQLQueryFields,
    });
  };

  return (
    <div>
      <Table
        dataSource={items}
        isMultiSelect
        tableMenuOptions={options}
        rowMenuOptions={rowOptions}
        minHeight={400}
        maxHeight={700}
        isFabVisible
        onFabClick={onFabClick}
        fabText="Add Jurisdiction"
        fabIcon="add"
        fabAriaLabel="Add New Jurisdiction"
        onStateChange={tableStateChanged}
        hasMore={hasMore}
        takeOptions={[100, 500]}
        isPagerVisible
        loading={loading || isDeleting}
        cypressId="jurisdictions"
        title="Jurisdictions"
        tableState={tableState}
      >
        {[
          {
            key: 'description',
            header: 'Description',
            field: 'description',
            isFilterable: true,
            isSortable: true,
          },
          {
            key: 'parent.description',
            header: 'Parent',
            field: 'parent.description',
            sortFilterField: 'parent',
            isFilterable: true,
            isSortable: true,
          },
        ]}
      </Table>
      <ConfirmationDialog
        isOpen={isConfirmationDialogVisible}
        text="Do you really want to delete these jurisdictions?"
        onClose={onDeleteConfirmationClose}
      />
    </div>
  );
};

export default JurisdictionTable;

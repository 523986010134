import React, { FC } from 'react';
import MaterialIcon from '@fv-components/material-icon';
import { AltButton } from '@components';

export interface IFabProps {
  fabIcon: string;
  fabClickHandler: VoidFunction;
}

interface IProps {
  isFabVisible?: boolean;
  fabIcon?: string;
  fabText?: string;
  fabAriaLabel?: string;
  onFabClick?: VoidFunction;
  disabled?: boolean;
  cypressId?: string;
  title?: JSX.Element | string;
}

const TitleBar: FC<IProps> = ({
  isFabVisible,
  fabIcon,
  fabText,
  fabAriaLabel,
  onFabClick,
  disabled,
  cypressId,
  title,
}) => (
  (title || isFabVisible) ? (
    <div className="flex flex-row justify-between items-center m-1">
      {(typeof title === 'string') ? <b className="text-2xl flex-1">{title}</b> : title}
      {isFabVisible ? (
        <AltButton
          onClick={onFabClick}
          aria-label={fabAriaLabel}
          disabled={disabled}
          data-cy={`footer-fab-${cypressId || ''}`}
          icon={fabIcon ? <MaterialIcon icon={fabIcon} /> : undefined}
        >
          {fabText}
        </AltButton>
      ) : null}
    </div>
  ) : <></>
);

export default TitleBar;

import React, { FC, ReactElement } from 'react';

const css = require('./CalculatorHeader.module.scss');

interface ICalculatorHeaderProps {
  leftControl?: ReactElement;
  rightControl?: ReactElement;
  title?: string;
  subHeaderElement?: ReactElement;
  matterDescription?: string;
}

const CalculatorHeader: FC<ICalculatorHeaderProps> = (
  {
    leftControl,
    rightControl,
    title,
    subHeaderElement,
    matterDescription,
  }: ICalculatorHeaderProps,
) => (
  <>
    <div className={css.container}>
      <div style={{ maxWidth: 1000 }} className="flex items-center sm:justify-between sm:flex-row flex-col sm:my-0 my-3 w-full">
        <div className="flex-1 flex justify-start">
          {leftControl}
        </div>
        <div
          className="flex-1 justify-center flex font-bold text-3xl m-2.5 whitespace-nowrap"
          data-cy="calc-header"
        >
          {title}
        </div>
        <div className="flex-1 flex justify-end">
          {rightControl}
        </div>
      </div>
      {subHeaderElement}
    </div>
    <div className="text-2xl screen:hidden">{matterDescription}</div>
  </>
);

export default CalculatorHeader;
